import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Radio, Select, Spin } from 'antd';
import type { SelectProps } from 'antd';
import debounce from 'lodash/debounce';
import { API_URL } from '../../env';

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string, status: number) => Promise<ValueType[]>;
  debounceTimeout?: number;
  disabled?: boolean;
}

function DebounceSelect<
  ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
>({ fetchOptions, debounceTimeout = 800, disabled, ...props }: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);
  const [status, setStatus] = useState(0)
  // const [selectAutoFocus, setSelectAutoFocus] = useState(false)

  const onStatusChange = (e: any) => {
    e.stopPropagation()
    setStatus(e.target.value)
    // setSelectAutoFocus(true)
    debounceFetcher('', e.target.value)
  }

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string, status: number) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value, status).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      className="rs-select"
      popupClassName="rs-select-dropdown"
      {...props}
      options={options}
      disabled={disabled}
      autoFocus={false}
      onFocus={() => debounceFetcher('', status)}
      onSearch={(string) => debounceFetcher(string, status)}
      dropdownRender={(menu) => (
        <>
          <Radio.Group
            options={[
              { value: 0, label: 'Nhóm riêng tư' },
              { value: 1, label: 'Nhóm được chia sẻ' },
            ]}
            defaultValue={status}
            optionType="button"
            className="rs-radio-group rs-radio-group-button"
            onChange={(e) => onStatusChange(e)}
          />
          {menu}
        </>
      )}
    />
  );
}

// Usage of DebounceSelect
export interface UserValue {
  label: string;
  value: number;
}

async function fetchList(groupName: string, status?: number): Promise<UserValue[]> {
  // const url = `groupCustomer/listGroup?search=${groupName}&status=${status}`
  const url = `apiChild/listGroupCustomer?search=${groupName}&type=${status}`

  // if (!Number(status)) {
  //   url = `groupCustomer/listGroup?search=${groupName}`
  // } else {
  //   url = `groupCustomer/listGroup?search=${groupName}&status=${status}`
  // }

  return fetch(`${API_URL}${url}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
    .then((response) => response.json())
    .then((body) =>
      body.data.map(
        (val: any) => ({
          label: val.title,
          value: val.id,
        }),
      ),
    );
}

interface Props {
  initValue?: any,
  disabled?: boolean
  placeholder?: string,
  onChange?: (value: UserValue[]) => void;
  onChangeValue?: (value: UserValue[]) => void;
}

const SelectCustomerGroupWithStatus: React.FC<Props> = ({
  onChange, initValue = [], placeholder = 'Tìm tên nhóm', disabled, onChangeValue
}) => {
  const [value, setValue] = useState<UserValue[]>([]);

  // INITIAL VALUE
  useEffect(() => {
    if (initValue.length > 0) {
      setValue(initValue)
    }
  }, [initValue])

  useEffect(() => {
    if (onChange) {
      const temp = [] as any
      value.map((item) => {
        temp.push({
          value: item.value,
          label: item.label,
        })
        return true
      });
      onChange(temp);
    }
  }, [value]);

  return (
    <DebounceSelect
      mode="multiple"
      value={value}
      placeholder={placeholder}
      fetchOptions={fetchList}
      onChange={(newValue) => {
        setValue(newValue as UserValue[]);
      }}
      style={{ width: '100%' }}
      disabled={disabled}
    />
  );
};

export default SelectCustomerGroupWithStatus;
