import React, { ChangeEvent, useEffect, useState } from 'react';
import Layout from '../../Layout';
import './AddRank.scss';
import { Form, Table, TableProps, TableColumnsType, Input, Breadcrumb, message, Button } from 'antd';
import { CrownOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { SearchProps } from 'antd/es/input';
import * as BenefitApi from '../../api/rank/benefitApi';
import * as RankApi from '../../api/rank/rankApi';
import useToast from '../../hook/useToast';


const { Search } = Input;

const MarketingCampaigns: React.FC = () => {
  const [valueNumber, setValueNumber] = useState<string>('');
  const [dataBenefit, setDataBenefit] = useState<BenefitApi.DataTypeBenefit[]>([])
  const [listBenefits, setListBenefits] = useState<number[]>([])
  const pushToast = useToast();
  const navigator = useNavigate()
  const columns: TableColumnsType<BenefitApi.DataTypeBenefit> = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      sorter: {
        compare: (a, b) => a.stt - b.stt,
        multiple: 1,
      },
    },
    {
      title: 'Tên quyền lợi',
      dataIndex: 'title',
      render: (text) => (
        <div className="t-name-ql">
          <div className="icon-svg"><CrownOutlined /></div>
          <span className="neutral-gray-500">{text}</span>
        </div>
      ),
    },
    {
      title: 'Mô tả quyền lợi dễ hiểu',
      dataIndex: 'description',
    },
  ];
  const getListBenefit = async (search: string = '') => {
    const result = await BenefitApi.getListBenefit(undefined, undefined, search, undefined, 1);
    if (result?.status) {
      setDataBenefit(convertData(result.data))
    } else {
      pushToast(result.message, 'error', 2000)
    }
  }

  const convertData = (data: BenefitApi.IBenefit[]) => {
    var stt = 1;
    const convertData = data.map((item) => ({
      key: item.id,
      stt: stt++,
      title: item.title,
      description: item.description,
      status: item.status
    }))
    return convertData
  }


  useEffect(() => {
    document.title = 'THACO AUTO CRM';
    getListBenefit()
  }, []);

  const onFinish = async (values: any) => {
    const result = await RankApi.addRank(values.name, values.price, uploadedFile, listBenefits);
    if (result?.status) {
      pushToast("Thêm mới thành công!", "success", 2000)
      navigator(-1)
      // getListRegions()
    } else {
      pushToast(result.message, "error", 2000)

    }
  };

  const onFinishFailed = (errorInfo: any) => {
  };

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;

    if (file) {
      const isLt1M = file.size / 1024 / 1024 < 1; // Kiểm tra xem kích thước tập tin có nhỏ hơn 1MB không
      if (!isLt1M) {
        // message.error('Ảnh phải nhỏ hơn 1MB!');
        pushToast('Ảnh phải nhỏ hơn 1MB!', 'error', 2000)

      } else {
        setUploadedFile(file);
      }
    }
  };

  const handleUploadClick = (event: React.MouseEvent) => {
    event.preventDefault();
    const inputElement = document.querySelector('input[type="file"]') as HTMLInputElement | null;
    if (inputElement) {
      inputElement.click();
    }
  };

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    getListBenefit(value)
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      const numberArray: number[] = selectedRowKeys.map(str => Number(str))
      setListBenefits(numberArray)
    },
  };


  const formatNumber = (num: string) => {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/\./g, '');
    if (!isNaN(Number(rawValue)) || rawValue === '') {
      setValueNumber(Number(rawValue).toLocaleString('en-US'));
    }
  };
  return (
    <div className="marketing-campaigns marketing-campaigns-detail add-rank-page">
      <Layout>
        <Breadcrumb
          items={[
            {
              title: 'THACO AUTO CRM',
            },
            {
              title: <a href="/">Cấu hình</a>,
            },
            {
              title: <a href="/">Quản lý phân hạng</a>,
            },
            {
              title: 'Tạo mới hạng thành viên',
            },
          ]}
        />

        <div className='title-page title-btn-back ctrl-title-vouche'>
          <Link to="/member-card/class-list" className="icon-svg">
            <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.6564 7.80321H4.04305L10.3955 2.37463C10.4971 2.28713 10.4354 2.12463 10.3012 2.12463H8.69582C8.62508 2.12463 8.55796 2.14963 8.50536 2.19428L1.65045 8.04963C1.58767 8.10321 1.53732 8.16944 1.50281 8.24385C1.4683 8.31825 1.45044 8.39909 1.45044 8.48088C1.45044 8.56268 1.4683 8.64352 1.50281 8.71792C1.53732 8.79233 1.58767 8.85856 1.65045 8.91213L8.54526 14.8032C8.57247 14.8264 8.60512 14.8389 8.63959 14.8389H10.2993C10.4336 14.8389 10.4953 14.6746 10.3937 14.5889L4.04305 9.16035H14.6564C14.7363 9.16035 14.8016 9.09606 14.8016 9.01749V7.94606C14.8016 7.86749 14.7363 7.80321 14.6564 7.80321Z" fill="currentColor" fillOpacity="0.88" />
            </svg>
          </Link>
          Tạo mới hạng thành viên
        </div>

        <div className="content-add-rank">
          <div className="form-add-rank">
            <div className="title-fz16-medium t-mb-18">Thiết lập thông tin</div>
            <Form
              className="form-block"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
            >

              <Form.Item
                name="img"
                rules={[{ required: true, message: 'Vui lòng thêm hình!' }]}
              >
                <div className="input-block">
                  {
                    uploadedFile
                      ? (
                        <div className="avatar-uploader-block">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                          />
                          <img src={URL.createObjectURL(uploadedFile)} alt="Uploaded" className="avatar-uploader" onClick={handleUploadClick} />
                        </div>

                      ) : (
                        <div className="avatar-uploader-block">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                          />

                          <div className="avatar-uploader avatar-uploader-none-img" onClick={handleUploadClick}>
                            <div className="icon-svg"><PlusOutlined /></div>
                            <div className="text">Tải lên Icon của hạng</div>
                          </div>
                        </div>
                      )
                  }
                </div>
              </Form.Item>

              <Form.Item
                label="Tên hạng"
                name="name"
                rules={[{ required: true, message: 'Tên hạng không được để trống!' }]}
              >
                <div className="input-block">
                  <Input placeholder="Vui lòng nhập tên hạng" />
                </div>
              </Form.Item>

              <Form.Item
                label="Số tiền chi tiêu để đạt được hạng"
                name="price"
                rules={[{ required: true, message: 'Số tiền không được để trống!' }]}
              >
                <Input
                  addonAfter="VNĐ"
                  type="number"
                  placeholder="Nhập số tiền"
                  value={valueNumber}
                  onChange={handleChangeNumber}
                />
              </Form.Item>
              <Form.Item>
                <div className="btn-block">
                  <Button className="btn btn-primary" type="primary" htmlType="submit">
                    <div className="text">
                      Thêm hạng
                    </div>
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>

          <div className="detail-block t-mt-0">
            <div className="title-fz16-medium">Chọn quyền lợi cho hạng thẻ</div>
            <div className='t-search-block t-my-10'>
              <Search placeholder="Tìm quyền lợi" onSearch={onSearch} />
            </div>
            <div className="t-table-block t-table-add-rank">
              <Table
                rowSelection={rowSelection}
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
                columns={columns}
                dataSource={dataBenefit}
                pagination={false}
                showSorterTooltip={false}
              />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default MarketingCampaigns;
