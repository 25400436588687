import React, { useEffect, useState } from 'react'
import { CaretDownOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Tag } from 'antd'
import './RelativeCollapse.scss'

interface Props {
  title: string
  relationship: string
  name: string
  gender: string
  phoneNumber: string
  dateOfBirth: string
  career: string
  email: string
  address: string
  show?: boolean
  showControl?: boolean
  className?: string
  onToggle?: () => void
  onDelete?: () => void
  onEdit?: () => void
}

const RelativeCollapse:React.FC<Props> = (props) => {
  const { title, relationship, name, gender, phoneNumber, dateOfBirth, career, email, address, show = false, showControl = false, className = '', onToggle, onDelete, onEdit } = props

  const [isShow, setIsShow] = useState(show)

  useEffect(() => {
    setIsShow(show)
  }, [show])

  const toggleShow = () => {
    // setIsShow(!isShow)

    if (onToggle) {
      onToggle()
    }
  }

  const handleDelete = (e: any) => {
    e.preventDefault()

    if (onDelete) {
      onDelete()
    }
  }

  const handleEdit = (e: any) => {
    e.preventDefault()

    if (onEdit) {
      onEdit()
    }
  }

  return (
    <div className={`relative-collapse ${className} ${showControl ? 'relative-collapse-with-control' : ''}`}>
      <div
        className={`relative-collapse__title ${isShow ? 'show' : 'collapsed'}`}
      >
        <div
          className="icon"
          onClick={toggleShow}
        >
          <CaretDownOutlined />
        </div>
        <div
          className="title"
          onClick={toggleShow}
        >
          <span className='font-bold neutral-gray-700'>{ title }</span>
          <Tag
            className='rs-tag sunset-orange no-border'
          >{ relationship }</Tag>
        </div>
        {
          showControl && (
            <div className="control">
              <Button
                icon={<CloseCircleOutlined />}
                className="rs-button delete"
                onClick={handleDelete}
              />
              <Button
                icon={<EditOutlined />}
                className="rs-button"
                onClick={handleEdit}
              />
            </div>
          )
        }
      </div>

      <div
        className={`relative-collapse__content ${isShow ? 'show' : 'collapsed'}`}
      >
        <div className="item">
          <div className="item__label">Họ và tên</div>
          <div className="item__value">{ name }</div>
        </div>
        <div className="item">
          <div className="item__label">Giới tính</div>
          <div className="item__value">{ gender }</div>
        </div>
        <div className="item">
          <div className="item__label">Số điện thoại</div>
          <div className="item__value">{ phoneNumber }</div>
        </div>
        <div className="item">
          <div className="item__label">Sinh nhật</div>
          <div className="item__value">{ dateOfBirth }</div>
        </div>
        <div className="item">
          <div className="item__label">Nghề nghiệp</div>
          <div className="item__value">{ career }</div>
        </div>
        <div className="item">
          <div className="item__label">Email</div>
          <div className="item__value">{ email }</div>
        </div>
        <div className="item">
          <div className="item__label">Địa chỉ</div>
          <div className="item__value">{ address }</div>
        </div>
      </div>
    </div>
  )
}

export default RelativeCollapse
