import { CalendarOutlined, DeleteOutlined, EditOutlined, PlusCircleOutlined, UserAddOutlined } from '@ant-design/icons';
import { Breadcrumb, ConfigProvider, Pagination, PaginationProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../Layout';
import './style.scss'
import AccountRoleItem from './Components/AccountRoleItem';
import AccountRoleList from './Components/AccountRoleList';
import EmptyComponent from '../../../components/EmptyComponent/EmptyComponent';
import ModalDeleteManager from '../../../components/ModalDeleteManager';
import viVN from 'antd/locale/vi_VN';
import useToast from '../../../hook/useToast';
import useDebounce from '../../../hook/useDebounce';
import * as groupPermissionApi from '../../../api/groupPermission/groupPermissionApi';
import { dataPagination } from '../../../until';

const arrayRole = [
  {
    id: 1,
    title: 'Quản trị cấp cao',
    date: 'Chỉnh sửa lần cuối 14/06/2023',
    numberUser: 'Có 116 tài khoản đang thuộc nhóm',
    description: 'Tất cả các quyền',
    members: 116,
    lastUpdated: '14/06/2023'
  },
  {
    id: 2,
    title: 'Quản trị cấp cao',
    date: 'Chỉnh sửa lần cuối 14/06/2023',
    numberUser: 'Có 116 tài khoản đang thuộc nhóm',
    description: 'Tất cả các quyền',
    members: 116,
    lastUpdated: '14/06/2023'
  },
  {
    id: 3,
    title: 'Quản trị cấp cao',
    date: 'Chỉnh sửa lần cuối 14/06/2023',
    numberUser: 'Có 116 tài khoản đang thuộc nhóm',
    description: 'Tất cả các quyền',
    members: 116,
    lastUpdated: '14/06/2023'
  },
  {
    id: 4,
    title: 'Quản trị cấp cao',
    date: 'Chỉnh sửa lần cuối 14/06/2023',
    numberUser: 'Có 116 tài khoản đang thuộc nhóm',
    description: 'Tất cả các quyền',
    members: 116,
    lastUpdated: '14/06/2023'
  },
  {
    id: 5,
    title: 'Quản trị cấp cao',
    date: 'Chỉnh sửa lần cuối 14/06/2023',
    numberUser: 'Có 116 tài khoản đang thuộc nhóm',
    description: 'Tất cả các quyền',
    members: 116,
    lastUpdated: '14/06/2023'
  },
  {
    id: 6,
    title: 'Nhóm mặc định đầy đủ quyền',
    date: 'Chỉnh sửa lần cuối 14/06/2023',
    numberUser: 'Có 116 tài khoản đang thuộc nhóm',
    description: 'Tất cả các quyền',
    members: 116,
    lastUpdated: '14/06/2023'
  }
]
const AccountRole: React.FC = () => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';

    gListGroupPermission()

    return () => {

      setIdRole(undefined)

      setPagination({ current: 1, pageSize: 10, total: 0 })

      setListRole([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pushToast = useToast();
  const navigate = useNavigate()

  const [isOpenDeleteAccountRole, setIsOpenDeleteAccountRole] = useState<boolean>(false)

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });

  const [idRole, setIdRole] = useState<number>()
  const [listRole, setListRole] = useState<groupPermissionApi.IGroupPermission[]>([])

  const gListGroupPermission = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await groupPermissionApi.getListGroupPermission(pageSize, pageCurent)
    if (result?.status) {
      setListRole(result.data)
      setPagination({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const onPaginationChange: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    gListGroupPermission(pageNumber, pageSize)
  }

  const onEdit = (id: number) => {
    navigate('/system-configuration/edit-account-role', { state: { id: id } })
  }

  const openModalDelete = (id: number) => {
    setIdRole(id)
    setIsOpenDeleteAccountRole(true)
  }

  const closeModalDelete = () => {
    setIdRole(undefined)
    setIsOpenDeleteAccountRole(false)
  }

  const onConfirmDelete = async () => {
    const result = await groupPermissionApi.deleteGroupPermission(Number(idRole))
    if (result?.status) {
      pushToast(result.message, "success")
      closeModalDelete()
      gListGroupPermission()
    } else {
      pushToast(result?.message, "warning")
    }
  }

  return (
    <Layout>
      <ModalDeleteManager
        isOpen={isOpenDeleteAccountRole}
        title="Bạn có chắc chắn xóa nhóm này"
        titleDelete="Xóa nhóm"
        content="Nhóm và dữ liệu liên quan sẽ bị xóa vĩnh viễn
      không thể khôi phục"
        closeModal={closeModalDelete}
        onConfirm={onConfirmDelete}
      />
      <Breadcrumb
        items={[
          {
            title: 'THACO AUTO CRM',
          },
          {
            title: <a href="/">Cấu hình</a>,
          },
          {
            title: 'Phân quyền',
          },
        ]}
      />
      <div className='title-page'>
        Phân quyền theo nhóm
      </div>
      <div className='d-flex align-center justify-between  pb-18-px '>
        <p className='text-base font-medium text-344054 white-space-nowrap'>Danh sách nhóm</p>
        <button
          className='px-4 h-8 white-space-nowrap d-flex justify-center align-center border-none text-white bg-096DD9 rounded-2 text-sm font-regular gap-10-px'
          onClick={() => navigate('/system-configuration/create-account-role')}
        >
          <PlusCircleOutlined className='text-sm text-white' />
          Thêm mới nhóm
        </button>
      </div>

      {
        listRole?.length > 0
          ?
          <AccountRoleList>
            {
              listRole.map((item) => (
                <AccountRoleItem
                  key={item.id}
                  item={item}
                  onEdit={onEdit}
                  onDelete={openModalDelete}
                // onShowInfo={() => navigate('/system-configuration/info-account-role')}
                />
              ))
            }
          </AccountRoleList>
          :
          <EmptyComponent />
      }
      <ConfigProvider locale={viVN}>
        <div className="pagination-block">
          <Pagination
            showSizeChanger
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            showTotal={(total) => `Tổng cộng ${dataPagination(pagination.total, pagination.pageSize, pagination.current).totalPage} trang`}
            className='rs-pagination'
            onChange={onPaginationChange}
          />
        </div>
      </ConfigProvider>
    </Layout>
  );
}

export default AccountRole;
