import { BulbOutlined } from "@ant-design/icons"
import { Select } from "antd"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import BreadcrumbBlock from "../../../../../components/BreadcrumbBlock/BreadcrumbBlock"
import Layout from "../../../../../Layout"
import DoubleCircleChart from "./Chart/DoubleCircleChart"
import './style.scss'
import useToast from '../../../../../hook/useToast';
import * as groupCustomerApi from "../../../../../api/groupCustomer/groupCustomerApi"
import { fommatSelectOption } from "../../../../../until"
const Grouping: React.FC = () => {

  useEffect(() => {
    document.title = 'THACO AUTO CRM';

    gSLGroup(true)
    return () => {
      handleReset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pushToast = useToast();


  const [checkDuplicate, setCheckDuplicate] = useState(true);
  const [checkLoading, setCheckLoading] = useState(false)
  const [doubleCircleChart, setDoubleCircleChart] = useState(false)

  const [idGroup1, setIdGroup1] = useState<number>()
  const [idGroup2, setIdGroup2] = useState<number>()

  const [sLGroup1, setSLGroup1] = useState<groupCustomerApi.ISLGroup[]>([])
  const [sLGroup2, setSLGroup2] = useState<groupCustomerApi.ISLGroup[]>([])

  const [infoDuplicate, setInfoDuplicate] = useState<groupCustomerApi.IDuplicate>()

  const gSLGroup = async (isGroup1: boolean) => {
    const id = !isGroup1 ? idGroup1 : undefined
    console.log(idGroup1);

    const result = await groupCustomerApi.getListGroupAndGroup(id)
    if (result?.status) {
      if (isGroup1) {
        setSLGroup1(result.data)
      } else {
        setSLGroup2(result.data)
      }
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const handleCheckDuplicate = async () => {
    setCheckLoading(true)
    const result = await groupCustomerApi.getCountDuplicate(Number(idGroup1), Number(idGroup2))
    if (result?.status) {

      setCheckLoading(false)
      setDoubleCircleChart(true)
      setCheckDuplicate(false)
      setInfoDuplicate(result.data)
    } else {
      pushToast(result?.message, "warning")
      setCheckLoading(false)
    }


    // setCheckDuplicate(false)
    // setCheckLoading(true)
    // setTimeout(() => {
    //   setCheckLoading(false)
    //   setDoubleCircleChart(true)
    // }, 2000)

  }
  const handleReset = () => {
    setDoubleCircleChart(!doubleCircleChart)
    setCheckDuplicate(true)

    setIdGroup1(undefined)
    setIdGroup2(undefined)
  }

  useEffect(() => {
    if (idGroup1) {
      setIdGroup2(undefined)
      gSLGroup(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idGroup1])

  return (
    <Layout>
      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: <Link to="/customer/list">Khách hàng</Link>, },
          { title: <Link to="/customer/list">Nhóm khách hàng</Link>, },
          { title: 'Gộp nhóm', },
        ]}
        title='Gộp nhóm'
        backLink='/customer/group'
      />
      <div className="d-flex justify-between align-center px-6 py-5 border-bottom-F0F0F0">
        <p className="font-medium fw-500 text-344054">Nhóm 1</p>
        <div className='w-370-px'>
          <Select
            placeholder="Chọn nhóm"

            className="custome-select-98A2B3 "
            popupClassName='popup-select'
            value={idGroup1}
            options={fommatSelectOption(sLGroup1)}
            onChange={(e) => setIdGroup1(e)}
          />
        </div>
      </div>
      <div className="d-flex justify-between align-center px-6 py-5 border-bottom-F0F0F0">
        <p className="font-medium fw-500 text-344054">Nhóm 2</p>
        <div className='w-370-px'>
          <Select
            placeholder="Chọn nhóm"
            className="custome-select-98A2B3 "
            popupClassName='popup-select'
            value={idGroup2}
            options={fommatSelectOption(sLGroup2)}
            onChange={(e) => setIdGroup2(e)}
          />
        </div>
      </div>
      <div className="w-full d-flex justify-center pt-20">
        {
          checkDuplicate && (
            <div className="d-flex flex-column align-center gap-6 pb-6">
              <div className="d-flex flex-column align-center gap-2">
                <img src="/images/exportImg/empty-image.svg" alt="" className="w-326-px h-200-px" />
                <p
                  className={`text-sm text-667085 font-regular ${idGroup2 ? "hidden" : ""}`}
                >Chọn nhóm để kiểm tra trùng lặp</p>
              </div>
              <div className="h-10 w-395-px pl-4 bg-E6F7FF border-91D5FF rounded-2 d-flex align-center gap-10-px">
                <BulbOutlined className="text-20-px text-1890FF" />
                <p className="text-sm text-344054 font-regular">Hệ thống sẽ loại bỏ tập trùng lặp giữa các nhóm</p>
              </div>
              <button
                className={`px-15-px h-8 bg-00539F rounded-6-px text-white border-none text-sm font-regular w-fit ${!idGroup2 ? "hidden" : ""}`}
                onClick={handleCheckDuplicate}
              >
                Kiểm tra trùng lặp
              </button>
            </div>
          )
        }
        {
          checkLoading && (
            <div className=" d-flex flex-column gap-4 justify-center align-center">
              <img src='/images/exportImg/check_cus.svg' alt="" className="w-295-px h-200-px" />
              <div className="loading">
                <div className="bar"></div>
              </div>
              <span className="text-sm text-344054 font-regular w-208-px text-center">Hệ thống đang kiểm tra khách hàng
                trùng lặp giữa 02 nhóm</span>
            </div>
          )
        }
        {
          doubleCircleChart && (
            <DoubleCircleChart
              infoDuplicate={infoDuplicate}
              reset={handleReset}
            />
          )
        }
      </div>
    </Layout>
  )
}
export default Grouping
