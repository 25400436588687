import React, { useEffect, useState } from "react";
import RightPopup from "../../../components/Popup/RightPopup";
import { Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import * as historyApi from '../../../api/history/historyApi';
import './VehicleInfoPopup.scss';
import { URL_CAR, URL_CAR_CATEGORY, URL_COUNTRY } from "../../../env";
import { useParams } from "react-router-dom";
import useToast from "../../../hook/useToast";

interface Props {
  className?: string;
  onClose?: () => void;
  onShowHistory?: () => void;
  data: historyApi.IHistoryCarDetail | undefined
  dataHistoryMaintenance: historyApi.IHistoryMaintenance[]
  // idCustomer: number 
}

const VehicleInfoPopup: React.FC<Props> = ({
  data,
  dataHistoryMaintenance,
  className,
  onClose, onShowHistory }) => {

  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }
  }
  const handleOnShowHistory = () => {
    if (onShowHistory) {
      onShowHistory()
    }
  }


  return (
    <RightPopup
      title="Thông tin xe"
      className={className}
      contentClassName="vehicle-info-popup__content"
      onClose={handleOnClose}
    >
      <img
        src={`${URL_CAR}${data?.carImage}`}
        alt="bmw-series-7"
        className="avatar"
      />

      <div className="block">
        <div className="title">Thông tin mua bán</div>
        <div className="item">
          <span>Đại lý bán</span>
          <span className="neutral-gray-400">{data?.agentName}</span>
        </div>
        <div className="item">
          <span>Mã đơn hàng</span>
          <span className="neutral-gray-400">{data?.codeContract}</span>
        </div>
        <div className="item">
          <span>Số hợp đồng</span>
          <span className="neutral-gray-400">{data?.numberContract}</span>
        </div>
        <div className="item">
          <span>Mã xe</span>
          <span className="neutral-gray-400">{data?.carCode}</span>
        </div>
        <div className="item">
          <span>Ngày sở hữu</span>
          <span className="neutral-gray-400">{data?.dateTransaction}</span>
        </div>
      </div>
      {dataHistoryMaintenance?.length === 0 ? '' : (
        <div className="block">
          <div className="title">Thông tin bảo hành & bảo dưỡng</div>
          <div className="item">
            <span>Bảo hành từ</span>
            <span className="neutral-gray-400">{dataHistoryMaintenance[0].dateMaintenance ? dataHistoryMaintenance[0].dateMaintenance : ''}</span>
          </div>
          <div className="item">
            <span>Thời hạn bảo hành</span>
            <span className="neutral-gray-400">{dataHistoryMaintenance[0].warrantyPeriod ? dataHistoryMaintenance[0].warrantyPeriod : ''} năm <span className="success-600">{dataHistoryMaintenance[0].dateComplete}</span></span>
          </div>
          <div className="item">
            <span>Số KM bảo dưỡng gần nhất</span>
            <span className="neutral-gray-400">{dataHistoryMaintenance[0].codeMaintenance ? dataHistoryMaintenance[0].codeMaintenance : ''}</span>
          </div>
          <div className="item">
            <span>Ngày bảo dưỡng gần nhất</span>
            <span className="neutral-gray-400">{dataHistoryMaintenance[0].dateComplete ? dataHistoryMaintenance[0].dateComplete : ''}</span>
          </div>
          <div className="item">
            <Button
              block
              className="rs-button primary-outline"
              onClick={handleOnShowHistory}
            >
              <span>Xem lịch sử bảo dưỡng</span>
              <RightOutlined />
            </Button>
          </div>
        </div>
      )}



      <div className="block">
        <div className="title">Chi tiết thông tin xe</div>
        <div className="item">
          <span>Xuất xứ</span>
          <div className="item-right flag">
            <span className="neutral-gray-400">{data?.countryName}</span>
            <img src={`${URL_COUNTRY}${data?.countryImage}`} alt="" />
          </div>
        </div>
        <div className="item">
          <span>Thương hiệu</span>
          <div className="item-right brand">
            <span className="neutral-gray-400">{data?.categoryCarName}</span>
            <img src="/images/logo-partner.svg" alt="" />
          </div>
        </div>
        <div className="item">
          <span>Màu xe</span>
          <span className="neutral-gray-400">{data?.color}</span>
        </div>
        <div className="item">
          <span>Kiểu dáng</span>
          <span className="neutral-gray-400">{data?.typeBodyVehicle}</span>
        </div>
        <div className="item">
          <span>Nhiên liệu</span>
          <span className="neutral-gray-400">{data?.fuel}</span>
        </div>
        <div className="item">
          <span>Số khung</span>
          <span className="neutral-gray-400">{data?.VIN}</span>
        </div>
        <div className="item">
          <span>Số máy</span>
          <span className="neutral-gray-400">{data?.engineNumber}</span>
        </div>
      </div>
    </RightPopup>
  )
}

export default VehicleInfoPopup
