import React from 'react'
import { Button, Drawer, Flex } from 'antd'

interface Props {
  show: boolean
  onClose: () => void
}

const OrderHistoryDrawer:React.FC<Props> = (props) => {
  const { show, onClose } = props

  return (
    <Drawer
      open={show}
      title='Chi tiết lịch sử mua hàng'
      className='rs-drawer'
      closeIcon={false}
      footer={
        <Button
          block
          onClick={onClose}
        >Đóng</Button>
      }
      onClose={onClose}
    >
      <Flex vertical className='gap-18px'>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Số hóa đơn</span>
          <span className="neutral-gray-400 text-end">#08333333</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Mã SR/ĐL</span>
          <span className="neutral-gray-400 text-end">#SR0993333</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Họ & tên </span>
          <span className="neutral-gray-400 text-end">Trần Nam Bá</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Số lượng </span>
          <span className="neutral-gray-400 text-end">01</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Số lượng DKDV </span>
          <span className="neutral-gray-400 text-end">01</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Số lượng DKDV </span>
          <span className="neutral-gray-400 text-end">01</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Hình thức TT </span>
          <span className="neutral-gray-400 text-end">Trả thẳng</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Số khung </span>
          <span className="neutral-gray-400 text-end">38042093</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Số KM hiện tại</span>
          <span className="neutral-gray-400 text-end">30.000 KM</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Ngày GDTD</span>
          <span className="neutral-gray-400 text-end">20/06/2022</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Ngày ĐNHS</span>
          <span className="neutral-gray-400 text-end">20/06/2022</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Ngày xuất</span>
          <span className="neutral-gray-400 text-end">20/06/2022</span>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default OrderHistoryDrawer
