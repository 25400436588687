import { Button, Form, Input, Modal, notification, Select } from 'antd'
import React, { useRef, useState } from 'react'

interface Props {
  id: number,
  show: boolean
  modalClose?: () => void
  onSuccess?: () => void
}

const EditAgencyModal:React.FC<Props> = (props) => {
  const { id, show, modalClose, onSuccess } = props
  const formRef: any = useRef(null)

  const [loading, setLoading] = useState(false)
  const onFormFinish = (values: any) => {
    setLoading(true)

    setTimeout(() => {
      setLoading(false)
      openNotification('success', 'Tạo mới thành công')
      if (onSuccess) {
        onSuccess()
      }
      if (formRef.current) {
        formRef.current.resetFields()
      }
    }, 1500)
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'error' = 'success', message: string = '') => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    })
  }

  return (
    <>
      { contextHolder }

      <Modal
        open={show}
        title='Chỉnh sửa đại lý'
        className='rs-modal w-400 footer-content-end add-relative-modal'
        centered
        footer={
          <>
            <Button
              className='rs-button rs-button--sm'
            >Hủy</Button>
            <Button
              type='primary'
              className='rs-button rs-button--sm'
              loading={loading}
              onClick={() => formRef?.current.submit()}
            >Xác nhận</Button>
          </>
        }
        onCancel={modalClose}
      >
        <Form
          ref={formRef}
          className='rs-form add-relative-form'
          onFinish={onFormFinish}
          requiredMark={false}
          layout='vertical'
        >
          <Form.Item
            // label='Tên đơn vị'
            name='name'
            rules={[
              { required: true, message: "Vui lòng nhập Tên đơn vị" }
            ]}
          >
            <Input
              placeholder='Tên đơn vị'
              className='rs-input rs-input--32'
            />
          </Form.Item>

          <Form.Item
            // label='Số điện thoại'
            name='phoneNumber'
            rules={[
              { required: true, message: "Vui lòng nhập Số điện thoại" }
            ]}
          >
            <Input
              placeholder='Số điện thoại'
              className='rs-input rs-input--32'
            />
          </Form.Item>

          <Form.Item
            // label='Email'
            name='email'
            // rules={[
            //   { required: true, message: "Vui lòng nhập Email" }
            // ]}
          >
            <Input
              placeholder='Email'
              className='rs-input rs-input--32'
            />
          </Form.Item>

          <Form.Item
            // label='Địa chỉ'
            name='address'
            rules={[
              { required: true, message: "Vui lòng nhập Địa chỉ" }
            ]}
          >
            <Input
              placeholder='Địa chỉ'
              className='rs-input rs-input--32'
            />
          </Form.Item>

          <Form.Item
            // label='Thành phố'
            name='province'
            rules={[
              { required: true, message: "Vui lòng chọn Thành phố" }
            ]}
          >
            <Select
              placeholder='Thành phố'
              className='rs-select'
              popupClassName='rs-select-dropdown'
              options={[{ value: '1', label: 'Hà Nội' }, { value: '2', label: 'Hồ Chí Minh' }]}
            />
          </Form.Item>

          <Form.Item
            // label='Quận huyện'
            name='district'
            rules={[
              { required: true, message: "Vui lòng chọn Quận huyện" }
            ]}
          >
            <Select
              placeholder='Quận huyện'
              className='rs-select'
              popupClassName='rs-select-dropdown'
              options={[{ value: '1', label: 'Quận huyện' }]}
            />
          </Form.Item>

          <Form.Item
            // label='Phường'
            name='ward'
            rules={[
              { required: true, message: "Vui lòng chọn Phường" }
            ]}
          >
            <Select
              placeholder='Phường'
              className='rs-select'
              popupClassName='rs-select-dropdown'
              options={[{ value: '1', label: 'Phường' }]}
            />
          </Form.Item>

          <Form.Item
            // label='Link website'
            name='website'
            // rules={[
            //   { required: true, message: "Vui lòng nhập Link website" }
            // ]}
          >
            <Input
              placeholder='Link website'
              className='rs-input rs-input--32'
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default EditAgencyModal
