import React, { useState } from 'react'
import { Button, Flex, Modal, Table, Tag } from 'antd'
import ImportDataResultItem from './ImportDataResultItem'
import fakeData from '../../customer-list.json'
import { mixGender, mixGetTime } from '../../../../services/mixin'

const exclamationIcon = <svg width="1em" height="1.028em" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M18 5.42188C10.7789 5.42188 4.92188 11.2789 4.92188 18.5C4.92188 25.7211 10.7789 31.5781 18 31.5781C25.2211 31.5781 31.0781 25.7211 31.0781 18.5C31.0781 11.2789 25.2211 5.42188 18 5.42188ZM16.875 10.9062C16.875 10.7516 17.0016 10.625 17.1562 10.625H18.8438C18.9984 10.625 19.125 10.7516 19.125 10.9062V20.4688C19.125 20.6234 18.9984 20.75 18.8438 20.75H17.1562C17.0016 20.75 16.875 20.6234 16.875 20.4688V10.9062ZM18 26.375C17.5584 26.366 17.138 26.1842 16.8288 25.8687C16.5197 25.5533 16.3466 25.1292 16.3466 24.6875C16.3466 24.2458 16.5197 23.8217 16.8288 23.5063C17.138 23.1908 17.5584 23.009 18 23C18.4416 23.009 18.8621 23.1908 19.1712 23.5063C19.4803 23.8217 19.6534 24.2458 19.6534 24.6875C19.6534 25.1292 19.4803 25.5533 19.1712 25.8687C18.8621 26.1842 18.4416 26.366 18 26.375Z" fill="#FFF7E6"/> <path d="M18 2.75C9.30234 2.75 2.25 9.80234 2.25 18.5C2.25 27.1977 9.30234 34.25 18 34.25C26.6977 34.25 33.75 27.1977 33.75 18.5C33.75 9.80234 26.6977 2.75 18 2.75ZM18 31.5781C10.7789 31.5781 4.92188 25.7211 4.92188 18.5C4.92188 11.2789 10.7789 5.42188 18 5.42188C25.2211 5.42188 31.0781 11.2789 31.0781 18.5C31.0781 25.7211 25.2211 31.5781 18 31.5781Z" fill="#FA8C16"/> <path d="M17.1562 20.75H18.8438C18.9984 20.75 19.125 20.6234 19.125 20.4688V10.9062C19.125 10.7516 18.9984 10.625 18.8438 10.625H17.1562C17.0016 10.625 16.875 10.7516 16.875 10.9062V20.4688C16.875 20.6234 17.0016 20.75 17.1562 20.75ZM16.3125 24.6875C16.3125 25.1351 16.4903 25.5643 16.8068 25.8807C17.1232 26.1972 17.5524 26.375 18 26.375C18.4476 26.375 18.8768 26.1972 19.1932 25.8807C19.5097 25.5643 19.6875 25.1351 19.6875 24.6875C19.6875 24.2399 19.5097 23.8107 19.1932 23.4943C18.8768 23.1778 18.4476 23 18 23C17.5524 23 17.1232 23.1778 16.8068 23.4943C16.4903 23.8107 16.3125 24.2399 16.3125 24.6875Z" fill="#FA8C16"/> </svg>
interface Props {
  listFiles?: any
}
const ImportDataStep4: React.FC<Props> = (props) => {
  const [showImportDataModal, setShowImportDataModal] = useState(false)
  const { listFiles } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: '2'
    },
    {
      title: 'Họ và tên',
      dataIndex: 'name',
      render: (_: any, record: any) => (
        <div className='td-customer-name'>{record.name}</div>
      ),
      key: '3'
    },
    {
      title: 'Nguồn khách hàng',
      dataIndex: 'source',
      render: (source: any) => (
        <Flex align='center' className='gap-10px'>
          <Tag className='rs-tag daybreak-blue'>DDMS</Tag>
          <Tag className='rs-tag daybreak-blue'>IPCC</Tag>
          <Tag className='rs-tag daybreak-blue'>SALEFORCE</Tag>
        </Flex>
      ),
      key: 'source'
    },
    {
      title: 'CCCD/CMND',
      // dataIndex: 'CCCD',
      dataIndex: 'cccd',
      align: 'right',
      key: '4'
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'birthday',
      sorter: {
        compare: (a: any, b: any) => {
          const dateA = mixGetTime(a.birthday);
          const dateB = mixGetTime(b.birthday);
          return dateA - dateB;
        },
        multiple: 0,
      },
      key: '5'
    },
    {
      title: 'Giới tính',
      dataIndex: 'gender',
      sorter: {
        compare: (a: any, b: any) => a.gender - b.gender,
        multiple: 1,
      },
      render: (_: any, record: any) => (
        <span>{mixGender(record.gender)?.title}</span>
      ),
      key: '6'
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: '7'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: '8'
    },
    {
      title: 'Quan tâm',
      dataIndex: 'interestedCar',
      key: '9'
    },
  ] as any

  return (
    <>
      <div className="import-data-step-4">
        <Flex vertical className='gap-24px'>
          <div className="exclamation-block fsz-16 lh-24 font-medium sunset-orange-6 text-center">
            { exclamationIcon }
            <div>
              Hệ thống đang xử lý dữ liệu.
              <br />
              Tiến trình này có thể mất nhiều thời gian tùy thuộc vào số lượng bản ghi cần nhập.
              <br />
              Vui lòng không thoát khỏi hệ thống cho đến khi tiến trình hoàn thành.
            </div>
          </div>
          {listFiles.map((val: any, index: any) => (
            <ImportDataResultItem
              key={index}
              fileName={val.name}
              percent={val?.res?.data?.percent_success}
              res={val}
              showDetail={() => setShowImportDataModal(true)}
            />
          ))}

          {/* <ImportDataResultItem
            fileName="DDMS_IPCC_Customer_20240716.xls"
            percent={40}
          /> */}
        </Flex>
      </div>

      <Modal
        open={showImportDataModal}
        title='Chọn hệ thống và nhập dữ liệu'
        className='rs-modal w-1216 footer-content-end'
        centered
        footer={[
          <Button
            type="primary"
            className="rs-button"
            onClick={() => setShowImportDataModal(false)}
          >Đóng</Button>
        ]}
        onCancel={() => setShowImportDataModal(false)}
      >
        <Table
          className='rs-table'
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
          rowKey="id"
          columns={columns}
          // dataSource={customser}
          dataSource={fakeData}
          showSorterTooltip={false}
          scroll={{ x: true }}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 15,
            showTotal: (total) => `Tổng cộng ${Math.ceil(total / 15)} trang`,
            locale: { items_per_page: ' / trang', jump_to: 'Tới' },
            position: ['bottomCenter'],
            className: 'rs-pagination',
            showQuickJumper: true,
          }}
        />
      </Modal>
    </>
  )
}

export default ImportDataStep4
