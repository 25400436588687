import { paserParams } from "../../until";
import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";
import { SortType } from "../carLine/carLineApi";

export interface ICar {
    id: number
    title: string
    code: string
    image: string
    yearCreate: number
    seatingCapacity: number
    categoryCar: string
    categoryCarImage: string
    carLines: string
    typeVehicle: string
    version: string
    segmentsVehicle: string
    fuel: string
    color: string
    modelVehicle: string
    typeBodyVehicle: string
}
export interface ICarEdit {
    id: number
    title: string
    country: string
    code: string
    yearCreate: number
    categoryCar: string
    categoryCarImage: string
    carLines: string
    version: string
    versionId: string
    typeVehicle: string
    seatingCapacity: number
    fuelId: number
    fuel: string
    segmentsVehicleId: number
    segmentsVehicle: string
    colorId: number
    color: string
    modelVehicleId: number
    modelVehicle: string
    typeBodyVehicleId: number
    typeBodyVehicle: string
    countryId: number
    categoryCarId: number
    typeVehicleId: number
    carLinesId: number
    carImage: string
}


//Danh sách xe
export const getListCar = async (
    limit?: number, pageNumber?: number, search?: string
) => {
    const path = "car/listCar"
    const params = {
        limit, pageNumber, search
    }
    const result: ResponseSuccessTotal<ICar[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thông tin xe
export const getInfoCar = async (
    id: number
) => {
    const path = "car/infoCar"
    const params = {
        id
    }
    const result: ResponseSuccessTotal<ICarEdit> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thêm mới xe
export const addCar = async (
    code: string, title: string, idCategoryCar: number, idSegmentsVehicle: number, idCarLines: number, idTypeVehicle: number, yearCreate: number, idVersion: number, image: any,
    seatingCapacity?: number, idFuel?: number, idCountry?: number, idModelVehicle?: number, idColor?: number, idTypeBodyVehicle?: number
) => {
    const url = "car/addCar";
    const params = {
        code, title, idCategoryCar, idCarLines, idSegmentsVehicle, idTypeVehicle, yearCreate, idVersion, image,
        seatingCapacity, idFuel, idCountry, idModelVehicle, idColor, idTypeBodyVehicle
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        url, params
    );
    return result;
}

//Chỉnh sửa xe
export const editCar = async (
    id: number, code: string, title: string, idCategoryCar: number, idSegmentsVehicle: number, idCarLines: number, idTypeVehicle: number, yearCreate: number, idVersion: number, image: any,
    seatingCapacity?: number, idFuel?: number, idCountry?: number, idModelVehicle?: number, idColor?: number, idTypeBodyVehicle?: number
) => {
    const url = "car/editCar";
    const params = {
        id, code, title, idCategoryCar, idCarLines, idSegmentsVehicle, idTypeVehicle, yearCreate, idVersion, image,
        seatingCapacity, idFuel, idCountry, idModelVehicle, idColor, idTypeBodyVehicle
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, params
    );
    return result;
}

//Xóa xe
export const deleteCar = async (
    id: Number
) => {
    const url = "car/deleteCar";
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        url, { params }
    );
    return result;
}