import { paserParams } from "../../until";
import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";
import { SortType } from "../carLine/carLineApi";

export interface ITypeAttributeVehicle {
    id: number
    title: string
}

export interface IAttributeVehicle {
    id: number
    title: string
}

export interface IAttributeVehicleEdit {
    id: number
    title: string
    categoryCarId: number
    carLinesId: number
}

//Danh sách loại thuộc tính
export const getListTypeAttributeVehicle = async (

) => {
    const path = "attributeVehicle/listTypeAttributeVehicle"
    const params = {
    }
    const result: ResponseSuccessTotal<ITypeAttributeVehicle[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Danh sách thuộc tính
export const getListAttributeVehicle = async (
    typeAttributeVehicleId: number, limit?: number, pageNumber?: number
) => {
    const path = "attributeVehicle/listAttributeVehicle"
    const params = {
        typeAttributeVehicleId, limit, pageNumber
    }
    const result: ResponseSuccessTotal<IAttributeVehicle[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thông tin thuộc tính
export const getInfoAttributeVehicle = async (
    typeAttributeVehicleId: number, id: number
) => {
    const path = "attributeVehicle/infoAttributeVehicle"
    const params = {
        typeAttributeVehicleId, id
    }
    const result: ResponseSuccessTotal<IAttributeVehicle> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thêm mới thuộc tính
export const addAttributeVehicle = async (
    typeAttributeVehicleId: number, title: string
) => {
    const url = "attributeVehicle/addAttributeVehicle";
    const params = {
        typeAttributeVehicleId, title
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        url, paserParams(params, undefined)
    );
    return result;
}

//Chỉnh sửa thuộc tính
export const editAttributeVehicle = async (
    typeAttributeVehicleId: number, id: number, title: string
) => {
    const url = "attributeVehicle/editAttributeVehicle";
    const params = {
        typeAttributeVehicleId, id, title
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, paserParams(params, undefined)
    );
    return result;
}

//Xóa thuộc tính
export const deleteAttributeVehicle = async (
    typeAttributeVehicleId: number, id: number
) => {
    const url = "attributeVehicle/deleteAttributeVehicle";
    const params = {
        typeAttributeVehicleId, id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        url, { params }
    );
    return result;
}