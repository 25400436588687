import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

// Analysis Report Pages
import MarketingCampaigns from './pages/AnalysisReport/MarketingCampaigns'
import MarketingCampaignsDetail from './pages/AnalysisReport/MarketingCampaignsDetail'
import Customers from './pages/AnalysisReport/Customers'
import VIPCustomerCare from './pages/AnalysisReport/VIPCustomerCare';
import CustomerCare from './pages/AnalysisReport/CustomerCare';
import Voucher from './pages/AnalysisReport/Voucher';
import VoucherDetail from './pages/AnalysisReport/VoucherDetail'

// Customers Pages
import CustomerList from './pages/Customers/CustomerList';
import CustomerDetail from './pages/Customers/CustomerDetail';
import CreateGroup from './pages/Customers/CustomerGroup/Component/Content/CreateGroup';
import DetailGroup from './pages/Customers/CustomerGroup/Component/Content/DetailGroup';
import PoolingData from './pages/Customers/PoolingData';
import PoolingDataDetail from './pages/Customers/PoolingDataDetail';
import PoolingDataMapping from './pages/Customers/PoolingDataMapping';
import PoolingDataAuto from './pages/Customers/PoolingDataAuto';
import PoolingDataAutoDetail from './pages/Customers/PoolingDataAutoDetail';
import PoolingDataSetup from './pages/Customers/PoolingDataSetup';
import Grouping from './pages/Customers/CustomerGroup/Component/Grouping';
import CustomerPoolingDataHistory from './pages/Customers/PoolingDataHistory';
import CustomerPoolingDataTemplateManagement from './pages/Customers/PoolingDataTemplateManagement';
import PoolingDateCustomerList from './pages/Customers/PoolingDateCustomerList';

// Member Card Pages
import CardIssuance from './pages/MemberCard/CardIssuance';
import ClassList from './pages/MemberCard/ClassList';
import AddRank from './pages/MemberCard/AddRank';
import EditRank from './pages/MemberCard/EditRank';
import ClassBenefits from './pages/MemberCard/ClassBenefits';

// Vehicle Management Pages
import VehicleList from './pages/VehicleManagement/VehicleList';
import BrandManagement from './pages/VehicleManagement/BrandManagement';
import VehicleTypeManagement from './pages/VehicleManagement/VehicleTypeManagement';
import VehicleCategory from './pages/VehicleManagement/VehicleCategoryManagement';
import VehicleVersionManagement from './pages/VehicleManagement/VehicleVersionManagement';

// Marketing Pages
import CampaignSendsNotifications from './pages/Marketing/CampaignSendsNotifications';
import CampaignSendsNotificationsDetail from './pages/Marketing/CampaignSendsNotificationsDetail';
import NotificationTemplatesManagement from './pages/Marketing/NotificationTemplatesManagement';
import NotificationSMSEdit from './pages/Marketing/NotificationSMSEdit';
import NotificationZNSCreate from './pages/Marketing/NotificationZNSCreate';
import NotificationZNSEdit from './pages/Marketing/NotificationZNSEdit';
import NotificationEmailCreate from './pages/Marketing/NotificationEmailCreate';
import NotificationEmailEdit from './pages/Marketing/NotificationEmailEdit';
import PreviewSentEmail from './pages/Marketing/PreviewSentEmail';
import PreviewSentEmail2 from './pages/Marketing/PreviewSentEmail2';
import NotificationFeesSetting from './pages/Marketing/NotificationFeesSetting';
import VouchersManagement from './pages/Marketing/VouchersManagement';
import VoucherSearch from './pages/Marketing/VoucherSearch';
import VoucherCreate from './pages/Marketing/VoucherCreate';
import VoucherEdit from './pages/Marketing/VoucherEdit';
import MarketingVoucherDetail from './pages/Marketing/VoucherDetail';

// Departmental Unit Pages
import Nationwide from './pages/DepartmentalUnit/NationWide';
import Regions from './pages/DepartmentalUnit/Regions';
import Units from './pages/DepartmentalUnit/Units';
import Departments from './pages/DepartmentalUnit/Departments';
import IssuingUnit from './pages/DepartmentalUnit/IssuingUnit';
import DepartmentUnitAgency from './pages/DepartmentalUnit/Agency/Agency';

// System Configuration Pages
import AccountsManagement from './pages/SystemConfiguration/AccountsManagement';
import AccountRole from './pages/SystemConfiguration/AccountRole';
import CreateAccountRole from './pages/SystemConfiguration/AccountRole/CreateGroupRole';
import EditAccountRole from './pages/SystemConfiguration/AccountRole/EditGroupRole';
import InfoGroupRole from './pages/SystemConfiguration/AccountRole/InfoGroupRole';
import GeneralConfiguration from './pages/SystemConfiguration/GeneralConfiguration';

// Page Not Found
import NotFound from './pages/NotFound';
import AddCustomer from './pages/Customers/Component/AddCustomer';
import CustomerGroup from './pages/Customers/CustomerGroup/index';
import UpdateCustomer from './pages/Customers/Component/UpdateCustomer';
import Login from './pages/Login';
import useResetData from './hook/useResetData';
import axiosClient from './api/axiosClient';
import tokenService from './services/token';
import ToastGlobal from './components/ToastGlobal';

import NotificationCMSCreate from './pages/Marketing/NotificationCMSCreate';
import LoadingGlobal from './components/LoadingGLobal';
import CustomerHobby from './pages/Customers/Hobby/CustomerHobby';
import CustomerCareer from './pages/Customers/Career/CustomerCareer';

const App: React.FC = () => {

  const navigate = useNavigate()
  const { resetLoginExpired } = useResetData()
  const token = tokenService.getToken()

  axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if ((error.response) && error.response.status === 401) {
        resetLoginExpired()
        navigate("/login")
        return error?.response?.data;
      }
    }
  );

  useEffect(() => {
    if (!token) {
      navigate("/login")
    }
  }, [token, navigate]);

  return (
    <>
      <ToastGlobal />
      <LoadingGlobal />
      <Routes>



        <Route path="/login" element={<Login />} />

        {/* Analysis Report Pages */}
        {/* <Route path="/analysis-report/marketing-campaigns" element={<MarketingCampaigns />} /> */}
        <Route path="/" element={<MarketingCampaigns />} />
        <Route path="/marketing-campaigns/:id" element={<MarketingCampaignsDetail />} />
        <Route path="/analysis-report/customers" element={<Customers />} />
        <Route path="/analysis-report/vip-customer-care" element={<VIPCustomerCare />} />
        <Route path="/analysis-report/customer-care" element={<CustomerCare />} />
        <Route path="/analysis-report/voucher" element={<Voucher />} />
        <Route path="/analysis-report/voucher/:id" element={<VoucherDetail />} />

        {/* Customer Pages */}
        <Route path="/customer/list" element={<CustomerList />} />
        <Route path="/customer/list/:id" element={<CustomerDetail />} />
        <Route path="/customer/add-customer" element={<AddCustomer />} />
        <Route path="/customer/update-customer/:id" element={<UpdateCustomer />} />
        <Route path="/customer/group" element={<CustomerGroup />} />
        <Route path="/customer/grouping" element={<Grouping />} />
        <Route path="/customer/create-group" element={<CreateGroup />} />
        <Route path="/customer/detail-group/:id" element={<DetailGroup />} />
        <Route path="/customer/pooling-data" element={<PoolingData />} />
        <Route path="/customer/pooling-data/merge-manually" element={<PoolingDataDetail />} />
        <Route path="/customer/pooling-data/mapping" element={<PoolingDataMapping />} />
        <Route path="/customer/pooling-data/pooling-data-auto" element={<PoolingDataAuto />} />
        <Route path="/customer/pooling-data/pooling-data-auto-detail" element={<PoolingDataAutoDetail />} />
        <Route path="/customer/pooling-data/pooling-data-setup" element={<PoolingDataSetup />} />
        <Route path="/customer/pooling-data/history" element={<CustomerPoolingDataHistory />} />
        <Route path="/customer/pooling-data/template-management" element={<CustomerPoolingDataTemplateManagement />} />
        <Route path="/customer/pooling-data/customer-list" element={<PoolingDateCustomerList />} />
        <Route path="/customer/hobby" element={<CustomerHobby />} />
        <Route path="/customer/career" element={<CustomerCareer />} />

        {/* Member Card Pages */}
        <Route path="/member-card/card-issuance" element={<CardIssuance />} />
        <Route path="/member-card/class-list" element={<ClassList />} />
        <Route path="/member-card/add-rank" element={<AddRank />} />
        <Route path="/member-card/edit-rank/:id" element={<EditRank />} />
        <Route path="/member-card/class-benefits" element={<ClassBenefits />} />

        {/* Vehicle Management Pages */}
        <Route path="/vehicle-management/vehicle-list" element={<VehicleList />} />
        <Route path="/vehicle-management/brand-management" element={<BrandManagement />} />
        <Route path="/vehicle-management/vehicle-type-management" element={<VehicleTypeManagement />} />
        <Route path="/vehicle-management/vehicle-category-management" element={<VehicleCategory />} />
        <Route path="/vehicle-management/vehicle-version-management" element={<VehicleVersionManagement />} />

        {/* Marketing Pages */}
        <Route path="/marketing/campaign-sends-notifications" element={<CampaignSendsNotifications />} />
        <Route path="/marketing/campaign-sends-notifications/:id" element={<CampaignSendsNotificationsDetail />} />
        <Route path="/marketing/notification-templates-management" element={<NotificationTemplatesManagement />} />
        <Route path="/marketing/notification-templates-management/add" element={<NotificationCMSCreate />} />
        <Route path="/marketing/notification-templates-management/edit-sms" element={<NotificationSMSEdit />} />
        <Route path="/marketing/notification-templates-management/addzns" element={<NotificationZNSCreate />} />
        <Route path="/marketing/notification-templates-management/editzns" element={<NotificationZNSEdit />} />
        <Route path="/marketing/notification-templates-management/addemail" element={<NotificationEmailCreate />} />
        <Route path="/marketing/notification-templates-management/edit-email" element={<NotificationEmailEdit />} />
        <Route path="/marketing/notification-templates-management/previewemail" element={<PreviewSentEmail />} />
        <Route path="/marketing/notification-templates-management/previewemail2" element={<PreviewSentEmail2 />} />
        <Route path="/marketing/notification-fees-setting" element={<NotificationFeesSetting />} />
        <Route path="/marketing/vouchers-management" element={<VouchersManagement />} />
        <Route path="/marketing/vouchers-management/add" element={<VoucherCreate />} />
        <Route path="/marketing/vouchers-management/edit/:id" element={<VoucherEdit />} />
        <Route path="/marketing/vouchers-management/:id" element={<MarketingVoucherDetail />} />
        <Route path="/marketing/voucher-search" element={<VoucherSearch />} />

        {/* Departmental Unit Pages */}
        <Route path="/departmental-unit/nationwide" element={<Nationwide />} />
        <Route path="/departmental-unit/regions" element={<Regions />} />
        <Route path="/departmental-unit/units" element={<Units />} />
        <Route path="/departmental-unit/departments" element={<Departments />} />
        <Route path="/departmental-unit/issuing-unit" element={<IssuingUnit />} />
        <Route path="/departmental-unit/agency" element={<DepartmentUnitAgency />} />

        {/* System Configuration Pages */}
        <Route path="/system-configuration/accounts-management" element={<AccountsManagement />} />
        <Route path="/system-configuration/account-role" element={<AccountRole />} />
        <Route path="/system-configuration/create-account-role" element={<CreateAccountRole />} />
        <Route path="/system-configuration/edit-account-role" element={<EditAccountRole />} />
        <Route path="/system-configuration/info-account-role" element={<InfoGroupRole />} />
        <Route path="/system-configuration/general-configuration" element={<GeneralConfiguration />} />

        {/* Not Found Page */}
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
