import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import React, { ReactNode } from 'react'
import './ARCustomerStatisticalCard.scss'

interface Props {
  className?: string;
  title: string,
  icon: ReactNode,
  number: number,
  type: 'increase' | 'decrease',
  percent: number,
}

const numberFormat = (number: any) => {
  const result = new Intl.NumberFormat().format(number)
  return result
}

const ARCustomerCard: React.FC<Props> = (props) => {
  const {className, title, icon, number, type, percent} = props

  return (
    <div className={`ar-customer-statistical-card ${className}`}>
      <div className="title">
        <span>{ title }</span>
        { icon }
      </div>
      <div className="number">{ numberFormat(number) }</div>
      <div className="percent">
        <span>{ type === 'increase' ? 'Tăng' : 'Giảm'}&nbsp;</span>
        <span>{ percent }%</span>
        {
          type === 'increase'
            ? <CaretUpOutlined />
            : <CaretDownOutlined />
        }
      </div>
      <img src="/images/brand-icon--blue-24p.svg" alt="" className="bg-img" />
    </div>
  )
}

export default ARCustomerCard