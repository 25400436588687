import { ArrowLeftOutlined, DesktopOutlined, DownOutlined, TabletOutlined } from "@ant-design/icons"
import { Breadcrumb } from "antd"
import { useState } from "react"
import { Link } from "react-router-dom"
import Layout from "../../Layout"

const PreviewSentEmail: React.FC = () => {
  const [activeBtn, setActiveBtn] = useState(1)
  return (
    <Layout>
      <div className="app-breadcrumb-block">
        <Breadcrumb
          items={[
            { title: <Link to="/">THACO AUTO CRM</Link>, },
            { title: <Link to="/marketing/notification-templates-management">Marketing</Link>, },
            { title: <Link to="/marketing/notification-templates-management">...</Link>, },
            { title: <Link to="/marketing/notification-templates-management/addemail">Tạo nội dung mẫu Email</Link>, },
            { title: 'Xem trước', }
          ]}
        />
        <div className="app-breadcrumb-title">
          <Link to='/marketing/notification-templates-management/addemail'><ArrowLeftOutlined /></Link>
          <div className="title">Xem trước </div>
        </div>
        <div className="py-6 d-flex justify-between pl-4">
          <div className="d-flex align-center gap-3">
            <img src="/images/exportImg/logo-mail.svg" alt="" className="h-8 w-88-px" />
            <span className="h-3 border-EDEDEE"></span>
            <p className="text-base text-344054 fw-600 font-bold">Giao diện khi bấm vào xem Email </p>
          </div>
          <div className="d-flex gap-2">
            <button
              className={`d-flex align-center gap-2 bg-white rounded-2  py-3 px-10-px  text-sm font-regular ${activeBtn === 1 ? 'border-40A9FF text-1890FF' : 'border-EAECF0 text-344054'}`}
              onClick={() => setActiveBtn(1)}
            >
              <DesktopOutlined className={`text-18-px ${activeBtn === 1 ? 'text-1890FF' : 'text-344054'}`} />
              Máy tính
            </button>
            <button className={`d-flex align-center gap-2 bg-white rounded-2  py-3 px-10-px  text-sm font-regular ${activeBtn === 2 ? 'border-40A9FF text-1890FF' : 'border-EAECF0 text-344054'}`}
              onClick={() => setActiveBtn(2)}
            >
              <TabletOutlined className={`text-18-px ${activeBtn === 2 ? 'text-1890FF' : 'text-344054'}`} />
              Điện thoại
            </button>
          </div>
        </div>
        <div className="border-F2F4F7 rounded-2 p-6">
          <div className="d-flex flex-column gap-3">
            <p className="text-base text-344054 fw-600 font-bold">THACO AUTO Khuyến mãi lên đến 10.000.000 đồng </p>
            <div className="d-flex align-center gap-3">
              <img src="/images/exportImg/Logo-thaco.svg" alt="" className="w-15 h-15" />
              <div className="d-flex flex-column ">
                <p className="text-base text-344054 fw-600 font-bold ">THACO AUTO <span className="text-sm font-regular text-98A2B3">(cskh@thacoauto.com.vn)</span> </p>
                <p className="text-base font-regular text-98A2B3">đến tôi</p>
              </div>
            </div>
            <div className="d-flex flex-column gap-18-px">
              <img src="/images/exportImg/img-thaco-car.svg" alt="" />
              <div className="d-flex align-center justify-center gap-4">
                <p className="text-base text-344054 font-bold fw-600">Xem chi tiết</p>
                <DownOutlined className="text-344054 text-24-px" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}
export default PreviewSentEmail
