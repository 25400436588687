
import axiosClient, { ResponseSuccess } from "../axiosClient";


export const activeArray = [
    "Chưa kích hoạt", "Đang sử dụng"
]
export interface ISelectOption {
    value: number
    label: string
}

export interface Phone {
    type: boolean,
    phone: string,
}
export interface IProvince {
    id: number
    areaId: number
    title: string
}

export interface IHobby {
    id: number
    title: string
}

export interface ITag {
    id: number
    title: string
}
export interface IGroup {
    title: string
    description: string
    type: number
    image: any
    nameAdmin: string
    dateCreate: string
    groupCustomerDetails: number
}

export interface ICarCompany {
    id: number
    title: string
    image: string
}
export interface ICar {
    id: number
    title: string
    image: string
    seatingCapacity: number
    carLinesTitle: string
    categoryCar: string
}

export interface IRelative {
    id: number
    title: string
}

export interface IStaff {
    id: number
    username: string
}

export const getListProvince = async () => {
    const path = "province/listProvince"
    const result: ResponseSuccess<IProvince[]> = await axiosClient.get(
        path, {}
    );
    return result;
};

export const getListHobby = async () => {
    const path = "hobby/listHobby"
    const result: ResponseSuccess<IHobby[]> = await axiosClient.get(
        path, {}
    );
    return result;
};

export const getListTag = async () => {
    const path = "tags/listTags"
    const result: ResponseSuccess<ITag[]> = await axiosClient.get(
        path, {}
    );
    return result;
};

export const getListGroup = async (search?: string) => {
    const params = { search }
    const path = "groupCustomer/listGroup"
    const result: ResponseSuccess<IGroup[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};


export const getListCarCompany = async () => {
    const path = "apiChild/listCategoryCar"
    const result: ResponseSuccess<ICarCompany[]> = await axiosClient.get(
        path, {}
    );
    return result;
};


export const getListCar = async (categoryCarId?: number | null, search?: string) => {
    const params = { categoryCarId, search }
    const path = "apiChild/listCar"
    const result: ResponseSuccess<ICar[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

export const getListRelatives = async () => {
    const path = "relatives/listRelatives"
    const result: ResponseSuccess<IRelative[]> = await axiosClient.get(
        path, {}
    );
    return result;
};

export const getCLCarLines = async (categoryCarId: number) => {
    const path = "apiChild/listCarLines"
    const params = { categoryCarId }
    const result: ResponseSuccess<IRelative[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Danh sách chủng loại xe
export const getCLTypeVehicle = async (IdCarlines: number) => {
    const path = "apiChild/listTypeVehicle"
    const params = { IdCarlines }
    const result: ResponseSuccess<IRelative[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Danh sách loại tương tác
export const getCLTypeInteraction = async () => {
    const path = "apiChild/listTypeInteraction"
    const params = {}
    const result: ResponseSuccess<ICarCompany[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Danh sách quốc gia
export const getCLCountry = async (limit?: number, pageNumber?: number) => {
    const path = "apiChild/listCountry"
    const params = { limit, pageNumber }
    const result: ResponseSuccess<ICarCompany[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Danh sách phiên bản xe
export const getCLVersion = async (idTypeVehicle: number) => {
    const path = "apiChild/getlListVersion"
    const params = { idTypeVehicle }
    const result: ResponseSuccess<ITag[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Danh sách nhân vie
export const getCLStaff = async () => {
    const path = "apiChild/listStaff"
    const params = {}
    const result: ResponseSuccess<IStaff[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};
