

import * as React from "react";
// import "../styles.css";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/plugins/code_beautifier.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import Froala from "react-froala-wysiwyg";
import './FroalaEditor.scss'
// const defaultContent = `<div>

//   </div>`;

const froalaEditorConfig = {
  // nlineMode: true,
  attribution: false,
  height: 400,
  quickInsertEnabled: false,
  imageDefaultWidth: 0,
  imageResizeWithPercent: true,
  imageMultipleStyles: false,
  imageOutputSize: true,
  imageRoundPercent: true,
  imageMaxSize: 1024 * 1024 * 2.5,
  codeBeautifierOptions: {
    end_with_newline: true,
    indent_inner_html: true,
    extra_liners: "['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pre', 'ul', 'ol', 'table', 'dl']",
    brace_style: 'expand',
    indent_char: '\t',
    indent_size: 1,
    wrap_line_length: 0
  },
  codeViewKeepActiveButtons: ['selectAll', 'moreMisc'],
  imageEditButtons: [
    "imageReplace",
    "imageAlign",
    "imageRemove",
    "imageSize",
    "-",
    "imageLink",
    "linkOpen",
    "linkEdit",
    "linkRemove"
  ],
  imageAllowedTypes: ["jpeg", "jpg", "png", "gif"],
  imageInsertButtons: ["imageBack", "|", "imageUpload"],
  placeholderText: "Soạn thảo nội dung",
  colorsStep: 5,
  colorsText: [
    "#000000",
    "#2C2E2F",
    "#6C7378",
    "#FFFFFF",
    "#009CDE",
    "#003087",
    "#FF9600",
    "#00CF92",
    "#DE0063",
    "#640487",
    "REMOVE"
  ],
  colorsBackground: [
    "#000000",
    "#2C2E2F",
    "#6C7378",
    "#FFFFFF",
    "#009CDE",
    "#003087",
    "#FF9600",
    "#00CF92",
    "#DE0063",
    "#640487",
    "REMOVE"
  ],
  toolbarButtons: {
    moreText: {
      buttons: [
        "paragraphFormat",
        "fontSize",
        "textColor",
        "backgroundColor",
        "insertImage",
        "alignLeft",
        "alignRight",
        "alignJustify",
        "formatOL",
        "formatUL",
        "indent",
        "outdent"
      ],
      buttonsVisible: 6
    },
    moreRich: {
      buttons: [
        "|",
        "bold",
        "italic",
        "underline",
        "insertHR",
        "insertLink",
        "insertTable",
        "html"
      ],
      name: "additionals",
      buttonsVisible: 3
    },
    // dummySection: {
    //   buttons: ["|"]
    // },
    moreMisc: {
      buttons: ["|", "undo", "redo", "help"],
      align: "right",
      buttonsVisible: 3
    }
  },
  tableEditButtons: [
    "tableHeader",
    "tableRemove",
    "tableRows",
    "tableColumns",
    "tableStyle",
    "-",
    "tableCells",
    "tableCellBackground",
    "tableCellVerticalAlign",
    "tableCellHorizontalAlign"
  ],
  tableStyles: {
    grayTableBorder: "Gray Table Border",
    blackTableBorder: "Black Table Border",
    noTableBorder: "No Table Border"
  },
  toolbarSticky: true,
  pluginsEnabled: [
    "align",
    "colors",
    "entities",
    "fontSize",
    "help",
    "image",
    "link",
    "lists",
    "paragraphFormat",
    "paragraphStyle",
    "save",
    "table",
    "wordPaste",
    'codeView', 'codeBeautifier'
  ],
  events: {
    "image.beforeUpload": function () {
    }
  }
};

interface Props {
  content?: any,
  onChange?: (elem: any) => void
}

const Ckeditor: React.FC<Props> = (props) => {
  const { content, onChange } = props
  const [value, setValue] = React.useState<string>('')

  // const onModelChange = (model: any) => {
  //   let edData = model;
  //   let elem = document.createElement("div");
  //   elem.innerHTML = edData;
  //   let textContent = elem.textContent || elem.innerText || '';
  //   if (onChange) {
  //     onChange(edData)
  //   }
  // }

  const onModelChange = (model: string) => {
    // setValue(model)
    // props.content = value
    // let edData = model;
    // let elem = document.createElement("div");
    // elem.innerHTML = edData;
    // let textContent = elem.textContent || elem.innerText || '';
    if (onChange) {
      onChange(model)
    }

  };

  return (
    <div className="App custom-froala">
      <Froala
        model={content}
        onModelChange={onModelChange}
        tag="textarea"
        config={froalaEditorConfig}
      ></Froala>
    </div>
  )
}

export default Ckeditor
