import React from "react";
import RightPopup from "../../../components/Popup/RightPopup";
import { Progress } from "antd";
import { CrownOutlined } from "@ant-design/icons";
import './RankBenefitPopup.scss';
import { ICardCustomer } from "../../../api/rank/rankApi";
import { URL_RANK } from "../../../env";

interface Props {
  className?: string;
  infoCardCustomer?: ICardCustomer
  onClose?: () => void;
}

const RankBenefitPopup: React.FC<Props> = ({
  className, infoCardCustomer,
  onClose
}) => {
  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <RightPopup
      title="Chi tiết"
      className={className}
      contentClassName="rank-benefit-popup__content"
      onClose={handleOnClose}
    >
      <div className="rank-block">
        <span className="font-medium rank-label">Hạng</span>

        <div className="rank">
          {/* <img
            src={`${URL_RANK}${infoCardCustomer?.infoCustomer.rank.icon}`}
            alt="Vàng"
            className='rank-img'
          />
          <span className='rank-name'>{infoCardCustomer?.infoCustomer.rank.title}</span> */}
          <img
            src='/images/medal/golden.svg'
            alt="Vàng"
            className='rank-img'
          />
          <span className='rank-name'>Thành viên Vàng</span>
          {/* <span className='rank-point primary-7'>(3000 Điểm)</span> */}
        </div>
      </div>

      <div className="rank-progress">
        <Progress
          percent={Number(infoCardCustomer?.point ?? 40)}
          showInfo={false}
          className="rs-progress primary"
        />
        {/* <span className="neutral-gray-500">{`Tiến độ thăng hạng ${infoCardCustomer?.point}%`}</span> */}
        <span className="neutral-gray-500">{`Tiến độ thăng hạng 40%`}</span>
      </div>

      <div className="benefit-block">
        <div className="benefit-block__title">Quyền lợi hạng</div>
        <div className="benefit-card">
          {/* {
            infoCardCustomer?.listBenefit.map((item, i) =>
              <div
                key={i}
                className="item"
              >
                <CrownOutlined />
                <span className="neutral-gray-500">{item.description}</span>
              </div>
            )
          } */}
          <div className="item">
            <CrownOutlined />
            <span className="neutral-gray-500">Giảm 20% khi sử dụng dịch vụ từ THACO AUTO</span>
          </div>
          <div className="item">
            <CrownOutlined />
            <span className="neutral-gray-500">Tặng thêm 10% tích luỹ điểm thưởng tại các đơn vị liên kết</span>
          </div>
          <div className="item">
            <CrownOutlined />
            <span className="neutral-gray-500">Giảm 20% khi sử dụng dịch vụ từ THACO AUTO</span>
          </div>
          <div className="item">
            <CrownOutlined />
            <span className="neutral-gray-500">Giảm 20% khi sử dụng dịch vụ từ THACO AUTO</span>
          </div>

          <img
            src="/images/brand-icon--blue.svg"
            alt="THACO AUTO"
            className="img-brand"
          />
        </div>
      </div>
    </RightPopup>
  )
}

export default RankBenefitPopup
