import React, { useEffect, useState } from 'react';
import Layout from '../../Layout';
import { Breadcrumb, Input, Table, Pagination, ConfigProvider, notification, Switch } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { mixGetV2, mixPostV2 } from '../../services/mixin'
import useToast from "../../hook/useToast";

import {
  EyeOutlined, EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  DownOutlined,
  CloseCircleOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import type { SearchProps } from 'antd/es/input/Search';
import viVN from 'antd/locale/vi_VN';
import { Link, useFetcher } from 'react-router-dom';
import './PoolingDataSetup.scss'
import PopupShowData from './ComponentPoolingData/PopupShowData';
import ShowParam from './ShowParam';
import ModalParamCreate from './Modal/ModalParamCreate';
import ModalParamEdit from './Modal/ModalParamEdit';

interface DataType {
  key: React.Key
}

const { Search } = Input;

const PoolingDataSetup: React.FC = () => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);

  const pushToast = useToast();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [isShowData, setShowData] = useState(false);
  const [isShowParam, setShowParam] = useState(false)
  const [openParamCreate, setOpenParamCreate] = useState(false)
  const [openParamEdit, setOpenParamEdit] = useState(false)

  const [showSelectFilter, setActive] = useState(false);
  const handleClearFilter = () => {
    setActive(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (event.target.matches('.filter-select, .filter-select *, .list-filter, .list-filter *') === false) {
        setActive(false)
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const columns: TableColumnsType<DataType> =  [
    {
      title: 'Hành động',
      dataIndex: '',
      align: 'center',
      key: 'action',
      render: (_, row) => (
        <>
          <div className="t-hd-table">
            <div className="item-hd-table" onClick={() => {setShowParam(true); setEditData(row)}}><span><EyeOutlined /></span></div>
            <div className="item-hd-table cl-1890FF" onClick={() => {setOpenParamEdit(true); setEditData(row)}}><span><EditOutlined /></span></div>
            {/* <div className="item-hd-table cl-1890FF"><span><CopyOutlined /></span></div> */}
          </div>
        </>
      ),
    },
    {
      title: 'Tên Param',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mã Param',
      dataIndex: 'maparam',
      align: 'center',
      key: 'maparam',
      render: (d: any, row: any) => (
        row.column_warehouse?.column
      ),
    },
    // {
    //   title: 'Hệ thống',
    //   dataIndex: 'hethong',
    //   align: 'center',
    //   render: (text: any, row: any) => (
    //     <>
    //       <div className="t-table-list-tags">
    //         <div className="item-tag">{row.source?.name}</div>
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: 'Trạng thái',
      dataIndex: 'date',
      align: 'right',
      render: (_, row: any) => (
        <>
          <Switch
            loading={waits[`id${row.id}`]}
            checked={row.is_active === 1}
            onChange={(e) => {activeChange(e, row)}}
            className="rs-switch rs-switch--sm"
          />
        </>
      ),
    },
  ];

  const [waits, setWaits] = useState({} as any)
  const activeChange = async (e: any, item: any) => {
    // const temp = item
    // if (e === true) {
    //   temp.is_active = 1
    // } else {
    //   temp.is_active = 0
    // }

    const param = {
      is_active: item.is_active === 0 ? 1 : 0,
      _method: 'PUT',
    }

    let wait = {} as any
    wait[`id${item.id}`] = true
    setWaits({...waits, ...wait})

    const res = await mixPostV2(`/api/v1/be/source_column_warehouse/${item.id}`, param)
    if (res.status) {
      wait[`id${item.id}`] = false
      setWaits({...waits, ...wait})

      const findData = listData.find((x: any) => x.id === item.id) as any
      if (findData) {
        findData.is_active = param.is_active
      }
      setListData([...listData])
    } else {
      pushToast("Error!", "warning")
    }
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  const onChangeTB: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const setShowNoti = () => {
    setShowData(false)
    openNotification()
  };

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api['success']({
      message: 'Tạo Voucher thành công!',
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    setParamListData({...paramListData, name: value})
  }

  const [paramListData, setParamListData] = useState({
    'sort[]': 'id,desc',
    page: 1,
    limit: 100000,
    name: '',
    type_id : null,
  })
  let wait = false
  const [listData, setListData] = useState([])
  const getListData = async () => {
    if (wait) {
      return
    }
    wait = true
    const res = await mixGetV2('/api/v1/be/source_column_warehouses', paramListData)
    if (res.status) {
      setListData(res.data.items)
    }
  }

  useEffect(() => {
    getListData()
  }, [paramListData])

  const [listSource, setListSource] = useState([] as any)
  let wait1 = false
  const getSource = async () => {
    if (wait1) {
      return
    }
    wait1 = true
    const res = await mixGetV2('/api/v1/fe/setting/column_warehouse')
    wait1 = false
    if (res.status) {
      const items = JSON.parse(res.data.value)
      const temp = [] as any
      items.map((val: any) => {
        temp.push({
          value: val.id,
          label: val.name,
        })
        return true
      })
      setListSource([...temp])
    }
  }

  useEffect(() => {
    getSource()
  }, [])

  const [editData, setEditData] = useState(null as any)
  const [ftlabel, setFtlabel] = useState('Chọn loại')

  return (
    <div className='pooling-data-auto-detail customer-list'>
      {contextHolder}
      {/* <Helmet>
        <title>THACO AUTO CRM</title>
      </Helmet> */}
      <Layout>
        <Breadcrumb
          items={[
            {
              title: 'THACO AUTO CRM',
            },
            {
              title: <a href="/customer/list">Khách hàng</a>,
            },
            {
              title: <a href="/customer/pooling-data">Gộp dữ liệu khách hàng</a>,
            },
            {
              title: 'Cài đặt param hệ thống',
            },
          ]}
        />

        <div className='title-page t-title-btn-back'>
          <Link to="/customer/pooling-data" className="icon-svg">
            <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.6564 7.80321H4.04305L10.3955 2.37463C10.4971 2.28713 10.4354 2.12463 10.3012 2.12463H8.69582C8.62508 2.12463 8.55796 2.14963 8.50536 2.19428L1.65045 8.04963C1.58767 8.10321 1.53732 8.16944 1.50281 8.24385C1.4683 8.31825 1.45044 8.39909 1.45044 8.48088C1.45044 8.56268 1.4683 8.64352 1.50281 8.71792C1.53732 8.79233 1.58767 8.85856 1.65045 8.91213L8.54526 14.8032C8.57247 14.8264 8.60512 14.8389 8.63959 14.8389H10.2993C10.4336 14.8389 10.4953 14.6746 10.3937 14.5889L4.04305 9.16035H14.6564C14.7363 9.16035 14.8016 9.09606 14.8016 9.01749V7.94606C14.8016 7.86749 14.7363 7.80321 14.6564 7.80321Z" fill="currentColor" fillOpacity="0.88" />
            </svg>
          </Link>
          Cài đặt param hệ thống
        </div>

        {/* Fillter block */}
        <div className='filter-block t-mb-18'>
          <div className='t-search-block w-100'>
            <Search placeholder="Tìm tên param" onSearch={onSearch} />
          </div>

          <div className='filter-select'>
            <div
              className='btn-select-show'
            >
              <span className="content-select" onClick={() => setActive(true)} >{ftlabel}</span>
              <div
                className={`icon-down ${showSelectFilter ? "active" : ""}`}
                onClick={() => setActive(true)}
              >
                <DownOutlined />
              </div>
              <div
                className={`icon-close ${showSelectFilter && ftlabel !== 'Chọn loại' ? "active" : ""}`}
                onClick={() => {handleClearFilter(); setFtlabel('Chọn loại'); setParamListData({...paramListData, type_id : null})}}
              >
                <CloseCircleOutlined />
              </div>
            </div>
            <div className={`list-filter ${showSelectFilter ? "active" : ""}`}>
              <div className="list-item">
                {
                  listSource.map((val: any) => (
                    <div key={val.value} className="item-select" onClick={() => {handleClearFilter(); setFtlabel(val.label); setParamListData({...paramListData, type_id : val.value})}} >{val.label}</div>
                  ))
                }
              </div>
            </div>
          </div>

          {/* <div
            className="btn-input btn-primary"
            onClick={() => setOpenParamCreate(true)}
          >
            <div className="text">Tạo mới</div>
          </div> */}
        </div>
        <div className="t-table-block t-table-pooling-data-auto-detail">
          <Table
            columns={columns}
            dataSource={listData}
            showSorterTooltip={false}
            className="rs-table"
            rowKey="id"
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 15,
              showTotal: (total) => `Tổng cộng ${Math.ceil(total / 15)} trang`,
              locale: { items_per_page: ' / trang', jump_to: 'Tới' },
              position: ['bottomCenter'],
              className: 'rs-pagination',
              showQuickJumper: true,
            }}
          />
        </div>

        {/* <div className="t-table-block t-table-pooling-data-auto-detail">
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
            columns={columns}
            dataSource={data}
            onChange={onChangeTB}
            pagination={false}
            showSorterTooltip={false}
          />

          <ConfigProvider locale={viVN}>
            <div className="pagination-block">
              <Pagination
                total={85}
                showSizeChanger
                showQuickJumper
                showTotal={(total) => `Tổng cộng ${total} trang`}
                locale={{ items_per_page: 'trang' }}
              />
            </div>
          </ConfigProvider>
        </div> */}

        {
          isShowParam
            ? <ShowParam
              className="show"
              detailData={editData}
              closeModal={() => setShowParam(!isShowParam)}
            />
            : null
        }

        <ModalParamCreate
          isOpen={openParamCreate}
          closeModal={() => setOpenParamCreate(!openParamCreate)} data={[]}
        />

        <ModalParamEdit
          isOpen={openParamEdit}
          editData={editData}
          closeModal={() => setOpenParamEdit(false)} data={[]}
          modalFinish={() => {setOpenParamEdit(false); getListData()}}
        />
      </Layout>
    </div>
  );
}

export default PoolingDataSetup;
