import { EyeOutlined } from '@ant-design/icons';
import { Button, Switch, Table, TableColumnsType } from 'antd';
import React from 'react';
import './AutomatedCampaignTable.scss'

interface Props {
  tableData: Array<any>
  isLoading?: boolean
  onShowDetail?: () => void
}

// TABLE
interface DataType {
  key: React.Key;
  muc_tieu: string;
  ten_chien_dich: string;
  trang_thai: number;
  kenh_gui: string;
  kenh_gui_img: string;
  khach_hang_nhan: string;
  da_gui: number;
  total: number;
  thoi_gian_gui: string;
  da_chi_tieu: number;
  dieu_kien: string;
}

const currencyFormat = (number: any) => {
  try {
    number = parseInt(number);
    const formattedNumber = new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    }).format(number);
    return formattedNumber;
  } catch (error) {
    return number;
  }
};

const numberFormat = (number: any) => {
  let result
  if (typeof number === 'number') {
    result = new Intl.NumberFormat().format(number)
  } else {
    result = number
  }
  return result
}

const AutomatedCampaignTable: React.FC<Props> = (props) => {
  const { tableData, isLoading = false, onShowDetail } = props

  const showDetail = () => {
    if (onShowDetail) {
      onShowDetail()
    }
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Hành động',
      dataIndex: '',
      key: 'action',
      render: () => (
        <div className="d-flex align-center action">
          <Button
            className="rs-button rs-button--icon-only"
            onClick={showDetail}
          ><EyeOutlined /></Button>
          <Switch
            defaultChecked
            className="rs-switch rs-switch--sm"
          />
        </div>
      ),
    },
    {
      title: 'Mục tiêu',
      dataIndex: 'muc_tieu',
      key: 'muc_tieu',
      sorter: true,
      render: (text) => ( <div className='text-2-row'>{ text }</div> )
    },
    {
      title: 'Tên chiến dịch',
      dataIndex: 'ten_chien_dich',
      key: 'ten_chien_dich',
      render: (text) => ( <div className='text-2-row'>{ text }</div> )
    },
    // {
    //   title: 'Trạng thái',
    //   dataIndex: 'trang_thai',
    //   key: 'trang_thai',
    //   sorter: true,
    //   render: (status) => (
    //     // status: 0: 'Chờ gửi' | 1: 'Đang gửi' | 2: 'Đã gửi'
    //     <div className={`status ${status === 2 ? 'status-sent' : status === 1 ? 'status-sending' : 'status-pending'}`}>
    //       <span>{ status === 2 ? 'Đã gửi' : status === 1 ? 'Đang gửi' : 'Chờ gửi' }</span>
    //       <span className="primary-7 cursor-pointer">Log</span>
    //     </div>
    //   ),
    // },
    {
      title: 'Kênh gửi',
      dataIndex: 'kenh_gui',
      key: 'kenh_gui',
      align: 'center',
      // render: (text, record, index) => <img src={record.kenh_gui_img} alt="" className="channel-img" />
      render: (text, record, index) => (
        <div className="d-flex align-center gap-1">
          {
            text.map((item: any) => (
              <img src={item.img} alt={item.text} className="channel-img" />
            ))
          }
        </div>
      )
    },
    {
      title: 'Điều kiện',
      dataIndex: 'dieu_kien',
      key: 'dieu_kien',
    },
    {
      title: 'Khách hàng nhận',
      dataIndex: 'khach_hang_nhan',
      key: 'khach_hang_nhan',
      render: (text) => (
        <a href="/marketing/campaign-sends-notifications/123" className="d-flex justify-between">
          <span>{text}</span>
          <span className="primary-7 cursor-pointer">Xem DS</span>
        </a>
      )
    },
    {
      title: 'Tin đã gửi',
      dataIndex: 'da_gui',
      key: 'da_gui',
      render: (text, record, index) => (
        <>
          <span>{numberFormat(text)}</span>
          {/* &nbsp;/&nbsp;<span className="neutral-gray-400">{ numberFormat(record.total) }</span> */}
        </>
      )
    },
    // {
    //   title: 'Thời gian gửi',
    //   dataIndex: 'thoi_gian_gui',
    //   key: 'thoi_gian_gui',
    //   sorter: true,
    // },
    {
      title: 'Đã chi tiêu',
      dataIndex: 'da_chi_tieu',
      key: 'da_chi_tieu',
      sorter: true,
      render: (value) => (
        <span>{currencyFormat(value)}</span>
      )
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      showSorterTooltip={false}
      className="rs-table automated-campaign-table"
      loading={isLoading}
      pagination={{
        position: ['bottomCenter'],
        defaultPageSize: 15,
        className: 'rs-pagination',
        showQuickJumper: true,
        // hideOnSinglePage: true,
      }}
    />
  )
}

export default AutomatedCampaignTable
