import React, { useState } from 'react'
import Layout from '../../Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { Button, Flex, Input, Table, TableColumnsType, Tag } from 'antd'
import { SearchProps } from 'antd/es/input'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import fakeData  from './pooling-data-history.json'
const { Search } = Input;

interface DataType {
  key: React.Key
  customerName: string
  phoneNumber: string
  source: string | string[]
  date: string
}

const CustomerPoolingDataHistory:React.FC = () => {
  //  SEARCH CHANGE
  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    // console.log(info?.source, value);
  }

  // TABLE COLUMNS
  const columns: TableColumnsType<DataType> = [
    {
      title: 'Hành động',
      dataIndex: '',
      key: 'action',
      render: () => (
        <Flex align='center' className='gap-10px'>
          <Button className="rs-button rs-button--icon-only"><EyeOutlined /></Button>
          <Button className="rs-button rs-button--icon-only edit"><EditOutlined /></Button>
          <Button className="rs-button rs-button--icon-only delete"><DeleteOutlined /></Button>
        </Flex>
      ),
    },
    {
      title: 'Khách hàng',
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Nguồn dữ liệu',
      dataIndex: 'source',
      key: 'source',
      render: (source: any) => (
        <Flex align='center' className='gap-10px'>
          {
            source.map((item: any, index: number) => (
              <Tag key={index} className='rs-tag daybreak-blue'>{ item }</Tag>
            ))
          }
        </Flex>
      ),
      align: 'center'
    },
    {
      title: 'Ngày thực hiện',
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      align: 'end'
    },
  ];

  return (
    <>
      {/* {contextHolder} */}

      <Layout className='pooling-data-history'>
        <BreadcrumbBlock
          items={[
            { title: <Link to="/">THACO AUTO CRM</Link>, },
            { title: 'Khách hàng', },
            { title: <Link to="/customer/pooling-data">Gộp dữ liệu khách hàng</Link>, },
            { title: 'Lịch sử', },
          ]}
          title='Lịch sử'
          backLink='/customer/pooling-data'
          className='gutter-block-end'
        />

        <Flex vertical className='gap-18px'>
          <Search
            placeholder="Tìm kiếm số điện thoại khách hàng"
            allowClear
            className="rs-input-search"
            onSearch={onSearch}
          />

          <Table
            columns={columns}
            dataSource={fakeData}
            showSorterTooltip={false}
            className="rs-table"
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 15,
              showTotal: (total) => `Tổng cộng ${Math.ceil(total / 15)} trang`,
              locale: { items_per_page: ' / trang', jump_to: 'Tới' },
              position: ['bottomCenter'],
              className: 'rs-pagination',
              showQuickJumper: true,
            }}
          />
        </Flex>
      </Layout>
    </>
  )
}

export default CustomerPoolingDataHistory
