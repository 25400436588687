import React, { useState } from 'react'
import Layout from '../../../Layout'
import BreadcrumbBlock from '../../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { Button, Flex, Input, notification, Select, Table, TableColumnsType } from 'antd'
import { SearchProps } from 'antd/es/input'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import tableData from './agencyData.json'
import './Agency.scss'
import AddAgencyModal from './AddAgencyModal'
import ModalDeleteManager from '../../../components/ModalDeleteManager'
import EditAgencyModal from './EditAgencyModal'
const { Search } = Input

interface TableDataType {
  key: React.Key
  name: string
  phoneNumber: string
  email: string
  address: string
}

const DepartmentUnitAgency:React.FC = () => {
  // MODAL
  const [selectedId, setSelectedId] = useState<any>(null)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditHobbyModal, setShowEditModal] = useState(false)
  const [showDeleteHobbyModal, setShowDeleteModal] = useState(false)

  // TABLE COLUMNS
  const columns: TableColumnsType<TableDataType> = [
    {
      title: 'Hành động',
      dataIndex: '',
      key: 'action',
      render: (_, record) => (
        <Flex align='center' className='gap-10px'>
          <Button
            icon={<DeleteOutlined />}
            className="rs-button rs-button--icon-only delete"
            onClick={() => {
              setSelectedId(record.key)
              setShowDeleteModal(true)
            }}
          />
          <Button
            icon={<EditOutlined />}
            className="rs-button rs-button--icon-only edit"
            onClick={() => {
              setSelectedId(record.key)
              setShowEditModal(true)
            }}
          />
        </Flex>
      ),
      width: 93,
    },
    {
      title: 'Đại lý',
      dataIndex: 'name',
      key: 'name',
      width: 329,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 232,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 218,
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      width: 329,
    },
  ]

  // ON SEARCH
  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    // console.log(info?.source, value);
  }

  // DELETE AGENCY
  const [loading, setLoading] = useState(false)
  const onDelete = () => {
    setLoading(true)

    setTimeout(() => {
      setLoading(false)
      setSelectedId(null)
      setShowDeleteModal(false)
      openNotification('success', 'Xóa thành công')
    }, 1500)
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'error' = 'success', message: string = '') => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    })
  }

  return (
    <>
      { contextHolder }

      <Layout className='customer-hobby'>
        <BreadcrumbBlock
          items={[
            { title: <Link to="/">THACO AUTO CRM</Link>, },
            { title: 'Danh mục đơn vị', },
            { title: 'Đại lý', },
          ]}
          title='Đại lý'
          className='gutter-block-end'
        />

        <Flex vertical className="gap-18px">
          <Flex align='center' className='gap-10px agency-filter-block'>
            <span className='fsz-16 font-medium text-nowrap'>Danh sách đại lý</span>
            <Search
              placeholder="Tìm kiếm sở thích"
              allowClear
              className="rs-input-search"
              onSearch={onSearch}
            />
            <Select
              placeholder='Chọn thành phố'
              className='rs-select'
              popupClassName='rs-select-dropdown'
              options={[{ value: '1', label: 'Hà Nội' }, { value: '2', label: 'Hồ Chí Minh' }]}
            />
            <Select
              placeholder='Chọn quận huyện'
              className='rs-select'
              popupClassName='rs-select-dropdown'
              options={[{ value: '1', label: 'Quận/huyện' }]}
            />
            <Button
              type='primary'
              icon={<PlusOutlined />}
              className='rs-button rs-button--sm'
              onClick={() => setShowAddModal(true)}
            >Tạo mới</Button>
          </Flex>

          <Table
            columns={columns}
            dataSource={tableData}
            showSorterTooltip={false}
            className="rs-table rs-table--cell-0-padding-block"
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 15,
              showTotal: (total) => `Tổng cộng ${Math.ceil(total / 15)} trang`,
              locale: { items_per_page: ' / trang', jump_to: 'Tới' },
              position: ['bottomCenter'],
              className: 'rs-pagination',
              showQuickJumper: true,
            }}
          />
        </Flex>
      </Layout>

      {/* MODAL ADD AGENCY */}
      <AddAgencyModal
        show={showAddModal}
        modalClose={() => setShowAddModal(false)}
        onSuccess={() => setShowAddModal(false)}
      />

      {/* MODAL EDIT AGENCY */}
      <EditAgencyModal
        id={Number(selectedId)}
        show={showEditHobbyModal}
        modalClose={() => setShowEditModal(false)}
        onSuccess={() => {
          setSelectedId(null)
          setShowEditModal(false)
        }}
      />

      {/* MODAL DELETE AGENCY */}
      <ModalDeleteManager
        isOpen={showDeleteHobbyModal}
        loading={loading}
        title='Xóa đại lý'
        titleDelete='Xóa'
        content='Đại lý và dữ liệu liên quan sẽ bị xóa vĩnh viễn không thể khôi phục'
        closeModal={() => setShowDeleteModal(false)}
        onConfirm={onDelete}
      />
    </>
  )
}

export default DepartmentUnitAgency
