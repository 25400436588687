import React, { useState } from 'react'
import { Button, DatePicker, Select, TreeSelect } from 'antd';
import { UpOutlined, DownOutlined, SettingOutlined, SearchOutlined } from '@ant-design/icons';
import './ARCCFilterBlock.scss'

const { RangePicker } = DatePicker;
const { SHOW_PARENT } = TreeSelect;

const treeData = [
  {
    title: 'Miền Bắc',
    value: 'mien-bac',
    key: 'mien-bac',
    children: [
      {
        title: 'Hà Nội',
        value: 'ha-noi',
        key: 'ha-noi',
      },
    ],
  },
  {
    title: 'Miền Trung',
    value: 'mien-trung',
    key: 'mien-trung',
    children: [
      {
        title: 'Quảng Trị',
        value: 'quang-tri',
        key: 'quang-tri',
      },
    ],
  },
  {
    title: 'Miền Nam',
    value: 'mien-nam',
    key: 'mien-nam',
    children: [
      {
        title: 'Hồ Chí Minh',
        value: 'ho-chi-minh',
        key: 'ho-chi-minh',
      },
      {
        title: 'Vũng Tàu',
        value: 'vung-tau',
        key: 'vung-tau',
      },
    ],
  },
];

const ARCCFilterBlock: React.FC = () => {
  const [filterExpanded, setFilterExpanded] = useState(false)

  const [value, setValue] = useState([] as Array<any>);
  const onChange = (newValue: string[]) => {
    setValue(newValue);
  };

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    suffixIcon: <SearchOutlined />,
    placeholder: 'Tìm & chọn khu vực',
  };

  return (
    <div className="arcc-filter">
      <div className="arcc-filter__head">
        <span className="title">Dữ liệu khách hàng</span>
        <div className="control">
          { filterExpanded
            ?
              <Button
                className="rs-button"
                onClick={ () => setFilterExpanded(false) }
              >
                <span>Thu gọn</span>
                <UpOutlined />
              </Button>
            :
              <Button
                className="rs-button"
                onClick={ () => setFilterExpanded(true) }
              >
                <span>Mở rộng</span>
                <DownOutlined />
              </Button>
          }

          <Button className="rs-button">
            <span>Nâng cao</span>
            <SettingOutlined />
          </Button>
        </div>
      </div>

      <div className="arcc-filter__body">
        <div className="default-filters">
          <TreeSelect
            className="rs-select"
            popupClassName="rs-select-dropdown"
            {...tProps}
          />

          <Select
            placeholder="Giới tính"
            options={[
              { value: 'nam', label: 'Nam' },
              { value: 'nu', label: 'Nữ' },
            ]}
            className="rs-select"
            popupClassName="rs-select-dropdown"
          />

          <Select
            placeholder="Hạng"
            popupMatchSelectWidth={false}
            options={[
              { value: 'bac', label: 'Bạc' },
              { value: 'vang', label: 'Vàng' },
              { value: 'kim-cuong', label: 'Kim cương' },
            ]}
            className="rs-select"
            popupClassName="rs-select-dropdown"
          />

          <Select
            placeholder="Độ tuổi"
            popupMatchSelectWidth={false}
            options={[
              { value: 'duoi-30', label: 'Dưới 30 tuổi' },
              { value: 'tren-30', label: 'Trên 30 tuổi' },
            ]}
            className="rs-select"
            popupClassName="rs-select-dropdown"
          />

          <Select
            placeholder="Thương hiệu"
            popupMatchSelectWidth={false}
            options={[
              { value: 'thuong-hieu-1', label: 'Thương hiệu 1' },
              { value: 'thuong-hieu-2', label: 'Thương hiệu 2' },
            ]}
            className="rs-select"
            popupClassName="rs-select-dropdown"
          />

          <RangePicker
            className="rs-range-picker"
            popupClassName="rs-range-picker-dropdown"
          />
        </div>

        {
          filterExpanded
            ?
              <div className="advance-filters">
                <Select
                  placeholder="Nghề nghiệp"
                  options={[
                    { value: 'nghe-nghiep-1', label: 'Nghề nghiệp 1' },
                    { value: 'nghe-nghiep-2', label: 'Nghề nghiệp 2' },
                  ]}
                  className="rs-select"
                  popupClassName="rs-select-dropdown"
                />

                <Select
                  placeholder="Thương hiệu"
                  options={[
                    { value: 'thuong-hieu-1', label: 'Thương hiệu 1' },
                    { value: 'thuong-hieu-2', label: 'Thương hiệu 2' },
                  ]}
                  className="rs-select"
                  popupClassName="rs-select-dropdown"
                />

                <Select
                  placeholder="Chủng loại xe"
                  options={[
                    { value: 'chung-loai-1', label: 'Chủng loại xe 1' },
                    { value: 'chung-loai-2', label: 'Chủng loại xe 2' },
                  ]}
                  className="rs-select"
                  popupClassName="rs-select-dropdown"
                />

                <Select
                  placeholder="Phân khúc"
                  options={[
                    { value: 'phan-khuc-1', label: 'Phân khúc 1' },
                    { value: 'phan-khuc-2', label: 'Phân khúc 2' },
                  ]}
                  className="rs-select"
                  popupClassName="rs-select-dropdown"
                />

                <Select
                  placeholder="Dòng xe"
                  options={[
                    { value: 'dong-xe-1', label: 'Dòng xe 1' },
                    { value: 'dong-xe-2', label: 'Dòng xe 2' },
                  ]}
                  className="rs-select"
                  popupClassName="rs-select-dropdown"
                />

                <Select
                  placeholder="Đời xe"
                  options={[
                    { value: 'doi-xe-1', label: 'Đời xe 1' },
                    { value: 'doi-xe-2', label: 'Đời xe 2' },
                  ]}
                  className="rs-select"
                  popupClassName="rs-select-dropdown"
                />
              </div>
            : null
        }
      </div>
    </div>
  )
}

export default ARCCFilterBlock
