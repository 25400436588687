import { Drawer } from "antd"
import { IAccountDetail } from "../../../../api/groupPermission/groupPermissionApi"

interface DrawerProps {
  isOpen: boolean
  infoAccount?: IAccountDetail
  closeDrawer: () => void

}
const DrawerInfoAccount: React.FC<DrawerProps> = ({
  isOpen, infoAccount,
  closeDrawer
}) => {
  return (
    <Drawer
      title={<p className="font-medium fw-500 text-344054 py-3 px-6 bg-FCFCFD">Thông tin người dùng</p>}
      placement="right"
      rootClassName="custom-drawer"
      closable={false}
      onClose={closeDrawer}
      open={isOpen}
      footer={[]}
    >
      <div className="d-flex flex-column ">
        <p className="text-sm fw-700 font-bold text-344054 py-4">Thông tin cơ bản</p>
        <div className="d-flex flex-column gap-3">
          <div className="d-flex align-center">
            <p className="w-120-px text-sm fw-500 font-medium text-475467 mr-6">Họ và tên</p>
            <p className=" text-sm  font-regular text-475467">{infoAccount?.fullname}</p>
          </div>
          <div className="d-flex align-center">
            <p className="w-120-px text-sm fw-500 font-medium text-475467 mr-6">Email</p>
            <p className=" text-sm  font-regular text-475467">{infoAccount?.email}</p>
          </div>
        </div>
        <p className="text-sm fw-700 font-bold text-344054 py-5">Quyền và nhóm quyền</p>
        <div className="d-flex flex-column gap-3">
          <div className="d-flex align-center">
            <p className="w-120-px text-sm fw-500 font-medium text-475467 mr-6">Nhóm quyền</p>
            <p className=" text-sm  font-regular text-475467">{infoAccount?.groupPermission.title}</p>
          </div>
          <div className="d-flex align-center">
            <p className="w-120-px text-sm fw-500 font-medium text-475467 mr-6">Toàn quốc</p>
            <p className=" text-sm  font-regular text-475467">{infoAccount?.region}</p>
          </div>
          <div className="d-flex align-center">
            <p className="w-120-px text-sm fw-500 font-medium text-475467 mr-6">Đơn vị</p>
            <p className=" text-sm  font-regular text-475467">{infoAccount?.unit.title}</p>
          </div>
          <div className="d-flex align-center">
            <p className="w-120-px text-sm fw-500 font-medium text-475467 mr-6">Khối nghiệp vụ</p>
            <p className=" text-sm  font-regular text-475467">{infoAccount?.businessUnit}</p>
          </div>
          <div className="d-flex align-center">
            <p className="w-120-px text-sm fw-500 font-medium text-475467 mr-6">Phòng ban</p>
            <p className=" text-sm  font-regular text-475467">{infoAccount?.department}</p>
          </div>
        </div>
      </div>
    </Drawer>
  )
}
export default DrawerInfoAccount
