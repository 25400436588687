import { Button, Flex, Form, Input, Modal, notification, Switch } from 'antd'
import React, { useRef, useState } from 'react'

interface Props {
  id: number,
  show: boolean
  modalClose?: () => void
  onSuccess?: () => void
}

const EditCareerModal:React.FC<Props> = (props) => {
  const { id, show, modalClose, onSuccess } = props
  const formRef: any = useRef(null)

  const [loading, setLoading] = useState(false)
  const onFormFinish = (values: any) => {
    setLoading(true)

    setTimeout(() => {
      setLoading(false)
      openNotification('success', 'Cập nhật thành công')
      if (onSuccess) {
        onSuccess()
      }
      if (formRef.current) {
        formRef.current.resetFields()
      }
    }, 1500)
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'error' = 'success', message: string = '') => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    })
  }

  return (
    <>
      { contextHolder }

      <Modal
        open={show}
        title='Chỉnh sửa nghề nghiệp'
        className='rs-modal w-400 footer-content-end add-relative-modal'
        centered
        footer={
          <Button
            type='primary'
            className='rs-button rs-button--sm'
            loading={loading}
            onClick={() => formRef?.current.submit()}
          >Lưu</Button>
        }
        onCancel={modalClose}
      >
        <Form
          ref={formRef}
          className='rs-form add-relative-form'
          onFinish={onFormFinish}
          requiredMark={false}
        >
          <Form.Item
            layout='vertical'
            label='Tên nghề nghiệp'
            name='name'
            rules={[
              { required: true, message: "Vui lòng nhập Tên nghề nghiệp" }
            ]}
          >
            <Input
              placeholder='Tên nghề nghiệp'
              className='rs-input rs-input--32'
            />
          </Form.Item>

          <Form.Item name='status'>
            <Flex className='gap-24px'>
              <span>Sử dụng</span>
              <Switch
                defaultChecked
                className="rs-switch"
              />
            </Flex>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default EditCareerModal
