import { CloseOutlined, DownloadOutlined, InboxOutlined } from "@ant-design/icons"
import { message, Modal, Upload, UploadProps } from "antd"
import { useEffect, useState } from "react";
import './style.scss'
import useToast from "../../hook/useToast";
import axios from "axios";
import tokenService from '../../services/token';
import * as XLSX from 'xlsx';
import { dataGender, dataStatusMarital } from "../../services/data";
const { Dragger } = Upload;

interface ModalProps {
  isOpen: boolean
  linkSampleFile?: string
  linkUploandFile?: string

  onResultUploadFile?: (iSsuccess: boolean, errorMessage?: string) => void;
  exportExcelByUpload?: (data: any, message: string) => void
  closeModal: (value: boolean) => void
}
const ModalUpload_V2: React.FC<ModalProps> = ({
  isOpen,
  linkSampleFile = "https://api.thacocrm.bdata.link/uploads/fileExcel/customer/DanhSachKhachHang.xlsx",
  linkUploandFile = "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onResultUploadFile, exportExcelByUpload, closeModal
}) => {
  const pushToast = useToast();

  const [isFileError, setIsFileError] = useState(false)
  const [isDowloadLog, setIsDowloadLog] = useState(true)
  const [isUploadList, setIsUploadList] = useState(true)

  const [textError, setTextError] = useState("")
  const [dataExcel, setDataExcel] = useState<any[]>([])

  const props: UploadProps = {
    name: "file",
    multiple: false,
    showUploadList: isUploadList,
    maxCount: 1,
    accept: ".xls,.xlsx",
    action: linkUploandFile,
    beforeUpload(file) {
      const fileType = file.name.split('.').pop()?.toLowerCase() ?? "";
      const allowedTypes = ['xls', 'xlsx'];
      if (!allowedTypes.includes(fileType)) {
        pushToast("File không đúng định dạng hoặc file lỗi!", "warning");
        return false; // Ngăn không cho tải lên
      } else {
        return true; // Cho phép tải lên
      }
    },

    customRequest(options) {
      setIsUploadList(true)
      const { file, onError, onProgress, onSuccess } = options;
      const token = tokenService.getToken(); // Thay YOUR_TOKEN_HERE bằng token của bạn
      const formData = new FormData();
      formData.append('file', file);

      axios.post(linkUploandFile, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (onProgress) {
            onProgress({ percent: percentCompleted });
          }
        }
      }).then((response) => {
        resultUpFile(true, response.data)
        if (onSuccess)
          onSuccess(response.data);
      }).catch((error) => {
        resultUpFile(false, error.response.data)
        if (onError)
          onError(error, file);
      });

    },
    onRemove() {
      setIsFileError(false);
    },
  };

  const resultUpFile = (isSuccess: boolean, data: any) => {
    if (isSuccess) {
      if (data?.status) {
        onResultUploadFile?.(true, "Thêm mới khách hàng từ excel thành công")
      } else {
        setTextError(data?.message)
        setIsFileError(true);
        if (data?.data?.length > 0) {
          setIsDowloadLog(true)
          setDataExcel(data?.data)
        } else {
          setIsDowloadLog(false)
        }


      }
    }
    else {
      setTextError(data?.message)
      setIsFileError(true);
      setIsDowloadLog(data?.data?.length > 0)
    }

  }

  useEffect(() => {
    if (!isOpen) {
      setIsFileError(false)
      setIsDowloadLog(true)
      setTextError("")
      setIsUploadList(false)
      setDataExcel([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])
  return (
    <Modal
      title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
        <span className="text-base text-344054 fw-500 font-medium">Tải lên danh sách</span>
        <CloseOutlined className="text-base text-8B8B8B"
          onClick={() => closeModal(false)}
        />
      </div>}
      onCancel={() => closeModal(false)}
      open={isOpen}
      centered
      className="w-modal modal-content ant-modal-size m-none-header"
      closeIcon={false}
      footer={[]}
    >
      <div className="d-flex flex-column gap-4 p-6">
        <Dragger {...props} className="custom-uploadDS">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="text-sm text-344054 font-regular">
            Kéo file vào khu vực này hoặc <br />
            nhấn để chọn file tải lên
          </p>
          <div className="flex items-center justify-center">
            <span className="text-sm font-regular text-667085 ">
              Hãy tải lên danh sách đúng định dạng <br />
              <span className="text-sm font-regular text-667085">
                (.xls hoặc .xlsx)
              </span>
            </span>
          </div>
          <div className="d-flex flex-column gap-5">
            <div className="d-flex align-center justify-center gap-2 pt-5">
              <DownloadOutlined className="text-base text-1890FF" />
              <a
                href={linkSampleFile} className="text-sm text-1890FF font-regular"
                onClick={(e) => {
                  e.stopPropagation(); // Ngăn chặn sự kiện lan truyền lên cấp cha
                }}
              >Tải xuống file mẫu</a>
            </div>
            <div className={`${isFileError ? 'd-flex align-center justify-between w-full rounded-2 border-FFA39E h-12 px-4 bg-FFF1F0' : ' none'}`}>
              <p className="text-sm  text-344054 font-regular text-start">{textError}</p>
              <button
                className={`border-EAECF0 bg-white h-8 d-flex align-center justify-center px-4 gap-10-px rounded-6-px ${isDowloadLog ? "" : 'none'}`}
                onClick={(e) => {
                  e.stopPropagation(); // Ngăn chặn sự kiện lan truyền lên cấp cha
                  exportExcelByUpload?.(dataExcel, textError);
                }}
              >
                <DownloadOutlined
                  className="text-sm text-344054"
                />
                <p className="text-sm  text-344054 font-regular white-space-nowrap">Tải log</p>
              </button>
            </div>
          </div>

        </Dragger>
      </div>
    </Modal>
  )
}
export default ModalUpload_V2
