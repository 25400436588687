import React, { useEffect, useState } from 'react';
import Layout from '../../Layout';
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './VoucherDetail.scss'
import VoucherDetailCard from '../../components/Cards/VoucherDetailCard';
import VoucherDetailTable from './VoucherComponents/VoucherDetailTable';
import useToast from '../../hook/useToast';
import { IVoucherEdit, activeVoucher, getInfoVoucher } from '../../api/voucher/voucherApi';
import useThrottle from '../../hook/useThrottle';

const VoucherDetail: React.FC = () => {

  useEffect(() => {

    return (() => {
      setInfoVoucher(undefined)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { id } = useParams();
  const navigate = useNavigate()
  const pushToast = useToast();

  const [infoVoucher, setInfoVoucher] = useState<IVoucherEdit>()

  const gInfoVoucher = async () => {
    const result = await getInfoVoucher(Number(id))
    if (result?.status) {
      setInfoVoucher(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const onChangeActive = useThrottle(async (value: boolean) => {
    const result = await activeVoucher(Number(id))
    if (result?.status) {
      gInfoVoucher()
    } else {
      pushToast(result?.message, "warning")
    }
  }, 2000)

  useEffect(() => {
    if (id) {
      gInfoVoucher()
    } else {
      navigate(-1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, id])

  return (
    <Layout contentClassName="voucher-detail-content">
      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: 'Marketing', },
          { title: 'Quản lý Voucher', },
          { title: 'Chi tiết Voucher', },
        ]}
        title='Chi tiết Voucher'
        backLink='/marketing/vouchers-management'
      />

      <VoucherDetailCard
        infoVoucher={infoVoucher}
        onChangeActive={onChangeActive}
      />

      <VoucherDetailTable
        id={Number(id)}
      />
    </Layout>
  );
}

export default VoucherDetail;
