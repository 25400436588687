import React, { useRef, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { mixFnum, mixGetV1, mixPostV1, mixTreeIds, mixDelV1, mixGender, mixGetTime } from '../../services/mixin'
import Layout from '../../Layout';
import ShowSetting from '../../components/Filter/ShowSetting';
import ShowSettingAddFilter from '../../components/Filter/ShowSettingAddFilter';
import { Input, Checkbox, Slider, DatePicker, Tree, Space, Table, notification, Form, FormInstance, FormProps, Tag, Flex } from 'antd';
import type { GetProp, SliderSingleProps, TreeProps, TableProps, CheckboxProps } from 'antd';
import {
  DownOutlined, UserOutlined, DeleteOutlined, FilterOutlined, CloseOutlined, DownloadOutlined, UploadOutlined, UserAddOutlined, SettingOutlined, EyeOutlined, EditOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import type { SearchProps } from 'antd/es/input/Search';
// import viVN from 'antd/locale/vi_VN';
import './CustomerList.scss';
import { Link, useNavigate } from 'react-router-dom';
import * as customerApi from '../../api/customer/customerApi';
import { API_URL, LIMIT_EXPORT_EXCEL, URL_CAR_CATEGORY } from '../../env';
import ModalAddGroupV2 from './CustomerGroup/Component/Modal/ModalAddGroupV2';
import ModalUpload from '../../components/Modal/ModalUpload';
import ModalGroupCustomerList from './CustomerGroup/Component/Modal/ModalGroupCustomerList';
import useToast from '../../hook/useToast';
import useThrottle from '../../hook/useThrottle';
import * as XLSX from 'xlsx';
import { dataGender } from '../../services/data';
import ModalDeleteManager from '../../components/ModalDeleteManager';
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock';
import fakeData from './customer-list.json'
import ImportDataModal from './Component/ImportDataModal/ImportDataModal'

const { RangePicker } = DatePicker;
const marks: SliderSingleProps['marks'] = {
  0: '0',
  1000000000: '1 tỷ',
  3000000000: '3 tỷ',
  5000000000: '5tỷ',
};

const dotuoi: SliderSingleProps['marks'] = {
  0: '0',
  18: '18',
  30: '30',
  100: '100',
};

const CustomerList: React.FC = () => {
  const pushToast = useToast();
  const navigator = useNavigate()

  const [openModalUploadDS, setOpenModalUploadDS] = useState(false);
  const [showSelectFilter, setActive] = useState(false);
  const [showSelectAddFilter, setAddActive] = useState(false);
  const [isSettingShow, setSettingShow] = useState(false)
  const [isSettingShowAddFilter, setSettingShowAddFilter] = useState(false)
  const [openDeleteListCustomer, setOpenDeleteListCustomer] = useState(false)

  const [accessSelectFilter, setAccessActive] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [idCustomer, setIdCustomer] = useState<number>();

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const { Search } = Input;

  const handleSettingShowAddFilter = () => {
    setAddActive(false)
    setSettingShowAddFilter(true)
  }

  const onSearch: SearchProps['onSearch'] = (value, _e) => {
    setParamCustomer({ ...paramCustomer, search: value, pageNumber: 1 })
    setPagination({ ...pagination, current: 1 })
  }

  const defaultColumnsShow = ['1', '2', '3', 'source', '4', '5', '6', '7', '8', '9'] as any
  const columns = [
    {
      title: 'Hành động',
      dataIndex: 'action',
      render: (_: any, record: any) => (
        <Space size="middle">
          <Link to={`/customer/list/${record.id}`}><span><EyeOutlined /></span></Link>
          <span onClick={() => navigator(`/customer/update-customer/${record.id}`)}><EditOutlined /></span>
          {/* <DeleteOutlined
            className='text-F5222D text-sm border-EAECF0 p-5-px bg-white rounded-2'
            // onClick={() => setOpenDeleteListCustomer(true)}
            onClick={() => openModalDelete(Number(record.id))}
          /> */}
        </Space>
      ),
      key: '1'
    },
    {
      title: 'STT',
      dataIndex: 'stt',
      key: '2'
    },
    {
      title: 'Họ và tên',
      dataIndex: 'name',
      render: (_: any, record: any) => (
        <div className='td-customer-name'>{record.name}</div>
      ),
      key: '3'
    },
    {
      title: 'Nguồn khách hàng',
      dataIndex: 'source',
      render: (source: any) => (
        <Flex align='center' className='gap-10px'>
          <Tag className='rs-tag daybreak-blue'>DDMS</Tag>
          <Tag className='rs-tag daybreak-blue'>IPCC</Tag>
          <Tag className='rs-tag daybreak-blue'>SALEFORCE</Tag>
        </Flex>
      ),
      key: 'source'
    },
    {
      title: 'CCCD/CMND',
      // dataIndex: 'CCCD',
      dataIndex: 'cccd',
      align: 'right',
      key: '4'
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'birthday',
      sorter: {
        compare: (a: any, b: any) => {
          const dateA = mixGetTime(a.birthday);
          const dateB = mixGetTime(b.birthday);
          return dateA - dateB;
        },
        multiple: 0,
      },
      key: '5'
    },
    {
      title: 'Giới tính',
      dataIndex: 'gender',
      sorter: {
        compare: (a: any, b: any) => a.gender - b.gender,
        multiple: 1,
      },
      render: (_: any, record: any) => (
        <span>{mixGender(record.gender)?.title}</span>
      ),
      key: '6'
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: '7'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: '8'
    },
    {
      title: 'Quan tâm',
      dataIndex: 'interestedCar',
      key: '9'
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: '10'
    },
    {
      title: 'Hạng thành viên',
      dataIndex: 'rankTitle',
      key: '11'
    },
    {
      title: 'Tình trạng hôn nhân',
      dataIndex: 'statusMarital',
      key: '12'
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'birthday',
      key: '13'
    },
    {
      title: 'Nghề nghiệp',
      dataIndex: 'profession',
      key: '14'
    },
    {
      title: 'Thành phố/Tỉnh',
      dataIndex: 'province',
      key: '15'
    },
  ] as any;

  const handleChangePagination = (current: number, pageSize: number) => {
    setPagination({ ...pagination, current, pageSize })
    setParamCustomer({ ...paramCustomer, limit: pageSize, pageNumber: current })
  }

  const hasSelected = selectedRowKeys.length > 0;

  const setActiveAddFilter = () => {
    setActive(false)
    setAddActive(true)
  };

  const setActiveCloseFilter = () => {
    setAccessActive(true)
  };

  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onChangeTB: TableProps<customerApi.DataTypeCustomer>['onChange'] = (pagination, filters, sorter, extra) => {
  };



  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const onChangeSW = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (event.target.matches('.filter-select, .filter-select *, .list-filter, .list-filter *') === false) {
        setActive(false)
      }
      if (event.target.matches('.add-filter, .add-filter *, .show-access-filter, .show-access-filter *, .list-filter, .list-filter *, .ant-picker-dropdown, .ant-picker-dropdown *') === false) {
        setAddActive(false)
      }
      if (event.target.matches('.show-access-filter, .show-access-filter *, .btn-df, .btn-df *') === false) {
        setAccessActive(false)
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const [showModalAddGroupCustomer, setShowModalAddGroupCustomer] = useState(false)

  // HANDLE MODAL
  const [isModalShow, setModalShow] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const onSubmitForm = () => {
    setConfirmLoading(true)
    setTimeout(() => {
      setModalShow(false)
      setConfirmLoading(false)
      openNotification('Thêm khách hàng vào nhóm thành công!')
    }, 1500)
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (content: string) => {
    api['success']({
      message: content,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  // Fliter block logic
  const inputRef = useRef<FormInstance>(null);
  const [form] = useForm();
  const [dataFilter, setDataFilter] = useState({
    ownedbrand: [],
    price: [0, 1000000000],
    maintCal: [],
    gender: [],
    birthday: [],
    birthdayDay: [],
    area: [],
    rank: [],
    otherProperties: [],
  } as any)
  const handleApplyFiltering = async () => {
    const params = {
      priceLowest: dataFilter.price[0],
      priceHighest: dataFilter.price[1],
      gender: dataFilter.gender,
      areaId: mixTreeIds(dataFilter.area),
      rankId: dataFilter.rank,
      categoryCarId: dataFilter.ownedbrand,
    } as any
    if (dataFilter.maintCal) {
      params.dateMaintenanceStart = dataFilter.maintCal[0]
      params.dateMaintenanceEnd = dataFilter.maintCal[1]
    }
    if (dataFilter.birthday) {
      params.birthdayStart = dataFilter.birthday[0]
      params.birthdayEnd = dataFilter.birthday[1]
    }
    setParamCustomer({
      ...paramCustomer,
      ...params,
      pageNumber: 1
    })
    setPagination({ ...pagination, current: 1 })
    setAddActive(false)
    setAccessActive(false)
  }
  const handleAddFilter = async (values = {} as any) => {
    const params = {
      title: values.title,
      priceMin: dataFilter.price[0],
      priceMax: dataFilter.price[1],
    } as any
    if (dataFilter.maintCal) {
      params.maintenanceStart = dataFilter.maintCal[0]
      params.maintenanceEnd = dataFilter.maintCal[1]
    }
    if (dataFilter.birthday) {
      params.birthdayStart = dataFilter.birthday[0]
      params.birthdayEnd = dataFilter.birthday[1]
    }
    if (dataFilter.area.length > 0) {
      params.listArea = mixTreeIds(dataFilter.area)
    }
    if (dataFilter.rank.length > 0) {
      params.listRank = dataFilter.rank
    }
    if (dataFilter.ownedbrand.length > 0) {
      params.listCategoryCar = dataFilter.ownedbrand
    }
    if (dataFilter.gender.length > 0) {
      params.gender = dataFilter.gender
    }

    const res = await mixPostV1('filter/addFilter', params)
    if (res.status) {
      notification.open({
        message: 'Tạo bộ lọc thành công',
        type: "success",
        duration: 2.5,
        placement: 'bottomRight',
        className: 'single-message',
        closeIcon: false
      });
      rsFilter()
      setAddActive(false)
      setAccessActive(false)
    } else {
      notification.open({
        message: res.message,
        type: "error",
        duration: 2.5,
        placement: 'bottomRight',
        className: 'single-message',
        closeIcon: false
      });
    }
  }
  const submitForm = () => {
    if (inputRef.current) {
      inputRef.current.submit();
    }
  };
  const onFinish: FormProps["onFinish"] = (values) => {
    handleAddFilter(values)
  };
  const onChangeOwnedBrand: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    setDataFilter({ ...dataFilter, ownedbrand: [...checkedValues] })
  }
  const onChangePrice = (value: number[]) => {
    setDataFilter({ ...dataFilter, price: [...value] })
  }
  const onChangeMaintCal = (_: any, dateString: any) => {
    setDataFilter({ ...dataFilter, maintCal: dateString })
  };
  const onChangeBirthday = (_: any, dateString: any) => {
    setDataFilter({ ...dataFilter, birthday: dateString })
  };
  const onChangeGender: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    setDataFilter({ ...dataFilter, gender: [...checkedValues] })
  }
  const onCheckArea: TreeProps['onCheck'] = (checkedKeys, info) => {
    setDataFilter({ ...dataFilter, area: checkedKeys })
  };
  const onChangeRank: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    setDataFilter({ ...dataFilter, rank: [...checkedValues] })
  }
  const onChangeOtherProperties: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    setDataFilter({ ...dataFilter, otherProperties: [...checkedValues] })
  }

  // Get categoryCar
  const { data: resCategoryCar } = useQuery('resCategoryCar', () => mixGetV1('filter/categoryCar'))
  const [categoryCar, setCategoryCar] = useState([])
  useEffect(() => {
    if (resCategoryCar && resCategoryCar.data) {
      setCategoryCar(resCategoryCar.data)
    }
  }, [resCategoryCar])

  // Get customser
  const [paramCustomer, setParamCustomer] = useState({
    limit: pagination.pageSize,
    pageNumber: pagination.current,
    search: '',
    filterCustomerId: null,
  })
  const { data: resCustomer, refetch: rsCustomer } = useQuery('resCustomer', () => mixGetV1('customer/listCustomer', { ...paramCustomer }))
  const [customser, setCustomer] = useState([])
  useEffect(() => {
    rsCustomer()
  }, [paramCustomer])
  useEffect(() => {
    if (resCustomer && resCustomer.data) {
      setCustomer(resCustomer.data)
      setPagination({ ...pagination, total: resCustomer.total })
    }
  }, [resCustomer])

  // Get Area
  const { data: resArea } = useQuery('resArea', () => mixGetV1('filter/area'))
  const [listArea, setListArea] = useState([])
  useEffect(() => {
    if (resArea && resArea.data) {
      const temp = [] as any
      resArea.data.map((val: any) => {
        const children = [] as any
        if (val.province) {
          val.province.map((val2: any) => {
            children.push({
              title: `${val2.title} (${mixFnum(val2.customerCount)})`,
              key: `${val.id}-${val2.id}`,
            })
            return true
          })
        }
        temp.push({
          title: `${val.title} (${mixFnum(val.countCustomer)})`,
          key: val.id,
          children
        })
        return true
      })
      setListArea(temp)
    }
  }, [resArea])

  // Get Rank
  const { data: resRank } = useQuery('resRank', () => mixGetV1('rank/listRank'))
  const [listRank, setListRank] = useState([])
  useEffect(() => {
    if (resRank && resRank.data) {
      setListRank(resRank.data)
    }
  }, [resRank])

  // Get allFilter
  const [paramFilter, setParamFilter] = useState({
    search: '',
  })
  const { data: resFilter, refetch: rsFilter } = useQuery('resFilter', () => mixGetV1('filter/allFilter', { ...paramFilter }))
  const [listFilter, setListFilter] = useState([])
  useEffect(() => {
    rsFilter()
  }, [paramFilter])
  useEffect(() => {
    if (resFilter && resFilter.data) {
      setListFilter(resFilter.data)
    }
  }, [resFilter])

  // Chose filterCustomerId
  const [itemFilter, setItemFilter] = useState({} as any)
  const handleChoseFilter = (item = {} as any) => {
    if (item.id) {
      setItemFilter(item)
      setParamCustomer({ ...paramCustomer, filterCustomerId: item.id, pageNumber: 1 })
      setPagination({ ...pagination, current: 1 })
      setActive(false)
    }
  }
  const handleClearFilter = () => {
    setItemFilter({})
    setParamCustomer({ ...paramCustomer, filterCustomerId: null, pageNumber: 1 })
    setPagination({ ...pagination, current: 1 })
    setActive(false)
  }

  // Del filter
  const handleDelFilter = async (item = {} as any) => {
    const params = {
      id: item.id,
    }
    const res = await mixDelV1('filter/deleteFilter', params)
    if (res.status) {
      rsFilter()
    }
  }

  // setting block logic
  const handleCloseSetting = (value: any, isChange: boolean) => {
    if (isChange) {
      localStorage.setItem('customerListColums', JSON.stringify(value))
    }
    setSettingShow(false)
    setSettingShowAddFilter(false)
  }

  const handleCloseSettingAddFilter = (value: any, isChange: boolean) => {
    setSettingShowAddFilter(false)
    setAddActive(true)
  }

  const checkedList = localStorage.getItem('customerListColums') ? JSON.parse(localStorage.getItem('customerListColums') as string) : [...defaultColumnsShow] as any
  const newColumns = columns.map((item: any) => ({
    ...item,
    hidden: !checkedList.includes(item.key as string),
  }));



  const onSubmitFormModalAddGroupCustomerList = (iSsuccess: boolean, message?: string) => {
    if (iSsuccess) {
      setModalShow(false);
      setSelectedRowKeys([])
      pushToast(message, "success")
    }
    else {
      pushToast(message, "warning")
    }
  }

  const openAddGroupCustomer = () => {
    setModalShow(false)
    setShowModalAddGroupCustomer(true)
  }

  const onSubmitFormModalAddInfoGroup = (iSsuccess: boolean, message?: string) => {
    if (iSsuccess) {
      setShowModalAddGroupCustomer(false);
      setSelectedRowKeys([])
      pushToast(message, "success")
    }
    else {
      pushToast(message, "warning")
    }
  }

  const openModalDelete = (id: number) => {
    setIdCustomer(id)
    setOpenDeleteListCustomer(true)
  }

  const closeModalDelete = () => {
    setIdCustomer(undefined)
    setOpenDeleteListCustomer(false)
  }

  const onConfirmDelete = async () => {
    const result = await customerApi.deleteCustomer(Number(idCustomer))
    if (result?.status) {
      pushToast(result.message, "success")
      closeModalDelete()
      rsCustomer()
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const glistCustomerByExcel = useThrottle(async () => {
    const result = await customerApi.getListCustomer(
      LIMIT_EXPORT_EXCEL, 1, paramCustomer.search, Number(paramCustomer.filterCustomerId)
    )
    if (result?.status) {
      formattedDataExcel(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const gListCustomerSelectByExcel = useThrottle(async () => {
    const filteredList = resCustomer.data.filter((item: any) => selectedRowKeys.includes(item.id));
    formattedDataExcel(filteredList)
    setSelectedRowKeys([])
  }, 1000)

  const formattedDataExcel = (data: customerApi.ICustomer[]) => {
    const formattedData = data.map((item) => {
      return {
        "Họ và tên": item.name,
        "CCCD/CMND": item.CCCD,
        "Ngày sinh": item.birthday,
        "Giới tính": dataGender[item.gender].title,
        "Số điện thoại": item.phone,
        "Email": item.email,
      }
    }
    );
    exportExcel(formattedData)
  }

  function exportExcel(convertData: any): boolean {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData);
      const workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách');

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = window.URL.createObjectURL(data);
        link.download = fileName;
        link.click();
      };

      saveAsExcel(excelBuffer, `${"Danh sách khách hàng"}.xlsx`);
      return true; // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error);
      return false; // Export failed
    }
  }

  const onResultUploadFile = (iSsuccess: boolean, message?: string) => {
    if (iSsuccess) {
      setOpenModalUploadDS(false);
      pushToast(message, "success")
      rsCustomer()
    }
    else {
      pushToast(message, "warning")
    }
  }

  // IMPORT DATA MODAL
  const [showImportDataModal, setShowImportDataModal] = useState(false)

  // check all group

  const CheckboxGroup = Checkbox.Group;

  const nNOptions = ['Lập trình viên', 'COO', 'CEO', 'Doanh nhân'];
  const defaultCheckedListNN = ['Lập trình viên'];

  const sTOptions = ['Bóng đá', 'Nhạc pop', 'Đọc sách', 'Xem phim'];
  const defaultCheckedListST = ['Bóng đá'];

  const dVOptions = ['THACO AUTO BÌNH TÂN - HCM', 'THACO AUTO TRƯỜNG CHINH - HCM', 'THACO AUTO PHÚ NHUẬN', 'THACO AUTO BÌNH TÂN - HN'];
  const defaultCheckedListDV = ['THACO AUTO BÌNH TÂN - HCM'];

  const nKHOptions = ['Khách hàng lớn tuổi', 'Khách hàng quan trọng'];
  const defaultCheckedListNKH = ['Khách hàng lớn tuổi'];

  const [checkedListNN, setCheckedListNN] = useState<string[]>(defaultCheckedListNN as any);
  const [checkedListST, setCheckedListST] = useState<string[]>(defaultCheckedListST as any);
  const [checkedListDV, setCheckedListDV] = useState<string[]>(defaultCheckedListDV as any);
  const [checkedListNKH, setCheckedListNKH] = useState<string[]>(defaultCheckedListNKH as any);

  const checkAllNN = nNOptions.length === checkedListNN.length;
  const indeterminate = checkedListNN.length > 0 && checkedListNN.length < nNOptions.length;

  const checkAllST = sTOptions.length === checkedListST.length;
  const indeterminateST = checkedListST.length > 0 && checkedListST.length < sTOptions.length;

  const checkAllDV = dVOptions.length === checkedListDV.length;
  const indeterminateDV = checkedListDV.length > 0 && checkedListDV.length < dVOptions.length;

  const checkAllNKH = nKHOptions.length === checkedListNKH.length;
  const indeterminateNKH = checkedListNKH.length > 0 && checkedListNKH.length < nKHOptions.length;

  const onChange = (list: string[]) => {
    setCheckedListNN(list);
    setCheckedListST(list);
    setCheckedListDV(list);
    setCheckedListNKH(list);
  };

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    setCheckedListNN(e.target.checked ? nNOptions : []);
    setCheckedListST(e.target.checked ? sTOptions : []);
    setCheckedListDV(e.target.checked ? dVOptions : []);
    setCheckedListNKH(e.target.checked ? nKHOptions : []);
  };

  return (
    <>
      {contextHolder}

      <Layout className='customer-list'>
        <BreadcrumbBlock
          items={[
            { title: <Link to="/">THACO AUTO CRM</Link>, },
            { title: 'Khách hàng', },
            { title: 'Danh sách khách hàng', },
          ]}
          title='Danh sách khách hàng'
          className='gutter-block-end'
        />

        {/* Fillter block */}
        <div className={`filter-block ${hasSelected ? "active" : ""}`}>
          <div className='t-search-block'>
            <Search placeholder="Tìm kiếm số điện thoại, hoặc email" onSearch={onSearch} />
          </div>

          <div className='filter-select'>
            <div
              className='btn-select-show'
            >
              <span className="content-select" onClick={() => setActive(true)} >{itemFilter.title || 'Chọn bộ lọc'}</span>
              <div
                className={`icon-down ${showSelectFilter ? "active" : ""}`}
                onClick={() => setActive(true)}
              >
                <DownOutlined />
              </div>
              <div
                className={`icon-close ${showSelectFilter ? "active" : ""}`}
                onClick={() => handleClearFilter()}
              >
                <CloseCircleOutlined />
              </div>
            </div>
            <div className={`list-filter ${showSelectFilter ? "active" : ""}`}>
              <Input placeholder="Tìm kiếm bộ lọc" onKeyUp={(e) => setParamFilter({ ...paramFilter, search: (e.target as HTMLInputElement).value })} />
              <div className="list-item">
                {listFilter.map((item: any) => (
                  <div key={item.id} className="item-select">
                    <div className="icon-left" onClick={() => handleChoseFilter(item)}>
                      <UserOutlined />
                      <div className="name-select">{item.title}</div>
                    </div>
                    <div className="icon-right">
                      <DeleteOutlined onClick={() => handleDelFilter(item)} />
                    </div>
                  </div>
                ))}

              </div>
              <div
                className="btn-filter"
                onClick={setActiveAddFilter}
              >
                <div className="icon-btn-filter"><FilterOutlined /></div>
                <div className="text-btn-filter">Bộ lọc</div>
              </div>
            </div>
          </div>

          <div
            className="btn-input"
            onClick={glistCustomerByExcel}
          >
            <div className="icon-svg">
              <DownloadOutlined />
            </div>
            <div className="text">Xuất DS</div>
          </div>

          {/* <div className="btn-input"
            onClick={() => setShowImportDataModal(true)}
          >
            <div className="icon-svg">
              <UploadOutlined />
            </div>
            <div className="text">Nhập DS</div>
          </div> */}

          <div
            className="btn-input btn-primary"
            onClick={() => navigator('/customer/add-customer')}
          >
            <div className="icon-svg">
              <UserAddOutlined />
            </div>
            <Link to="/customer/add-customer" className="text">Thêm khách hàng</Link>
          </div>

          <div className="btn-input t-px-8" onClick={() => setSettingShow(true)}>
            <div className="icon-svg">
              <SettingOutlined />
            </div>
          </div>
        </div>

        {/* Fillter block */}
        <div className={`filter-block filter-active-show ${hasSelected ? "active" : ""}`}>
          <div className='total-chosse'>
            {hasSelected ? `Đã chọn ${selectedRowKeys.length} khách hàng` : ''}
          </div>

          <div className="btn-input">
            <div className="icon-svg">
              <img src="/customer/cm.svg" alt="" />
            </div>
            <div className="text">Gửi SMS</div>
          </div>

          <div className="btn-input">
            <div className="icon-svg">
              <img src="/customer/email.svg" alt="" />
            </div>
            <div className="text">Gửi Email</div>
          </div>

          <div className="btn-input">
            <div className="icon-svg">
              <img src="/customer/zalo.svg" alt="" />
            </div>
            <div className="text">Gửi ZNS</div>
          </div>

          <div
            className="btn-input"
            // onClick={() => handleButtonInfoClick()}
            onClick={() => setModalShow(true)}
          >
            <div className="icon-svg">
              <img src="/customer/use.svg" alt="" />
            </div>
            <div className="text">Nhóm khách hàng</div>
          </div>

          <div
            className="btn-input"
            onClick={gListCustomerSelectByExcel}
          >
            <div className="icon-svg">
              <img src="/customer/down.svg" alt="" />
            </div>
            <div className="text">Xuất DS </div>
          </div>
        </div>

        <div className="t-table-block t-table-customer">
          <Table
            className='rs-table'
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
            rowKey="id"
            rowSelection={rowSelection}
            columns={newColumns}
            // dataSource={customser}
            dataSource={fakeData}
            onChange={onChangeTB}
            showSorterTooltip={false}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 15,
              showTotal: (total) => `Tổng cộng ${Math.ceil(total / 15)} trang`,
              locale: { items_per_page: ' / trang', jump_to: 'Tới' },
              position: ['bottomCenter'],
              className: 'rs-pagination',
              showQuickJumper: true,
            }}
          />
          {/* <ConfigProvider locale={viVN}>
            <div className="pagination-block">
              <Pagination
                showSizeChanger
                total={pagination.total}
                defaultPageSize={pagination.pageSize}
                current={pagination.current}

                showTotal={() => `Tổng cộng ${Math.ceil(pagination.total / pagination.pageSize) === 0 ? 1 : Math.ceil(pagination.total / pagination.pageSize)} trang`}
                locale={{ items_per_page: 'trang' }}
                onChange={handleChangePagination}
              />
            </div>
          </ConfigProvider> */}
        </div>

        <div className={`add-filter ${showSelectAddFilter ? "active" : ""}`}>
          <div className="title-add-filter title-popup-add-filter">
            <div className="text">Bộ lọc</div>
            <div className="setting-icon" onClick={handleSettingShowAddFilter}>
              <div className="text">Tùy chỉnh</div>
              <div className="icon">
                <SettingOutlined />
              </div>
            </div>
          </div>

          <div className="content-add-filter t-scrollbar">
            <div className="col-add-filter">
              <div className="one-add-filter-block t-mb-24 t-scrollbar">
                <div className="col-title-add-filter">Độ tuổi</div>
                <div className="list-add-filter-content slider-price">
                  <Slider
                    min={0}
                    max={100}
                    range marks={dotuoi}
                    tooltip={{ open: false }}
                    defaultValue={dataFilter.price}
                    onChange={onChangePrice}
                  />
                </div>
              </div>

              <div className="one-add-filter-block t-mb-24">
                <div className="col-title-add-filter">Giới tính</div>
                <div className="list-select-2col">
                  <Checkbox.Group onChange={onChangeGender}>
                    <Checkbox className="one-add-filter-content" value="1">
                      <div className="text">Nam</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="0">
                      <div className="text">Nữ</div>
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              </div>

              <div className="one-add-filter-block t-mb-24">
                <div className="col-title-add-filter">Khu vực</div>
                <div className="list-select-2col tree-select">
                  <Tree
                    checkable
                    defaultExpandedKeys={dataFilter.area}
                    defaultSelectedKeys={dataFilter.area}
                    defaultCheckedKeys={dataFilter.area}
                    onSelect={onSelect}
                    onCheck={onCheckArea}
                    treeData={listArea}
                    defaultExpandAll={true}
                  />
                </div>
              </div>

              <div className="one-add-filter-block t-mb-24 group-select-all">
                <div className="col-title-add-filter title-btn-check-all">
                  <div className="text">Nghề nghiệp</div>
                  <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAllNN}>Tất cả</Checkbox>
                </div>
                <div className="list-add-filter-content">
                  <div className='t-search-block'>
                    <Search placeholder="Tìm kiếm" onSearch={onSearch} />
                  </div>
                  <div className="border-group">
                    <div className="rs-scroll mx-h-120">
                      <CheckboxGroup options={nNOptions} value={checkedListNN} onChange={onChange} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="one-add-filter-block t-mb-24">
                <div className="col-title-add-filter">Hạng thành viên</div>
                <div className="list-add-filter-content">
                  <Checkbox.Group onChange={onChangeRank}>
                    {
                      listRank.map((item: any) => (
                        <Checkbox className="one-add-filter-content" value={item.id} key={item.id}>
                          <div className="text">{item.title}</div>
                        </Checkbox>
                      ))
                    }
                  </Checkbox.Group>
                </div>
              </div>

              <div className="one-add-filter-block t-mb-24">
                <div className="col-title-add-filter">Tình trạng hôn nhân</div>
                <div className="list-add-filter-content">
                  <Checkbox.Group onChange={onChangeRank}>
                    <Checkbox className="one-add-filter-content" value={1}>
                      <div className="text">Độc thân</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value={2}>
                      <div className="text">Đã có gia đình</div>
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              </div>

              <div className="one-add-filter-block t-mb-24 group-select-all">
                <div className="col-title-add-filter title-btn-check-all">
                  <div className="text">Sở thích</div>
                  <Checkbox indeterminate={indeterminateST} onChange={onCheckAllChange} checked={checkAllST}>Tất cả</Checkbox>
                </div>
                <div className="list-add-filter-content">
                  <div className='t-search-block'>
                    <Search placeholder="Tìm kiếm" onSearch={onSearch} />
                  </div>
                  <div className="border-group">
                    <div className="rs-scroll mx-h-120">
                      <CheckboxGroup options={sTOptions} value={checkedListST} onChange={onChange} />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="one-add-filter-block t-mb-24 t-pt-16">
                <div className="col-title-add-filter">Lịch sử bảo dưỡng</div>
                <div className="list-add-filter-content date-picker">
                  <RangePicker
                    className="rs-range-picker"
                    popupClassName="rs-range-picker-dropdown"
                    placeholder={['Chọn từ ngày', 'Đến ngày']}
                    onChange={(value, dateString) => onChangeMaintCal(value, dateString)}
                  />
                </div>
              </div> */}
            </div>
            <div className="col-add-filter">
              <div className="one-add-filter-block t-mb-24 t-scrollbar">
                <div className="col-title-add-filter">Thương hiệu sở hữu</div>
                <div className="list-add-filter-content">
                  <Checkbox.Group onChange={onChangeOwnedBrand}>
                    {categoryCar.map((item: any) => (
                      <Checkbox className="one-add-filter-content" value={item.id} key={item.id}>
                        <div className="img-text">
                          <img src={`${URL_CAR_CATEGORY}${item.image}`} alt="" />
                          <div className="text">{item.title} ({mixFnum(item.historyCount)})</div>
                        </div>
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </div>
              </div>

              {/* <div className="one-add-filter-block t-mb-24">
                <div className="col-title-add-filter">Sinh nhật sắp tới</div>
                <div className="list-add-filter-content date-picker">
                  <RangePicker
                    className="rs-range-picker"
                    popupClassName="rs-range-picker-dropdown"
                    placeholder={['Chọn từ ngày', 'Đến ngày']}
                    onChange={(value, dateString) => onChangeBirthday(value, dateString)}
                  />
                </div>
              </div> */}
              <div className="one-add-filter-block t-mb-48">
                <div className="col-title-add-filter">Mức giá xe sở hữu</div>
                <div className="list-add-filter-content slider-price">
                  <Slider
                    min={0}
                    max={5000000000}
                    range marks={marks}
                    tooltip={{ open: false }}
                    defaultValue={dataFilter.price}
                    onChange={onChangePrice}
                  />
                </div>
              </div>

              <div className="one-add-filter-block t-mb-24">
                <div className="col-title-add-filter">Số lượng xe sở hữu</div>
                <div className='t-search-block t-mt-12'>
                  <input className='rs-input' placeholder="Nhập số lượng" />
                </div>
                <div className="list-add-filter-content list-count-car">
                  <Checkbox.Group onChange={onChangeRank}>
                    <Checkbox className="one-add-filter-content" value={1}>
                      <div className="text">1</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value={2}>
                      <div className="text">2</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value={3}>
                      <div className="text">3</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value={4}>
                      <div className="text">4</div>
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              </div>

              <div className="one-add-filter-block t-mb-24">
                <div className="col-title-add-filter">Thời gian sở hữu xe</div>
                <div className="list-add-filter-content">
                  <Checkbox.Group onChange={onChangeRank}>
                    <Checkbox className="one-add-filter-content" value={1}>
                      <div className="text">Dưới 1 năm</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value={2}>
                      <div className="text">1 - 5 năm</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value={3}>
                      <div className="text">Trên 5 năm</div>
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              </div>
            </div>

            <div className="col-add-filter">
              {/* <div className="one-add-filter-block">
                <div className="col-title-add-filter">Thuộc tính khác</div>
                <div className="list-add-filter-content">
                  <Checkbox.Group onChange={onChangeOtherProperties}>
                    <Checkbox className="one-add-filter-content" value="1">
                      <div className="text">Tình trạng hôn nhân</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="2">
                      <div className="text">Thuộc đại lý</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="3">
                      <div className="text">Sở thích</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="4">
                      <div className="text">Nghề nghiệp</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="5">
                      <div className="text">Nhóm</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="6">
                      <div className="text">Loại xe quan tâm</div>
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              </div> */}

              <div className="one-add-filter-block t-mb-24 group-select-all">
                <div className="col-title-add-filter title-btn-check-all">
                  <div className="text">Đơn vị</div>
                  <Checkbox indeterminate={indeterminateDV} onChange={onCheckAllChange} checked={checkAllDV}>Tất cả</Checkbox>
                </div>
                <div className="list-add-filter-content">
                  <div className='t-search-block'>
                    <Search placeholder="Tìm kiếm" onSearch={onSearch} />
                  </div>
                  <div className="border-group">
                    <div className="rs-scroll mx-h-120">
                      <CheckboxGroup options={dVOptions} value={checkedListDV} onChange={onChange} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="one-add-filter-block t-mb-24 group-select-all">
                <div className="col-title-add-filter title-btn-check-all">
                  <div className="text">Nhóm khách hàng</div>
                  <Checkbox indeterminate={indeterminateNKH} onChange={onCheckAllChange} checked={checkAllNKH}>Tất cả</Checkbox>
                </div>
                <div className="list-add-filter-content">
                  <div className='t-search-block'>
                    <Search placeholder="Tìm kiếm" onSearch={onSearch} />
                  </div>
                  <div className="border-group">
                    <div className="rs-scroll mx-h-120">
                      <CheckboxGroup options={nKHOptions} value={checkedListNKH} onChange={onChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-filter-block">
            <div className="btn-block">
              <div className="btn btn-df" onClick={() => setActiveCloseFilter()}>
                <img src="/customer/wrapper.svg" alt="" />
                <div className="text">
                  Lưu bộ lọc
                </div>
              </div>

              <div className="btn btn-primary" onClick={handleApplyFiltering}>
                <div className="icon-svg"><FilterOutlined /></div>
                <div className="text">
                  Áp dụng lọc
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`show-access-filter ${accessSelectFilter ? "active" : ""}`}>
          <div className="title-access">
            <span>Lưu bộ lọc</span>
            <div className="icon-close" onClick={() => setAccessActive(false)}>
              <CloseOutlined />
            </div>
          </div>
          <div className="input-add-name">
            <Form
              form={form}
              ref={inputRef}
              onFinish={onFinish}
              initialValues={{ remember: true }}
            >
              <Form.Item
                name="title"
                rules={[{ required: true, message: 'Vui lòng nhập tên bộ lọc' }]}
                className="m-none-form-item text-explan"
              >
                <Input className="plac-color" placeholder="Nhập tên bộ lọc" />
              </Form.Item>
            </Form>
          </div>
          <div className="btn-footer-access">
            <div className="btn btn-primary" onClick={submitForm}>
              Lưu
            </div>
          </div>
        </div>

        {
          isSettingShow
            ? <ShowSetting
              className="show"
              columns={columns}
              defaultColumnsShow={defaultColumnsShow}
              onClose={(value, isChange) => { handleCloseSetting(value, isChange) }}
            />
            : null
        }

        {
          isSettingShowAddFilter
            ? <ShowSettingAddFilter
              className="show"
              columns={columns}
              defaultColumnsShow={defaultColumnsShow}
              onClose={(value, isChange) => { handleCloseSettingAddFilter(value, isChange) }}
            />
            : null
        }
      </Layout>

      <ModalGroupCustomerList
        isOpen={isModalShow}
        listCustomer={selectedRowKeys}

        openAddGroupCustomer={openAddGroupCustomer}
        onSubmitFormModalAdd={onSubmitFormModalAddGroupCustomerList}
        closeModal={() => setModalShow(false)}
      />

      <ModalAddGroupV2
        isOpen={showModalAddGroupCustomer}
        listCustomer={selectedRowKeys}

        onSubmitFormModalAdd={onSubmitFormModalAddInfoGroup}
        closeModal={() => setShowModalAddGroupCustomer(false)}
      />

      <ModalUpload
        isOpen={openModalUploadDS}
        linkSampleFile={"https://api.thacocrm.bdata.link/uploads/fileExcel/customer/DanhSachKhachHang.xlsx"}
        linkUploandFile={`${API_URL}customer/excel`}

        onResultUploadFile={onResultUploadFile}
        closeModal={() => setOpenModalUploadDS(!openModalUploadDS)}
      />

      <ModalDeleteManager
        isOpen={openDeleteListCustomer}
        // onConfirm={handleDelete}

        title="Bạn có chắc chắn xóa khách hàng này"
        titleDelete="Xóa khách hàng"
        content="Khách hàng và dữ liệu liên quan sẽ bị xóa vĩnh viễn không thể khôi phục"
        closeModal={closeModalDelete}
        onConfirm={onConfirmDelete}
      />

      {/* MODAL IMPORT DATA */}
      <ImportDataModal
        show={showImportDataModal}
        modalClose={() => setShowImportDataModal(false)}
      />
    </>
  )
}

export default CustomerList
