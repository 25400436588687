import { Progress, Tag } from 'antd';
import React from 'react';
import './CampaignNetworkCard.scss'
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';

interface Props {
  networkImg?: string,
  networkName?: string,
  code?: string,
  numberOfSuccess?: number,
  numberOfFail?: number,
  total?: number,
  progressing?: boolean,
  progressingNumber?: number,
  onDetail?: () => void,
  onDownload?: () => void,
}

const numberFormat = (number: any) => {
  let result
  if (typeof number === 'number') {
    result = new Intl.NumberFormat('vi-VN').format(number)
  } else {
    result = number
  }
  return result
}

const CampaignNetworkCard: React.FC<Props> = (props) => {
  const {
    networkImg,
    networkName,
    code,
    numberOfSuccess,
    numberOfFail,
    progressing = false,
    progressingNumber = 1,
    total = 1,
    onDetail,
    onDownload,
  } = props

  const percent = progressingNumber * 100 / total

  const detailClick = () => {
    if (onDetail) {
      onDetail()
    }
  }

  const downloadClick = () => {
    if (onDownload) {
      onDownload()
    }
  }

  return (
    <div className="campaign-network-card">
      <div className="flex justify-between align-center top">
        <div className="flex align-center gap-8px">
          <img src={ networkImg } alt={ networkName } />
          <span className="fsz-12 lh-20">{ networkName }</span>
        </div>
        <div className="flex align-center gap-16px">
          <Tag className="rs-tag geek-blue">{ code }</Tag>
          <div className="flex align-center gap-8px">
            <span className="fsz-12 lh-20 neutral-gray-400">Chi tiết nội dung</span>
            <EyeOutlined onClick={detailClick}/>
          </div>
        </div>
      </div>

      {
        progressing &&
          <div className="progress-block flex align-center gap-8px">
            <Progress
              percent={percent}
              showInfo={false}
              className="rs-progress primary"
            />
            <span className="neutral-gray-400">{ numberFormat(progressingNumber) }/{ numberFormat(total) }</span>
          </div>
      }

      {
        !progressing &&
          <div className="flex justify-between align-center gap-8px bottom">
            <div className="status status-success">
              <span className="neutral-gray-400">Gửi thành công&nbsp;</span>
              <span className="primary-7">({ numberFormat(numberOfSuccess) })</span>
            </div>
            <div className="status status-fail">
              <span className="neutral-gray-400">Gửi thất bại&nbsp;</span>
              <span className="dust-red-6">({ numberFormat(numberOfFail) })</span>
            </div>
            <div className="flex align-center gap-8px">
                <span className="fsz-12 lh-20 neutral-gray-400">Tải báo cáo</span>
                <DownloadOutlined onClick={downloadClick}/>
              </div>
          </div>
      }
    </div>
  );
}

export default CampaignNetworkCard;
