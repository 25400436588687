import React from 'react'
import { Button, Divider, Drawer, Flex, Tag } from 'antd'
import CarCard from '../../../../components/Cards/CarCard'

interface Props {
  show: boolean
  onClose: () => void
}

const FavoriteCarDrawer:React.FC<Props> = (props) => {
  const { show, onClose } = props

  return (
    <Drawer
      open={show}
      title='Thông tin xe quan tâm'
      className='rs-drawer'
      closeIcon={false}
      footer={
        <Button
          block
          onClick={onClose}
        >Đóng</Button>
      }
      onClose={onClose}
    >
      <Flex vertical className='gap-18px'>
        <CarCard
          name='BMW X3'
          avatar='/images/car-avatar-320x130.png'
        />
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Thương hiệu</span>
          <Flex align='center' justify='flex-end' className='gap-12px'>
            <span className="neutral-gray-400 text-end">BMW</span>
            <img src='/images/car-logo.svg' alt='' className='h-24px w-24px' style={{ objectFit: 'contain' }}/>
          </Flex>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Phân khúc</span>
          <span className="neutral-gray-400 text-end">Xe cỡ nhỏ</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Dòng xe</span>
          <span className="neutral-gray-400 text-end">BMW 1 Series</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Màu xe</span>
          <span className="neutral-gray-400 text-end"> Xanh Đen</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Năm sản xuất</span>
          <span className="neutral-gray-400 text-end">2016</span>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default FavoriteCarDrawer
