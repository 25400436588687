// import EmptyComponentKH from '../../../components/EmptyComponent/EmptyComponentKH';
import './Step.scss'
import { CloseCircleOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons';
import Input, { SearchProps } from 'antd/es/input';
import { useEffect, useState } from 'react';
// import { Select } from 'antd';

interface Props {
  className?: string;
  listSource?: any;
  showListSelect?: (value: boolean) => void;
}

const Select: React.FC<Props> = ({ className, showListSelect, listSource }) => {

  const [showSelectFilterAddSeclect, setActiveAddSelect] = useState(false);
  const [isShowValue, setShowValue] = useState('Chọn giá trị')

  const handleClearFilter = (e:any) => {
    setActiveAddSelect(false)
    setShowValue(e)
    if (showListSelect) {  // Check if the function is passed before calling
      showListSelect(e);
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (event.target.matches('.filter-select, .filter-select *, .list-filter, .list-filter *') === false) {
        setActiveAddSelect(false)
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  return (
    <>
      <div className='filter-select mt--10 w-100'>
        <div
          className='btn-select-show'
        >
          <span className="content-select" onClick={() => setActiveAddSelect(true)} >{isShowValue} </span>
          <div
            className={`icon-down ${showSelectFilterAddSeclect ? "active" : ""}`}
            onClick={() => setActiveAddSelect(true)}
          >
            <DownOutlined />
          </div>
          {/* <div
            className={`icon-close ${showSelectFilterAddSeclect ? "active" : ""}`}
            onClick={() => handleClearFilter('Chọn hệ thống')}
          >
            <CloseCircleOutlined />
          </div> */}
        </div>
        <div className={`list-filter ${showSelectFilterAddSeclect ? "active" : ""}`}>
          <div className="list-item">
            {listSource && listSource.length > 0 &&
              <>
                {listSource.map(( val:any ) => (
                  <div key={val.value} className="item-select" onClick={() => handleClearFilter(val)} >{ val.label }</div>
                ))
                }
              </>
            }
            {/* <div className="item-select" onClick={() => handleClearFilter('Hệ thống DDMS Kinh doanh')} >Hệ thống DDMS Kinh doanh</div>
            <div className="item-select" onClick={() => handleClearFilter('Hệ thống DDMS Dịch vụ')} >Hệ thống DDMS Dịch vụ</div>
            <div className="item-select" onClick={() => handleClearFilter('Hệ thống IPCC')} >Hệ thống IPCC</div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Select;
