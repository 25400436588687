import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../Layout';
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock';
import { useForm } from 'antd/lib/form/Form';
import { Link, useNavigate } from 'react-router-dom';
import './VouchersManagement.scss'
import './VoucherCreate.scss'
import { Button, DatePicker, Form, FormInstance, Input, InputNumber, Radio, RadioChangeEvent, Select, TimePicker, notification } from 'antd';
import { FormProps } from 'antd/lib';
import { GlobalOutlined, LockOutlined, SearchOutlined, UserAddOutlined } from '@ant-design/icons';
import SelectCustomer from './SelectCustomer';
import SelectCustomerGroup from './SelectCustomerGroup';
import useToast from '../../hook/useToast';
import useThrottle from '../../hook/useThrottle';
import { addVoucher } from '../../api/voucher/voucherApi';

const { TextArea } = Input;

type FieldType = {
  title?: string;
  description?: string;
  quantity?: number;
  condition?: number;
  discountType?: number;
  discountMax?: number;
  usesPerPerson?: number;
  startDate?: string,
  startTime?: string,
  endDate?: string,
  endTime?: string,
  reducedAmount?: number;
  percentReduction?: number;
  customers?: number[];
  customerGroups?: number[];
};
const onConditionChange = (e: RadioChangeEvent) => {
  // console.log(`radio checked:${e.target.value}`);
};

const VoucherCreate: React.FC = () => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);


  const pushToast = useToast();
  const navigator = useNavigate()

  const [form] = useForm();
  const formRef = useRef<FormInstance<FieldType>>(null);

  const [api, contextHolder] = notification.useNotification();

  const [discountType, setDiscountType] = useState(null)

  const [confirmLoading, setConfirmLoading] = useState(false)



  const onDiscountTypeChange = (e: RadioChangeEvent) => {
    setDiscountType(e.target.value)
  };

  const [voucherType, setVoucherType] = useState('public')

  const onVoucherTypeChange = (e: RadioChangeEvent) => {
    setVoucherType(e.target.value)
  };

  // FORM SUBMIT





  const changeCustomer = (e: any) => {
    const temp = [] as any
    e.map((item: any) => {
      temp.push(item.value)
      return true
    });
    form.setFieldValue('customers', temp)
  }

  const changeCustomerGroup = (e: any) => {
    const temp = [] as any
    e.map((item: any) => {
      temp.push(item.value)
      return true
    });
    form.setFieldValue('customerGroups', temp)
  }

  const onSubmitForm = () => {
    if (formRef.current) {
      formRef.current.submit()
    }
  };

  // FORM SUBMIT SUCCESSFULLY

  const handleOk = () => {
    setConfirmLoading(true)
    setTimeout(() => {
      setConfirmLoading(false)
    }, 1500)
  }

  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0") // Tháng bắt đầu từ 0 nên cần cộng thêm 1
    const day = (date.getDate()).toString().padStart(2, "0")
    const hours = (date.getHours()).toString().padStart(2, "0")
    const minutes = (date.getMinutes()).toString().padStart(2, "0")

    return {
      date: `${year}-${month}-${day}`,
      time: `${hours}:${minutes}`
    }
  };

  const checkDate = (startDate?: string, startTime?: string, endDate?: string, endTime?: string) => {

    const dateStart1 = new Date(startDate?.toString() || "");
    const dateStart2 = new Date(startTime?.toString() || "");

    const mergedDateStart = new Date(
      dateStart1.getFullYear(), // Lấy năm từ chuỗi thứ hai
      dateStart1.getMonth(),    // Lấy tháng từ chuỗi thứ hai
      dateStart1.getDate(),     // Lấy ngày từ chuỗi thứ hai
      dateStart2.getHours(),    // Lấy giờ từ chuỗi đầu tiên
      dateStart2.getMinutes(),  // Lấy phút từ chuỗi đầu tiên
    );

    const dateEnd1 = new Date(endDate?.toString() || "");
    const dateEnd2 = new Date(endTime?.toString() || "");

    const mergedDateEnd = new Date(
      dateEnd1.getFullYear(), // Lấy năm từ chuỗi thứ hai
      dateEnd1.getMonth(),    // Lấy tháng từ chuỗi thứ hai
      dateEnd1.getDate(),     // Lấy ngày từ chuỗi thứ hai
      dateEnd2.getHours(),    // Lấy giờ từ chuỗi đầu tiên
      dateEnd2.getMinutes(),  // Lấy phút từ chuỗi đầu tiên
    );

    const startDateTMP = formatDate(mergedDateStart)
    const endDateTMP = formatDate(mergedDateEnd)
    if (mergedDateEnd > mergedDateStart) {

      return (
        {
          isSS: true,
          startDate: startDateTMP.date,
          startTime: startDateTMP.time,
          endDate: endDateTMP.date,
          endTime: endDateTMP.time
        }
      )
    } else {
      return (
        {
          isSS: false,
          startDate: startDateTMP.date,
          startTime: startDateTMP.time,
          endDate: endDateTMP.date,
          endTime: endDateTMP.time
        }
      )
    }

  }

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    const data = checkDate(values?.startDate, values.startTime, values.endDate, values.endTime)
    if (!data.isSS) {
      pushToast("Ngày bắt đầu phải nhỏ hơn ngày kết thúc", "warning")
      return
    } else {
      onAddSubmitFormModal(values, data)
    }

  };

  const onAddSubmitFormModal = useThrottle(async (
    values: FieldType, data: {
      isSS: boolean;
      startDate: string;
      startTime: string;
      endDate: string;
      endTime: string;
    }) => {
    const type = voucherType === "public" ? 0 : voucherType === "group" ? 1 : 2
    const role = discountType === "price" ? 0 : 1

    const result = await addVoucher(
      values.title + "", values.description + "", Number(values.quantity), type, Number(values.condition), role, Number(values.usesPerPerson),
      data.startDate, data.startTime, data.endDate, data.endTime,
      values.reducedAmount, values.percentReduction, values.discountMax, values.customerGroups, values.customers
    )
    if (result?.status) {
      pushToast(result.message, "success")
      navigator(-1)
    } else {
      pushToast(result?.message, "warning")
    }

    // const result = 

  }, 1000)




  return (
    <Layout contentClassName="voucher-management-content">
      {contextHolder}

      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: 'Marketing', },
          { title: <Link to="/marketing/vouchers-management">Quản lý Voucher</Link>, },
          { title: 'Tạo mới', },
        ]}
        title='Tạo mới voucher'
        backLink='/marketing/vouchers-management'
      />
      <div
        className="voucher-add-wrapper"
        style={{ flexGrow: 1 }}
      >
        <Form
          form={form}
          ref={formRef}
          onFinish={onFinish}
          autoComplete="off"
          className="rs-form voucher-add__content"
        >
          <div className="block">
            <span className="font-medium lh-24 fsz-16">Tên chương trình Voucher</span>
            <Form.Item<FieldType>
              name="title"
              rules={[{ required: true, message: 'Vui lòng nhập tên chương trình!' }]}
            >
              <Input
                placeholder="Tên chương trình Voucher"
                className="rs-input"
              />
            </Form.Item>
          </div>

          <Form.Item<FieldType>
            name="description"
            rules={[{ required: true, message: 'Vui lòng nhập mô tả chương trình!' }]}
          >
            <TextArea
              placeholder="Nội dung mô tả về chương trình Voucher"
              className="rs-input rs-input--textarea-default"
            />
          </Form.Item>

          {voucherType === 'public' &&
            <Form.Item<FieldType>
              name="quantity"
              rules={[
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject(new Error('Vui lòng nhập số lượng!'));
                    }
                    if (!Number.isInteger(+value) || value <= 0) {
                      return Promise.reject(new Error('Số lượng ký tự phải là số nguyên dương!'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              initialValue={3000}
            >
              <div className="block-flex">
                <span className="font-medium lh-24 fsz-16">Số lượng mã Voucher tự động tạo</span>
                <div className="block-flex__content">
                  <InputNumber
                    addonAfter="Voucher"
                    min={1}
                    defaultValue={3000}
                    controls
                    className="rs-input-number"
                  />
                </div>
              </div>
            </Form.Item>
          }

          <Radio.Group
            defaultValue={voucherType}
            className="rs-radio-group rs-radio-group--button voucher-type-group"
            onChange={onVoucherTypeChange}
          >
            <Radio.Button
              value="public"
              className="rs-radio rs-radio--button"
            >
              <GlobalOutlined />
              <div className="content">
                <span className="title">Voucher công khai</span>
                <div className="description">Là loại Voucher bất kỳ khách hàng nào có mã đều dùng được.</div>
              </div>
            </Radio.Button>
            <Radio.Button
              value="group"
              className="rs-radio rs-radio--button"
            >
              <LockOutlined />
              <div className="content">
                <span className="title">Voucher cho nhóm</span>
                <div className="description">Là loại Voucher chỉ dành cho một tập khách hàng được chọn.</div>
              </div>
            </Radio.Button>
            <Radio.Button
              value="private"
              className="rs-radio rs-radio--button"
            >
              <UserAddOutlined />
              <div className="content">
                <span className="title">Voucher cá nhân</span>
                <div className="description">Chỉ áp dụng cho khách hàng được chọn.</div>
              </div>
            </Radio.Button>
          </Radio.Group>

          {
            voucherType === 'group' &&
            <Form.Item<FieldType>
              name="customerGroups"
              rules={[{ required: true, message: 'Vui lòng tìm và chọn nhóm khách hàng!' }]}
            >
              <div className="block block-gap-12">
                <span className="font-medium lh-24 fsz-16">Tìm và chọn nhóm khách hàng</span>
                <SelectCustomerGroup
                  onChange={(e) => changeCustomerGroup(e)}
                />
              </div>
            </Form.Item>
          }

          {
            voucherType === 'private' &&
            <Form.Item<FieldType>
              name="customers"
              rules={[{ required: true, message: 'Vui lòng tìm để thêm khách hàng!' }]}
            >
              <div className="block block-gap-12">
                <span className="font-medium lh-24 fsz-16">Tìm để thêm khách hàng</span>
                <SelectCustomer
                  onChange={(e) => changeCustomer(e)}
                />
              </div>
            </Form.Item>
          }

          <Form.Item<FieldType>
            name="condition"
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(new Error('Vui lòng nhập giá trị đơn hàng!'));
                  }
                  if (!Number.isInteger(+value) || value <= 0) {
                    return Promise.reject(new Error('Giá trị đơn hàng phải là số nguyên dương!'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <div className="block-flex">
              <span className="font-medium lh-24 fsz-16">Điều kiện</span>

              <div className="block-flex__content">
                <InputNumber
                  addonAfter="VNĐ"
                  min={1000}
                  controls
                  placeholder="Số tiền"
                  className="rs-input-number"
                />
              </div>
            </div>
          </Form.Item>

          <Form.Item<FieldType>
            name="discountType"
            rules={[{ required: true, message: 'Vui lòng chọn hình thức giảm!' }]}
          >
            <div className="block-flex">
              <span className="font-medium lh-24 fsz-16">Hình thức giảm</span>

              <div className="block-flex__content">
                <Radio.Group
                  className="rs-radio-group"
                  onChange={onDiscountTypeChange}
                >
                  <Radio.Button
                    value="price"
                    className="rs-radio"
                  >
                    <span>Giảm số tiền</span>
                  </Radio.Button>
                  <Radio.Button
                    value="percent"
                    className="rs-radio"
                  >
                    <span>Giảm %</span>
                  </Radio.Button>
                </Radio.Group>
              </div>
            </div>
          </Form.Item>

          {discountType === 'price' &&
            <Form.Item<FieldType>
              name="reducedAmount"
              rules={[
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject(new Error('Vui lòng nhập số tiền!'));
                    }
                    if (!Number.isInteger(+value) || value <= 0) {
                      return Promise.reject(new Error('Số tiền phải là số nguyên dương!'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <div className="block-flex">
                <span className="font-medium lh-24 fsz-16">Số tiền giảm</span>

                <div className="block-flex__content fix-rv">
                  <InputNumber
                    addonAfter="VNĐ"
                    min={1000}
                    controls
                    placeholder="Số tiền giảm"
                    className="rs-input-number"
                  />
                </div>
              </div>
            </Form.Item>
          }

          {discountType === 'percent' &&
            <Form.Item<FieldType>
              name="percentReduction"
              rules={[
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject(new Error('Vui lòng nhập phần trăm giảm!'));
                    }
                    if (!Number.isInteger(+value) || value <= 0) {
                      return Promise.reject(new Error('Phần trăm giảm phải là số nguyên dương!'));
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <div className="block-flex">
                <span className="font-medium lh-24 fsz-16">Phần trăm giảm</span>

                <div className="block-flex__content fix-rv">
                  <InputNumber
                    addonAfter="%"
                    min={1}
                    max={100}
                    controls
                    placeholder="Phần trăm giảm"
                    className="rs-input-number"
                  />
                </div>
              </div>
            </Form.Item>
          }

          {
            discountType === 'percent'
            &&
            <Form.Item<FieldType>
              name="discountMax"
              rules={[
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject(new Error('Vui lòng nhập giá trị!'));
                    }
                    if (!Number.isInteger(+value) || value <= 0) {
                      return Promise.reject(new Error('Giá trị phải là số nguyên dương!'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <div className="block-flex">
                <span className="font-medium lh-24 fsz-16">Giảm tối đa</span>
                <InputNumber
                  addonAfter="VNĐ"
                  min={1000}
                  controls
                  placeholder="Số tiền giảm tối đa"
                  className="rs-input-number"
                />
              </div>
            </Form.Item>
          }

          <Form.Item<FieldType>
            name="usesPerPerson"
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(new Error('Vui lòng nhập giá trị!'));
                  }
                  if (!Number.isInteger(+value) || value <= 0) {
                    return Promise.reject(new Error('Giá trị phải là số nguyên dương!'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
            // rules={[{ required: true, message: 'Vui lòng nhập giá trị!' }]}
            initialValue={1}
          >
            <div className="block-flex">
              <span className="font-medium lh-24 fsz-16">{voucherType === 'private' ? 'Khách hàng có thể áp dụng Voucher' : 'Mỗi khách hàng có thể áp dụng Voucher'}</span>

              <div className="block-flex__content">
                <InputNumber
                  addonAfter="Lần"
                  min={1}
                  defaultValue={1}
                  controls
                  placeholder="Số lần sử dụng"
                  className="rs-input-number"
                />
              </div>
            </div>
          </Form.Item>

          <div className="block">
            <span className="font-medium lh-24 fsz-16">Thời gian Voucher hiệu lực</span>

            <div className="block-grid">
              <div className="block-grid__item">
                <span>Bắt đầu</span>

                <div className="block-grid">
                  <div className="block-grid__item">
                    <Form.Item<FieldType>
                      name="startDate"
                      rules={[{ required: true, message: 'Vui lòng chọn ngày bắt đầu!' }]}
                    >
                      <DatePicker
                        placeholder="Chọn ngày"
                        className="rs-date-picker"
                        popupClassName="rs-date-picker-dropdown"
                      />
                    </Form.Item>
                  </div>
                  <div className="block-grid__item">
                    <Form.Item<FieldType>
                      name="startTime"
                      rules={[{ type: 'object' as const, required: true, message: 'Vui lòng chọn giờ bắt đầu!' }]}
                    >
                      <TimePicker
                        minuteStep={30}
                        placeholder="Chọn giờ"
                        className="rs-date-picker"
                        popupClassName="rs-date-picker-dropdown"
                        format={'HH:mm'}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="block-grid__item">
                <span>Kết thúc</span>

                <div className="block-grid">
                  <div className="block-grid__item">
                    <Form.Item<FieldType>
                      name="endDate"
                      rules={[{ required: true, message: 'Vui lòng chọn ngày kết thúc!' }]}
                    >
                      <DatePicker
                        placeholder="Chọn ngày"
                        className="rs-date-picker"
                        popupClassName="rs-date-picker-dropdown"
                      />
                    </Form.Item>
                  </div>
                  <div className="block-grid__item">
                    <Form.Item<FieldType>
                      name="endTime"
                      rules={[{ type: 'object' as const, required: true, message: 'Vui lòng chọn giờ kết thúc!' }]}
                    >
                      <TimePicker
                        minuteStep={30}
                        placeholder="Chọn giờ"
                        className="rs-date-picker"
                        popupClassName="rs-date-picker-dropdown"
                        format={'HH:mm'}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>

        <div className="voucher-add__footer">
          <Button
            className="rs-button"
            onClick={() => navigator(-1)}
          >
            Quay lại
          </Button>
          <Button
            type="primary"
            className="rs-button"
            loading={confirmLoading}
            onClick={onSubmitForm}
          >Xác nhận thông tin</Button>
        </div>
      </div>
    </Layout>
  );
}

export default VoucherCreate;
