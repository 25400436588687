import React, { useRef, useState } from 'react'
import { Button, DatePicker, Flex, Form, Input, Modal, notification, Radio, RadioChangeEvent, Select } from 'antd'
import './AddRelative.scss'

interface Props {
  show: boolean
  modalClose?: () => void
  onSuccess?: () => void
}

const AddRelativeModal:React.FC<Props> = (props) => {
  const { show, modalClose, onSuccess } = props
  const formRef: any = useRef(null)

  const [loading, setLoading] = useState(false)
  const onFormFinish = (values: any) => {
    setLoading(true)

    setTimeout(() => {
      setLoading(false)
      openNotification('success', 'Tạo mới thành công')
      if (onSuccess) {
        onSuccess()
      }
      if (formRef.current) {
        formRef.current.resetFields()
      }
    }, 1500)
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'error' = 'success', message: string = '') => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  // GENDERS
  const genders = [
    { value: 'nam', label: 'Nam', icon: <svg width="1.143em" height="1.143em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14.4643 1H9.96429C9.90536 1 9.85714 1.04821 9.85714 1.10714V2.10714C9.85714 2.16607 9.90536 2.21429 9.96429 2.21429H12.8286L9.26964 5.77321C8.37679 5.08571 7.2875 4.71429 6.14286 4.71429C4.76964 4.71429 3.47679 5.25 2.50714 6.22143C1.5375 7.19286 1 8.48393 1 9.85714C1 11.2304 1.53571 12.5232 2.50714 13.4929C3.47679 14.4643 4.76964 15 6.14286 15C7.51607 15 8.80893 14.4643 9.77857 13.4929C10.75 12.5232 11.2857 11.2304 11.2857 9.85714C11.2857 8.7125 10.9143 7.62679 10.2286 6.73393L13.7857 3.17679V6.03572C13.7857 6.09464 13.8339 6.14286 13.8929 6.14286H14.8929C14.9518 6.14286 15 6.09464 15 6.03572V1.53571C15 1.24107 14.7589 1 14.4643 1ZM6.14286 13.6429C4.05536 13.6429 2.35714 11.9446 2.35714 9.85714C2.35714 7.76964 4.05536 6.07143 6.14286 6.07143C8.23036 6.07143 9.92857 7.76964 9.92857 9.85714C9.92857 11.9446 8.23036 13.6429 6.14286 13.6429Z" fill="#1890FF"/> </svg> },
    { value: 'nu', label: 'Nữ', icon: <svg width="1.143em" height="1.143em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_15451_51524)"> <path d="M11.5859 8.65714C12.543 7.7 13.0716 6.425 13.0716 5.07143C13.0716 3.71607 12.5448 2.44286 11.5859 1.48571C10.6287 0.528571 9.35371 0 8.00014 0C6.64657 0 5.37157 0.526786 4.41443 1.48571C3.45728 2.44464 2.92871 3.71607 2.92871 5.07143C2.92871 6.21071 3.30193 7.29286 3.98943 8.17679C4.11978 8.34464 4.26085 8.50357 4.41264 8.65714C4.56443 8.80893 4.72514 8.95 4.89121 9.08036C5.59835 9.63036 6.43228 9.98036 7.32157 10.0982V12H5.28585C5.20728 12 5.143 12.0643 5.143 12.1429V13.2143C5.143 13.2929 5.20728 13.3571 5.28585 13.3571H7.32157V15.8571C7.32157 15.9357 7.38585 16 7.46443 16H8.53585C8.61443 16 8.67871 15.9357 8.67871 15.8571V13.3571H10.7144C10.793 13.3571 10.8573 13.2929 10.8573 13.2143V12.1429C10.8573 12.0643 10.793 12 10.7144 12H8.67871V10.0982C9.77693 9.95179 10.7894 9.45357 11.5859 8.65714ZM8.00014 8.78572C7.00728 8.78572 6.07693 8.4 5.37335 7.69821C4.67157 6.99643 4.28585 6.06429 4.28585 5.07143C4.28585 4.07857 4.67157 3.14821 5.37335 2.44464C6.07514 1.74107 7.00728 1.35714 8.00014 1.35714C8.993 1.35714 9.92335 1.74286 10.6269 2.44464C11.3287 3.14643 11.7144 4.07857 11.7144 5.07143C11.7144 6.06429 11.3287 6.99464 10.6269 7.69821C9.92335 8.4 8.993 8.78572 8.00014 8.78572Z" fill="#8C62FF"/> </g> <defs> <clipPath id="clip0_15451_51524"> <rect width="16" height="16" fill="white"/> </clipPath> </defs> </svg> },
  ]
  const [selectedGender, setSelectedGender] = useState('nam')
  const onChangeGender = ({ target: { value } }: RadioChangeEvent) => {
    setSelectedGender(value)
  }

  return (
    <>
      { contextHolder }

      <Modal
        open={show}
        title='Thêm người thân'
        className='rs-modal w-572 footer-content-end add-relative-modal'
        centered
        footer={
          <Button
            type='primary'
            className='rs-button rs-button--sm'
            loading={loading}
            onClick={() => formRef?.current.submit()}
          >Lưu</Button>
        }
        onCancel={modalClose}
      >
        <Form
          ref={formRef}
          className='rs-form add-relative-form'
          onFinish={onFormFinish}
        >
          <Form.Item
            layout='vertical'
            label='Họ và tên'
            name='ho-ten'
            rules={[
              { required: true, message: "Vui lòng nhập Họ và tên" }
            ]}
          >
            <Input
              placeholder='Họ và tên'
              className='rs-input rs-input--32'
            />
          </Form.Item>

          <Form.Item
            label=''
            name='gioi-tinh'
            rules={[
              { required: true, message: "Vui lòng chọn giới tính" }
            ]}
          >
            <Radio.Group
              value={selectedGender}
              className='rs-radio-group gender-group'
              onChange={onChangeGender}
            >
              {
                genders.map((item: any) => (
                  <Radio.Button
                    key={item.value}
                    value={item.value}
                    className='rs-radio'
                  >
                    <span className='inner-text'>{ item.icon }{ item.label }</span>
                  </Radio.Button>
                ))
              }
            </Radio.Group>
          </Form.Item>

          <Form.Item
            layout='vertical'
            label='Mối quan hệ'
            name='moi-quan-he'
            rules={[
              { required: true, message: "Vui lòng chọn mối quan hệ" }
            ]}
          >
            <Select
              placeholder='Mối quan hệ'
              className='rs-select'
              popupClassName='rs-select-dropdown'
              options={[
                { value: 'cha', label: 'Cha' },
                { value: 'me', label: 'Mẹ' },
              ]}
            />
          </Form.Item>

          <Form.Item
            layout='vertical'
            label='Email'
            name='email'
          >
            <Input
              placeholder='Email'
              className='rs-input rs-input--32'
            />
          </Form.Item>

          <Form.Item
            layout='vertical'
            label='Số điện thoại'
            name='so-dien-thoai'
            rules={[
              { required: true, message: "Vui lòng nhập số điện thoại" }
            ]}
          >
            <Input
              placeholder='Số điện thoại'
              className='rs-input rs-input--32'
            />
          </Form.Item>

          <Form.Item
            layout='vertical'
            label='Sinh nhật'
            name='sinh-nhat'
            rules={[
              { required: true, message: "Vui lòng chọn ngày sinh" }
            ]}
          >
            <DatePicker
              placeholder='Chọn ngày sinh'
              className='rs-date-picker w-full'
              popupClassName='rs-date-picker-dropdown'
            />
          </Form.Item>

          <Form.Item
            layout='vertical'
            label='Nghề nghiệp'
            name='nghe-nghiep'
            rules={[
              { required: true, message: "Vui lòng chọn nghề nghiệp" }
            ]}
          >
            <Select
              placeholder='Nghề nghiệp'
              className='rs-select'
              popupClassName='rs-select-dropdown'
              options={[
                { value: '1', label: 'Nhân viên ngân hàng' },
              ]}
            />
          </Form.Item>

          <Form.Item
            layout='vertical'
            label='Địa chỉ'
          >
            <Flex vertical className='gap-4px'>
              <Flex className='gap-10px'>
                <Form.Item
                  label=''
                  name='thanh-pho'
                  rules={[
                    { required: true, message: "Vui lòng chọn tỉnh/ thành phố" }
                  ]}
                  className='w-full'
                >
                  <Select
                    placeholder='Chọn tỉnh/thành phố'
                    className='rs-select'
                    popupClassName='rs-select-dropdown'
                    options={[
                      { value: '1', label: 'Hà Nội' },
                      { value: '2', label: 'Hồ Chí Minh' },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  label=''
                  name='quan'
                  rules={[
                    { required: true, message: "Vui lòng chọn quận/ huyện" }
                  ]}
                  className='w-full'
                >
                  <Select
                    placeholder='Chọn quận/huyện'
                    className='rs-select'
                    popupClassName='rs-select-dropdown'
                    options={[
                      { value: '1', label: 'Quận / Huyện' },
                    ]}
                  />
                </Form.Item>
              </Flex>

              <Form.Item
                label=''
                name='dia-chi'
                rules={[
                  { required: true, message: "Vui lòng nhập địa chỉ" }
                ]}
              >
                <Input
                  placeholder='Địa chỉ'
                  className='rs-input rs-input--32'
                />
              </Form.Item>
            </Flex>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AddRelativeModal
