import React, { useState } from 'react'
import { Button, Checkbox, CheckboxProps, Drawer, Flex, Radio, RadioChangeEvent, Table } from 'antd'
import { DeleteOutlined, DownOutlined, EyeOutlined, PlusCircleOutlined, SettingOutlined, UpOutlined } from '@ant-design/icons'
import './OrderHistoryCard.scss'
import { dragIcon } from '../../../store/icons'

interface Props {
  tableData: any
  className?: string
  onQueryChange?: (query: string) => void
  onDateChange?: (date: string) => void
  onCarTypeChange?: (type: string) => void
  onShowOwnedCarDetail?: (id: number) => void
  onShowFavoriteCarDetail?: (id: number) => void
  onDeleteOwnedCarDetail?: (id: number) => void
  onDeleteFavoriteCarDetail?: (id: number) => void
  onCreateOwnedCar?: () => void
  onCreateFavoriteCar?: () => void
}

const CarListCard: React.FC<Props> = (props) => {
  const {
    tableData,
    className = '',
    onCarTypeChange,
    onShowOwnedCarDetail,
    onShowFavoriteCarDetail,
    onDeleteOwnedCarDetail,
    onDeleteFavoriteCarDetail,
    onCreateOwnedCar,
    onCreateFavoriteCar,
  } = props

  const handleShowDetail = (id: number) => {
    if (selectedCarType === 'xe-dang-su-dung') {
      if (onShowOwnedCarDetail) {
        onShowOwnedCarDetail(id)
      }
    }
    if (selectedCarType === 'xe-quan-tam') {
      if (onShowFavoriteCarDetail) {
        onShowFavoriteCarDetail(id)
      }
    }
  }

  const handleDelete = (id: number) => {
    if (selectedCarType === 'xe-dang-su-dung') {
      if (onDeleteOwnedCarDetail) {
        onDeleteOwnedCarDetail(id)
      }
    }
    if (selectedCarType === 'xe-quan-tam') {
      if (onDeleteFavoriteCarDetail) {
        onDeleteFavoriteCarDetail(id)
      }
    }
  }

  const handleCreate = () => {
    if (selectedCarType === 'xe-dang-su-dung') {
      if (onCreateOwnedCar) {
        onCreateOwnedCar()
      }
    }
    if (selectedCarType === 'xe-quan-tam') {
      if (onCreateFavoriteCar) {
        onCreateFavoriteCar()
      }
    }
  }

  const [isShow, setIsShow] = useState(true)
  const onToggle = () => {
    setIsShow(!isShow)
  }

  const tableColumns: any = [
    {
      title: 'Hành động',
      key: 'action',
      render: (_: any, record: any) => (
        <Flex align='center' justify='center' className='gap-10px h-64px'>
          <Button
            icon={<EyeOutlined />}
            className='rs-button rs-button--sm'
            onClick={() => handleShowDetail(record?.id)}
          />
          <Button
            icon={<DeleteOutlined />}
            className='rs-button rs-button--sm delete'
            onClick={() => handleDelete(record?.id)}
          />
        </Flex>
      ),
      width: 93,
    },
    {
      title: 'Tên xe',
      dataIndex: 'name',
      key: 'name',
      render: (name: any, record: any) => (
        <Flex align='center' className='gap-10px'>
          <span>{ name }</span>
          <img src={record.logo} alt="logo" />
        </Flex>
      )
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'avatar',
      key: 'avatar',
      width: 100,
      render: (avatar: any) => (
        <Flex align='center' className=''>
          <img src={avatar} alt="avatar" />
        </Flex>
      )
    },
    {
      title: 'Loại xe',
      dataIndex: 'type',
      key: 'type',
      align: 'right'
    },
    {
      title: 'Thương hiệu',
      dataIndex: 'brand',
      key: 'brand',
      align: 'right'
    },
    {
      title: 'Dòng xe',
      dataIndex: 'class',
      key: 'class',
      align: 'right'
    },
    {
      title: 'Ngày mua xe',
      dataIndex: 'orderDate',
      key: 'orderDate',
      align: 'right'
    },
    {
      title: 'Ngày nhận xe',
      dataIndex: 'receiveDate',
      key: 'receiveDate',
      align: 'right'
    },
    {
      title: 'Năm sản xuất',
      dataIndex: 'year',
      key: 'year',
      align: 'right',
    },
    {
      title: 'Số khung (VIN)',
      dataIndex: 'vinNumber',
      key: 'vinNumber',
      align: 'right',
    },
  ]

  const favoriteTableColumns: any = [
    {
      title: 'Hành động',
      key: 'action',
      render: (_: any, record: any) => (
        <Flex align='center' justify='center' className='gap-10px h-64px'>
          <Button
            icon={<EyeOutlined />}
            className='rs-button rs-button--sm'
            onClick={() => handleShowDetail(record?.id)}
          />
          <Button
            icon={<DeleteOutlined />}
            className='rs-button rs-button--sm delete'
            onClick={() => handleDelete(record?.id)}
          />
        </Flex>
      ),
      width: 93,
    },
    {
      title: 'Tên xe',
      dataIndex: 'name',
      key: 'name',
      render: (name: any, record: any) => (
        <Flex align='center' className='gap-10px'>
          <span>{ name }</span>
          <img src={record.logo} alt="logo" />
        </Flex>
      )
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'avatar',
      key: 'avatar',
      width: 100,
      render: (avatar: any) => (
        <Flex align='center' className=''>
          <img src={avatar} alt="avatar" />
        </Flex>
      )
    },
    {
      title: 'Loại xe',
      dataIndex: 'type',
      key: 'type',
      align: 'right'
    },
    {
      title: 'Thương hiệu',
      dataIndex: 'brand',
      key: 'brand',
      align: 'right'
    },
    {
      title: 'Dòng xe',
      dataIndex: 'class',
      key: 'class',
      align: 'right'
    },
    {
      title: 'Ngày mua xe',
      dataIndex: 'orderDate',
      key: 'orderDate',
      align: 'right'
    },
    {
      title: 'Ngày nhận xe',
      dataIndex: 'receiveDate',
      key: 'receiveDate',
      align: 'right'
    },
  ]

  // TYPE CAR RADIO
  const carTypes = [
    { value: 'xe-dang-su-dung', label: 'Xe đang sử dụng' },
    { value: 'xe-quan-tam', label: 'Xe quan tâm' },
  ]
  const [selectedCarType, setSelectedCarType] = useState('xe-dang-su-dung')
  const onChangeCarType = ({ target: { value } }: RadioChangeEvent) => {
    setSelectedCarType(value)

    if (onCarTypeChange) {
      onCarTypeChange(value)
    }
  }

  const [open, setOpen] = useState(false)
  const onClose = () => {
    setOpen(false)
  }

  // DRAG AND DROP
  const [items, setItems] = useState<any>([
    { value: '1', label: 'Số hợp đồng' },
    { value: '2', label: 'Tên xe' },
    { value: '3', label: 'Hình ảnh' },
    { value: '4', label: 'Loại xe' },
    { value: '5', label: 'Thương hiệu' },
    { value: '6', label: 'Ngày mua xe' },
    { value: '7', label: 'Ngày nhập xe' },
    { value: '8', label: 'Năm sản xuất' },
    { value: '9', label: 'Số khung (VIN)' },
    { value: '10', label: 'Màu xe' },
    { value: '11', label: 'Màu nội thất' },
    { value: '12', label: 'TVBH' },
    { value: '13', label: 'Đại lý bán xe' },
  ])
  const [draggedItem, setDraggedItem] = useState<any>({})

  // ON SELECTED ITEMS DRAG START
  const onSelectedItemsDragStart = (e: any, index: number) => {
    setDraggedItem(items[index])
    e.dataTransfer.effectAllowed = "move"
    e.dataTransfer.setData("text/html", e.target.parentNode)
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20)
  }

  // ON SELECTED ITEMS DRAG OVER
  const onSelectedItemsDragOver = (index: number) => {
    const draggedOverItem = items[index]

    // if the item is dragged over itself, ignore
    if (draggedItem === draggedOverItem) {
      return
    }

    // filter out the currently dragged item
    let otherItems = items.filter((item: any) => item !== draggedItem)

    // add the dragged item after the dragged over item
    otherItems.splice(index, 0, draggedItem)
    setItems(otherItems)
  }

  // ON SELECTED ITEMS DRAG END
  const onSelectedItemsDragEnd = () => {
    setDraggedItem({})
  }

  // CHECK ALL
  const [checkedList, setCheckedList] = useState<string[]>(['2', '3', '4', '5', '6', '7', '8', '9'])
  const checkAll = items.length === checkedList.length
  const indeterminate = checkedList.length > 0 && checkedList.length < items.length
  const onChange = (list: string[]) => {
    setCheckedList(list)
  }
  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    const itemsId = items.map((item: any) => item.value)
    setCheckedList(e.target.checked ? itemsId : [])
  }

  return (
    <>
      <div className={`order-history-card ${className}`}>
        <div className="order-history-card__head">
          <Radio.Group
            value={selectedCarType}
            className='rs-radio-group'
            onChange={onChangeCarType}
          >
            {
              carTypes.map((item: any) => (
                <Radio.Button
                  key={item.value}
                  value={item.value}
                  className='rs-radio'
                >{ item.label }</Radio.Button>
              ))
            }
          </Radio.Group>

          <Button
            icon={<PlusCircleOutlined />}
            iconPosition='end'
            className='rs-button rs-button--sm primary-outline'
            onClick={() => handleCreate()}
          >Thêm mới</Button>

          {
            selectedCarType === 'xe-dang-su-dung' &&
              <Button
                icon={<SettingOutlined />}
                iconPosition='end'
                className='rs-button rs-button--sm setting'
                onClick={() => setOpen(true)}
              />
          }

          <Button
            className='rs-button rs-button--sm collapse-button'
            icon={ isShow ? <UpOutlined /> : <DownOutlined /> }
            iconPosition='end'
            onClick={onToggle}
          >{ isShow ? 'Thu gọn' : 'Mở rộng' }</Button>
        </div>

        <div className={`order-history-card__body ${isShow ? 'show' : 'collapsed'}`}>
          {
            selectedCarType === 'xe-dang-su-dung' &&
              <Table
                className='rs-table'
                rowKey="id"
                columns={tableColumns}
                dataSource={tableData}
                showSorterTooltip={false}
                pagination={false}
                scroll={{ x: true }}
                // pagination={{
                //   showSizeChanger: true,
                //   defaultPageSize: 15,
                //   showTotal: (total) => `Tổng cộng ${Math.ceil(total / 15)} trang`,
                //   locale: { items_per_page: ' / trang', jump_to: 'Tới' },
                //   position: ['bottomCenter'],
                //   className: 'rs-pagination',
                //   showQuickJumper: true,
                // }}
              />
          }
          {
            selectedCarType === 'xe-quan-tam' &&
              <Table
                className='rs-table'
                rowKey="id"
                columns={favoriteTableColumns}
                dataSource={tableData}
                showSorterTooltip={false}
                pagination={false}
                scroll={{ x: true }}
                // pagination={{
                //   showSizeChanger: true,
                //   defaultPageSize: 15,
                //   showTotal: (total) => `Tổng cộng ${Math.ceil(total / 15)} trang`,
                //   locale: { items_per_page: ' / trang', jump_to: 'Tới' },
                //   position: ['bottomCenter'],
                //   className: 'rs-pagination',
                //   showQuickJumper: true,
                // }}
              />
          }
        </div>
      </div>

      <Drawer
        title='Nâng cao'
        rootClassName="rs-drawer w-400"
        closeIcon={false}
        open={open}
        footer={
          <Button
            type='primary'
            block
            className='rs-button rs-button--sm'
          >Lưu</Button>
        }
        onClose={onClose}
      >
        <Flex vertical className='gap-18px'>
          <Flex align='center' justify='space-between'>
            <span className="fsz-16 font-medium">Tùy chỉnh trường hiển thị trên bảng</span>
            <Checkbox
              className='rs-checkbox rs-checkbox--sm'
              indeterminate={indeterminate}
              checked={checkAll}
              onChange={onCheckAllChange}
            ><span className='fsz-12 lh-20'>Chọn tất cả</span></Checkbox>
          </Flex>

          <Checkbox.Group
            className='rs-checkbox-group vertical gap-12px'
            value={checkedList}
            onChange={onChange}
          >
            {
              items.map((item: any, index: number) => (
                <Flex
                  key={index}
                  align='center'
                  justify='space-between'
                  className='gap-8px'
                  onDragOver={() => onSelectedItemsDragOver(index)}
                >
                  <Checkbox
                    className='rs-checkbox'
                    value={item.value}
                  >{ item.label }</Checkbox>
                  <span
                    className="icon"
                    draggable
                    onDragStart={(e: any) => onSelectedItemsDragStart(e, index)}
                    onDragEnd={onSelectedItemsDragEnd}
                  >{ dragIcon }</span>
                </Flex>
              ))
            }
          </Checkbox.Group>
        </Flex>
      </Drawer>
    </>
  )
}

export default CarListCard
