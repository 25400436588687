import { DownloadOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import React from "react";

const numberFormat = (number: any) => {
  const result = new Intl.NumberFormat().format(number)
  return result
}

const ARCTopCarOwned: React.FC = () => {
  return (
    <div className="arc-card primary-gradient">
      <div className="arc-card__head">
        <span className="title">Top xe sở hữu nhiều</span>
        <div className="control">
          <Select
            placeholder="Từ cao đến thấp"
            defaultValue="cao-den-thap"
            options={[
              { value: 'cao-den-thap', label: 'Từ cao đến thấp' },
              { value: 'thap-den-cao', label: 'Từ thấp đến cao' },
            ]}
            className='rs-select'
            popupClassName="rs-select-dropdown"
          />
          <Button
            className="rs-button"
          >
            <DownloadOutlined />
            <span>Xuất báo cáo</span>
          </Button>
        </div>
      </div>

      <div className="arc-card__content">
        <div className="top-car-list">
          <div className="top-car-item">
            <div className="brand">BMW</div>
            <div className="name">BMW Series 7</div>
            <div className="quantity">{ numberFormat(32234) }</div>
          </div>
          <div className="top-car-item">
            <div className="brand">KIA</div>
            <div className="name">KIA Morning</div>
            <div className="quantity">{ numberFormat(23234) }</div>
          </div>
          <div className="top-car-item">
            <div className="brand">Mazda</div>
            <div className="name">Mazda CX 5</div>
            <div className="quantity">{ numberFormat(13234) }</div>
          </div>
          <div className="top-car-item">
            <div className="brand">Mazda</div>
            <div className="name">Mazda CX 4</div>
            <div className="quantity">{ numberFormat(11234) }</div>
          </div>
          <div className="top-car-item">
            <div className="brand">Mini</div>
            <div className="name">MINI S 5 Door</div>
            <div className="quantity">{ numberFormat(10234) }</div>
          </div>
          <div className="top-car-item">
            <div className="brand">KIA</div>
            <div className="name">KIA K9</div>
            <div className="quantity">{ numberFormat(9234) }</div>
          </div>
          <div className="top-car-item">
            <div className="brand">KIA</div>
            <div className="name">KIA K4</div>
            <div className="quantity">{ numberFormat(4234) }</div>
          </div>
          <div className="top-car-item">
            <div className="brand">KIA</div>
            <div className="name">KIA K4</div>
            <div className="quantity">{ numberFormat(4234) }</div>
          </div>
          <div className="top-car-item">
            <div className="brand">KIA</div>
            <div className="name">KIA K4</div>
            <div className="quantity">{ numberFormat(4234) }</div>
          </div>
          <div className="top-car-item">
            <div className="brand">KIA</div>
            <div className="name">KIA K4</div>
            <div className="quantity">{ numberFormat(4234) }</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ARCTopCarOwned