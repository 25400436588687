import {
  CloseCircleOutlined,
  CloseOutlined,
  DownOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  UploadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Badge, Breadcrumb, ConfigProvider, Input, Pagination, Select, Switch, Table, message } from 'antd';
import type { SearchProps } from 'antd/es/input/Search';
import viVN from 'antd/locale/vi_VN';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Layout from '../../Layout';
import * as IssuedCardsApi from '../../api/rank/issuedCardApi';
import ModalUpload from '../../components/Modal/ModalUpload';
import { API_URL, LIMIT_EXPORT_EXCEL, URL_RANK } from '../../env';
import useThrottle from '../../hook/useThrottle';
import useToast from '../../hook/useToast';
import './CardIssuance.scss';
import SelectFilterRankUser from './ComponentMemberCar/SelectFilterRankUser';
import SelectFilterTypeRank from './ComponentMemberCar/SelectFilterTypeRank';
import DrawerEditCard from './Drawer/DrawerEditCard';
import ShowAddCard from './ShowAddCard';
import ShowDetailUserCard from './ShowDetailUserCard';
import * as RankApi from '../../api/rank/rankApi';
import ModalDeleteManager from '../../components/ModalDeleteManager';
const { Search } = Input;

const Home: React.FC = () => {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const [dataIssuedCards, setDataIssuedCards] = useState<IssuedCardsApi.DataTypeIssuedCards[]>([])
  const pushToast = useToast();

  const [accessSelectFilter, setAccessActive] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isUserCard, setUserCard] = useState(false)
  const [isAddCard, setAddCard] = useState(false)
  const [isEditCard, setEditCard] = useState(false)
  const [isFilterRankUser, setFilterRankUser] = useState(false)
  const [isFilterTypeRank, setFilterTypeRank] = useState(false)
  const [openUploadDSCard, setOpenUploadDSCard] = useState(false)
  // const [openDeleteCard, setOpenDeleteCard] = useState(false)
  const [typeCard, settypeCard] = useState<number | undefined>(undefined)
  const [rankId, setRankId] = useState<number | undefined>(undefined)
  const [search, setSearch] = useState<string>('')
  const [dataInfoIssuedCards, setDataInfoIssuedCards] = useState<IssuedCardsApi.IInfoIssuedCards>()
  const [dataInfoCardCustomer, setDataInfoCardCustomer] = useState<IssuedCardsApi.IInfoCardCustomer>()
  const [idEdit, setIdEdit] = useState<number>()

  const hasSelected = selectedRowKeys.length > 0;
  const columns: TableColumnsType<IssuedCardsApi.DataTypeIssuedCards> = [
    {
      title: 'Hành động',
      dataIndex: '',
      align: 'center',
      render: (text: string, record: IssuedCardsApi.DataTypeIssuedCards) => (
        <>
          {record.status === 2 || record.status === 3 ? (
            <div className="d-flex align-center justify-between t-op-40 pr-6-px pl-6-px">
              <div className='d-flex align-center gap-10-px '>
                <EyeOutlined className='text-344054 text-sm border-EAECF0 p-5-px bg-F5F5F5 rounded-2' />
                <Link to={'/customer/update-customer'}>
                  <EditOutlined className='text-344054 text-sm border-EAECF0 p-5-px bg-F5F5F5 rounded-2' />
                </Link>
              </div>


              <div className="t-switch-block t-switch-block-sm">
                <Switch
                  onChange={() => updateActiveIssuedCards(Number(record.key))}
                />
              </div>
            </div>
          ) : (
            <div className="t-hd-table">
              <div
                className="item-hd-table"
                onClick={() => { setUserCard(true); getInfoCardCustomer(record.key) }}>
                <span><EyeOutlined /></span>
              </div>
              <div
                className="item-hd-table cursor-pointer"
                onClick={() => { setIdEdit(record.key); setEditCard(true); getInfoIssuedCards(record.key); getListRankEdit(record.key) }}>
                <span><EditOutlined /></span>
              </div>
              {/* <DeleteOutlined
                className='text-F5222D text-sm border-EAECF0 p-5-px bg-white rounded-2'
                onClick={() => setOpenDeleteCard(true)}
              /> */}
              <div className="t-switch-block t-switch-block-sm">
                <Switch
                  defaultChecked={record.status ? true : false}
                  onChange={() => updateActiveIssuedCards(Number(record.key))} />
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
    },
    {
      title: 'Mã thẻ',
      dataIndex: 'mt',
      render: (text: string, record: IssuedCardsApi.DataTypeIssuedCards) => (
        <>
          {record.status === 2 || record.status === 3 ? (
            <div className="t-op-40">Chưa phát hành</div>
          ) : (
            <div>{text}</div>
          )}
        </>
      )
    },
    {
      title: 'Họ và tên',
      dataIndex: 'name',
      render: (text: string) => (
        <div className='td-customer-name'>{text}</div>
      )
    },
    {
      title: 'Hạng thẻ',
      dataIndex: 'ht',
      render: (text: string, record: IssuedCardsApi.DataTypeIssuedCards) => (
        <>
          {record.status === 2 || record.status === 3 ? (
            <div className="t-op-40">Chưa phát hành</div>
          ) : (
            <div className="ctrl-title-ht">
              <div className="img-box">
                <img src={URL_RANK + record.icon} alt="" />
              </div>
              <div className="text">{record.ht}</div>
            </div>
          )}
        </>
      )
    },
    {
      title: 'Ngày phát hành',
      dataIndex: 'nph',
      render: (text: string, record: IssuedCardsApi.DataTypeIssuedCards) => (
        <>
          {record.status === 2 || record.status === 3 ? (
            <div className="t-op-40">Chưa phát hành</div>
          ) : (
            <div>{text}</div>
          )}
        </>
      )
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'nhh',
      render: (text: string, record: IssuedCardsApi.DataTypeIssuedCards) => (
        <>
          {record.status === 2 || record.status === 3 ? (
            <div className="t-op-40">Chưa phát hành</div>
          ) : (
            <div>{text}</div>
          )}
        </>
      )
    },
    {
      title: 'Trạng thái thẻ',
      dataIndex: '',
      render: (e, record: IssuedCardsApi.DataTypeIssuedCards) => (
        <>
          {record.status === 3 ? (
            <div className="t-op-40">Chưa phát hành</div>
          ) : (
            <>
              {record.status === 1 && <Badge status="success" text="Hoạt động" />}
              {record.status === 0 && <Badge status="warning" text="Tạm khóa" />}
              {record.status === 2 && <Badge status="error" className="t-op-40" text="Hết hạn" />}
            </>
          )}
        </>
      )
    },
  ];
  const [dataRank, setDataRank] = useState<RankApi.IRank[]>([])
  const [dataRankEdit, setDataRankEdit] = useState<IssuedCardsApi.IListRankEdit[]>([])

  const setActiveHiddenFilter = () => {
    setAccessActive(false)
  };


  const getListIssuedCards = async (limit: number = 10, pageNumber: number = 1) => {
    const result = await IssuedCardsApi.getListIssuedCards(limit, pageNumber, typeCard, rankId, search);
    if (result.status) {
      setDataIssuedCards(convertData(result.data, pageNumber, limit))
      setPagination({ current: pageNumber, total: result.total, pageSize: limit })
    } else {
      pushToast(result.message, "error", 2000)

    }
  }
  const getListRank = async () => {
    const result = await RankApi.getListRank();
    if (result.status) {
      setDataRank(result.data)
    } else {
      pushToast(result.message, "error", 2000)

    }
  }

  const getListRankEdit = async (id: number) => {
    const result = await IssuedCardsApi.getlistRankEdit(id);
    if (result.status) {
      setDataRankEdit(result.data)
    } else {
      pushToast(result.message, "error", 2000)

    }
  }

  const getInfoIssuedCards = async (id: number) => {
    const result = await IssuedCardsApi.getInfoIssuedCards(id);
    if (result.status) {
      setDataInfoIssuedCards(result.data);
    } else {
      pushToast(result.message, "error", 2000)

    }
  }
  // lấy thông tin khahcs hàng để cho vào chỉnh sửa
  const getInfoCardCustomer = async (id: number) => {
    const result = await IssuedCardsApi.getInfoCardCustomer(id);
    if (result.status) {
      setDataInfoCardCustomer(result.data);
    } else {
      pushToast(result.message, "error", 2000)

    }
  }

  useEffect(() => {
    getListRank()
  }, [])
  const convertData = (data: IssuedCardsApi.IIssuedCards[], pageNumber: number, limit: number) => {
    var stt = (pageNumber - 1) * limit + 1;

    const convertData = data.map((item) => ({
      key: item.id,
      stt: stt++,
      mt: item.codeCard,
      name: item.name,
      ht: item.rank.title,
      nph: item.releaseDate,
      nhh: item.expirationDate,
      status: item.status,
      icon: item.rank.icon
    }
    ))
    return convertData
  }
  const updateActiveIssuedCards = async (id: number) => {
    const result = await IssuedCardsApi.updateActiveIssuedCards(id);
    if (result?.status) {
      getListIssuedCards(pagination.pageSize, pagination.current)
      pushToast(result.message, "success", 2000)
    } else {
      pushToast(result.message, "error", 2000)

    }
  }

  const handleChangePagination = (current: number, pageSize: number, typeCard?: number, rankId?: number, search?: string) => {
    getListIssuedCards(pageSize, current)
  }
  const setAddCardSuccess = () => {
    setAddCard(false)
    message.success({
      content: 'Phát hành thẻ thành công',
      duration: 2,
      className: 't-message',
    });
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    // getListIssuedCards(pagination.pageSize, pagination.current)
    setSearch(value)
  }

  const glistByExcel = useThrottle(async () => {
    const result = await IssuedCardsApi.getListIssuedCards(
      LIMIT_EXPORT_EXCEL, 1, typeCard, rankId, search
    )
    if (result?.status) {
      formattedDataExcel(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const formattedDataExcel = (data: IssuedCardsApi.IIssuedCards[]) => {
    const formattedData = data.map((item) => {
      return {
        "Họ và tên": item.name,
        "Hạng thẻ": item.rank,
        "Ngày phát hành": item.releaseDate,
        "Ngày hết hạn": item.expirationDate,
        "Trạng thái thẻ": item.status === 0 ? "Tạm khóa" : item.status === 1 ? "Hoạt Động" : item.status === 2 ? "Hết hạn" : 'Chưa phát hành',
      }
    }
    );
    exportExcel(formattedData)
  }
  const glistByExcelChoose = useThrottle(async () => {
    const result = await IssuedCardsApi.getListIssuedCards(
      LIMIT_EXPORT_EXCEL, 1, typeCard, rankId, search
    )
    if (result?.status) {
      formattedDataExcelChoose(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const formattedDataExcelChoose = (data: IssuedCardsApi.IIssuedCards[]) => {
    const filteredData = data.filter(item => selectedRowKeys.includes(item.id));
    const formattedData = filteredData.map((item) => {
      return {
        "Họ và tên": item.name,
        "Hạng thẻ": item.rank,
        "Ngày phát hành": item.releaseDate,
        "Ngày hết hạn": item.expirationDate,
        "Trạng thái thẻ": item.status === 0 ? "Tạm khóa" : item.status === 1 ? "Hoạt Động" : item.status === 2 ? "Hết hạn" : 'Chưa phát hành',
      }
    }
    );
    exportExcel(formattedData)
  }

  function exportExcel(convertData: any): boolean {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData);
      const workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách');

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = window.URL.createObjectURL(data);
        link.download = fileName;
        link.click();
      };

      saveAsExcel(excelBuffer, `${"Danh sách Phát hành thẻ"}.xlsx`);
      return true; // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error);
      return false; // Export failed
    }
  }


  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    console.log(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: IssuedCardsApi.DataTypeIssuedCards) => ({
      disabled: record.status === 1 || record.status === 0, // Column configuration not to be
      active: record.status === 3,
    }),
  };

  const onChangeTB: TableProps<IssuedCardsApi.DataTypeIssuedCards>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const [isAddCardUser, setAddCardUser] = useState(false)


  useEffect(() => {
    getListIssuedCards()

  }, [typeCard, search, rankId]);
  return (
    <div className='card-issuance-list'>
      {/* <Helmet>
        <title>THACO AUTO CRM</title>
      </Helmet> */}
      <Layout>
        <DrawerEditCard
          id={idEdit}
          isOpen={isEditCard}
          closeDrawer={() => setEditCard(!isEditCard)}
          dataInfoIssuedCards={dataInfoIssuedCards}
          dataRank={dataRankEdit}
          getListIssuedCards={getListIssuedCards}


        />
        <ModalUpload
          isOpen={openUploadDSCard}
          closeModal={() => setOpenUploadDSCard(!openUploadDSCard)}

          linkSampleFile={"https://api.thacocrm.bdata.link/uploads/fileExcel/cardIssuance/Danhsachphathanhthe.xlsx"}
          linkUploandFile={`${API_URL}rank/importExcel`}
        />
        {/* <ModalDeleteManager
          isOpen={openDeleteCard}
          // onConfirm={handleDelete}
          closeModal={() => setOpenDeleteCard(!openDeleteCard)}
          title="Bạn có chắc chắn xóa thẻ này"
          titleDelete="Xóa thẻ"
          content="Thẻ và dữ liệu liên quan sẽ bị xóa vĩnh viễn
    không thể khôi phục"/> */}
        <Breadcrumb
          items={[
            {
              title: 'THACO AUTO CRM',
            },
            {
              title: <a href="/">Thẻ thành viên</a>,
            },
            {
              title: 'Phát hành thẻ',
            },
          ]}
        />

        <div className='title-page'>
          Phát hành thẻ
        </div>

        {/* Fillter block */}
        <div className={`filter-block ${hasSelected ? "active" : ""}`}>
          <div className='t-search-block'>
            <Search placeholder="Tìm kiếm Họ và tên" onSearch={onSearch} />
          </div>

          <div className='filter-select'>
            <div
              className='btn-select-show'
            >
              <span className="content-select" onClick={() => setFilterRankUser(true)} >Nhóm khách hàng</span>
              <div
                className={`icon-down ${isFilterRankUser ? "active" : ""}`}
                onClick={() => setFilterRankUser(true)}
              >
                <DownOutlined />
              </div>
              <div
                className={`icon-close ${isFilterRankUser ? "active" : ""}`}
                onClick={() => setFilterRankUser(false)}
              >
                <CloseCircleOutlined />
              </div>
            </div>

            {
              isFilterRankUser
                ? <SelectFilterRankUser
                  onClose={() => { setFilterRankUser(false) }}
                  settypeCard={settypeCard}
                  typeCard={typeCard}

                />
                : null
            }
          </div>

          <div className='filter-select filter-select2'>
            <div
              className='btn-select-show'
            >
              <span className="content-select" onClick={() => setFilterTypeRank(true)} >Loại thẻ</span>
              <div
                className={`icon-down ${isFilterTypeRank ? "active" : ""}`}
                onClick={() => setFilterTypeRank(true)}
              >
                <DownOutlined />
              </div>
              <div
                className={`icon-close ${isFilterTypeRank ? "active" : ""}`}
                // Nhờ anh check lại chỗ này, vì khi mình mở modal thì chỗ này nó bị backup modal che rồi nên k thể click dc
                onClick={() => console.log('object')}
              >
                <CloseCircleOutlined />
              </div>
            </div>
            {
              isFilterTypeRank
                ? <SelectFilterTypeRank
                  className="show"
                  onClose={() => { setFilterTypeRank(false) }}
                  setRankId={setRankId}
                  rankId={rankId}
                  dataRank={dataRank}
                />
                : null
            }
          </div>

          <div className="btn-input"
            onClick={glistByExcel}
          >
            <div className="icon-svg">
              <DownloadOutlined />
            </div>
            <div className="text">Xuất DS</div>
          </div>

          <div className="btn-input btn-input2"
            onClick={() => setOpenUploadDSCard(true)}
          >
            <div className="icon-svg">
              <UploadOutlined />
            </div>
            <div className="text">Nhập DS</div>
          </div>

          <div className="btn-input btn-primary">
            <div
              className="text"
              onClick={() => setAddCardUser(true)}
            >
              Phát hành thẻ
            </div>
          </div>

          {/* <div className="btn-input t-px-8">
            <div className="icon-svg" onClick={ () => setSettingShow(true)}>
              <SettingOutlined />
            </div>
          </div> */}
        </div>

        {/* Fillter block */}
        <div className={`filter-block filter-active-show ${hasSelected ? "active" : ""}`}>
          <div className='total-chosse'>
            {hasSelected ? `Đã chọn ${selectedRowKeys.length} khách hàng` : ''}
          </div>

          <div className="btn-primary-auto" onClick={() => setAddCard(true)}>
            <div className="text">{`Phát hành ${selectedRowKeys.length} thẻ`}</div>
          </div>

          <div className="btn-input" onClick={glistByExcelChoose}>
            <div className="icon-svg">
              <DownloadOutlined />
            </div>
            <div className="text">Xuất DS </div>
          </div>
        </div>

        <div className="t-table-block t-table-card-issuance">
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={dataIssuedCards}
            onChange={onChangeTB}
            pagination={false}
            showSorterTooltip={false}
          />
          <ConfigProvider locale={viVN}>
            <div className="pagination-block">
              {/* <Pagination
                total={pagination.total}
                current={pagination.current}
                pageSize={pagination.pageSize}
                showSizeChanger
                showTotal={() => `Tổng cộng ${Math.ceil(pagination.total / pagination.pageSize) === 0 ? 1 : Math.ceil(pagination.total / pagination.pageSize)} trang`}
                locale={{ items_per_page: 'trang' }}
                onChange={(current, pageSize) => handleChangePagination(current, pageSize, typeCard, rankId, search)}
              /> */}
              <Pagination
                total={pagination.total}
                current={pagination.current}
                pageSize={pagination.pageSize}
                showSizeChanger
                // showQuickJumper
                showTotal={() => `Tổng cộng ${Math.ceil(pagination.total / pagination.pageSize) === 0 ? 1 : Math.ceil(pagination.total / pagination.pageSize)} trang`}
                // showTotal={() => `Tổng cộng ${total} trang`}

                // showTotal={(total, range) => <span className='text-[#8E8E8E]'>{`${range[0]}-${range[1]} trong ${total} dữ liệu`}</span>}
                locale={{ items_per_page: 'trang' }}
                onChange={handleChangePagination}
              />
            </div>
          </ConfigProvider>
        </div>

        <div className={`show-access-filter ${accessSelectFilter ? "active" : ""}`}>
          <div className="title-access">
            <span>Lưu bộ lọc</span>
            <div className="icon-close">
              <CloseOutlined />
            </div>
          </div>
          <div className="input-add-name">
            <Input placeholder="Nhập tên bộ lọc" />
          </div>
          <div className="btn-footer-access">
            <div className="btn btn-primary" onClick={() => setActiveHiddenFilter()}>
              Lưu
            </div>
          </div>
        </div>

        {/* form add group customers 1 */}
        {
          isAddCard
            ? <div
              className="group-customers-block"
            >
              <div className="title-access">
                <span>Phát hành thẻ</span>
                <div className="icon-close" onClick={() => setAddCard(false)}>
                  <CloseOutlined />
                </div>
              </div>
              <div className="modal-content">
                <div className="title-content">Xác nhận phát hành 15 thẻ thành viên</div>
                <Select
                  className='t-select-ctrl'
                  defaultValue="Hạng thẻ"
                  // onChange={handleChange}
                  popupClassName='t-ctrl-popup-select'
                  options={[
                    { value: 1, label: 'Thành viên Bạc' },
                    { value: 2, label: 'Thành viên Vàng' },
                    { value: 3, label: 'Thành viên kim cương' },
                  ]}
                />
              </div>
              <div className="btn-footer-access">
                <div className="btn btn-primary" onClick={() => setAddCardSuccess()}>
                  Tạo
                </div>
              </div>
            </div>
            : null
        }

        {
          isUserCard && <ShowDetailUserCard
            className="show"
            dataInfoCardCustomer={dataInfoCardCustomer}
            onClose={() => { setUserCard(false) }}
          />
        }

        {
          isAddCardUser && <ShowAddCard
            className="show"
            onClose={() => { setAddCardUser(false) }}
            dataRank={dataRank}
            getListIssuedCards={getListIssuedCards}
          />
        }

      </Layout>
    </div>
  );
}

export default Home;
