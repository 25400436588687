import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const data = [
  {
    name: 'Hồ Chí Minh',
    gia: 41,
    trai_nghiem: 20,
    phong_cach: 60,
    thai_do: 0,
    ban_giao: 0,
    ho_so: 0,
    kinh_doanh: 0,
    ban_giao_xe: 0
  },
  {
    name: 'Vũng Tàu',
    gia: 32,
    trai_nghiem: 18,
    phong_cach: 28,
    thai_do: 0,
    ban_giao: 0,
    ho_so: 0,
    kinh_doanh: 0,
    ban_giao_xe: 0
  },
  {
    name: 'Hà Nội',
    gia: 44,
    trai_nghiem: 26,
    phong_cach: 20,
    thai_do: 0,
    ban_giao: 0,
    ho_so: 0,
    kinh_doanh: 0,
    ban_giao_xe: 0
  },
  {
    name: 'Đà Nẵng',
    gia: 86,
    trai_nghiem: 36,
    phong_cach: 16,
    thai_do: 0,
    ban_giao: 0,
    ho_so: 0,
    kinh_doanh: 0,
    ban_giao_xe: 0
  },
  {
    name: 'Thái Nguyên',
    gia: 57,
    trai_nghiem: 23,
    phong_cach: 14,
    thai_do: 0,
    ban_giao: 0,
    ho_so: 0,
    kinh_doanh: 0,
    ban_giao_xe: 0
  },
  {
    name: 'Lai Châu',
    gia: 40,
    trai_nghiem: 18,
    phong_cach: 6,
    thai_do: 0,
    ban_giao: 0,
    ho_so: 0,
    kinh_doanh: 0,
    ban_giao_xe: 0
  },
  {
    name: 'Sơn La',
    gia: 42,
    trai_nghiem: 19,
    phong_cach: 15,
    thai_do: 0,
    ban_giao: 0,
    ho_so: 0,
    kinh_doanh: 0,
    ban_giao_xe: 0
  },
  {
    name: 'Điện Biên',
    gia: 30,
    trai_nghiem: 20,
    phong_cach: 11,
    thai_do: 0,
    ban_giao: 0,
    ho_so: 0,
    kinh_doanh: 0,
    ban_giao_xe: 0
  },
];

const ARCCComplaintStatisticsChartByProvince: React.FC = () => {
  return (
    <div className="arc-card">
      <div className="arc-card__head">
        <span className="title">Biểu đồ thống kê khiếu nại theo Tỉnh/Thành phố</span>
        <div className="control">
          <Button className="rs-button">
            <DownloadOutlined />
            <span>Xuất báo cáo</span>
          </Button>
        </div>
      </div>

      <div className="arc-card__content">
        <ResponsiveContainer aspect={1144 / 511}>
          <BarChart
            width={1144}
            height={511}
            data={data}
            barSize={78}
            className="rs-recharts-legend rs-recharts-tooltip"
          >
            <CartesianGrid
              strokeDasharray="4 4"
              vertical={false}
            />

            <XAxis dataKey="name" />

            <YAxis />

            <Tooltip cursor={{ fill: 'transparent' }} />

            <Legend
              iconType="circle"
            />

            <Bar stackId="a" dataKey="gia" name="Giá" fill="#1890FF" />
            <Bar stackId="a" dataKey="trai_nghiem" name="Trải nghiệm" fill="#13C2C2" />
            <Bar stackId="a" dataKey="phong_cach" name="Phong cách" fill="#2FC25B" />
            <Bar stackId="a" dataKey="thai_do" name="Thái độ" fill="#FACC14" />
            <Bar stackId="a" dataKey="ban_giao" name="Bàn giao" fill="#F04864" />
            <Bar stackId="a" dataKey="ho_so" name="Hồ sơ" fill="#8543E0" />
            <Bar stackId="a" dataKey="kinh_doanh" name="Kinh doanh" fill="#3436C7" />
            <Bar stackId="a" dataKey="ban_giao_xe" name="Bàn giao xe" fill="#223273" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ARCCComplaintStatisticsChartByProvince;
