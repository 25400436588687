import { PlusOutlined } from "@ant-design/icons";
import { Modal, Button, Input, Form } from "antd";
import TagNumber from "../TagNumber";
import { useForm } from "antd/es/form/Form";
import { FormInstance, FormProps } from "antd/lib";
import { useEffect, useRef, useState } from "react";
import '../NotificationFeesSetting.scss';
import { INetwork, editNetwork } from "../../../api/network/networkApi";
import { URL_NETWORK } from "../../../env";
import { displayPartsToString } from "typescript";

interface Props {
  isOpen: boolean
  infoNetwork?: INetwork
  onSubmitFormModal?: (iSsuccess: boolean, errorMessage?: string) => void;
  closeModal: () => void
}

const ModalUpdateNetwork: React.FC<Props> = ({
  isOpen, infoNetwork,
  onSubmitFormModal, closeModal
}) => {
  const [form] = useForm();
  const formRef = useRef<FormInstance<any>>(null);

  const [uploadedFile, setUploadedFile] = useState<File>()
  const [listTag, setListTag] = useState<string[]>([])

  const handleSetFileFromUrl = (url: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const nameFile = url.split('/').pop() ?? 'image.png';
        const file = new File([blob], nameFile, { type: blob.type });
        setUploadedFile(file);
      });
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      setImageView(URL.createObjectURL(file));
      setUploadedFile(file);
    }
  };

  const handleUploadClick = (event: React.MouseEvent) => {
    event.preventDefault();
    const inputElement = document.querySelector('input[type="file"]') as HTMLInputElement | null;
    if (inputElement) {
      inputElement.click();
    }
  };

  const submitForm = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };


  const onFinish: FormProps["onFinish"] = async (values) => {
    const {
      ten_nhaMang, dauSo, so_luong, chiPhi,
    } = values;

    const result = await editNetwork(
      Number(infoNetwork?.id), uploadedFile, ten_nhaMang, so_luong, chiPhi, dauSo
    )
    if (result?.status) {
      onSubmitFormModal?.(true, result.message)
    } else {
      onSubmitFormModal?.(false, result.message)
    }
  };

  const [imageView, setImageView] = useState(null as any)

  useEffect(() => {
    if (isOpen) {
      setImageView(URL_NETWORK + infoNetwork?.image)
      handleSetFileFromUrl(URL_NETWORK + infoNetwork?.image)
      form.setFieldValue('ten_nhaMang', infoNetwork?.title)
      const listTangTmp = infoNetwork?.numberPrefixs?.split(", ") ?? []

      form.setFieldsValue({ dauSo: listTangTmp });
      setListTag(listTangTmp)
      form.setFieldValue('so_luong', infoNetwork?.numberharacters)
      form.setFieldValue('chiPhi', infoNetwork?.expense)
    } else {

      setUploadedFile(undefined)
      form.setFieldValue('img1', undefined)
      form.setFieldValue('img2', undefined)
      form.setFieldValue('ten_nhaMang', undefined)

      form.setFieldsValue({ dauSo: undefined });
      setListTag([])
      form.setFieldValue('so_luong', undefined)
      form.setFieldValue('chiPhi', undefined)
    }

  }, [isOpen])

  return (
    <Form
      form={form}
      ref={formRef}
      name="basic"
      autoComplete="off"
      className="rs-form voucher-update-form"
      onFinish={onFinish}
      initialValues={{ remember: true }}
    >
      <Modal
        title="Chỉnh sửa nhà mạng"
        open={isOpen}
        centered
        footer={
          [
            <span
              key="back"
              className="neutral-gray-400 cursor-pointer"
            >Làm mới thông tin</span>,

            <Button
              key="submit"
              type="primary"
              // loading={confirmLoading}
              onClick={submitForm}
              className="rs-button"
            >
              Lưu
            </Button>,
          ]}
        className="rs-modal w-400"
        onCancel={closeModal}
      >
        <div className="d-flex flex-column gap-18-px">
          <div className="border-F2F4F7 rounded-2-px w-104-px h-104-px bg-F9FAFB d-flex align-center justify-center relative">
            <div>
              <div
                className="m-none-form-item text-explan "
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e)}
                  style={{ display: 'none' }}
                />
              </div>
              <img src={imageView} alt="Uploaded" className="w-104-px h-104-px rounded-3 cursor-pointer" onClick={handleUploadClick} />
            </div>
          </div>
          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Tên nhà mạng</p>
            <Form.Item
              name="ten_nhaMang"
              rules={[{ required: true, message: 'Vui lòng nhập tên nhà mạng!' }]}
            >
              <Input
                placeholder="Tên nhà mạng"
                className="rs-input rs-input--32"
              />
            </Form.Item>
          </div>

          <Form.Item
            name="dauSo"
            rules={[{ required: true, message: 'Vui lòng chọn đầu số' }]}
            className="m-none-form-item text-explan"
          >
            <TagNumber
              listTag={listTag}
              onChange={(e) => setListTag(e)}
            />
          </Form.Item>
          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Số lượng ký tự</p>
            <Form.Item
              name="so_luong"
              rules={[
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject(new Error('Vui lòng nhập số lượng ký tự!'));
                    }
                    if (!Number.isInteger(+value) || value <= 0) {
                      return Promise.reject(new Error('Số lượng ký tự phải là số nguyên dương!'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                placeholder="Số lượng ký tự tính là 1 tin"
                className="rs-input rs-input--32"
              />
            </Form.Item>
          </div>

          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Chi phí </p>
            <Form.Item
              name="chiPhi"
              rules={[
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject(new Error('Vui lòng nhập chi phí!'));
                    }
                    if (!Number.isInteger(+value) || value <= 0) {
                      return Promise.reject(new Error('Chi phí phải là số nguyên dương!'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                placeholder="Chi phí / 1 tin (đ)"
                className="rs-input rs-input--32"
              />
            </Form.Item>
          </div>

        </div>


      </Modal >
    </Form>
  )
}
export default ModalUpdateNetwork
