import React, { useRef } from "react";
import './RightPopup.scss';

interface Props {
  title?: string,
  className?: string,
  contentClassName?: string,
  children?: React.ReactNode;
  onClose?: () => void;
}

const RightPopup: React.FC<Props> = (props) => {
  const { className, title, contentClassName, children, onClose } = props;
  const popupRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const handleOutsideClick = (e: MouseEvent) => {
  //     if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
  //       if (onClose) {
  //         onClose();
  //       }
  //     }
  //   };

  //   document.addEventListener("mousedown", handleOutsideClick);
  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, [onClose]);

  const closePopup = () => {
    if (onClose) {
      onClose();
    }
  }

  return (
    <>
      <div ref={popupRef} className={`right-popup ${className}`}>
        <div className="right-popup__title">{ title }</div>
        <div className={`right-popup__content ${contentClassName}`}>
          { children }
        </div>
      </div>
      <div
        className="right-popup-backdrop"
        onClick={ closePopup }
      />
    </>
  )
}

export default RightPopup