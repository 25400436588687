import { ArrowLeftOutlined, CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Breadcrumb, Checkbox, ConfigProvider, Input, Pagination, Select, Table, TableColumnsType, TableProps } from "antd";
import viVN from 'antd/locale/vi_VN';
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../../Layout";
import * as DecentralizationApi from '../../../../api/configuration/decentralizationApi';
import * as SelectOptionApi from '../../../../api/selectOptions/selectOptionsApi';
import useToast from "../../../../hook/useToast";
import { fommatSelectOption } from "../../../../until";
import * as groupPermissionApi from "../../../../api/groupPermission/groupPermissionApi";
import useThrottle from "../../../../hook/useThrottle";


export interface DataTypeDecentralization {
  parentId?: number
  key: number
  stt: number
  name: string
  access: boolean
  add: boolean
  edit: boolean
  delete: boolean
  upload: boolean
  download: boolean
  share: boolean
  children?: DataTypeDecentralizationChildren[];
}
export interface DataTypeDecentralizationChildren {
  parentId: number
  key: number
  stt: number
  name: string
  access: boolean
  add: boolean
  edit: boolean
  delete: boolean
  upload: boolean
  download: boolean
  share: boolean
}

export interface FlattenedData {
  id: number;
  access: boolean;
  add: boolean;
  edit: boolean;
  delete: boolean;
  upload: boolean;
  download: boolean;
  share: boolean;
}

const CreateAccountRole: React.FC = () => {
  const columns: TableColumnsType<DataTypeDecentralization> = [
    {
      title: '',
      key: 'key',
      render: (_, value: DataTypeDecentralization | DataTypeDecentralizationChildren) =>
        <Checkbox
          checked={value.add && value.edit && value.delete && value.share && value.access && value.upload && value.download ? true : false}
          onChange={(e) => handleChangeTypeAll(e.target.checked, value.key, value.parentId)}
        />,
      width: '5%'
    },
    {
      title: 'STT',
      key: 'id',
      dataIndex: 'stt',
      className: "w-71-px",
      align: 'center'
    },
    {
      title: 'Quyền',
      key: 'name',
      dataIndex: 'name',
      className: 'w-386-px ',
    },
    {
      title: 'Truy cập',
      dataIndex: 'access',
      render: (_, value: DataTypeDecentralization | DataTypeDecentralizationChildren) =>
        <Checkbox
          className="size-check-box size-check-box-inner p-checkbox icon-check-box"
          checked={value.access}
          onChange={(e) => handleChangeType(e.target.checked, value.key, "access", value.parentId)}
        />,

      className: 'w-100-px',
      align: 'center',
    },
    {
      title: 'Thêm mới',
      dataIndex: 'add',
      render: (_, value: DataTypeDecentralization | DataTypeDecentralizationChildren) =>
        <Checkbox
          className="size-check-box size-check-box-inner p-checkbox icon-check-box"
          checked={value.add}
          onChange={(e) => handleChangeType(e.target.checked, value.key, "add", value.parentId)}
        />,

      className: 'w-100-px',
      align: 'center',
    },
    {
      title: 'Sửa',
      dataIndex: 'edit',
      render: (_, value: DataTypeDecentralization | DataTypeDecentralizationChildren) =>
        <Checkbox
          className="size-check-box size-check-box-inner p-checkbox icon-check-box"
          checked={value.edit}
          onChange={(e) => handleChangeType(e.target.checked, value.key, "edit", value.parentId)}
        />,

      className: 'w-100-px',
      align: 'center',
    },
    {
      title: 'Xoá',
      dataIndex: 'delete',
      render: (_, value: DataTypeDecentralization | DataTypeDecentralizationChildren) =>
        <Checkbox
          className="size-check-box size-check-box-inner p-checkbox icon-check-box"
          checked={value.delete}
          onChange={(e) => handleChangeType(e.target.checked, value.key, "delete", value.parentId)}
        />,

      className: 'w-100-px',
      align: 'center',
    },
    {
      title: 'Tải lên',
      dataIndex: 'upload',
      render: (_, value: DataTypeDecentralization | DataTypeDecentralizationChildren) =>
        <Checkbox
          className="size-check-box size-check-box-inner p-checkbox icon-check-box"
          checked={value.upload}
          onChange={(e) => handleChangeType(e.target.checked, value.key, "upload", value.parentId)}
        />,

      className: 'w-100-px',
      align: 'center',
    },
    {
      title: 'Tải về',
      dataIndex: 'download',
      render: (_, value: DataTypeDecentralization | DataTypeDecentralizationChildren) =>
        <Checkbox
          className="size-check-box size-check-box-inner p-checkbox icon-check-box"
          checked={value.download}
          onChange={(e) => handleChangeType(e.target.checked, value.key, "download", value.parentId)}
        />,

      className: 'w-100-px',
      align: 'center',
    },
    {
      title: 'Chia sẻ',
      dataIndex: 'share',
      render: (_, value: DataTypeDecentralization | DataTypeDecentralizationChildren) =>
        <Checkbox
          className="size-check-box size-check-box-inner p-checkbox icon-check-box"
          checked={value.share}
          onChange={(e) => handleChangeType(e.target.checked, value.key, "share", value.parentId)}
        />,

      className: 'w-100-px',
      align: 'center',
    },
  ];



  const staffColumn: TableColumnsType<DecentralizationApi.DataTypeAdmin> = [
    {
      title: 'Họ và tên',
      key: 'ho_ten',
      dataIndex: 'ho_ten',
    },
    {
      title: 'Tên tài khoản',
      key: 'ten_tai_khoan',
      dataIndex: 'ten_tai_khoan',
    },
    {
      title: 'Phòng ban',
      key: 'phong_ban',
      dataIndex: 'phong_ban',
    },
    {
      title: 'Khối nghiệp vụ',
      key: 'khoi_nghiep_vu',
      dataIndex: 'khoi_nghiep_vu',
    },
    {
      title: 'Đơn vị',
      key: 'don_vi',
      dataIndex: 'don_vi',
    },
    {
      title: 'Vùng miền',
      key: 'vung_mien',
      dataIndex: 'vung_mien',
    },
    {
      title: 'Toàn quốc',
      key: 'toan_quoc',
      dataIndex: 'toan_quoc',
    },
    {
      title: 'Nhóm quyền',
      key: 'nhom_quyen',
      dataIndex: 'nhom_quyen',
    },
  ]

  const navigator = useNavigate()

  useEffect(() => {
    gSLUnit()
    gSLUnitOption()
    gListMenu()

    return (() => {
      setSelectedStaff([])
      setTitle("")
      setDescription("")

      setUnitId(undefined)
      setBusinessUnitId(undefined)
      setSearch("")
      setSearchBackup("")

      setPagination({ current: 1, pageSize: 10, total: 0 })
    })
  }, [])

  // CHECKBOX ON TABLE ROLES
  const [selectedRole, setSelectedRole] = useState<React.Key[]>([])



  // CHECKBOX ON TABLE ROLES
  const [selectedStaff, setSelectedStaff] = useState<React.Key[]>([])

  const onSelectedStaffChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('staff changed: ', newSelectedRowKeys);
    setSelectedStaff(newSelectedRowKeys);
  };
  const staffSelection = {
    selectedStaff,
    onChange: onSelectedStaffChange,
  }


  const pushToast = useToast();
  const [sLUnitOption, setSLUnitOption] = useState<SelectOptionApi.IUnitOption[]>([])
  const [sLUnit, setSLUnit] = useState<SelectOptionApi.IBusinessUnit[]>([])


  const [unitId, setUnitId] = useState<number | undefined>(undefined)
  const [businessUnitId, setBusinessUnitId] = useState<number | undefined>(undefined)
  const [search, setSearch] = useState<string>('')
  const [searchBackup, setSearchBackup] = useState<string>('')

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });

  const [dataAdmin, setDataAdmin] = useState<DecentralizationApi.DataTypeAdmin[]>([])
  const [dataMenu, setDataMenu] = useState<DataTypeDecentralization[]>([])

  const gSLUnit = async () => {
    const result = await SelectOptionApi.getListBusinessUnit();
    if (result?.status) {
      setSLUnit(result.data);
    } else {
      pushToast(result.message, "error", 2000)

    }
  }

  const gSLUnitOption = async () => {
    const result = await SelectOptionApi.getListUnitOption();
    if (result?.status) {
      setSLUnitOption(result.data);
    } else {
      pushToast(result.message, "error", 2000)

    }
  }

  const gListMenu = async () => {
    const result = await DecentralizationApi.getListmenu()
    if (result?.status) {
      setDataMenu(convertDataMenu(result.data))
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const convertDataMenu = (data: DecentralizationApi.IDecentralization[]) => {
    var stt = 1;
    const convertData = data.map((item) => ({
      key: item.id,
      stt: stt++,
      name: item.name,
      access: item.access,
      add: item.add,
      edit: item.edit,
      delete: item.delete,
      upload: item.upload,
      download: item.download,
      share: item.share,
      children: item.children?.map((child) => ({
        parentId: item.id,
        key: child.id,
        stt: stt++,
        name: child.name,
        access: child.access,
        add: child.add,
        edit: child.edit,
        delete: child.delete,
        upload: child.upload,
        download: child.download,
        share: child.share,
      }))
    }))
    return convertData
  }

  const gListUserAccount = async (limit: number = 10, pageNumber: number = 1) => {
    const result = await groupPermissionApi.getListUserAccount(limit, pageNumber, unitId, businessUnitId, search);
    if (result.status) {
      setDataAdmin(convertDataUserAccount(result.data, pageNumber, limit))
      setPagination({ current: pageNumber, total: result.total, pageSize: limit })
    } else {
      pushToast(result.message, "error", 2000)
    }
  }

  const convertDataUserAccount = (data: groupPermissionApi.IAccount[], pageNumber: number, limit: number) => {
    const convertData = data.map((item) => ({
      key: item.id,
      id: item.id,
      ho_ten: item.fullname,
      ten_tai_khoan: item.fullname,
      phong_ban: item.department,
      khoi_nghiep_vu: item.businessUnit,
      don_vi: item.unit,
      vung_mien: item.region,
      toan_quoc: item.region,
      nhom_quyen: item.groupPermission,
    }
    ))
    return convertData
  }

  const handleChangePagination = (current: number, pageSize: number, businessUnitId?: number, unitId?: number, search?: string) => {
    gListUserAccount(pageSize, current)
  }



  const handleChangeTypeAll = (value: boolean, id: number, parentId?: number) => {
    const updateBooleans = (item: DataTypeDecentralization | DataTypeDecentralizationChildren) => {
      item.access = value;
      item.add = value;
      item.edit = value;
      item.delete = value;
      item.upload = value;
      item.download = value;
      item.share = value;
    };

    const updatedDataMenu = dataMenu.map(item => {
      if (item.key === id) {
        // Trường hợp không có childrenId, cập nhật cả cha và con
        if (!parentId) {
          updateBooleans(item);
          if (item.children) {
            item.children = item.children.map(child => {
              updateBooleans(child);
              return child;
            });
          }
        }

      }
      if (item.key !== id) {
        // Trường hợp có childrenId, chỉ cập nhật con
        if (item.children && item.key === parentId) {
          item.children = item.children.map(child => {
            if (child.key === id) {
              updateBooleans(child);
            }
            return child;
          });
        }
      }
      return item;
    });
    setDataMenu(updatedDataMenu);
  };

  const handleChangeType = (value: boolean, id: number, type: "access" | "add" | "edit" | "delete" | "upload" | "download" | "share", parentId?: number) => {
    const updateBooleanField = (item: DataTypeDecentralization | DataTypeDecentralizationChildren) => {
      item[type] = value;
    };

    const updatedDataMenu = dataMenu.map(item => {
      if (item.key === id && !parentId) {
        // Trường hợp không có parentId, cập nhật cả cha và con
        updateBooleanField(item);
        if (item.children) {
          item.children = item.children.map(child => {
            updateBooleanField(child);
            return child;
          });
        }
      } else
        if (item.children && item.key === parentId) {
          // Trường hợp có parentId, chỉ cập nhật con
          item.children = item.children.map(child => {
            if (child.key === id) {
              updateBooleanField(child);
            }
            return child;
          });
        }
      return item;
    });

    setDataMenu(updatedDataMenu);
  };

  const flattenDataMenu = (data: DataTypeDecentralization[]): FlattenedData[] => {
    const result: FlattenedData[] = [];
    const flatten = (items: DataTypeDecentralization[]) => {
      items.forEach(item => {
        const { key, access, add, edit, delete: del, upload, download, share, children } = item;
        result.push({ id: key, access, add, edit, delete: del, upload, download, share });
        if (children) {
          flatten(children);
        }
      });
    };

    flatten(data);
    return result;
  };

  const onFinish = useThrottle(() => {
    if (!title.trim().length) {
      pushToast("Vui lòng nhập tên nhóm!", "warning")
      return
    }
    if (!description.trim().length) {
      pushToast("Vui lòng nhập mô tả!", "warning")
      return
    }
    if (!selectedStaff.length) {
      pushToast("Vui lòng chọn khách hàng!", "warning")
      return
    }

    const permissionTmp = flattenDataMenu(dataMenu)
    const selectedStaffTmp = selectedStaff.map((item) => Number(item))
    aGroupPermission(title.trim(), description.trim(), selectedStaffTmp, permissionTmp)

  }, 2000)

  const aGroupPermission = async (title: string, description: string, admin: number[], permission: FlattenedData[]) => {
    const result = await groupPermissionApi.addGroupPermission(title, description, admin, permission);
    if (result?.status) {
      pushToast(result.message, "success", 2000)
      navigator(-1)
    } else {
      pushToast(result.message, "error", 1000)
    }
  };



  useEffect(() => {
    gListUserAccount()

  }, [unitId, search, businessUnitId])

  return (
    <Layout>
      <div className="app-breadcrumb-block">
        <Breadcrumb
          items={[
            { title: <Link to="/">THACO AUTO CRM</Link>, },
            { title: <Link to="/system-configuration/account-role">Cấu hình</Link>, },
            { title: <Link to="/system-configuration/account-role">Phân quyền</Link>, },
            { title: 'Thêm nhóm', },
          ]}
        />
        <div className="d-flex justify-between">
          <div className="app-breadcrumb-title">
            <Link to='/system-configuration/account-role'><ArrowLeftOutlined /></Link>
            <div className="title">Thêm nhóm </div>
          </div>
          <button
            className="h-8 px-4 border-none text-white bg-096DD9 rounded-6-px text-sm font-regular"
            onClick={onFinish}
          >
            Lưu thông tin
          </button>
        </div>

      </div>
      <div className=" w-full pb-18-px">
        <div className="d-flex gap-3 rounded-1   py-2 ">
          <div className='d-flex align-center gap-10-px w-half'>
            <p className='text-base fw-500 font-medium white-space-nowrap'>Tên nhóm</p>
            <Input
              placeholder="Ví dụ: Nhóm cấp cao"
              className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
              maxLength={50}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className='d-flex align-center gap-10-px w-half'>
            <p className='text-base fw-500 font-medium white-space-nowrap'>Mô tả ngắn</p>
            <Input
              placeholder="Ví dụ: Nhóm cấp cao"
              className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
              maxLength={50}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="pb-18-px">
        <div className="t-table-block ">
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
            columns={columns}
            dataSource={dataMenu}
            pagination={false}
            showSorterTooltip={false}
            // rowSelection={{
            //   type: 'checkbox',
            //   ...handleRowSelection, checkStrictly
            // }}
            className=" border-end-table  "
            expandable={{
              expandIcon: ({ expanded, onExpand, record }) =>
                record.children?.length ? (
                  expanded ? (
                    <CaretDownOutlined className={` pr-1 text-8E8E8E`} onClick={e => onExpand(record, e)} />
                  ) : (
                    <CaretRightOutlined className={` pr-1 text-8E8E8E`} onClick={e => onExpand(record, e)} />
                  )
                ) : (
                  <CaretDownOutlined className={` `} style={{ opacity: 0 }} />
                ),
              defaultExpandAllRows: true
            }}
          />
        </div>
      </div>
      <div className="d-flex flex-column gap-18-px">
        <div className="d-flex gap-10-px">
          <div className='w-166-px'>
            <Select
              placeholder="Lọc theo đơn vị"
              options={fommatSelectOption(sLUnitOption)}
              className="custome-select-t "
              popupClassName='popup-select'
              onChange={(e) => setUnitId(e)}
            />
          </div>
          <div className='w-166-px'>
            <Select
              placeholder="Khối/Nghiệp vụ"
              options={fommatSelectOption(sLUnit)}
              className="custome-select-t "
              popupClassName='popup-select'
              onChange={(e) => setBusinessUnitId(e)}
            />
          </div>
          <div className="w-730-px">
            <Input
              placeholder="Tìm tài khoản để phân quyền"
              className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
              onChange={(e) => setSearchBackup(e.target.value)}
            />
          </div>
          <button className="border-096DD9 text-096DD9 bg-white w-100-px text-center rounded-2 text-base fw-600 font-regular "
            onClick={() => setSearch(searchBackup)}>
            Tìm kiếm
          </button>
        </div>
        <div className="t-table-block ">
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
            columns={staffColumn}
            dataSource={dataAdmin}
            pagination={false}
            showSorterTooltip={false}
            rowSelection={staffSelection}
            className="border-end-table w-fit-table zise-filter"
            scroll={{ x: 'auto' }}
          />
          <ConfigProvider locale={viVN}>
            <div className="pagination-block">
              <Pagination
                total={pagination.total}
                current={pagination.current}
                pageSize={pagination.pageSize}
                showSizeChanger
                showTotal={() => `Tổng cộng ${Math.ceil(pagination.total / pagination.pageSize) === 0 ? 1 : Math.ceil(pagination.total / pagination.pageSize)} trang`}
                locale={{ items_per_page: 'trang' }}
                onChange={(current, pageSize) => handleChangePagination(current, pageSize, businessUnitId, unitId, search)}
              />
            </div>
          </ConfigProvider>
        </div>
      </div>
    </Layout>
  )
}
export default CreateAccountRole
