import { Tag } from "antd";
import React from "react";
import './WarrantyHistoryItem.scss'
import { CopyOutlined } from "@ant-design/icons";

interface Props {
  type?: string;
  title?: string;
  info?: {
    type?: string,
    bks?: string,
    vin?: string,
    carId?: string,
    id?: string|number,
    maintenanceDay?: string,
    finishDay?: string,
  };
}

const WarrantyHistoryItem: React.FC<Props> = (props) => {
  const { type, title, info } = props

  return (
    <div className="warranty-item">
      <div className="warranty-item__head">
        <Tag className={`rs-tag ${ type === 'Bảo hành' ? 'sunset-orange' : 'dust-red' }`}>{ type }</Tag>
        <span className="title">{ title }</span>
      </div>
      <div className="item">
        <span>Loại xe</span>
        <div className="right">
          <span className="neutral-gray-400">{ info?.type }</span>
        </div>
      </div>
      <div className="item">
        <span>BKS</span>
        <div className="right">
          <span className="neutral-gray-400">{ info?.bks }</span>
        </div>
      </div>
      <div className="item">
        <span>VIN</span>
        <div className="right">
          <span className="neutral-gray-400">{ info?.vin }</span>
        </div>
      </div>
      <div className="item">
        <span>Mã xe</span>
        <div className="right">
          <span className="neutral-gray-400">{ info?.carId }</span>
        </div>
      </div>
      <div className="item">
        <span>Mã bảo dưỡng</span>
        <div className="right">
          <span className="neutral-gray-400">{ info?.id }</span>
          <CopyOutlined className="primary-6" />
        </div>
      </div>
      <div className="item">
        <span>Ngày bảo dưỡng</span>
        <div className="right">
          <span className="neutral-gray-400">{ info?.maintenanceDay }</span>
        </div>
      </div>
      <div className="item">
        <span>Ngày hoàn thành</span>
        <div className="right">
          <span className="neutral-gray-400">{ info?.finishDay }</span>
        </div>
      </div>
    </div>
  )
}

export default WarrantyHistoryItem