import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, ConfigProvider, Input, Pagination, Switch, Table, TableColumnsType } from 'antd';
import viVN from 'antd/locale/vi_VN';
import React, { useEffect, useState } from 'react';
import Layout from '../../../Layout';
import * as SelectOptionApi from '../../../api/selectOptions/selectOptionsApi';
import * as DepartmentalUnitApi from '../../../api/unit/departmentalUnitApi';
import * as UnitApi from '../../../api/unit/unitApi';
import ModalDeleteManager from '../../../components/ModalDeleteManager';
import useToast from '../../../hook/useToast';
import { findParentIdLevelOne, flattenDonViArray } from '../../../until';
import ModalCreateDepartment from './Modal/ModalCreateDepartment';
import ModalEditDepartment from './Modal/ModalEditDepartment';
import ModalManagerMajor from './Modal/ModalManangeMajor';

const Departments: React.FC = () => {

  const [openCreateDepartment, setOpenCreateDepartment] = useState(false)
  const [openEditDepartment, setOpenEditDepartment] = useState(false)
  const [openManageMajor, setOpenManageMajor] = useState(false)
  const [openDeleteDepartment, setOpenDeleteDepartment] = useState(false)
  const [dataDepartmentalUnit, setDataDepartmentalUnit] = useState<DepartmentalUnitApi.DataTypeDepartmentalUnit[]>([])
  const [dataConvert, setDataConvert] = useState<SelectOptionApi.dataConvert[]>([])
  const [dataBusinessUnit, setDataBusinessUnit] = useState<SelectOptionApi.ISelectOption[]>([])
  const [dataListBusinessUnit, setDataListBusinessUnit] = useState<SelectOptionApi.IBusinessUnit[]>([])
  const [dataListNationwide, setDataListNationwide] = useState<any>()
  const [dataListNationwideCreate, setDataListNationwideCreate] = useState<any>()
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const pushToast = useToast();
  const [id, setId] = useState<number>()
  const [search, setSearch] = useState<string>()

  const columns: TableColumnsType<DepartmentalUnitApi.DataTypeDepartmentalUnit> = [
    {
      title: 'Hành động',
      dataIndex: '',
      render: (_, record) => (
        <div className='d-flex align-center justify-center gap-10-px'>
          <DeleteOutlined
            className='text-F5222D text-sm border-EAECF0 p-5-px bg-white rounded-2'
            onClick={() => { setOpenDeleteDepartment(true); setId(Number(record.key)) }}
          />
          <EditOutlined
            className='text-98A2B3 text-sm border-EAECF0 p-5-px bg-white rounded-2'
            onClick={() => { setOpenEditDepartment(true); setId(Number(record.key)) }}
          />
        </div>

      ),
      className: "w-83-px",
      align: 'center'
    },

    {
      title: 'Mã phòng ban',
      dataIndex: 'codeDepartment',
      className: "w-140-px",
      sorter: (a, b) => a.codeDepartment.length - b.codeDepartment.length,
    },
    {
      title: 'Tên phòng ban',
      dataIndex: 'nameDepartment',
      className: "w-168-px",
      sorter: (a, b) => a.nameDepartment.length - b.nameDepartment.length,
      // filters: [
      //   { text: 'Joe', value: 'Joe' },
      //   { text: 'Jim', value: 'Jim' },
      // ],
    },
    {
      title: 'Khối/nghiệp vụ',
      dataIndex: 'businessUnitTitle',
      className: "w-170-px",
      // sorter: (a, b) => a.major.length - b.major.length,
      // filters: [
      //   { text: 'Joe', value: 'Joe' },
      //   { text: 'Jim', value: 'Jim' },
      // ],
    },
    {
      title: 'Toàn quốc',
      className: "w-156-px ",
      sorter: (a, b) => findParentIdLevelOne(dataConvert, a.parentId, 1).localeCompare(findParentIdLevelOne(dataConvert, b.parentId, 1)),
      render: (_, record) => (
        <div>{findParentIdLevelOne(dataConvert, record.parentId, 1)}</div>
      )
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit',
      className: "w-175-px",
      sorter: (a, b) => findParentIdLevelOne(dataConvert, a.parentId, 3).localeCompare(findParentIdLevelOne(dataConvert, b.parentId, 3)),
      render: (_, record) => (
        <div>{findParentIdLevelOne(dataConvert, record.parentId, 3)}</div>
      )
    },
    {
      title: 'Vùng miền',
      dataIndex: 'region',
      className: "w-125-px ",
      sorter: (a, b) => findParentIdLevelOne(dataConvert, a.parentId, 2).localeCompare(findParentIdLevelOne(dataConvert, b.parentId, 2)),
      render: (_, record) => (
        <div>{findParentIdLevelOne(dataConvert, record.parentId, 2)}</div>
      )
    },
    {
      title: 'Trạng thái',
      dataIndex: '',
      className: "w-175-px",
      render: (_, record) => <div className='d-flex align-center gap-10-px'>
        <Switch
          defaultChecked={record.view ? true : false}
          onChange={() => updateActiveDepartmentalUnit(Number(record.key))}
          className="rs-switch rs-switch--sm"
        />
        <p className='font-medium fw-500 text-344054'>{UnitApi.activeArray[record.view]}</p>
      </div>
    },
  ];



  const getListBusinessUnit = async () => {
    const result = await SelectOptionApi.getListBusinessUnit();
    if (result?.status) {
      setDataBusinessUnit(convertDataBusinessUnit(result.data));
      setDataListBusinessUnit(result.data);
    } else {
      pushToast(result.message, "error", 2000)

    }
  }
  const convertDataBusinessUnit = (data: SelectOptionApi.IBusinessUnit[]) => {
    const convertData = data.map((item) => ({
      value: item.id,
      label: item.title
    }))
    return convertData
  }

  const getListDepartmentalUnit = async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await DepartmentalUnitApi.getListDepartmentalUnit(pageSize, pageCurent, search);
    if (result?.status) {
      setDataDepartmentalUnit(convertData(result.data))
      setPagination({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result.message, "error", 2000)

    }
  }
  const convertData = (data: DepartmentalUnitApi.IDepartmentalUnit[]) => {
    const convertData = data.map((item) => ({
      codeDepartment: item.code,
      nameDepartment: item.title,
      businessUnitTitle: item.businessUnitTitle,
      nationwide: '',
      unit: '',
      region: '',
      key: item.id,
      parentId: item.parentId,
      view: item.view,
    }
    ))
    return convertData
  }



  function convertNationwide(data: UnitApi.OptionSelect[], id: number) {
    return data.filter((value) => value.value !== id).map((item) => {
      const treeItem: UnitApi.OptionSelect = {
        value: item.value,
        label: `${item.label}`,
        title: item.title,
        children: []
      };
      if (item.children != null && item.children.length > 0) {
        treeItem.children = convertNationwide(item.children, id);
      }
      return treeItem;
    });
  }

  const gListNationwide = async () => {
    const result = await DepartmentalUnitApi.getListNationwide();
    if (result?.status) {
      setDataConvert(flattenDonViArray(result.data))
      setDataListNationwide(UnitApi.convertNationwideValueToTree(result.data))
      setDataListNationwideCreate(UnitApi.convertNationwideValueToTree(result.data))
    } else {
      pushToast(result.message, "error", 2000)

    }
  }

  // thay đổi trang thái
  const updateActiveDepartmentalUnit = async (id: number) => {
    const result = await DepartmentalUnitApi.updateActiveDepartmentalUnit(id);
    if (result?.status) {
      getListDepartmentalUnit(pagination.current, pagination.pageSize)
      pushToast(result.message, "success", 2000)
    } else {
      pushToast(result.message, "error", 2000)
    }
  }

  const handleDelete = async () => {
    if (id) {
      const result = await DepartmentalUnitApi.deleteDepartments(id);
      if (result?.status) {
        getListDepartmentalUnit(pagination.current, pagination.pageSize)
        setOpenDeleteDepartment(false)
        pushToast("Xóa đơn vị thành công!", "success", 2000)

      } else {
        pushToast(result.message, "error", 2000)
      }
    }
  }
  const handleChangePagination = (current: number, pageSize: number) => {
    getListDepartmentalUnit(current, pageSize)
  }
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
    gListNationwide()
    getListBusinessUnit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getListDepartmentalUnit(1, 10)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  useEffect(() => {
    if (id) {
      setDataListNationwide(convertNationwide(dataListNationwideCreate, id))
    }
  }, [id, openEditDepartment]);
  return (
    <Layout>
      <ModalCreateDepartment
        dataBusinessUnit={dataBusinessUnit}
        dataListNationwide={dataListNationwideCreate}
        isOpen={openCreateDepartment}
        getListDepartmentalUnit={getListDepartmentalUnit}
        closeModal={() => setOpenCreateDepartment(!openCreateDepartment)}
      />
      <ModalEditDepartment
        dataBusinessUnit={dataBusinessUnit}
        dataListNationwide={dataListNationwide}
        id={id}
        getListDepartmentalUnit={getListDepartmentalUnit}
        isOpen={openEditDepartment}
        closeModal={() => setOpenEditDepartment(!openEditDepartment)}
      />
      <ModalManagerMajor
        isOpen={openManageMajor}
        getListBusinessUnit={getListBusinessUnit}
        dataListBusinessUnit={dataListBusinessUnit}
        closeModal={() => setOpenManageMajor(!openManageMajor)}
      />
      <ModalDeleteManager
        isOpen={openDeleteDepartment}
        onConfirm={handleDelete}
        closeModal={() => setOpenDeleteDepartment(!openDeleteDepartment)}
        title="Bạn có chắc chắn xóa phòng ban này"
        titleDelete="Xóa phòng ban"
        content="Phòng ban và dữ liệu liên quan sẽ bị xóa vĩnh viễn
 không thể khôi phục"
      />
      <Breadcrumb
        items={[
          {
            title: 'THACO AUTO CRM',
          },
          {
            title: <a href="/">Danh mục đơn vị</a>,
          },
          {
            title: 'Phòng ban',
          },
        ]}
      />
      <div className='title-page'>
        Phòng ban
      </div>
      <div className='d-flex align-center gap-10-px pb-18-px departments-filter'>
        <p className='text-base font-medium text-344054 white-space-nowrap'>Danh sách phòng ban</p>
        <Input
          placeholder="Tìm kiếm"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="bg-white border-E1E1E1 text-sm rounded-2  font-regular  input-custom"
        />
        <button className='h-38 white-space-nowrap d-flex justify-center align-center border-E1E1E1 text-4B4B4B bg-white rounded-2 text-sm font-regular gap-1'
          onClick={() => setOpenManageMajor(true)}
        >
          <PlusOutlined className='text-18-px text-4B4B4B' />
          Khối/nghiệp vụ
        </button>
        <button
          className='h-38 white-space-nowrap d-flex justify-center align-center border-none text-white bg-096DD9 rounded-2 text-sm font-regular gap-10-px'
          onClick={() => setOpenCreateDepartment(true)}
        >
          <PlusOutlined className='text-sm text-white' />
          Tạo mới phòng ban
        </button>
      </div>
      <div className="t-table-block d-flex flex-column justify-between h-822-px">
        <Table
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
          columns={columns}
          dataSource={dataDepartmentalUnit}
          pagination={false}
          showSorterTooltip={false}
          className=" border-end-table  zise-filter"
        />
        <div className="pagination-block border-top-F2F4F7">
          <ConfigProvider locale={viVN}>
            <div className="pagination-block">
              <Pagination
                total={pagination.total}
                current={pagination.current}
                pageSize={pagination.pageSize}
                showSizeChanger
                // showQuickJumper
                showTotal={() => `Tổng cộng ${Math.ceil(pagination.total / pagination.pageSize) === 0 ? 1 : Math.ceil(pagination.total / pagination.pageSize)} trang`}
                // showTotal={() => `Tổng cộng ${total} trang`}

                // showTotal={(total, range) => <span className='text-[#8E8E8E]'>{`${range[0]}-${range[1]} trong ${total} dữ liệu`}</span>}
                locale={{ items_per_page: 'trang' }}
                onChange={handleChangePagination}
              />
            </div>
          </ConfigProvider>
        </div>
      </div>
    </Layout>
  );
}

export default Departments;
