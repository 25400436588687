import { CloseCircleFilled, CloseOutlined, LockOutlined, UserOutlined } from "@ant-design/icons"
import { Checkbox, Form, Input } from "antd"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import './style.scss'
import useThrottle from "../../hook/useThrottle"
import * as loginApi from "../../api/login/loginApi"
import tokenService from '../../services/token';
import { useDispatch, useSelector } from "react-redux"
import { clearSaveAcccount, selectorDataAcccount, setDataAcccount } from "../../store/slices/userSlice"
import { setInfoUser } from "../../store/slices/infoUserSlice"
import useInfoUser from "../../hook/useInfoUser"

const Login: React.FC = () => {

  useEffect(() => {


    return (() => {
      setIsSaveAccount(false)
      setIsMesseageError(false)
      setMesseageError("")
      setEnableLogin(false)

      // setUsername("")
      setPassword("")
    })
  }, []);

  const navigate = useNavigate()
  const dispatch = useDispatch();

  const { setInfoUserData, setAccountPasswordUser, clearAccountPasswordUser } = useInfoUser()
  const dataAcccount = useSelector(selectorDataAcccount)

  const [isSaveAccount, setIsSaveAccount] = useState(false)
  const [isMesseageError, setIsMesseageError] = useState(false)
  const [messeageError, setMesseageError] = useState("")
  const [enableLogin, setEnableLogin] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
    updateEnableLogin(e.target.value, password);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    updateEnableLogin(username, e.target.value);
  };

  const updateEnableLogin = (user: string, pass: string) => {
    setEnableLogin(user.trim() !== '' && pass.trim() !== '');
  };

  const enterLogin = useThrottle(async () => {
    if (username.trim().length < 4 || username.trim().length > 40) {
      setMesseageError("Tài khoản phải từ 4 đến 40 kí tự")
      return
    }
    if (password.trim().length < 4 || password.trim().length > 20) {
      setMesseageError("Mật khẩu phải từ 4 đến 20 kí tự")
      return
    }

    const result = await loginApi.login(username, password)
    if (result?.status) {
      setDataUser(result.token, result.data)
    } else {
      setMesseageError(result.message)
      setIsMesseageError(true)
    }
  }, 1000)

  const setDataUser = (token: string, data: loginApi.IUser) => {
    setInfoUserData(data)
    if (isSaveAccount) {
      setAccountPasswordUser(username, password)
    } else {
      clearAccountPasswordUser()
    }
    tokenService.setToken(token)
    navigate('/')
  }
  useEffect(() => {
    if (tokenService.getToken()) {
      navigate("/")
    }

    if (dataAcccount.username) {
      setIsSaveAccount(true)
      setEnableLogin(true)
      setUsername(dataAcccount.username)
      setPassword(dataAcccount.password)
    }
  }, [dataAcccount.username, dataAcccount.password, navigate]);
  return (
    <div className=" h-100vh d-flex items-center overflow-hidden">
      <div className="bg-login d-flex align-center">
        <div className=" d-flex flex-column justify-between pl-110-px">
          <div className=" d-flex flex-column ">
            <img src='/images/exportImg/logo_thaco.svg' alt="" className="w-544-px" />
            <div>
              <span className="text-11 fw-600 font-regular text-white line-height-normal">Hệ Thống Quản Lý <br />Quan hệ khách hàng</span>
            </div>

          </div>
          {/* <p className="font-regular fw-400 text-white text-sm absolute b-8">Copyright ©2023 Produced by GoTRUST & Bluebolt SoftWare</p> */}
        </div>
      </div>
      <div className="h-100vh d-flex  align-center pl-58-px">
        <Form className="bg-form-login d-flex  align-center">
          <div className="w-364-px d-flex flex-column gap-6">
            <div className="d-flex flex-column gap-5">
              <span className="text-30-px font-medium fw-500">Đăng nhập</span>
              <div
                className={` ${isMesseageError ? 'd-flex align-center rounded-1 border-FFCCD2 bg-FEF2F2 h-57-px' : 'none'}`}
              >
                <div className="d-flex gap-3 align-baseline" >
                  <CloseCircleFilled className="text-sm text-F64C4C pl-3 overflow-auto" />
                  <p className="text-sm text-1F1F1F font-regular fw-400">{messeageError}</p>
                </div>
                <CloseOutlined className="text-sm text-8E8E8E pr-3 cursor-pointer" onClick={() => setIsMesseageError(false)} />
              </div>
              <div className="d-flex flex-column gap-4">
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: 'Nhập tên đăng nhập hoặc số điện thoại, email!' }]}
                  className="m-none-form-item text-explan"
                >

                  <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center gap-3 px-3 py-2-px h-10  overflow-hidden">
                    <UserOutlined className="text-base text-096DD9" />
                    <Input
                      placeholder="Tên đăng nhập hoặc số điện thoại, email"
                      className="bg-FCFCFD place-holder-input-98A2B3 text-base border-none custom-input-focus none-focus-erro font-regular p-none-input hover-none"
                      maxLength={40}
                      value={username}
                      onChange={handleUsernameChange}
                    />
                  </div>

                  {/* <span className="text-ff4d4f text-base font-regular">{errorsObj.username}</span> */}


                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Nhập mật khẩu của bạn!' }]}
                  className="m-none-form-item text-explan"
                >

                  <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center gap-3 px-3 py-2-px h-10  overflow-hidden">
                    <LockOutlined className="text-base text-096DD9" />
                    <Input.Password
                      placeholder="Mật khẩu của bạn"
                      autoComplete="new-password"
                      className="bg-FCFCFD place-holder-98A2B3 text-base border-none custom-input-focus none-focus-erro font-regular p-none-input-password input-password-none hover-none font-input"
                      maxLength={20}
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </div>
                </Form.Item>
              </div>
            </div>
            <div className="d-flex justify-between align-center">
              <Checkbox
                className="size-check-box size-check-box-inner p-checkbox icon-check-box"
                checked={isSaveAccount}
                onChange={(e) => setIsSaveAccount(!isSaveAccount)}
              >
                <span className="text-sm font-regular fw-400"
                >Lưu tài khoản
                </span>
              </Checkbox>
              <span className="text-base fw-400 font-regular text-1890FF">Lấy lại mật khẩu</span>
            </div>
            <button
              className={`w-full h-38-px  rounded-2 text-base font-regular fw-400 ${!enableLogin ? 'bg-F5F5F5 text-98A2B3 border-EAECF0 shadow-login' : 'shadow-active bg-1890FF text-white border-none'}`} disabled={!enableLogin}
              onClick={enterLogin}
            >
              Đăng nhập
            </button>
          </div>
        </Form>

      </div>
    </div>
  )
}
export default Login
