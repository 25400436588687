import React, { useRef, useState } from 'react'
import { Button, Flex, Form, Input, Modal, Select } from 'antd'
import './CreateOwnedCar.scss'

const { Search } = Input

interface Props {
  show: boolean
  modalClose?: () => void
}

const CreateFavoriteCarModal:React.FC<Props> = (props) => {
  const { show, modalClose } = props
  const formRef: any = useRef(null)

  const carBrands = [
    { value: 'bmw', label: 'BMW', icon: '/images/brands/bmw.svg' },
    { value: 'kia', label: 'KIA', icon: '/images/brands/kia.svg' },
    { value: 'mazda', label: 'MAZDA', icon: '/images/brands/mazda.svg' },
    { value: 'mini', label: 'MINI', icon: '/images/brands/mini.svg' },
  ]

  const [loading, setLoading] = useState(false)
  const onFormFinish = (values: any) => {
    setLoading(true)

    setTimeout(() => {
      setLoading(false)

      if (modalClose) {
        modalClose()
      }
      if (formRef.current) {
        formRef.current.resetFields()
      }
    }, 1500)
  }

  return (
    <Modal
      open={show}
      title='Tạo xe quan tâm'
      className='rs-modal w-572 footer-content-end create-owned-car-modal'
      footer={
        <Button
          type='primary'
          className='rs-button rs-button--sm'
          loading={loading}
          onClick={() => formRef?.current.submit()}
        >Tạo xe</Button>
      }
      onCancel={modalClose}
    >
      <Form
        ref={formRef}
        className='rs-form create-owned-car-form'
        onFinish={onFormFinish}
      >
        <Form.Item
          layout='vertical'
          label='Tên xe'
          name='ten-xe'
        >
          <Flex className='search-with-select'>
            <Select
              placeholder='Thương hiệu'
              popupMatchSelectWidth={false}
              options={carBrands}
              optionRender={(option) => (
                <Flex align='center' className='gap-8px'>
                  <img src={option.data.icon} alt="" className='h-24 w-24' />
                  <span>{ option.data.label }</span>
                </Flex>
              )}
              className='rs-select'
              popupClassName='rs-select-dropdown'
            />
            <Search
              placeholder="Tìm kiếm theo tên xe, dòng xe, loại xe"
              className='rs-search'
            />
          </Flex>
        </Form.Item>

        <Form.Item
          layout='vertical'
          label='Dòng xe'
          name='dong-xe'
        >
          <Select
            placeholder='Dòng xe'
            className='rs-select'
            options={[{ value: '1', label: 'BMW 1 Series' }]}
          />
        </Form.Item>

        <Form.Item
          layout='vertical'
          label='Loại xe'
          name='loai-xe'
        >
          <Select
            placeholder='Loại xe'
            className='rs-select'
            popupClassName='rs-select-dropdown'
            options={[{ value: '1', label: 'Du lịch' }]}
          />
        </Form.Item>

        <Form.Item
          layout='vertical'
          label='Năm sản xuất'
          name='nam-san-xuat'
        >
          <Input
            placeholder='Năm sản xuất'
            className='rs-input rs-input--32'
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateFavoriteCarModal
