import React from "react";
import './WarrantyHistoryVertical.scss'

interface Props {
  children?: React.ReactNode;
}

const WarrantyHistoryVertical: React.FC<Props> = ({ children }) => {
  return (
    <div className="warranty-history-vertical">
      { children }
    </div>
  )
}

export default WarrantyHistoryVertical