import { paserParams } from "../../until";
import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";
import { SortType } from "../carLine/carLineApi";

export interface INetwork {
    id: number;
    image: string;
    title: string;
    numberharacters: number;
    expense: number;
    numberPrefixs: string;
}


//Danh sách network
export const getListNetwork = async (
    limit?: number, pageNumber?: number) => {
    const path = "network/listNetwork"
    const params = {
        limit, pageNumber
    }
    const result: ResponseSuccessTotal<INetwork[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thông tin network
export const getInfoNetwork = async (
    id: number) => {
    const path = "network/infoNetwork"
    const params = {
        id
    }
    const result: ResponseSuccessTotal<INetwork> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thêm mới network
export const addNetwork = async (
    image: File | undefined, title: number, numberharacters: number, expense: number, numberPrefix: string[]
) => {
    const url = `network/addNetwork`;
    const params = {
        image, title, numberharacters, expense, numberPrefix
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        url, params
    );
    return result;
}

//Chỉnh sửa network
export const editNetwork = async (
    id: number, image: File | undefined, title: number, numberharacters: number, expense: number, numberPrefix: string[]
) => {
    const url = `network/editNetwork`;
    const params = {
        id, image, title, numberharacters, expense, numberPrefix
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, params
    );
    return result;
}

//Xóa network
export const deleteNetwork = async (
    id: Number
) => {
    const url = `network/deleteNetwork`;
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        url, { params }
    );
    return result;
}

