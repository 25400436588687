import React, { useState } from 'react'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import './RelativeCard.scss'
import RelativeCollapse from './RelativeCollapse'
import AddRelativeModal from '../../pages/Customers/Modal/AddRelative'

interface CollapseProps {
  title: string
  relationship: string
  name: string
  gender: string
  phoneNumber: string
  dateOfBirth: string
  career: string
  email: string
  address: string
  show?: boolean
}

interface Props {
  title: string
  items: CollapseProps[]
}

const RelativeCard:React.FC<Props> = (props) => {
  const { title, items } = props

  const [openIndex, setOpenIndex] = useState<any>(0)
  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  // ADD RELATIVE
  const [showAddRelativeModal, setShowAddRelativeModal] = useState(false)

  return (
    <>
      <div className="relative-card">
        <div className="relative-card__title">
          <span className="font-bold">{ title }</span>
          <Button
            icon={<PlusCircleOutlined />}
            iconPosition="end"
            className='rs-button rs-button--sm'
            onClick={() => setShowAddRelativeModal(true)}
          >Thêm mới</Button>
        </div>
        <div className="relative-card__content">
          {
            items.map((item: CollapseProps, index: number) => (
              <RelativeCollapse
                key={index}
                show={openIndex === index}
                {...item}
                // title={item.name}
                // relationship={item.relationship}
                // name={item.name}
                // gender={item.gender}
                // phoneNumber={item.phoneNumber}
                // dateOfBirth={item.dateOfBirth}
                // career={item.career}
                // email={item.email}
                // address={item.address}
                onToggle={() => handleToggle(index)}
              />
            ))
          }
        </div>
      </div>

      {/* ADD RELATIVE MODAL */}
      <AddRelativeModal
        show={showAddRelativeModal}
        modalClose={() => setShowAddRelativeModal(false)}
        onSuccess={() => setShowAddRelativeModal(false)}
      />
    </>
  )
}

export default RelativeCard
