import { CloseOutlined } from "@ant-design/icons";
import { Form, FormInstance, FormProps, Input, Modal, TreeSelect } from "antd";
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useRef } from "react";
// import * as UnitApi from '../../../api/unit/unitApi';
import useToast from "../../../hook/useToast";
import './style.scss';
interface ModalProps {
  isOpen: boolean
  data: []
  closeModal: () => void
}
const ModalParamCreate: React.FC<ModalProps> = ({
  isOpen,
  data,
  closeModal
}) => {
  const [form] = useForm();
  const inputRef = useRef<FormInstance>(null);
  const pushToast = useToast();


  const submitForm = () => {
    if (inputRef.current) {
      inputRef.current.submit();
    }
  };
  const onFinish: FormProps["onFinish"] = async (values) => {
    closeModal();
  };

  useEffect(() => {
    if (isOpen) {
      form.resetFields();

    }
  }, [form, isOpen])
  return (
    <Modal
      title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
        <span className="text-base text-344054 fw-500 font-medium">Cài đặt param hệ thống</span>
        <CloseOutlined className="text-base text-8B8B8B" onClick={closeModal} />
      </div>}
      open={isOpen}
      onCancel={closeModal}
      centered
      className="w-modal modal-content-creat-area ant-modal-size"
      closeIcon={false}
      footer={<div className="bg-FCFCFD d-flex justify-end align-center py-2 px-6 w-full border-t-F0F0F0 gap-2">
        <button className="text-sm text-344054 border-F2F4F7 bg-white h-8 px-15-px font-regular rounded-2 shadow-btn-save" onClick={closeModal}>Đóng</button>
        <button className="text-white text-sm bg-096DD9 border-none shadow-btn-save rounded-2 h-8 px-15-px font-regular shadow-btn-save"
          onClick={submitForm}>
          Tạo
        </button>
      </div>}
    >
      <Form
        form={form}
        ref={inputRef}
        onFinish={onFinish}
        initialValues={{ remember: true }}
      >
        <div className="d-flex flex-column gap-18-px p-6">
          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Mã Param</p>
            <Form.Item
              name="codeaffiliated"
              rules={[{ required: true, message: 'Nhập mã Param!' }]}
              className="m-none-form-item text-explan"
            >
              <Input
                placeholder="Mã Param"
                className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
              />
            </Form.Item>
          </div>

          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Tên Param</p>
            <Form.Item
              name="nameaffiliated"
              rules={[{ required: true, message: 'Nhập tên Param!' }]}
              className="m-none-form-item text-explan"
            >
              <Input
                placeholder="Tên Param"
                className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
              />
            </Form.Item>
          </div>
          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Chọn hệ thống</p>
            <Form.Item
              name="idParent"
              rules={[{ required: true, message: 'Chọn Hệ thống!' }]}
              className="m-none-form-item text-explan"
            >
              <TreeSelect
                showSearch
                placeholder="Hệ thống"
                allowClear
                treeDefaultExpandAll={false}
                treeData={data}
                className="custom-tree-select "
                popupClassName="custom-dropdown-tree-select"
                filterTreeNode={(input, option) =>
                  typeof option?.label === 'string' && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </Form.Item>
          </div>


        </div>
      </Form>
    </Modal>
  )
}
export default ModalParamCreate
