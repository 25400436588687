import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";

// export interface ICampaign {
//     id: number;
//     image: string;
//     title: string;
//     numberharacters: number;
//     expense: number;
//     numberPrefixs: string;
// }

export interface ICampaign {
    id: number
    title: string
    role: number
    type: number
    view: number
    status: number
    auto: number
    dateSend: string
    timeSend: string
    totalDeposit: number
    targetId: number
    targetTitle: string
    network: Network[]
    numberSend: string
}

export interface Network {
    id: number
    title: string
    image: string
}

export interface ICampaignCosts {
    SMS: Sms[]
    ZNS: Zns[]
    totalExpense: number
    totalChannelExpense: number
    totalZNSExpense: number
    totalSMSExpense: number
    totalEmailExpense: number
}

export interface Sms {
    networkId: number
    networkTitle: string
    networkImage: string
    totalCustomers: number
    expense: number
}

export interface Zns {
    networkId: string
    networkTitle: string
    networkImage: string
    totalCustomers: number
    expense: number
}


export interface ICustomerReceived {
    title: string
    dataSend: DataSend[]
}

export interface DataSend {
    id: number
    status: number
    timeSend: string
    customerId: number
    customerName: string
}


export interface IICampaignDetail {
    id: number
    code: string
    title: string
    auto: number
    dateSend: string
    timeSend: string
    role: number
    type: number
    view: number
    status: number
    targetId: number
    dateCreated: string
    targetTitle: string
    numberSend: number
    adminId: number
    adminName: string
    network: NetworkDetail[]
}

export interface NetworkDetail {
    channelId: number
    title: string
    image: string
    notificationTemplateId: number
    notificationTemplateCode: string
    description: string
    sendSuccess: number
    sendFail: number
}


//Danh sách chiến dịch
export const getListCampaign = async (
    limit?: number, pageNumber?: number, channelId?: number, auto?: number
) => {
    const path = "campaign/listCampaign"
    const params = {
        limit, pageNumber, channelId, auto
    }
    const result: ResponseSuccessTotal<ICampaign[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Danh sách khách hàng nhận tin nhắn
export const getlistCustomerReceived = async (
    campaignId: number, limit?: number, pageNumber?: number, sortStatus?: number, dateStart?: string, dateEnd?: string
) => {
    const path = "campaign/listCustomerReceived"
    const params = {
        campaignId, limit, pageNumber, sortStatus, dateStart, dateEnd
    }
    const result: ResponseSuccessTotal<ICustomerReceived> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thông tin chiến dịch
export const getInfoCampaign = async (
    id: number) => {
    const path = "campaign/infoCampaign"
    const params = {
        id
    }
    const result: ResponseSuccess<IICampaignDetail> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Danh sách khách hàng nhận tin nhắn theo kênh
export const getlistCampaignChannel = async (
    campaignId: number, notificationTemplateId: number, channelId: number
) => {
    const path = "campaign/listCampaignChannel"
    const params = {
        campaignId, notificationTemplateId, channelId
    }
    const result: ResponseSuccess<DataSend[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Chi phí diện dịch
export const getInfoCampaignCosts = async (
    customerId?: number[], groupCustomerId?: number[], channelList?: any[]
) => {
    const path = "campaign/campaignCosts"
    const params = {
        customerId, groupCustomerId, channelList
    }
    const result: ResponseSuccess<ICampaignCosts> = await axiosClient.post(
        path, params
    );
    return result;
};


//Thêm mới chiến dịch
export const addCampaign = async (
    title: string, adminId: number, targetId: number, channelList: any[], type: number, role: number, auto: number,
    customerId?: number[], groupCustomerId?: number[], dateSend?: string, timeSend?: string
) => {
    const url = `campaign/addCampaign`;
    const params = {
        title, adminId, targetId, channelList, type, role, auto,
        customerId, groupCustomerId, dateSend, timeSend
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        url, params
    );
    return result;
}

//Chỉnh sửa chiến dịch
export const editCampaign = async (
    id: number, image: File | undefined, title: number, numberharacters: number, expense: number, numberPrefix: string[]
) => {
    const url = `campaign/editCampaign`;
    const params = {
        id, image, title, numberharacters, expense, numberPrefix
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, params
    );
    return result;
}

//Cập nhật trạng thái chiến dịch
export const activeCampaign = async (
    id: number
) => {
    const url = `campaign/activeCampaign`;
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, params
    );
    return result;
}

//Xóa chiến dịch
export const deleteCampaign = async (
    id: Number
) => {
    const url = `campaign/deleteCampaign`;
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        url, { params }
    );
    return result;
}



