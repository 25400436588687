import { CloseOutlined } from "@ant-design/icons"
import { Form, FormInstance, FormProps, Input, Modal, notification } from "antd"
import { useEffect, useRef } from "react"
import * as attributeVehicleApi from "../../../../../api/attributeVehicle/attributeVehicleApi"
import { useForm } from "antd/es/form/Form"
interface ModalProps {
  isOpen: boolean
  infoTypeAttributeVehicle?: attributeVehicleApi.ITypeAttributeVehicle
  infoAttributeVehicle?: attributeVehicleApi.IAttributeVehicle
  onSubmitFormModal?: (iSsuccess: boolean, errorMessage?: string) => void;
  closeModal: (value: boolean) => void
}
const ModalEditSegment: React.FC<ModalProps> = ({
  isOpen, infoTypeAttributeVehicle, infoAttributeVehicle,
  onSubmitFormModal, closeModal
}) => {
  const [form] = useForm();
  const title = infoTypeAttributeVehicle?.title.toLocaleLowerCase()
  const inputRef = useRef<FormInstance>(null);
  const submitForm = () => {
    if (inputRef.current) {
      inputRef.current.submit();
    }
  };

  const onFinish: FormProps["onFinish"] = async (values) => {
    const result = await attributeVehicleApi.editAttributeVehicle(Number(infoTypeAttributeVehicle?.id), Number(infoAttributeVehicle?.id), values.namesegment)
    if (result?.status) {
      onSubmitFormModal?.(true)
    } else {
      onSubmitFormModal?.(false, result.message)
    }
  };

  useEffect(() => {
    if (isOpen) {
      form.setFieldValue('namesegment', infoAttributeVehicle?.title)
    } else {
      form.setFieldValue('namesegment', "")
    }
  }, [isOpen])
  return (
    <Modal
      title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
        <span className="text-base text-344054 fw-500 font-medium">Chỉnh sửa {title}</span>
        <CloseOutlined className="text-base text-8B8B8B" onClick={() => closeModal(false)} />
      </div>}
      open={isOpen}
      onCancel={() => closeModal(false)}
      centered
      className="w-modal modal-content ant-modal-size"
      closeIcon={false}
      footer={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-2 px-6 w-full border-t-F0F0F0">
        <span className="text-sm text-98A2B3 cursor-pointer" onClick={() => closeModal(false)}>Huỷ</span>
        <button className="text-white text-sm bg-096DD9 border-none shadow-btn-save rounded-2 h-8 px-15-px"
          onClick={submitForm}>
          Chỉnh sửa {title}
        </button>
      </div>}
    >
      <div className="p-6 ">
        <Form
          form={form}
          ref={inputRef}
          onFinish={onFinish}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="namesegment"
            rules={[{ required: true, message: 'Nhập tên ' + title }]}
            className="m-none-form-item text-explan"
          >
            <Input
              placeholder={`Nhập tên ${title}`}
              className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
            />
          </Form.Item>
        </Form>

      </div>
    </Modal>
  )
}
export default ModalEditSegment
