import React, { useState } from 'react'
import { Button, DatePicker, Flex, Input, Table } from 'antd'
import { DownOutlined, EyeOutlined, UpOutlined } from '@ant-design/icons'
import './OrderHistoryCard.scss'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import type { GetProps } from 'antd'

const { Search } = Input

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker
// eslint-disable-next-line arrow-body-style
const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days before today and today
  return current && current > dayjs().endOf('day');
};

interface Props {
  title: string
  tableData: any
  onQueryChange?: (query: string) => void
  onDateChange?: (date: string) => void
  onShowDetail?: (id: number) => void
}

const WarrantyHistoryCard: React.FC<Props> = (props) => {
  const { title, tableData, onShowDetail } = props

  const handleShowDetail = (id: number) => {
    if (onShowDetail) {
      onShowDetail(id)
    }
  }

  const [isShow, setIsShow] = useState(true)
  const onToggle = () => {
    setIsShow(!isShow)
  }

  const tableColumns: any = [
    {
      title: 'Hành động',
      key: 'action',
      render: (_: any, record: any) => (
        <Flex align='center' justify='center' className='gap-10px h-64px'>
          <Button
            icon={<EyeOutlined />}
            className='rs-button rs-button--sm'
            onClick={() => handleShowDetail(record?.id)}
          />
        </Flex>
      ),
      width: 93,
    },
    {
      title: 'Số RO',
      dataIndex: 'roNumber',
      key: 'roNumber',
      align: 'right'
    },
    {
      title: 'Số BCK',
      dataIndex: 'bckNumber',
      key: 'bckNumber',
      align: 'right'
    },
    {
      title: 'Số TI - TS',
      dataIndex: 'titsNumber',
      key: 'titsNumber',
      align: 'right'
    },
    {
      title: 'Số khung (VIN)',
      dataIndex: 'vinNumber',
      key: 'vinNumber',
      align: 'right'
    },
    {
      title: 'Màu xe',
      dataIndex: 'carColor',
      key: 'carColor',
      align: 'right'
    },
    {
      title: 'Loại xe',
      dataIndex: 'carType',
      key: 'carType',
      align: 'right'
    },
    {
      title: 'Số KM hiện tại',
      dataIndex: 'kmNumber',
      key: 'kmNumber',
      align: 'right'
    },
    {
      title: 'Loại dịch vụ',
      dataIndex: 'serviceType',
      key: 'serviceType',
      align: 'right'
    },
    {
      title: 'Thành tiền (bao gồm VAT)',
      dataIndex: 'price',
      key: 'price',
      align: 'right'
    },
  ]

  return (
    <div className="order-history-card">
      <div className="order-history-card__head">
        <span className="title fsz-16 lh-24 font-medium">{ title }</span>
        <Search
          placeholder="Tìm kiếm"
          className='rs-search'
        />
        <RangePicker
          className="rs-range-picker"
          popupClassName="rs-range-picker-dropdown"
          disabledDate={disabledDate}
          placeholder={['Từ ngày', 'Đến ngày']}
        />
        <Button
          className='rs-button rs-button--sm collapse-button'
          icon={ isShow ? <UpOutlined /> : <DownOutlined /> }
          iconPosition='end'
          onClick={onToggle}
        >{ isShow ? 'Thu gọn' : 'Mở rộng' }</Button>
      </div>

      <div className={`order-history-card__body ${isShow ? 'show' : 'collapsed'}`}>
        <Table
          className='rs-table'
          rowKey="id"
          columns={tableColumns}
          dataSource={tableData}
          showSorterTooltip={false}
          pagination={false}
          scroll={{ x: true }}
          // pagination={{
          //   showSizeChanger: true,
          //   defaultPageSize: 15,
          //   showTotal: (total) => `Tổng cộng ${Math.ceil(total / 15)} trang`,
          //   locale: { items_per_page: ' / trang', jump_to: 'Tới' },
          //   position: ['bottomCenter'],
          //   className: 'rs-pagination',
          //   showQuickJumper: true,
          // }}
        />
      </div>
    </div>
  )
}

export default WarrantyHistoryCard
