import { FlattenedData } from "../../pages/SystemConfiguration/AccountRole/CreateGroupRole";
import { paserParams } from "../../until";
import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";
import { IDecentralization } from "../configuration/decentralizationApi";

export interface IAccount {
    id: number
    code: string
    fullname: string
    email: string
    status: number
    unit: string
    groupPermission: string
    region: string,
    businessUnit: string,
    department: string
}
export interface IAccountDetail {
    code: string
    id: number
    fullname: string
    email: string
    unit: ISL
    groupPermission: ISL
    region: string
    department: string
    businessUnit: string
}

export interface ISL {
    id: number
    title: string
}

export interface IGroupPermission {
    id: number
    title: string
    description: string
    so_luong: number
    createdAt: string
}

export interface IGroupPermissionDetail {
    groupPermission: GroupPermission
    listAdmin: number[]
    menu: IDecentralization[]
}

export interface GroupPermission {
    title: string
    description: string
}

//Danh sách tài khoản người dùng
export const getListUserAccount = async (
    limit?: number, pageNumber?: number, unitId?: number, businessUnitId?: number, search?: string
) => {
    const path = "groupPermission/listUserAccount"
    const params = {
        limit, pageNumber, unitId, businessUnitId, search
    }
    const result: ResponseSuccessTotal<IAccount[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Danh sách nhóm quyền
export const getListGroupPermission = async (
    limit?: number, pageNumber?: number
) => {
    const path = "groupPermission/getAll"
    const params = {
        limit, pageNumber
    }
    const result: ResponseSuccessTotal<IGroupPermission[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Chi tiết nhóm quyền
export const getInfoGroupPermission = async (
    id: number
) => {
    const path = "groupPermission/detail"
    const params = {
        id
    }
    const result: ResponseSuccess<IGroupPermissionDetail> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thông tin tài khoản người dùng
export const getInfoAccount = async (
    id: number) => {
    const path = "groupPermission/infoUserAccount"
    const params = {
        id
    }
    const result: ResponseSuccess<IAccountDetail> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thêm mới nhóm quyền
export const addGroupPermission = async (
    title: string, description: string, admin: number[], permission: FlattenedData[]
) => {
    const url = `groupPermission/add`;
    const params = {
        title, description, admin, permission
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        url, params
    );
    return result;
}

//Chỉnh sửa nhóm quyền
export const editGroupPermission = async (
    id: number, title: string, description: string, admin: number[], permission: FlattenedData[]
) => {
    const url = `groupPermission/edit`;
    const params = {
        id, title, description, admin, permission
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, params
    );
    return result;
}

//Chỉnh sửa tài khoản người dùng
export const editUserAccount = async (
    id: number, unitId: number, groupPermissionId: number
) => {
    const url = `groupPermission/editUserAccount`;
    const params = {
        id, unitId, groupPermissionId
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, params
    );
    return result;
}


//Cập nhật trạng thái hoạt động tài khoản người dùng
export const updateActiveUserAccount = async (
    id: number
) => {
    const url = `groupPermission/activeUserAccount`;
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, paserParams(params, undefined)
    );
    return result;
}

//Xóa nhóm quyền
export const deleteGroupPermission = async (
    id: number
) => {
    const url = `groupPermission/deleteGroupPermission`;
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        url, { params }
    );
    return result;
}


