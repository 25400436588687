import React, { useRef, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { mixFnum, mixPostV1, mixTreeIds, mixDelV1, mixGetV2, mixPostV2 } from '../../services/mixin'
import Layout from '../../Layout';
import ShowSetting from '../../components/Filter/ShowSetting';
import ShowSettingAddFilter from '../../components/Filter/ShowSettingAddFilter';
import { Input, Checkbox, Slider, DatePicker, Tree, Space, Table, notification, Form, FormInstance, FormProps, Tag, Flex, Pagination } from 'antd';
import type { GetProp, SliderSingleProps, TreeProps, TableProps, CheckboxProps } from 'antd';
import {
  DownOutlined, UserOutlined, DeleteOutlined, FilterOutlined, CloseOutlined, DownloadOutlined, UploadOutlined, UserAddOutlined, SettingOutlined, EyeOutlined, EditOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import type { SearchProps } from 'antd/es/input/Search';
// import viVN from 'antd/locale/vi_VN';
import './CustomerList.scss';
import { Link, useNavigate } from 'react-router-dom';
import * as customerApi from '../../api/customer/customerApi';
import { API_URL, LIMIT_EXPORT_EXCEL, URL_CAR_CATEGORY } from '../../env';
import ModalAddGroupV2 from './CustomerGroup/Component/Modal/ModalAddGroupV2';
import ModalUpload from '../../components/Modal/ModalUpload';
import ModalGroupCustomerList from './CustomerGroup/Component/Modal/ModalGroupCustomerList';
import useToast from '../../hook/useToast';
import useThrottle from '../../hook/useThrottle';
import * as XLSX from 'xlsx';
import { dataGender } from '../../services/data';
import ModalDeleteManager from '../../components/ModalDeleteManager';
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock';
import fakeData from './customer-list.json'
import ImportDataModal from './Component/ImportDataModal/ImportDataModal'

const { RangePicker } = DatePicker;
const marks: SliderSingleProps['marks'] = {
  0: '0',
  1000000000: '1 tỷ',
  3000000000: '3 tỷ',
  5000000000: '5tỷ',
};

const dotuoi: SliderSingleProps['marks'] = {
  0: '0',
  18: '18',
  30: '30',
  100: '100',
};

const CustomerList: React.FC = () => {
  const pushToast = useToast();
  const navigator = useNavigate()

  const [openModalUploadDS, setOpenModalUploadDS] = useState(false);
  const [showSelectFilter, setActive] = useState(false);
  const [showSelectAddFilter, setAddActive] = useState(false);
  const [isSettingShow, setSettingShow] = useState(false)
  const [isSettingShowAddFilter, setSettingShowAddFilter] = useState(false)
  const [openDeleteListCustomer, setOpenDeleteListCustomer] = useState(false)

  const [accessSelectFilter, setAccessActive] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [idCustomer, setIdCustomer] = useState<number>();

  const { Search } = Input;

  const handleSettingShowAddFilter = () => {
    setAddActive(false)
    setSettingShowAddFilter(true)
  }

  const onSearch: SearchProps['onSearch'] = (value, _e) => {
    setPagination({ ...pagination, current: 1 })
  }

  const hasSelected = selectedRowKeys.length > 0;

  const setActiveAddFilter = () => {
    setActive(false)
    setAddActive(true)
  };

  const setActiveCloseFilter = () => {
    setAccessActive(true)
  };

  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onChangeTB: TableProps<customerApi.DataTypeCustomer>['onChange'] = (pagination, filters, sorter, extra) => {
  };


  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (event.target.matches('.filter-select, .filter-select *, .list-filter, .list-filter *') === false) {
        setActive(false)
      }
      if (event.target.matches('.add-filter, .add-filter *, .show-access-filter, .show-access-filter *, .list-filter, .list-filter *, .ant-picker-dropdown, .ant-picker-dropdown *') === false) {
        setAddActive(false)
      }
      if (event.target.matches('.show-access-filter, .show-access-filter *, .btn-df, .btn-df *') === false) {
        setAccessActive(false)
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const [showModalAddGroupCustomer, setShowModalAddGroupCustomer] = useState(false)

  // HANDLE MODAL
  const [isModalShow, setModalShow] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const onSubmitForm = () => {
    setConfirmLoading(true)
    setTimeout(() => {
      setModalShow(false)
      setConfirmLoading(false)
      openNotification('Thêm khách hàng vào nhóm thành công!')
    }, 1500)
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (content: string) => {
    api['success']({
      message: content,
      placement: 'bottomRight',
      className: 'single-message'
    });
  }


  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const handleChangePagination = (current: number, pageSize: number) => {
    setPagination({ ...pagination, current, pageSize })
    setParamListData({ ...paramListData, limit: pageSize, page: current })
  }
  const [paramListData, setParamListData] = useState({
    'sort[]': 'id,desc',
    page: pagination.current,
    limit: pagination.pageSize,
    name: '',
    source_id: null,
  })
  let wait = false
  const [listData, setListData] = useState([])
  const getListData = async () => {
    if (wait) {
      return
    }
    wait = true
    const res = await mixGetV2('/api/v1/be/warehouses', paramListData)
    if (res.status) {
      setListData(res.data.items)
      setPagination({ ...pagination, total: res.data.total })
    }
  }

  useEffect(() => {
    getListData()
  }, [paramListData])


  // Chose filterCustomerId
  const [itemFilter, setItemFilter] = useState({} as any)


  const handleCloseSettingAddFilter = (value: any, isChange: boolean) => {
    setSettingShowAddFilter(false)
    setAddActive(true)
  }

  const onSubmitFormModalAddGroupCustomerList = (iSsuccess: boolean, message?: string) => {
    if (iSsuccess) {
      setModalShow(false);
      setSelectedRowKeys([])
      pushToast(message, "success")
    }
    else {
      pushToast(message, "warning")
    }
  }

  const openAddGroupCustomer = () => {
    setModalShow(false)
    setShowModalAddGroupCustomer(true)
  }

  const onSubmitFormModalAddInfoGroup = (iSsuccess: boolean, message?: string) => {
    if (iSsuccess) {
      setShowModalAddGroupCustomer(false);
      setSelectedRowKeys([])
      pushToast(message, "success")
    }
    else {
      pushToast(message, "warning")
    }
  }

  const openModalDelete = (id: number) => {
    setIdCustomer(id)
    setOpenDeleteListCustomer(true)
  }

  const closeModalDelete = () => {
    setIdCustomer(undefined)
    setOpenDeleteListCustomer(false)
  }

  const onConfirmDelete = async () => {
    const param = {
      list_ids: idCustomer?.toString()
    }
    const res = await mixPostV2('/api/v1/be/warehouse_delete', param)
    if (res.status) {
      pushToast('Xóa thành công', "success")
      closeModalDelete()
      setSelectedRowKeys([])
      getListData()
    } else {
      pushToast('Error', "warning")
    }
  }

  // IMPORT DATA MODAL
  const [showImportDataModal, setShowImportDataModal] = useState(false)

  // check all group

  const CheckboxGroup = Checkbox.Group;

  const nNOptions = ['Lập trình viên', 'COO', 'CEO', 'Doanh nhân'];
  const defaultCheckedListNN = ['Lập trình viên'];

  const sTOptions = ['Bóng đá', 'Nhạc pop', 'Đọc sách', 'Xem phim'];
  const defaultCheckedListST = ['Bóng đá'];

  const dVOptions = ['THACO AUTO BÌNH TÂN - HCM', 'THACO AUTO TRƯỜNG CHINH - HCM', 'THACO AUTO PHÚ NHUẬN', 'THACO AUTO BÌNH TÂN - HN'];
  const defaultCheckedListDV = ['THACO AUTO BÌNH TÂN - HCM'];

  const nKHOptions = ['Khách hàng lớn tuổi', 'Khách hàng quan trọng'];
  const defaultCheckedListNKH = ['Khách hàng lớn tuổi'];

  const [checkedListNN, setCheckedListNN] = useState<string[]>(defaultCheckedListNN as any);
  const [checkedListST, setCheckedListST] = useState<string[]>(defaultCheckedListST as any);
  const [checkedListDV, setCheckedListDV] = useState<string[]>(defaultCheckedListDV as any);
  const [checkedListNKH, setCheckedListNKH] = useState<string[]>(defaultCheckedListNKH as any);

  const checkAllNN = nNOptions.length === checkedListNN.length;
  const indeterminate = checkedListNN.length > 0 && checkedListNN.length < nNOptions.length;

  const checkAllST = sTOptions.length === checkedListST.length;
  const indeterminateST = checkedListST.length > 0 && checkedListST.length < sTOptions.length;

  const checkAllDV = dVOptions.length === checkedListDV.length;
  const indeterminateDV = checkedListDV.length > 0 && checkedListDV.length < dVOptions.length;

  const checkAllNKH = nKHOptions.length === checkedListNKH.length;
  const indeterminateNKH = checkedListNKH.length > 0 && checkedListNKH.length < nKHOptions.length;

  const onChange = (list: string[]) => {
    setCheckedListNN(list);
    setCheckedListST(list);
    setCheckedListDV(list);
    setCheckedListNKH(list);
  };

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    setCheckedListNN(e.target.checked ? nNOptions : []);
    setCheckedListST(e.target.checked ? sTOptions : []);
    setCheckedListDV(e.target.checked ? dVOptions : []);
    setCheckedListNKH(e.target.checked ? nKHOptions : []);
  };

  const [defaultColumnsShow, setDefaultColumnsShow] = useState(['id'] as any)
  const [columns, setColumns] = useState([] as any)
  const [newColumns, setNewColumns] = useState([] as any)

  const [sourceItems, setSourceItems] = useState<any>([])
  let wait1 = false
  const getcolumn = async () => {
    if (wait1) {
      return
    }
    wait1 = true
    const res = await mixGetV2('/api/v1/be/source_column_warehouses?is_active=1')
    wait1 = false
    if (res.status) {
      const df = [
        {
          title: 'Hành động',
          dataIndex: 'action',
          render: (_: any, record: any) => (
            <Space size="middle">
              <DeleteOutlined
                className='text-F5222D text-sm border-EAECF0 p-5-px bg-white rounded-2'
                // onClick={() => setOpenDeleteListCustomer(true)}
                onClick={() => openModalDelete(Number(record.warehouse_id_origin))}
              />
            </Space>
          ),
          key: 'hanhdong'
        },
        {
          title: 'ID',
          dataIndex: 'warehouse_id_origin',
          key: 'warehouse_id_origin'
        },
      ] as any
      const resColum = [] as any
      const tempdf = ['warehouse_id_origin'] as any
      res.data.items.map((val: any, index: any) => {
        resColum.push({ title: val.name, dataIndex: val.column_warehouse.column, key: val.id})
        if (index < 7) {
          tempdf.push(val.id)
        }
        return true
      })

      setColumns(resColum)

      setDefaultColumnsShow(tempdf)
      const checkedList = localStorage.getItem('customerListColums') ? JSON.parse(localStorage.getItem('customerListColums') as string) : [...tempdf] as any
      const tempColumns = resColum.map((item: any) => ({
        ...item,
        hidden: !checkedList.includes(item.key as string),
      }));
      setNewColumns([...df,...tempColumns])
    }
  }

  useEffect(() => {
    getcolumn()
  }, [])

  // setting block logic
  const handleCloseSetting = (value: any, isChange: boolean) => {
    if (isChange) {
      localStorage.setItem('customerListColums', JSON.stringify(value))
      const checkedList = localStorage.getItem('customerListColums') ? JSON.parse(localStorage.getItem('customerListColums') as string) : [...defaultColumnsShow] as any
      const tempColumns = columns.map((item: any) => ({
        ...item,
        hidden: !checkedList.includes(item.key as string),
      }));
      setNewColumns(tempColumns)
    }
    setSettingShow(false)
    setSettingShowAddFilter(false)
  }

  const mutiDel = (ids = [] as any) => {
    setIdCustomer(ids)
    setOpenDeleteListCustomer(true)
  }

  return (
    <>
      {contextHolder}

      <Layout className='customer-list'>
        <BreadcrumbBlock
          items={[
            { title: <Link to="/">THACO AUTO CRM</Link>, },
            { title: 'Khách hàng', },
            { title: <Link to="/customer/pooling-data">Gộp dữ liệu khách hàng</Link>, },
            { title: 'Danh sách khách hàng', },
          ]}
          title='Danh sách khách hàng'
          className='gutter-block-end'
        />

        {/* Fillter block */}
        <div className={`filter-block ${hasSelected ? "active" : ""}`}>
          <div className='t-search-block'>
            <Search placeholder="Tìm kiếm số điện thoại, hoặc email" onSearch={onSearch} />
          </div>

          <div className="btn-input"
            onClick={() => setShowImportDataModal(true)}
          >
            <div className="icon-svg">
              <UploadOutlined />
            </div>
            <div className="text">Nhập DS</div>
          </div>

          <div className="btn-input t-px-8" onClick={() => setSettingShow(true)}>
            <div className="icon-svg">
              <SettingOutlined />
            </div>
          </div>
        </div>

        {/* Fillter block */}
        <div className={`filter-block filter-active-show ${hasSelected ? "active" : ""}`}>
          <div className='total-chosse'>
            {hasSelected ? `Đã chọn ${selectedRowKeys.length} khách hàng` : ''}
          </div>

          <div className="btn-input" onClick={()=>{ mutiDel(selectedRowKeys) }}>
            <div className="icon-svg">
              <DeleteOutlined/>
            </div>
            <div className="text">{`Xóa ${selectedRowKeys.length} khách hàng`}</div>
          </div>
        </div>

        <div className="t-table-block t-table-customer c-table-scoll-x">
          <Table
            className='rs-table'
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
            rowKey="warehouse_id_origin"
            rowSelection={rowSelection}
            columns={newColumns}
            dataSource={listData}
            onChange={onChangeTB}
            showSorterTooltip={false}
            pagination={false}
          />
          <div className="pagination-block">
            <Pagination
              className='rs-pagination'
              showSizeChanger
              total={pagination.total}
              defaultPageSize={pagination.pageSize}
              current={pagination.current}

              showTotal={() => `Tổng cộng ${Math.ceil(pagination.total / pagination.pageSize) === 0 ? 1 : Math.ceil(pagination.total / pagination.pageSize)} trang`}
              locale={{ items_per_page: 'trang' }}
              onChange={handleChangePagination}
            />
          </div>
        </div>

        {
          isSettingShow
            ? <ShowSetting
              className="show"
              columns={columns}
              defaultColumnsShow={defaultColumnsShow}
              onClose={(value, isChange) => { handleCloseSetting(value, isChange) }}
            />
            : null
        }
      </Layout>

      <ModalDeleteManager
        isOpen={openDeleteListCustomer}
        // onConfirm={handleDelete}

        title="Bạn có chắc chắn xóa khách hàng này"
        titleDelete="Xóa khách hàng"
        content="Khách hàng và dữ liệu liên quan sẽ bị xóa vĩnh viễn không thể khôi phục"
        closeModal={closeModalDelete}
        onConfirm={onConfirmDelete}
      />

      {/* MODAL IMPORT DATA */}
      <ImportDataModal
        show={showImportDataModal}
        modalClose={() => {
          setShowImportDataModal(false)
          getListData()
        }}
      />
    </>
  )
}

export default CustomerList
