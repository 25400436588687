import React, { Fragment, useEffect, useState } from 'react';
import Layout from '../../Layout';
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock';
import { Link } from 'react-router-dom';
import { ConfigProvider, Pagination, PaginationProps, Tabs, TabsProps } from 'antd';
import './VouchersManagement.scss'
import VoucherFilterBlock from './VoucherComponents/VoucherFilterBlock';
import VoucherCard from '../../components/Cards/VoucherCard';
import EmptyComponent from '../../components/EmptyComponent/EmptyComponent';
import viVN from 'antd/locale/vi_VN';
import useToast from '../../hook/useToast';
import { dataPagination } from '../../until';
import useDebounce from '../../hook/useDebounce';
import * as voucherApi from '../../api/voucher/voucherApi';
import * as XLSX from 'xlsx';
import useThrottle from '../../hook/useThrottle';
import ModalDeleteManager from '../../components/ModalDeleteManager';
const tabItems: TabsProps['items'] = [
  {
    label: 'Đang hoạt động',
    key: "1",
  },
  {
    label: 'Đã kết thúc',
    key: "0",
  },
  {
    label: 'Sắp diễn ra',
    key: "2",
  },
];

export interface IDataVocher {
  active: voucherApi.IVoucher[],
  finished: voucherApi.IVoucher[],
  comingSoon: voucherApi.IVoucher[],
}

const VouchersManagement: React.FC = () => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';

    uStatusVoucher()
    return (() => {

      setIsModalDelete(false)

      setSelectedTab("1")

      setDataFilter({
        active: {
          name: "",
          date: ["", ""]
        },
        finished: {
          name: "",
          date: ["", ""]
        },
        comingSoon: {
          name: "",
          date: ["", ""]
        }
      })

      setPaginationActive({ current: 1, pageSize: 10, total: 0 })
      setPaginationFinished({ current: 1, pageSize: 10, total: 0 })
      setPaginationComingSoon({ current: 1, pageSize: 10, total: 0 })
    })

  }, []);

  const pushToast = useToast();

  const [isModalnDelete, setIsModalDelete] = useState(false)

  const [selectedTab, setSelectedTab] = useState<"0" | "1" | "2">("1");

  const [dataFilter, setDataFilter] = useState({
    active: {
      name: "",
      date: ["", ""]
    },
    finished: {
      name: "",
      date: ["", ""]
    },
    comingSoon: {
      name: "",
      date: ["", ""]
    }
  }
  )

  const [paginationActive, setPaginationActive] = useState({ current: 1, pageSize: 10, total: 0 });
  const [paginationFinished, setPaginationFinished] = useState({ current: 1, pageSize: 10, total: 0 });
  const [paginationComingSoon, setPaginationComingSoon] = useState({ current: 1, pageSize: 10, total: 0 });

  const [idVoucher, setIdVoucher] = useState<number>()

  const [listVoucherActive, setListVoucherActive] = useState<voucherApi.IVoucher[]>([])
  const [listVoucherFinished, setListVoucherFinished] = useState<voucherApi.IVoucher[]>([])
  const [listVoucherComingSoon, setListVoucherComingSoon] = useState<voucherApi.IVoucher[]>([])


  const gListVocherActive = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await voucherApi.getListVoucher(pageSize, pageCurent, 1, dataFilter.active.name, dataFilter.active.date[0], dataFilter.active.date[1])
    if (result?.status) {
      setListVoucherActive(result.data)
      setPaginationActive({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const gListVocherFinished = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await voucherApi.getListVoucher(pageSize, pageCurent, 0, dataFilter.finished.name, dataFilter.finished.date[0], dataFilter.finished.date[1])
    if (result?.status) {
      setListVoucherFinished(result.data)
      setPaginationFinished({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const gListVocherComingSoon = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await voucherApi.getListVoucher(pageSize, pageCurent, 2, dataFilter.comingSoon.name, dataFilter.comingSoon.date[0], dataFilter.comingSoon.date[1])
    if (result?.status) {
      setListVoucherComingSoon(result.data)
      setPaginationComingSoon({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)


  const uStatusVoucher = useThrottle(async () => {
    await voucherApi.updateStatusVoucher()
  }, 10000)

  const exportExcel = (convertData: any): boolean => {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData);
      const workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách');

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = window.URL.createObjectURL(data);
        link.download = fileName;
        link.click();
      };

      saveAsExcel(excelBuffer, `${"Danh sách voucher"}.xlsx`);
      return true; // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error);
      return false; // Export failed
    }
  }

  const onTabChange = (key: any) => {
    setSelectedTab(key)
  };


  const onPaginationChangeActive: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    gListVocherActive(pageNumber, pageSize)
  }

  const onPaginationChangeFinished: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    gListVocherFinished(pageNumber, pageSize)
  }
  const onPaginationChangeComingSoon: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    gListVocherComingSoon(pageNumber, pageSize)
  }

  const openModalDelete = (id: number) => {
    setIdVoucher(id)
    setIsModalDelete(true)
  }

  const closeModalDelete = () => {
    setIdVoucher(undefined)
    setIsModalDelete(false)
  }

  const onConfirmDelete = async () => {
    const result = await voucherApi.deleteVoucher(Number(idVoucher))
    if (result?.status) {
      pushToast(result.message, "success")
      closeModalDelete()
      switch (selectedTab) {
        case "1":
          gListVocherActive()
          break;
        case "0":
          gListVocherFinished()
          break;
        case "2":
          gListVocherComingSoon()
          break;
        default:
          break;
      }
    } else {
      pushToast(result?.message, "warning")
    }
  }



  useEffect(() => {
    gListVocherActive()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter.active])

  useEffect(() => {
    gListVocherFinished()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter.finished])

  useEffect(() => {
    gListVocherComingSoon()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter.comingSoon])



  return (
    <Layout contentClassName="voucher-management-content">
      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: 'Marketing', },
          { title: 'Quản lý Voucher', },
        ]}
        title='Quản lý Voucher'
      />

      <ModalDeleteManager
        isOpen={isModalnDelete}
        closeModal={closeModalDelete}
        onConfirm={onConfirmDelete}
        title='Bạn có chắc chắn xóa voucher này'
        titleDelete='Xóa voucher'
        content='Voucher và dữ liệu liên quan sẽ bị xóa vĩnh viễn không thể khôi phục'
      />

      <Tabs
        defaultActiveKey="tab-1"
        items={tabItems}
        className='rs-tabs'
        activeKey={selectedTab}
        onChange={onTabChange}
      />

      <VoucherFilterBlock
        selectedTab={selectedTab}
        dataFilter={dataFilter}

        setDataFilter={setDataFilter}
        exportExcel={exportExcel}
      />

      {
        selectedTab === "1" && (
          <Fragment>
            <div
              className={`voucher-cards ${!listVoucherActive?.length ? 'empty' : ''}`}
            >
              {
                !listVoucherActive?.length
                  ?
                  <EmptyComponent content="">
                    <span className="neutral-gray-500">Bạn chưa tạo Voucher nào, thử&nbsp;
                      <Link
                        to="/marketing/vouchers-management/add"
                        className="primary-7"
                        style={{ textDecoration: "underline" }}
                      >tạo mới ngay</Link>
                    </span>
                  </EmptyComponent>
                  :
                  listVoucherActive && listVoucherActive?.map((item, i) =>
                    < VoucherCard
                      key={i}
                      item={item}
                      openModalDelete={openModalDelete}
                    />
                  )

              }
            </div>

            <ConfigProvider locale={viVN}>
              <div className="pagination-block">
                <Pagination
                  showSizeChanger
                  current={paginationActive.current}
                  pageSize={paginationActive.pageSize}
                  total={paginationActive.total}
                  showTotal={(total) => `Tổng cộng ${dataPagination(paginationActive.total, paginationActive.pageSize, paginationActive.current).totalPage} trang`}
                  className='rs-pagination'
                  onChange={onPaginationChangeActive}

                />
              </div>
            </ConfigProvider>
          </Fragment>
        )
      }

      {
        selectedTab === "0" && (
          <Fragment>
            <div
              className={`voucher-cards ${!listVoucherFinished?.length ? 'empty' : ''}`}
            >
              {
                !listVoucherFinished?.length
                  ?
                  <EmptyComponent content="">
                    <span className="neutral-gray-500">Bạn chưa tạo Voucher nào, thử&nbsp;
                      <Link
                        to="/marketing/vouchers-management/add"
                        className="primary-7"
                        style={{ textDecoration: "underline" }}
                      >tạo mới ngay</Link>
                    </span>
                  </EmptyComponent>
                  :
                  listVoucherFinished && listVoucherFinished?.map((item, i) =>
                    < VoucherCard
                      key={i}
                      item={item}
                      openModalDelete={openModalDelete}
                    />
                  )

              }
            </div>

            <ConfigProvider locale={viVN}>
              <div className="pagination-block">
                <Pagination
                  showSizeChanger
                  current={paginationFinished.current}
                  pageSize={paginationFinished.pageSize}
                  total={paginationFinished.total}
                  showTotal={(total) => `Tổng cộng ${dataPagination(paginationFinished.total, paginationFinished.pageSize, paginationFinished.current).totalPage} trang`}
                  className='rs-pagination'
                  onChange={onPaginationChangeFinished}

                />
              </div>
            </ConfigProvider>
          </Fragment>
        )
      }

      {
        selectedTab === "2" && (
          <Fragment>
            <div
              className={`voucher-cards ${!listVoucherComingSoon?.length ? 'empty' : ''}`}
            >
              {
                !listVoucherComingSoon?.length
                  ?
                  <EmptyComponent content="">
                    <span className="neutral-gray-500">Bạn chưa tạo Voucher nào, thử&nbsp;
                      <Link
                        to="/marketing/vouchers-management/add"
                        className="primary-7"
                        style={{ textDecoration: "underline" }}
                      >tạo mới ngay</Link>
                    </span>
                  </EmptyComponent>
                  :
                  listVoucherComingSoon && listVoucherComingSoon?.map((item, i) =>
                    < VoucherCard
                      key={i}
                      item={item}
                      openModalDelete={openModalDelete}
                    />
                  )

              }
            </div>

            <ConfigProvider locale={viVN}>
              <div className="pagination-block">
                <Pagination
                  showSizeChanger
                  current={paginationComingSoon.current}
                  pageSize={paginationComingSoon.pageSize}
                  total={paginationComingSoon.total}
                  showTotal={(total) => `Tổng cộng ${dataPagination(paginationComingSoon.total, paginationComingSoon.pageSize, paginationComingSoon.current).totalPage} trang`}
                  className='rs-pagination'
                  onChange={onPaginationChangeComingSoon}

                />
              </div>
            </ConfigProvider>
          </Fragment>
        )
      }
    </Layout>
  );
}

export default VouchersManagement;
