import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorAlert, shiftAlert } from '../store/slices/toastSlice';
import { notification } from 'antd';
const ToastGlobal = () => {
    const dispatch = useDispatch();
    const { toasts } = useSelector(selectorAlert);
    useEffect(() => {
        if (toasts.length) {
            showNextToast();
        }
    }, [toasts])

    const showNextToast = () => {
        if (toasts.length > 0) {
            dispatch(shiftAlert());
            notification.open({
                message: `${toasts[0].message}`,
                type: toasts[0].icon,
                duration: 4.5,
                placement: 'bottomRight'
            });
        }
    }
    return (
        <></>
    )
}


export default ToastGlobal;