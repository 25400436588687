import React, { useEffect, useState } from 'react';
import Layout from '../../Layout';
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock';
import { Link } from 'react-router-dom';
import { Button, ConfigProvider, Pagination, PaginationProps, Table, TableColumnsType } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import './NotificationFeesSetting.scss';
import { dataPagination } from '../../until';
import viVN from 'antd/locale/vi_VN';
import useToast from '../../hook/useToast';
import useDebounce from '../../hook/useDebounce';
import * as networkApi from '../../api/network/networkApi';
import { URL_NETWORK } from '../../env';
import ModalAddNetwork from './Modal/ModalAddNetwork';
import useThrottle from '../../hook/useThrottle';
import ModalUpdateNetwork from './Modal/ModalUpdateNetWork';

// TABLE
interface DataType {
  key: React.Key;
  nhaMang: string;
  nhaMangImg: string;
  dauSo: string;
  soLuongKyTu: number;
  chiPhi: number;
}

const currencyFormat = (number: any) => {
  try {
    number = parseInt(number);
    const formattedNumber = new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    }).format(number);
    return formattedNumber;
  } catch (error) {
    return number;
  }
};



const NotificationFeesSetting: React.FC = () => {

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Hành động',
      dataIndex: '',
      key: 'action',
      render: (_, record) =>
        <EditOutlined className="fsz-16 cursor-pointer" onClick={() => gInfoNetwork(record.key)} />,
    },
    {
      title: 'Nhà mạng',
      dataIndex: 'nhaMang',
      key: 'nhaMang',
      render: (text, record, index) => (
        <div className="d-flex align-center network">
          <img src={record.nhaMangImg} alt="" />
          <span className="neutral-gray-500">{text}</span>
        </div>
      ),
    },
    {
      title: 'Đầu số',
      dataIndex: 'dauSo',
      key: 'dauSo',
      render: (value) => (
        <span className="neutral-gray-500">{value}</span>
      )
    },
    {
      title: 'Số lượng ký tự tính là 1 tin',
      dataIndex: 'soLuongKyTu',
      key: 'soLuongKyTu',
      align: 'right',
      render: (value) => (
        <span className="neutral-gray-500">{value} ký tự</span>
      )
    },
    {
      title: 'Chi phí cho 1 tin',
      dataIndex: 'chiPhi',
      key: 'chiPhi',
      align: 'right',
      render: (value) => (
        <span className="neutral-gray-500">{currencyFormat(value)}</span>
      )
    },
  ]


  useEffect(() => {
    gListNetwork()

    return () => {
      setIsModalAddNetwork(false)
      setIsModalEditNetwork(false)

      // setIdNetwork(undefined)
      setInfoNetwork(undefined)

      setPagination({ current: 1, pageSize: 10, total: 0 })

      setListNetwork([])


    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pushToast = useToast();

  const [isModalAddNetwork, setIsModalAddNetwork] = useState(false)
  const [isModalEditNetwork, setIsModalEditNetwork] = useState(false)

  // const [idNetwork, setIdNetwork] = useState<number>()

  const [infoNetwork, setInfoNetwork] = useState<networkApi.INetwork>()

  const [listNetwork, setListNetwork] = useState<DataType[]>([])

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });

  const gInfoNetwork = useThrottle(async (id: number) => {
    const result = await networkApi.getInfoNetwork(id)
    if (result?.status) {
      setInfoNetwork(result.data)
      setIsModalEditNetwork(true)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const gListNetwork = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await networkApi.getListNetwork(pageSize, pageCurent)
    if (result?.status) {
      setListNetwork(convertData(result.data, pageCurent, pageSize))
      setPagination({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const convertData = (data: networkApi.INetwork[], pageCurent: number, pageSize: number) => {
    // var stt = (pageCurent - 1) * pageSize + 1;
    const convertData = data.map((item) => ({
      key: item.id,
      nhaMang: item.title,
      nhaMangImg: URL_NETWORK + item.image,
      dauSo: item.numberPrefixs,
      soLuongKyTu: item.numberharacters,
      chiPhi: item.expense,
    }))
    return convertData
  }

  const onPaginationChange: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    gListNetwork(pageNumber, pageSize)
  }


  const onSubmitFormModalAdd = (iSsuccess: boolean, message?: string) => {
    if (iSsuccess) {
      setIsModalAddNetwork(false);
      pushToast(message, "success")
      gListNetwork()
    }
    else {
      pushToast(message, "warning")
    }
  }

  const onSubmitFormModalEdit = (iSsuccess: boolean, message?: string) => {
    if (iSsuccess) {
      setIsModalEditNetwork(false);
      pushToast(message, "success")
      gListNetwork()
    }
    else {
      pushToast(message, "warning")
    }
  }

  return (
    <Layout
      contentClassName="notification-fees-setting-content"
    >
      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: 'Marketing', },
          { title: 'Cài đặt phí gửi thông báo', },
        ]}
        title='Cài đặt phí gửi thông báo'
      />

      <ModalAddNetwork
        isOpen={isModalAddNetwork}
        onSubmitFormModal={onSubmitFormModalAdd}
        closeModal={() => setIsModalAddNetwork(false)}
      />

      <ModalUpdateNetwork
        isOpen={isModalEditNetwork}
        infoNetwork={infoNetwork}
        onSubmitFormModal={onSubmitFormModalEdit}
        closeModal={() => setIsModalEditNetwork(false)}
      />

      <div className="table-top">
        <span className="font-medium fsz-16 lh-24">Phí gửi SMS theo nhà mạng</span>
        <Button
          type="primary"
          className="rs-button primary-outline"
          onClick={() => setIsModalAddNetwork(true)}
        >Thêm nhà mạng</Button>
      </div>

      <Table
        columns={columns}
        dataSource={listNetwork}
        showSorterTooltip={false}
        className="rs-table notification-fees-table"
        // loading={loading}
        pagination={false}
      />

      <ConfigProvider locale={viVN}>
        <div className="pagination-block">
          <Pagination
            showSizeChanger
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            showTotal={(total) => `Tổng cộng ${dataPagination(pagination.total, pagination.pageSize, pagination.current).totalPage} trang`}
            className='rs-pagination'
            onChange={onPaginationChange}

          />
        </div>
      </ConfigProvider>
    </Layout>
  );
}

export default NotificationFeesSetting;
