import React, { useEffect, useRef, useState } from 'react';
import './ManualCampaignPopup.scss'
import { Alert, Button, Checkbox, DatePicker, DatePickerProps, Drawer, Form, FormInstance, FormProps, GetProp, Input, Radio, Select, TimePicker, Tree, notification } from 'antd';
import { MinusOutlined, PlusOutlined, TagOutlined } from '@ant-design/icons';
import './ManualCampaignPopup.scss'
import SelectCustomer from '../SelectCustomer';
import SelectCustomerGroupWithStatus, { UserValue } from '../../../components/Select/SelectCustomerGroupWithStatus';
import *  as selectOptionsApi from '../../../api/selectOptions/selectOptionsApi';
import useToast from '../../../hook/useToast';
import { DataSendingForm } from '../../../services/data';
import * as networkApi from '../../../api/network/networkApi';
import { useForm } from 'antd/lib/form/Form';
import useThrottle from '../../../hook/useThrottle';
import { ICampaignCosts, addCampaign, getInfoCampaignCosts } from '../../../api/campaign/campaignApi';
import dayjs from "dayjs";
import useInfoUser from '../../../hook/useInfoUser';
import Item from 'antd/es/list/Item';
import useDebounce from '../../../hook/useDebounce';
import { formatPrice } from '../../../until';
import { URL_NETWORK } from '../../../env';
import moment, { Moment } from 'moment';

type FieldType = {
  title?: string;
  target?: string;
  customerGroup?: string,
  customer?: string,
  channel?: any;
  sendDate?: string;
  sendTime?: string;
};
interface Props {
  isShow: boolean
  onSubmitFormModalAdd?: (iSsuccess: boolean, message?: string) => void;
  setModal?: (value: boolean) => void
}

const ManualCampaignPopup: React.FC<Props> = ({
  isShow,
  onSubmitFormModalAdd, setModal
}) => {

  const pushToast = useToast();
  const { getInfoUser } = useInfoUser()

  const formRef = useRef<FormInstance<FieldType>>(null);
  const [form] = useForm();
  const [api, contextHolder] = notification.useNotification();

  const [showFeeDetail, setShowFeeDetail] = useState(false)

  const [channelSelected, setChannelSelected] = useState<any[]>([])
  const [channels] = useState([
    { key: 1, value: 1, title: 'SMS' },
    { key: 2, value: 2, title: 'ZNS' },
    { key: 3, value: 3, title: 'Email' },
  ])
  const [sendType, setSendType] = useState(0)
  const [sendTimeType, setSendTimeType] = useState(0)

  const [sLTarget, setSLTarget] = useState<selectOptionsApi.IRelative[]>([])
  const [sLNetwork, setSLNetwork] = useState<networkApi.INetwork[]>([])
  const [sLtemplateOptions, setSLTemplateOptions] = useState<{ [key: string]: any[] }>({});

  const [infoCampaignCosts, setInfoCampaignCosts] = useState<ICampaignCosts>();

  const [listCustomerGroupWithStatus, setListCustomerGroupWithStatus] = useState<UserValue[]>([]);
  const [listCustomer, setListCustomer] = useState<UserValue[]>([]);

  const disabledDate: DatePickerProps['disabledDate'] = (current: any | null) => {
    if (!current) {
      return false;
    }
    // Ngày hiện tại
    const today = dayjs().startOf('day');
    // Ngày hiện tại + 1
    // const minDate = today.add(1, 'day');
    return current.isBefore(today, 'day');
  };

  const gSLTarget = async () => {
    const result = await selectOptionsApi.getListTarget()
    if (result?.status) {
      setSLTarget(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const gSLNetwork = async () => {
    const result = await networkApi.getListNetwork()
    if (result?.status) {
      setSLNetwork(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const gSLTemplateOptions = async (nodeValue: number) => {
    const result = await selectOptionsApi.getListTemplate(nodeValue);
    if (result?.status) {
      setSLTemplateOptions(prevState => ({
        ...prevState,
        [nodeValue]: result.data?.map(item => ({
          value: item.id,
          label: item.title
        }))
      }));
    } else {
      pushToast(result?.message, "warning");
    }
  };

  const gInfoCampaignCosts = useDebounce(async () => {
    const channelList = channelSelected.map(item => ({
      channelId: item.value,
      notificationTemplateId: item.notificationTemplateId
    }))

    const customerId = listCustomer?.map((item: any) => Number(item.value))
    const groupCustomerId = listCustomerGroupWithStatus?.map((item: any) => Number(item.value))

    const result = await getInfoCampaignCosts(customerId, groupCustomerId, channelList)
    if (result?.status) {
      setInfoCampaignCosts(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 2000)

  const onSubmitForm = () => {
    if (formRef.current) {
      formRef.current.submit()
    }
  };

  // FORM SUBMIT SUCCESSFULLY



  const onFinish: FormProps<FieldType>["onFinish"] = useThrottle((values: any) => {
    console.log(values);

    const { title, channel, customer, customerGroup, sendDate, sendTime, target } = values

    const channelList = channelSelected.map(item => ({
      channelId: item.value,
      notificationTemplateId: item.notificationTemplateId
    }))

    const customerId = customer?.map((item: any) => item.value)
    const groupCustomerId = customerGroup?.map((item: any) => item.value)
    let formattedDate = ""
    let formattedTime = ""
    if (sendDate && sendTime) {
      formattedDate = dayjs(sendDate.toISOString()).format('YYYY-MM-DD');
      formattedTime = dayjs(sendTime.toISOString()).format('HH:mm');
    }

    onAddSubmitFormModal(
      title, getInfoUser().id, target, channelList, sendType, sendTimeType, 0,
      customerId, groupCustomerId, formattedDate, formattedTime
    )
  }, 2000)

  const onAddSubmitFormModal = async (
    title: string, adminId: number, targetId: number, channelList: any[], type: number, role: number, auto: number,
    customerId?: number[], groupCustomerId?: number[], dateSend?: string, timeSend?: string) => {

    const result = await addCampaign(
      title, adminId, targetId, channelList, type, sendTimeType, auto,
      customerId, groupCustomerId, dateSend, timeSend
    )
    if (result?.status) {
      onSubmitFormModalAdd?.(true, result.message)
    } else {
      onSubmitFormModalAdd?.(false, result?.message)
    }
  }

  // CUSTOMER GROUPs CHANGE
  const onGroupChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    // console.log('checked = ', checkedValues);
  };


  // CHANNEL CHANGE
  const onChannelChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    const result: ({ key: number; value: number; title: string; notificationTemplateId?: number } | any)[] = []

    if (checkedValues.length) {
      const filteredItems = channelSelected.filter(item => checkedValues.includes(item.key))

      filteredItems.forEach((item) => {
        result.push(item)
      })

      const keysNotInStateArray = checkedValues.find(key => !channelSelected.some(item => item.key === key));
      result.push(channels.find(channel => channel.value === keysNotInStateArray))

      setChannelSelected(result?.filter(item => item !== undefined))
    } else {
      setChannelSelected(result)
    }

    //run api teaplate
    //const isEveryKeyExist = checkedValues.every(key => key.toString() in sLtemplateOptions);
    //if (!isEveryKeyExist) {
      //gSLTemplateOptions(+checkedValues[checkedValues.length - 1])
    //}

    //xóa biến đã chọn
    const keysInKeyButNotInChannelSelected = channelSelected.filter(channel => !checkedValues.includes(channel.key)).map(channel => channel.key);
    if (keysInKeyButNotInChannelSelected) {
      form.setFieldValue(`${keysInKeyButNotInChannelSelected}Template`, undefined)
    }
  };


  const onChannelChangeTemplate = (key: number, value: number) => {
    setChannelSelected(prevChannelSelected =>
      prevChannelSelected.map((channel) => {
        if (+channel.key === +key) {
          return { ...channel, notificationTemplateId: value };
        }
        return channel;
      })
    );
  }

  // // TREE SELECT DRAG & DROP
  const onDrop = (info: any) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1

    if (dropPosition === 0) return false

    const loop = (data: any, key: any, callback: any) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
      }
    };
    const data = [...channelSelected];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item: any, index: any, arr: any[]) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (info.dropToGap) {
      let ar: any[] = [];
      let i: any;
      loop(data, dropKey, (_item: any, index: any, arr: any[]) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        // Drop on the top of the drop node
        ar.splice(i, 0, dragObj);
      } else {
        // Drop on the bottom of the drop node
        ar.splice(i + 1, 0, dragObj);
      }
    }
    setChannelSelected(data);
  };

  useEffect(() => {
    if ((listCustomer.length || listCustomerGroupWithStatus.length) && channelSelected.length) {
      const isCondition = channelSelected.every((item) => item?.notificationTemplateId !== undefined)
      if (isCondition) {
        gInfoCampaignCosts()
      }
      else {
        setInfoCampaignCosts(undefined)
      }
    } else {
      setInfoCampaignCosts(undefined)
    }

  }, [listCustomer, listCustomerGroupWithStatus, channelSelected])

  useEffect(() => {
    if (isShow) {
      if (!sLTarget.length) {
        gSLTarget()
      }
      if (!sLNetwork.length) {
        gSLNetwork()
      }
    } else {
      setShowFeeDetail(false)
      setChannelSelected([])
      setSendType(0)
      setSendTimeType(0)

      setInfoCampaignCosts(undefined)

      setListCustomerGroupWithStatus([])
      setListCustomer([])

      form.setFieldValue(`title`, undefined)
      form.setFieldValue(`target`, undefined)
      form.setFieldValue(`customerGroup`, undefined)
      form.setFieldValue(`customer`, undefined)
      form.setFieldValue(`channel`, undefined)
      form.setFieldValue(`sendDate`, undefined)
      form.setFieldValue(`sendTime`, undefined)

      form.setFieldValue(`${1}Template`, undefined)
      form.setFieldValue(`${2}Template`, undefined)
      form.setFieldValue(`${3}Template`, undefined)
    }
  }, [isShow])

  return (
    <>
      {contextHolder}

      <Drawer
        title="Tạo chiến dịch"
        rootClassName="rs-drawer w-580"
        className="manual-campaign-popup"
        closeIcon={false}
        open={isShow}
        footer={
          <div className="drawer-footer-vertical">
            <div className="top">
              <span className="font-medium lh-24 fsz-16">Dự tính chi phí</span>
              <div className="fee-list">
                <div className="fee-list-item-wrapper">
                  <div className="fee-list-item">
                    <span
                      className="icon"
                      onClick={() => setShowFeeDetail(!showFeeDetail)}
                    >
                      {
                        showFeeDetail
                          ? <MinusOutlined />
                          : <PlusOutlined />
                      }
                    </span>
                    <span className="title">SMS</span>
                    <span className="number">{formatPrice(infoCampaignCosts?.totalSMSExpense || 0)}đ</span>
                  </div>
                  <div
                    className="fee-list-detail"
                  >
                    {
                      showFeeDetail && infoCampaignCosts?.SMS && infoCampaignCosts.SMS.map((item, i) =>
                        <div className="item">
                          <img src={`${URL_NETWORK}${item.networkImage}`} alt={item.networkImage} />
                          <span className="title">{`Phí SMS ${item.networkTitle} cho ${formatPrice(item.totalCustomers || 0)} khách hàng`}</span>
                          <span className="number">{formatPrice(item.expense || 0)}đ</span>
                        </div>
                      )
                    }
                  </div>
                  {/* {
                    showFeeDetail &&
                    <div className="fee-list-detail">
                      <div className="item">
                        <img src="/images/network/viettel.svg" alt="viettel" />
                        <span className="title">Phí SMS Viettel cho 12.400 khách hàng</span>
                        <span className="number">730.000đ</span>
                      </div>
                      <div className="item">
                        <img src="/images/network/vinaphone.svg" alt="vinaphone" />
                        <span className="title">Phí SMS Vinaphone cho 11.600 khách hàng</span>
                        <span className="number">730.000đ</span>
                      </div>
                    </div>
                  } */}
                </div>

                <div className="fee-list-item-wrapper">
                  <div className="fee-list-item">
                    <span className="icon empty"></span>
                    <span className="title">ZNS</span>
                    <span className="number">{formatPrice(infoCampaignCosts?.totalZNSExpense || 0)}đ</span>
                  </div>
                </div>

                <div className="fee-list-item-wrapper">
                  <div className="fee-list-item">
                    <span className="icon empty"></span>
                    <span className="title">Email</span>
                    <span className="number">{formatPrice(infoCampaignCosts?.totalEmailExpense || 0)}đ</span>
                  </div>
                </div>

                <div className="fee-list-item-wrapper total">
                  <div className="fee-list-item">
                    <span className="icon empty"></span>
                    <span className="title">Tạm tính</span>
                    <span className="number">{formatPrice(infoCampaignCosts?.totalChannelExpense || 0)}đ</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom">
              <span className="neutral-gray-400">Làm mới thông tin</span>
              <Button
                type="primary"
                className="rs-button rs-button--sm"
                icon={<PlusOutlined />}
                // loading={confirmLoading}
                onClick={onSubmitForm}
              >Tạo mới chiến dịch</Button>
            </div>
          </div>
        }
        onClose={() => setModal?.(false)}
      >
        <Form
          form={form}
          ref={formRef}
          onFinish={onFinish}
          autoComplete="off"
          className="rs-form voucher-add__content"
        >
          <div className="block">
            <span className="font-medium lh-24 fsz-16">Chiến dịch thủ công</span>
            <Form.Item<FieldType>
              name="title"
              rules={[{ required: true, message: 'Vui lòng nhập tên chương trình!' }]}
            >
              <Input
                placeholder="Tên chương trình"
                className="rs-input"
              />
            </Form.Item>
          </div>

          <div className="block">
            <span className="font-medium lh-24 fsz-16">Mục tiêu</span>
            <Form.Item
              name='target'
              // initialValue={'khuyen-mai'}
              rules={[{ required: true, message: 'Vui lòng chọn mục tiêu!' }]}
            >
              <Radio.Group
                className="rs-radio-group rs-radio-group--button"
              >
                {
                  sLTarget && sLTarget.map((item, i) =>
                    <Radio.Button
                      key={i}
                      value={item.id}
                      className="rs-radio rs-radio--button volcano"
                    >
                      <TagOutlined />
                      <span>{item.title}</span>
                    </Radio.Button>
                  )
                }
              </Radio.Group>
            </Form.Item>
          </div>

          <div className="block">
            <span className="font-medium lh-24 fsz-16">Chọn nhóm khách hàng</span>
            <Form.Item
              name='customerGroup'
              rules={[{ required: listCustomer.length ? false : true, message: 'Vui lòng tìm kiếm và chọn nhóm khách hàng!' }]}
            >
              <SelectCustomerGroupWithStatus
                placeholder='Tìm kiếm và chọn nhóm khách hàng'
                onChange={setListCustomerGroupWithStatus}
              />
            </Form.Item>
          </div>
          <div className="block">
            <span className="font-medium lh-24 fsz-16">Chọn khách hàng</span>
            <Form.Item
              name='customer'
              rules={[{ required: listCustomerGroupWithStatus.length ? false : true, message: 'Vui lòng tìm kiếm và chọn khách hàng!' }]}
            >
              <SelectCustomer
                placeholder='Tìm kiếm và chọn khách hàng'
                onChange={setListCustomer}
              />
            </Form.Item>
          </div>
          <div className="block-flex">
            <span className="font-medium lh-24 fsz-16" style={{ whiteSpace: 'nowrap' }}>Kênh gửi</span>
            <Form.Item
              name='channel'
              rules={[{ required: true, message: 'Vui lòng chọn kênh gửi!' }]}
            >
              <Checkbox.Group
                rootClassName="rs-checkbox-group"
                style={{ justifyContent: 'flex-end' }}
                onChange={onChannelChange}
              >
                {
                  channels && channels.map((item, index) => (
                    <Checkbox
                      key={index}
                      value={item.value}
                      className="rs-checkbox"
                    >{item.title}</Checkbox>
                  ))
                }
              </Checkbox.Group>
            </Form.Item>
          </div>

          <div className="block-flex">
            <span className="font-medium lh-24 fsz-16">Hình thức</span>
            <Select
              defaultValue={DataSendingForm[sendType].label}

              className="rs-select"
              popupClassName="rs-select-dropdown"
              options={DataSendingForm}
              onSelect={(val) => setSendType(+val)}
            />
          </div>

          {
            sendType === 0 &&
            <Alert
              message="Khách đã nhận thông báo ở kênh này thì không gửi ở kênh khác"
              type="info"
              showIcon
              closable
              className="rs-alert"
            />
          }
          {
            sendType === 1 &&
            <Alert
              message="Một khách có thể nhận nhiều nội dung ở nhiều kênh"
              type="info"
              showIcon
              closable
              className="rs-alert"
            />
          }

          <div className="block">
            <span className="font-medium lh-24 fsz-16">Chọn mẫu nội dung cho kênh gửi</span>
            <Tree
              className="simple-draggable"
              draggable={channelSelected.length > 1 && sendType !== 1}
              blockNode
              selectable={false}
              onDrop={onDrop}
              treeData={channelSelected}
              titleRender={(nodeData: any) => (
                <div className="simple-draggable-item">
                  <span className="title">{nodeData.title}</span>
                  <Form.Item
                    name={`${nodeData.key}Template`}
                    rules={[{ required: true, message: 'Vui lòng chọn mẫu nội dung!' }]}
                  >
                    <Select
                      className="rs-select"
                      popupClassName="rs-select-dropdown"
                      placeholder="Chọn mẫu nội dung"
                      value={nodeData?.notificationTemplateId}
                      options={sLtemplateOptions[nodeData.value] || []}
                      onChange={(e) => onChannelChangeTemplate(nodeData.value, e)}
                    />
                  </Form.Item>
                </div>
              )}
            />
          </div>

          <div className="block">
            <div className="block-flex">
              <span className="font-medium lh-24 fsz-16">Thời gian gửi</span>
              <Radio.Group
                defaultValue={sendTimeType}
                className="rs-radio-group"
                onChange={(e) => setSendTimeType(e.target.value)}
              >
                <Radio.Button value={0} className="rs-radio">Tức thì</Radio.Button>
                <Radio.Button value={1} className="rs-radio">Lên lịch</Radio.Button>
              </Radio.Group>
            </div>

            {
              sendTimeType === 1 &&
              <div className="grid-2-cols">
                <Form.Item
                  name='sendDate'
                  rules={[{ required: true, message: 'Vui lòng chọn ngày gửi!' }]}
                >
                  <DatePicker
                    placeholder="Chọn ngày"
                    className="rs-date-picker"
                    popupClassName="rs-date-picker-dropdown"
                    disabledDate={disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  name='sendTime'
                  rules={[{ required: true, message: 'Vui lòng chọn giờ gửi!' }]}
                >
                  <TimePicker
                    placeholder="Chọn giờ"
                    className="rs-date-picker"
                    popupClassName="rs-date-picker-dropdown"
                    minuteStep={30}
                    format={'HH:mm'}
                  />
                </Form.Item>
              </div>
            }
          </div>
        </Form>
      </Drawer >
    </>
  )
}

export default ManualCampaignPopup
