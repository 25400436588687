import React, { useState } from 'react'
import { Button, Flex, Input, notification, Switch, Table, TableColumnsType, TableProps } from 'antd'
import Layout from '../../../Layout'
import BreadcrumbBlock from '../../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { SearchProps } from 'antd/es/input'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import AddCareerModal from './AddCareerModal'
import EditCareerModal from './EditCareerModal'
import ModalDeleteManager from '../../../components/ModalDeleteManager'
const { Search } = Input

interface TableDataType {
  key: React.Key
  name: string
  status: number
}
type TableRowSelection<T extends object = object> = TableProps<T>['rowSelection']

const tableData = [
  { key: '1', name: 'Lập trình viên', status: 1 },
  { key: '2', name: 'CEO', status: 1 },
  { key: '3', name: 'CTO', status: 1 },
]

const CustomerCareer:React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  // MODAL
  const [selectedId, setSelectedId] = useState<any>(null)
  const [showAddCareerModal, setShowAddCareerModal] = useState(false)
  const [showEditCareerModal, setShowEditCareerModal] = useState(false)
  const [showDeleteCareerModal, setShowDeleteCareerModal] = useState(false)

  // TABLE COLUMNS
  const columns: TableColumnsType<TableDataType> = [
    {
      title: 'Hành động',
      dataIndex: '',
      key: 'action',
      render: (_, record) => (
        <Flex align='center' className='gap-10px'>
          <Button
            icon={<EditOutlined />}
            className="rs-button rs-button--icon-only edit"
            onClick={() => {
              setSelectedId(record.key)
              setShowEditCareerModal(true)
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            className="rs-button rs-button--icon-only delete"
            onClick={() => {
              setSelectedId(record.key)
              setShowDeleteCareerModal(true)
            }}
          />
        </Flex>
      ),
      width: 93,
    },
    {
      title: 'Tên nghề nghiệp',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Sử dụng',
      dataIndex: 'status',
      key: 'status',
      align: 'right',
      render: (status, record) => (
        <Switch
          defaultChecked={status === 1}
          // checked={record.status === 1}
          className="rs-switch"
          // onChange={(status) => updateStatusCondition(record.key, status)}
        />
      ),
    },
  ]

  // TABLE ROW SELECTION
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection: TableRowSelection<TableDataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  // ON SEARCH
  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    // console.log(info?.source, value);
  }

  // DELETE CAREER
  const [loading, setLoading] = useState(false)
  const onDeleteCareer = () => {
    setLoading(true)

    setTimeout(() => {
      setLoading(false)
      setSelectedId(null)
      setShowDeleteCareerModal(false)
      openNotification('success', 'Xóa thành công')
    }, 1500)
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'error' = 'success', message: string = '') => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    })
  }

  return (
    <>
      { contextHolder }

      <Layout className='customer-hobby'>
        <BreadcrumbBlock
          items={[
            { title: <Link to="/">THACO AUTO CRM</Link>, },
            { title: 'Khách hàng', },
            { title: 'Quản lý nghề nghiệp', },
          ]}
          title='Quản lý nghề nghiệp'
          className='gutter-block-end'
        />

        <Flex vertical className='gap-18px'>
          <Flex align='center' className='gap-12px'>
            <Search
              placeholder="Tìm kiếm nghề nghiệp"
              allowClear
              className="rs-input-search"
              onSearch={onSearch}
            />
            <Button
              type='primary'
              className='rs-button rs-button--sm'
              onClick={() => setShowAddCareerModal(true)}
            >Thêm nghề nghiệp</Button>
          </Flex>

          <Table
            columns={columns}
            dataSource={tableData}
            showSorterTooltip={false}
            className="rs-table"
            rowSelection={rowSelection}
            pagination={{
              position: ['bottomCenter'],
              defaultPageSize: 15,
              className: 'rs-pagination',
              showQuickJumper: true,
            }}
          />
        </Flex>
      </Layout>

      {/* MODAL ADD CAREER */}
      <AddCareerModal
        show={showAddCareerModal}
        modalClose={() => setShowAddCareerModal(false)}
        onSuccess={() => setShowAddCareerModal(false)}
      />

      {/* MODAL EDIT CAREER */}
      <EditCareerModal
        id={Number(selectedId)}
        show={showEditCareerModal}
        modalClose={() => setShowEditCareerModal(false)}
        onSuccess={() => {
          setSelectedId(null)
          setShowEditCareerModal(false)
        }}
      />

      {/* MODAL DELETE CAREER */}
      <ModalDeleteManager
        isOpen={showDeleteCareerModal}
        loading={loading}
        title='Xóa nghề nghiệp'
        titleDelete='Xóa'
        content='Nghề nghiệp và dữ liệu liên quan sẽ bị xóa vĩnh viễn không thể khôi phục'
        closeModal={() => setShowDeleteCareerModal(false)}
        onConfirm={onDeleteCareer}
      />
    </>
  )
}

export default CustomerCareer
