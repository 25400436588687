import React, { useEffect, useState } from 'react';
import Layout from '../../Layout';
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock';
import { Link } from 'react-router-dom';
import { Button, Select, Tabs, TabsProps } from 'antd';
import { BellOutlined, DownloadOutlined, EllipsisOutlined, FieldTimeOutlined, PlusCircleOutlined } from '@ant-design/icons';
import fakeData from './fake-data';
import ManualCampaignTable, { DataTypeCampaignManual } from './CampaignSendsNotificationsComponents/ManualCampaignTable';
import AutomatedCampaignTable from './CampaignSendsNotificationsComponents/AutomatedCampaignTable';
import './CampaignSendsNotifications.scss'
import ManualCampaignPopup from './CampaignSendsNotificationsComponents/ManualCampaignPopup';
import AutomatedCampaignPopup from './CampaignSendsNotificationsComponents/AutomatedCampaignPopup';
import ManualCampaignDetailPopup from './CampaignSendsNotificationsComponents/ManualCampaignDetailPopup';
import AutomatedCampaignDetailPopup from './CampaignSendsNotificationsComponents/AutomatedCampaignDetailPopup';
import useToast from '../../hook/useToast';
import { DataChannel, StatusSendCampaign } from '../../services/data';
import * as campaignApi from '../../api/campaign/campaignApi';
import useDebounce from '../../hook/useDebounce';
import { LIMIT_EXPORT_EXCEL, URL_CHANNEL, URL_NETWORK } from '../../env';
import * as XLSX from 'xlsx';
import useThrottle from '../../hook/useThrottle';
import { formatPrice } from '../../until';

// TABs
const tabItems: TabsProps['items'] = [
  {
    label: 'Thủ công',
    key: 'manual-campaign',
    icon: <BellOutlined />,
  },
  {
    label: 'Tự động',
    key: 'automated-campaign',
    icon: <FieldTimeOutlined />,
  },
];

const automatedTableData = [
  {
    key: 1,
    muc_tieu: 'Thông báo',
    ten_chien_dich: 'Gửi thông báo chào mừng thành viên',
    kenh_gui: [{ text: 'sms', img: '/images/campaign/sms.svg' }, { text: 'zns', img: '/images/campaign/zalo.svg' }, { text: 'mail', img: '/images/campaign/mail.svg' }],
    kenh_gui_img: '/images/campaign/mail.svg',
    dieu_kien: 'Tham gia thành viên',
    khach_hang_nhan: 'Khách hàng',
    da_gui: 100,
    da_chi_tieu: 460000
  },
  {
    key: 2,
    muc_tieu: 'Tiếp thị',
    ten_chien_dich: 'Gửi voucher quà tặng',
    kenh_gui: [{ text: 'sms', img: '/images/campaign/sms.svg' }],
    kenh_gui_img: '/images/campaign/sms.svg',
    dieu_kien: 'Mua hàng - Lần hai',
    khach_hang_nhan: 'Thành viên',
    da_gui: 100,
    da_chi_tieu: 460000
  },
  {
    key: 3,
    muc_tieu: 'Thông báo',
    ten_chien_dich: 'Gửi email xác nhận',
    kenh_gui: [{ text: 'sms', img: '/images/campaign/sms.svg' }],
    kenh_gui_img: '/images/campaign/sms.svg',
    dieu_kien: 'Lâu không Online - 01 tuần',
    khach_hang_nhan: 'Toàn bộ',
    da_gui: 100,
    da_chi_tieu: 460000
  },
  {
    key: 4,
    muc_tieu: 'Thông báo',
    ten_chien_dich: 'Gửi thông báo chúc mừng',
    kenh_gui: [{ text: 'sms', img: '/images/campaign/sms.svg' }],
    kenh_gui_img: '/images/campaign/sms.svg',
    dieu_kien: 'Sinh nhật',
    khach_hang_nhan: 'Toàn bộ',
    da_gui: 100,
    da_chi_tieu: 0
  },
]

const CampaignSendsNotifications: React.FC = () => {

  useEffect(() => {
    document.title = 'THACO AUTO CRM';
    return () => {
      setDataFilter(undefined)

      setPagination({ current: 1, pageSize: 12, total: 0 })

      setListCampaignManual([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const pushToast = useToast();

  const [dataFilter, setDataFilter] = useState<number>()

  const [pagination, setPagination] = useState({ current: 1, pageSize: 12, total: 0 });

  const [infoCampaign, setInfoCampaign] = useState<campaignApi.IICampaignDetail>()

  const [listCampaignManual, setListCampaignManual] = useState<DataTypeCampaignManual[]>([])


  const gListCampaignManual = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await campaignApi.getListCampaign(pageSize, pageCurent, dataFilter, 0)
    if (result?.status) {
      setListCampaignManual(convertData(result.data, pageSize, pageCurent))
      setPagination({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const convertData = (data: campaignApi.ICampaign[], pageSize: number, pageCurent: number) => {
    // var stt = (pageCurent - 1) * pageSize + 1;
    const convertData = data.map((item) => ({
      key: item.id,
      id: item.id,
      muc_tieu: item.targetTitle,
      ten_chien_dich: item.title,
      trang_thai: item.status,
      view: item.view,
      kenh_gui: item.network.map(item1 => ({
        text: item1.title,
        img: URL_CHANNEL + item1.image
      })),
      total: item.numberSend,
      thoi_gian_gui: item.dateSend,
      da_chi_tieu: item.totalDeposit,
    }))
    return convertData
  }

  const gInfoCampaign = async (id: number) => {
    const result = await campaignApi.getInfoCampaign(id)
    if (result?.status) {
      setInfoCampaign(result.data)
      setManualCampDetailPopupShow(true)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const glistCampaignManualByExcel = useThrottle(async () => {
    const result = await await campaignApi.getListCampaign(
      LIMIT_EXPORT_EXCEL, 1, dataFilter, 0
    )
    if (result?.status) {
      formattedDataExcel(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 2000)

  const formattedDataExcel = (data: campaignApi.ICampaign[]) => {
    const formattedData = data.map((item) => {
      return {
        "Mục tiêu": item.targetTitle,
        "Tên chiến dịch": item.title,
        "Kênh gửi": item.network.map((item1) => item1.title),
        "Đã gửi": item.numberSend,
        "Thời gian gửi": item.dateSend,
        "Đã chi tiêu": formatPrice(item.totalDeposit) + "đ",
        "Trạng thái": StatusSendCampaign[item.status].label,
        "Hoạt động": item.view ? "Đang hoạt động" : "Không hoạt động"
      }
    }
    );
    exportExcel(formattedData)
  }

  function exportExcel(convertData: any): boolean {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData);
      const workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách');

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = window.URL.createObjectURL(data);
        link.download = fileName;
        link.click();
      };

      saveAsExcel(excelBuffer, `${"Danh sách chiến dịch"}.xlsx`);
      return true; // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error);
      return false; // Export failed
    }
  }

  // TAB
  const [selectedTab, setSelectedTab] = useState('manual-campaign')
  const onTabChange = (key: string) => {
    setSelectedTab(key)
  };

  const [manualCampPopupShow, setManualCampPopupShow] = useState(false)
  const [manualCampDetailPopupShow, setManualCampDetailPopupShow] = useState(false)
  const [autoCampPopupShow, setAutoCampPopupShow] = useState(false)
  const [autoCampDetailPopupShow, setAutoCampDetailPopupShow] = useState(false)


  const onSubmitFormModalAdd = (iSsuccess: boolean, message?: string) => {
    if (iSsuccess) {
      pushToast(message, "success")
      setManualCampPopupShow(false)
    }
    else {
      pushToast(message, "warning")
    }
  }


  useEffect(() => {
    gListCampaignManual()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter])
  return (
    <Layout
      contentClassName="campaign-sends-notification-content"
    >
      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: 'Marketing', },
          { title: 'Gửi thông báo', },
        ]}
        title='Chiến dịch'
        description='Quản lý chiến dịch gửi thông tin (Notification/SMS/Zalo ZNS/Email)'
      />

      <Tabs
        defaultActiveKey={selectedTab}
        items={tabItems}
        className='rs-tabs'
        onChange={onTabChange}
      />

      <div className="vip-care-report-table">
        {
          selectedTab === 'manual-campaign'
          &&
          <>
            <div
              className="top"
              style={{ alignItems: 'center' }}
            >
              <span className="fsz-16 font-medium lh-24">Danh sách chiến dịch</span>

              <div className="control">

                <Select
                  placeholder="Kênh gửi"
                  className="rs-select"
                  popupClassName="rs-select-dropdown"
                  virtual={false}
                  options={DataChannel}
                  onChange={(e) => setDataFilter(e)}
                />

                <Button className="rs-button"
                  onClick={glistCampaignManualByExcel}
                >
                  <DownloadOutlined />
                  <span>Xuất DS</span>
                </Button>

                <Button
                  type="primary"
                  className="rs-button"
                  onClick={() => setManualCampPopupShow(true)}
                >
                  <PlusCircleOutlined />
                  <span>Chiến dịch mới</span>
                </Button>

                <div className="ctrl-select-kenh">
                  <Button
                    className="rs-button"
                    icon={<EllipsisOutlined />}
                  />
                  <div className="ctrl-pa">
                    <div className="list-kenh">
                      <div className="icon-top-right">
                        <svg width="1em" height="1em" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 6L6 0L0 6H12Z" fill="white"/>
                        </svg>
                      </div>

                      <div className="btn-input">
                        <div className="icon-svg">
                          <img src="/customer/cm.svg" alt="" />
                        </div>
                        <div className="text">Gửi SMS</div>
                      </div>

                      <div className="btn-input">
                        <div className="icon-svg">
                          <img src="/customer/email.svg" alt="" />
                        </div>
                        <div className="text">Gửi Email</div>
                      </div>

                      <div className="btn-input">
                        <div className="icon-svg">
                          <img src="/customer/zalo.svg" alt="" />
                        </div>
                        <div className="text">Gửi ZNS</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ManualCampaignTable
              pagination={pagination}
              tableData={listCampaignManual}
              gListCampaignManual={gListCampaignManual}
              onShowDetail={gInfoCampaign}
            />
          </>
        }
        {
          selectedTab === 'automated-campaign'
          &&
          <>
            <div
              className="top"
              style={{ alignItems: 'center' }}
            >
              <span className="fsz-16 font-medium lh-24">Danh sách chiến dịch tự động</span>

              <div className="control">

                <Button className="rs-button">
                  <DownloadOutlined />
                  <span>Xuất DS</span>
                </Button>

                <Button
                  type="primary"
                  className="rs-button"
                  onClick={() => setAutoCampPopupShow(true)}
                >
                  <PlusCircleOutlined />
                  <span>Thêm mới chiến dịch</span>
                </Button>

                <Button
                  className="rs-button"
                  icon={<EllipsisOutlined />}
                />
              </div>
            </div>

            <AutomatedCampaignTable
              tableData={automatedTableData}
              isLoading={false}
              onShowDetail={() => setAutoCampDetailPopupShow(true)}
            />
          </>
        }
      </div>

      <ManualCampaignPopup
        isShow={manualCampPopupShow}
        onSubmitFormModalAdd={onSubmitFormModalAdd}
        setModal={setManualCampPopupShow}
      />

      <AutomatedCampaignPopup
        isShow={autoCampPopupShow}
        onPopupClose={() => setAutoCampPopupShow(false)}
      />

      <ManualCampaignDetailPopup
        isShow={manualCampDetailPopupShow}
        infoCampaign={infoCampaign}
        onPopupClose={() => setManualCampDetailPopupShow(false)}
      />

      <AutomatedCampaignDetailPopup
        isShow={autoCampDetailPopupShow}
        onPopupClose={() => setAutoCampDetailPopupShow(false)}
      />
    </Layout>
  );
}

export default CampaignSendsNotifications;
