import { useDispatch, useSelector } from 'react-redux';
import tokenService from '../services/token';
import { clearInfoUser, selectorInfoUser, setInfoUser } from '../store/slices/infoUserSlice';
import { IUser } from '../api/login/loginApi';
import { clearSaveAcccount, setDataAcccount } from '../store/slices/userSlice';

const useInfoUser = () => {
    const dispatch = useDispatch();
    const infoUser = useSelector(selectorInfoUser)


    const setInfoUserData = (data: IUser) => {
        dispatch(setInfoUser(data))
    };

    const getInfoUser = () => {
        return infoUser
    };

    const setAccountPasswordUser = (username: string, password: string) => {
        dispatch(setDataAcccount({ username, password }))
    }

    const clearAccountPasswordUser = () => {
        dispatch(clearSaveAcccount())
    }

    return {
        setInfoUserData,
        getInfoUser,
        setAccountPasswordUser,
        clearAccountPasswordUser
    };
};

export default useInfoUser;
