import { UploadOutlined, DownloadOutlined, GiftOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input } from 'antd';
import { SearchProps } from 'antd/es/input';
import React, { Fragment, useEffect } from 'react';
import './VoucherFilterBlock.scss'
import { Link } from 'react-router-dom';
import useDebounce from '../../../hook/useDebounce';
import useThrottle from '../../../hook/useThrottle';
import { IVoucher, getListVoucher } from '../../../api/voucher/voucherApi';
import { LIMIT_EXPORT_EXCEL } from '../../../env';
import useToast from '../../../hook/useToast';
import { statusVoucher, typeReduced, typeVoucher } from '../../../services/data';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { Search } = Input;

const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
  // console.log(info?.source, value);
}

interface Props {
  selectedTab: "0" | "1" | "2"
  dataFilter: {
    active: {
      name: string;
      date: string[];
    };
    finished: {
      name: string;
      date: string[];
    };
    comingSoon: {
      name: string;
      date: string[];
    };
  }

  setDataFilter?: (value: {
    active: {
      name: string;
      date: string[];
    }
    finished: {
      name: string;
      date: string[];
    }
    comingSoon: {
      name: string;
      date: string[];
    }
  }) => void

  exportExcel?: (data: any) => void
}

const VoucherFilterBlock: React.FC<Props> = ({
  selectedTab, dataFilter,
  setDataFilter, exportExcel
}) => {

  const pushToast = useToast();

  // const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
  const setFilterName = (value: string) => {
    switch (selectedTab) {
      case "1":
        setDataFilter?.({
          ...dataFilter,
          active: {
            ...dataFilter.active,
            name: value
          }
        });
        break;
      case "0":
        setDataFilter?.({
          ...dataFilter,
          finished: {
            ...dataFilter.finished,
            name: value
          }
        });
        break;
      case "2":
        setDataFilter?.({
          ...dataFilter,
          comingSoon: {
            ...dataFilter.comingSoon,
            name: value
          }
        });
        break;

      default:
        break;
    }
  }

  const onDateChange1 = (date: any, dateString: string[], info: any) => {
    if (dateString[0].length === 0 && dateString[1].length === 0) {
      onDateChange(date, dateString, info)
    }
    if (dateString[0].length && dateString[1].length) {
      onDateChange(date, dateString, info)
    }

  }

  const onDateChange = useThrottle((date: any, dateString: string[], info: any) => {
    const isNotEmpty = dateString.some(item => item !== "");
    switch (selectedTab) {
      case "1":
        if (isNotEmpty) {
          setDataFilter?.({
            ...dataFilter,
            active: {
              ...dataFilter.active,
              date: [...dateString]
            }
          });
        } else {
          setDataFilter?.({
            ...dataFilter,
            active: {
              ...dataFilter.active,
              date: [...["", ""]]
            }
          });
        }
        break;
      case "0":
        if (isNotEmpty) {
          setDataFilter?.({
            ...dataFilter,
            finished: {
              ...dataFilter.finished,
              date: [...dateString]
            }
          });
        } else {
          setDataFilter?.({
            ...dataFilter,
            finished: {
              ...dataFilter.finished,
              date: [...["", ""]]
            }
          });
        }
        break;
      case "2":
        if (isNotEmpty) {
          setDataFilter?.({
            ...dataFilter,
            comingSoon: {
              ...dataFilter.comingSoon,
              date: [...dateString]
            }
          });
        } else {
          setDataFilter?.({
            ...dataFilter,
            comingSoon: {
              ...dataFilter.comingSoon,
              date: [...["", ""]]
            }
          });
        }
        break;

      default:
        break;
    }
  }, 2000)

  const gListVoucherByExcel = useThrottle(async () => {
    switch (selectedTab) {
      case "0":
        const result = await getListVoucher(
          LIMIT_EXPORT_EXCEL, undefined, 0, dataFilter.active.name, dataFilter.active.date[0], dataFilter.active.date[1]
        )
        if (result?.status) {
          formattedDataExcel(result.data)
        } else {
          pushToast(result?.message, "warning")
        }
        break;
      case "1":
        const result1 = await getListVoucher(
          LIMIT_EXPORT_EXCEL, undefined, 1, dataFilter.active.name, dataFilter.active.date[0], dataFilter.active.date[1]
        )
        if (result1?.status) {
          formattedDataExcel(result1.data)
        } else {
          pushToast(result1?.message, "warning")
        }
        break;
      case "2":
        const result2 = await getListVoucher(
          LIMIT_EXPORT_EXCEL, undefined, 22, dataFilter.active.name, dataFilter.active.date[0], dataFilter.active.date[1]
        )
        if (result2?.status) {
          formattedDataExcel(result2.data)
        } else {
          pushToast(result2?.message, "warning")
        }
        break;
      default:
        break;
    }


  }, 1000)

  const formattedDataExcel = (data: IVoucher[]) => {
    const formattedData = data.map((item) => {
      return {
        "Mã": item.code,
        "Tiêu đề": item.title,
        "Mô tả": item.description,
        "Ngày bắt đầu": item.dateStart,
        "Ngày kết thúc": item.dateStart,
        "Giờ bắt đầu": item.timeStart,
        "Giờ kết thúc": item.timeEnd,
        "Số lượng": item.quantity,
        "Trạng thái": statusVoucher[item.status ?? 0].label,
        "Giá trị đơn hàng": item.orderValue,
        "Số tiền giảm": item.reducedAmount,
        "Phần trăm giảm": item.percentDecrease,
        "Số tiền giảm tối đa": item.reducedAmountMaximum,
        "Số lần áp dụng": item.usageCount,
        "Giảm theo": typeReduced[item.role].label,
        "Loại": typeVoucher[item.type].label,
      }
    }
    );
    exportExcel?.(formattedData)
  }

  return (
    <div className="voucher-filter fixw">
      {
        selectedTab === "0" && (
          <Fragment>
            <Search
              placeholder="Tìm chương trình Voucher"
              allowClear
              className="rs-input-search"
              // onSearch={onSearch}
              value={dataFilter.finished.name}
              onChange={(e) => setFilterName(e.target.value)}
            />

            <RangePicker
              className="rs-range-picker"
              popupClassName="rs-range-picker-dropdown"
              placeholder={['Chọn từ ngày', 'Đến ngày']}
              value={dataFilter.finished.date[0].length > 1 ? [dayjs(dataFilter.finished.date[0]), dayjs(dataFilter.finished.date[1])] : null}
              onCalendarChange={onDateChange}
            />

            <Button className="rs-button">
              <UploadOutlined />
              <span>Nhập DS</span>
            </Button>

            <Button
              className="rs-button"
              onClick={gListVoucherByExcel}
            >
              <DownloadOutlined />
              <span>Xuất DS</span>
            </Button>

            <Link
              className="ant-btn ant-btn-primary rs-button"
              to="/marketing/vouchers-management/add"
            >
              <GiftOutlined />
              <span>Tạo mới</span>
            </Link>
          </Fragment>
        )
      }
      {
        selectedTab === "1" && (
          <Fragment>
            <Search
              placeholder="Tìm chương trình Voucher"
              allowClear
              className="rs-input-search"
              value={dataFilter.active.name}
              onChange={(e) => setFilterName(e.target.value)}
            />

            <RangePicker
              className="rs-range-picker"
              popupClassName="rs-range-picker-dropdown"
              placeholder={['Chọn từ ngày', 'Đến ngày']}
              value={dataFilter.active.date[0].length > 1 ? [dayjs(dataFilter.active.date[0]), dayjs(dataFilter.active.date[1])] : null}
              onCalendarChange={onDateChange1}
            />

            <Button className="rs-button">
              <UploadOutlined />
              <span>Nhập DS</span>
            </Button>

            <Button className="rs-button"
              onClick={gListVoucherByExcel}>
              <DownloadOutlined />
              <span>Xuất DS</span>
            </Button>

            <Link
              className="ant-btn ant-btn-primary rs-button"
              to="/marketing/vouchers-management/add"
            >
              <GiftOutlined />
              <span>Tạo mới</span>
            </Link>
          </Fragment>
        )
      }

      {
        selectedTab === "2" && (
          <Fragment>
            <Search
              placeholder="Tìm chương trình Voucher"
              allowClear
              className="rs-input-search"
              value={dataFilter.comingSoon.name}
              onChange={(e) => setFilterName(e.target.value)}
            />

            <RangePicker
              className="rs-range-picker"
              popupClassName="rs-range-picker-dropdown"
              placeholder={['Chọn từ ngày', 'Đến ngày']}
              value={dataFilter.comingSoon.date[0].length > 1 ? [dayjs(dataFilter.comingSoon.date[0]), dayjs(dataFilter.comingSoon.date[1])] : null}
              onCalendarChange={onDateChange}
            />

            <Button className="rs-button">
              <UploadOutlined />
              <span>Nhập DS</span>
            </Button>

            <Button className="rs-button"
              onClick={gListVoucherByExcel}
            >
              <DownloadOutlined />
              <span>Xuất DS</span>
            </Button>

            <Link
              className="ant-btn ant-btn-primary rs-button"
              to="/marketing/vouchers-management/add"
            >
              <GiftOutlined />
              <span>Tạo mới</span>
            </Link>
          </Fragment>
        )
      }



    </div>
  );
}

export default VoucherFilterBlock;
