import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";

export interface DataTypeIssuedCards {
    key: number;
    icon: string;
    stt: number;
    mt: string;
    name: string;
    ht: string;
    nph: string;
    nhh: string;
    status: number;
}

export interface IIssuedCards {
    id: number
    name: string
    codeCard: string
    releaseDate: string
    expirationDate: string
    rank: RankIssuedCards
    status: number
}

export interface RankIssuedCards {
    title: string
    icon: string
}
export interface IInfoIssuedCards {
    username: string;
    code: string;
    releaseDate: string;
    expirationDate: string;
    rank: RankId;
    phone: string;
}
interface RankId {
    id: number;
    title: string;
    icon: string;
}

export interface IInfoCardCustomer {
    listBenefit: ListBenefit2[];
    infoCustomer: InfoCustomer2;
    point: number
}
export interface InfoCustomer2 {
    status: number;
    timestamp: string;
    customer: Customer2;
    rank: Rank2;
}
export interface Rank2 {
    id: number;
    icon: string;
    title: string;
}
export interface Customer2 {
    username: string;
    image: string;
}
export interface ListBenefit2 {
    id: number;
    title: string;
    description: string;
    status: number;
}

export interface IListRankEdit {
    id: number;
    title: string;
    icon: string;
}

export const getInfoCardCustomer = async (id: number) => {
    const path = "rank/infoCardCustomer"
    const params = {
        id
    }
    const result: ResponseSuccessTotal<IInfoCardCustomer> = await axiosClient.get(
        path, { params }
    );
    return result;
};

export const updateCardIssuance = async (
    id: number, rankId: number, releaseDate: string, expirationDate: string) => {
    const path = "rank/editCardIssuance"
    const params = {
        id, rankId, releaseDate, expirationDate
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        path, params
    );
    return result;
};

export const getlistRankEdit = async (id: number) => {
    const path = "rank/listRankEdit"
    const params = {
        id
    }
    const result: ResponseSuccessTotal<IListRankEdit[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

export const getInfoIssuedCards = async (id: number) => {
    const path = "rank/infoIssuedCards"
    const params = {
        id
    }
    const result: ResponseSuccessTotal<IInfoIssuedCards> = await axiosClient.get(
        path, { params }
    );
    return result;
};

export const addCardIssuance = async (id: number, rankId: number, code: string) => {
    const path = "rank/cardIssuance"
    const params = {
        id, rankId, code
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        path, params
    );
    return result;
};

export const getListIssuedCards = async (limit?: number, pageNumber?: number, type?: number, rankId?: number, search?: string
) => {
    const path = "rank/listIssuedCards"
    const params = {
        limit, pageNumber, search, type, rankId
    }
    const result: ResponseSuccessTotal<IIssuedCards[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};


export const updateActiveIssuedCards = async (
    id: number) => {
    const path = "rank/activeIssuedCards"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        path, params
    );
    return result;
};

// export const addRank = async (title: string, price: number, image: File | null, listBenefits: number[]
// ) => {
//     const path = "rank/addRank"
//     const params = {
//         title, price, image, listBenefits
//     }
//     const result: ResponseSuccess<[]> = await axiosClient.post(
//         path, params
//     );
//     return result;
// };