import { useDispatch } from 'react-redux';
import tokenService from '../services/token';
import { clearInfoUser } from '../store/slices/infoUserSlice';

const useResetData = () => {
    const dispatch = useDispatch();

    const resetLoginOut = () => {
        tokenService.removeToken()
        dispatch(clearInfoUser())
    };

    const resetLoginExpired = () => {
        tokenService.removeToken()
        dispatch(clearInfoUser())
    };
    const resetAllData = () => {
        tokenService.removeToken()
        dispatch(clearInfoUser())
    };

    return {
        resetLoginOut,
        resetLoginExpired,
        resetAllData
    };
};

export default useResetData;
