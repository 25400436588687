import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, ConfigProvider, Input, Pagination, Switch, Table, TableColumnsType } from 'antd';
import viVN from 'antd/locale/vi_VN';
import React, { useEffect, useState } from 'react';
import Layout from '../../../Layout';
import * as SelectOptionApi from '../../../api/selectOptions/selectOptionsApi';
import * as UnitApi from '../../../api/unit/unitApi';
import ModalDeleteManager from '../../../components/ModalDeleteManager';
import { findParentIdLevelOne, flattenDonViArray } from '../../../until';
import ModalCreateUnit from './Modal/ModalCreateUnit';
import ModalEditUnit from './Modal/ModalEditUnit';
import useToast from '../../../hook/useToast';

const Units: React.FC = () => {

  const [openCreateUnit, setOpenCreateUnit] = useState(false)
  const [openEditUnit, setOpenEditUnit] = useState(false)
  const [openDeleteUnit, setOpenDeleteUnit] = useState(false)
  const [search, setSearch] = useState<string>()
  const pushToast = useToast();
  const [id, setId] = useState<number>()


  const [dataUnit, setDataUnit] = useState<UnitApi.DataTypeUnit[]>([])
  const [dataConvert, setDataConvert] = useState<SelectOptionApi.dataConvert[]>([])
  const [dataListNationwide, setDataListNationwide] = useState<any>()
  const [dataListNationwideCreate, setDataListNationwideCreate] = useState<any>()
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const columns: TableColumnsType<UnitApi.DataTypeUnit> = [
    {
      title: 'Hành động',
      render: (_, record) => (
        <div className='d-flex align-center justify-center gap-10-px'>
          <DeleteOutlined
            className='text-F5222D text-sm border-EAECF0 p-5-px bg-white rounded-2'
            onClick={() => { setOpenDeleteUnit(true); setId(Number(record.key)) }}
          />
          <EditOutlined
            className='text-98A2B3 text-sm border-EAECF0 p-5-px bg-white rounded-2'
            onClick={() => { setOpenEditUnit(true); setId(Number(record.key)) }}
          />
        </div>

      ),
      className: "w-83-px",
      align: 'center'
    },

    {
      title: 'Mã đơn vị',
      dataIndex: 'codeUnit',
      className: "w-109-px"
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit',
      className: "w-555-px",
      sorter: (a, b) => a.unit.localeCompare(b.unit),
    },
    {
      title: 'Toàn quốc',
      dataIndex: 'nationwide',
      className: "w-156-px ",
      sorter: (a, b) => findParentIdLevelOne(dataConvert, a.parentId, 1).localeCompare(findParentIdLevelOne(dataConvert, b.parentId, 1)),
      render: (_, record) => (
        <div>{findParentIdLevelOne(dataConvert, record.parentId, 1)}</div>
      )
    },
    {
      title: 'Vùng miền',
      dataIndex: 'region',
      className: "w-114-px ",
      sorter: (a, b) => findParentIdLevelOne(dataConvert, a.parentId, 2).localeCompare(findParentIdLevelOne(dataConvert, b.parentId, 2)),
      render: (_, record) => (
        <div>{findParentIdLevelOne(dataConvert, record.parentId, 2)}</div>
      )
    },
    {
      title: 'Trạng thái',
      className: "w-175-px",
      render: (_, record) => <div className='d-flex align-center gap-10-px'>
        <Switch
          defaultChecked={record.view ? true : false}
          onChange={() => updateActiveUnit(Number(record.key))}
          className="rs-switch rs-switch--sm"
        />
        <p className='font-medium fw-500 text-344054'>{UnitApi.activeArray[record.view]}</p>
      </div>
    },
  ];
  const gListUnit = async (pageCurent: number = 1, pageSize: number = 10, search: string = '') => {
    const result = await UnitApi.getListUnit(pageSize, pageCurent, search);
    if (result?.status) {
      setDataUnit(convertData(result.data, pageCurent, pageSize))
      setPagination({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result.message, 'error');
    }
  }

  function convertNationwide(data: UnitApi.OptionSelect[], id: number) {
    return data.filter((value) => value.value !== id).map((item) => {
      const treeItem: UnitApi.OptionSelect = {
        value: item.value,
        label: `${item.label}`,
        title: item.title,
        children: []
      };
      if (item.children != null && item.children.length > 0) {
        treeItem.children = convertNationwide(item.children, id);
      }
      return treeItem;
    });
  }

  const gListNationwide = async () => {
    const result = await SelectOptionApi.getListNationwide();
    if (result?.status) {
      setDataConvert(flattenDonViArray(result.data))
      setDataListNationwide(UnitApi.convertNationwideValueToTree(result.data))
      setDataListNationwideCreate(UnitApi.convertNationwideValueToTree(result.data))
    } else {
      console.log('object');
    }
  }

  const convertData = (data: UnitApi.IUnit[], pageCurent: number, pageSize: number) => {
    const convertData = data.map((item) => ({
      codeUnit: item.code,
      unit: item.title,
      nationwide: '',
      region: '',
      key: item.id,
      parentId: item.parentId,
      view: item.view
    }
    ))
    return convertData
  }

  const handleChangePagination = (current: number, pageSize: number) => {
    gListUnit(current, pageSize)
  }
  // thay đổi trang thái
  const updateActiveUnit = async (id: number) => {
    const result = await UnitApi.updateActiveUnit(id);
    if (result?.status) {
      gListUnit(pagination.current, pagination.pageSize)
      pushToast(result.message, "success", 2000)
    } else {
      console.log('object');
    }
  }

  const handleDelete = async () => {
    if (id) {
      const result = await UnitApi.deleteActiveNationwide(id);
      if (result?.status) {
        gListUnit(pagination.current, pagination.pageSize)
        setOpenDeleteUnit(false)
        pushToast("Xóa đơn vị thành công!", "success", 2000)

      } else {
        console.log('object');
      }
    }
  }
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
    gListUnit()
    gListNationwide()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    if (id) {
      setDataListNationwide(convertNationwide(dataListNationwideCreate, id))
    }
  }, [id, openEditUnit]);
  return (
    <Layout>
      <ModalCreateUnit
        isOpen={openCreateUnit}
        dataListNationwide={dataListNationwideCreate}
        gListUnit={gListUnit}
        closeModal={() => setOpenCreateUnit(!openCreateUnit)}
      />
      <ModalEditUnit
        dataListNationwide={dataListNationwide}
        id={id}
        gListUnit={gListUnit}
        isOpen={openEditUnit}
        closeModal={() => setOpenEditUnit(!openEditUnit)}
      />
      <ModalDeleteManager
        isOpen={openDeleteUnit}
        onConfirm={handleDelete}
        closeModal={() => setOpenDeleteUnit(!openDeleteUnit)}
        title="Bạn có chắc chắn xóa đại lý/công ty này"
        titleDelete="Xóa đại lý/công ty"
        content="Đại lý/Công ty và dữ liệu liên quan sẽ bị xóa vĩnh viễn
 không thể khôi phục"
      />
      <Breadcrumb
        items={[
          {
            title: 'THACO AUTO CRM',
          },
          {
            title: <a href="/">Danh mục đơn vị</a>,
          },
          {
            title: 'Đơn vị',
          },
        ]}
      />
      <div className='title-page'>
        Đơn vị
      </div>
      <div className='d-flex align-center gap-10-px pb-18-px departmental-unit-filter'>
        <p className='text-base font-medium text-344054 white-space-nowrap'>Danh sách đơn vị</p>
        <Input
          placeholder="Tìm kiếm"
          onChange={(e) => setSearch(e.target.value)}
          className="bg-white border-E1E1E1 text-sm rounded-2  font-regular  input-custom  h-38"
        />
        <button className='h-38 white-space-nowrap d-flex justify-center align-center border-096DD9 text-096DD9 bg-white rounded-2 text-sm font-regular'
          onClick={() => gListUnit(pagination.current, pagination.pageSize, search)}>
          Tìm kiếm
        </button>
        <button
          className='h-38 white-space-nowrap d-flex justify-center align-center border-none text-white bg-096DD9 rounded-2 text-sm font-regular gap-10-px'
          onClick={() => setOpenCreateUnit(true)}
        >
          <PlusOutlined className='text-sm text-white' />
          Tạo mới
        </button>
      </div>
      <div className="t-table-block d-flex flex-column justify-between h-822-px">
        <Table
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
          columns={columns}
          dataSource={dataUnit}
          pagination={false}
          showSorterTooltip={false}
          className=" border-end-table  zise-filter"
        />
        <div className="pagination-block border-top-F2F4F7">
          <ConfigProvider locale={viVN}>
            <div className="pagination-block">
              <Pagination
                total={pagination.total}
                current={pagination.current}
                pageSize={pagination.pageSize}
                showSizeChanger
                // showQuickJumper
                showTotal={() => `Tổng cộng ${Math.ceil(pagination.total / pagination.pageSize) === 0 ? 1 : Math.ceil(pagination.total / pagination.pageSize)} trang`}
                // showTotal={() => `Tổng cộng ${total} trang`}

                // showTotal={(total, range) => <span className='text-[#8E8E8E]'>{`${range[0]}-${range[1]} trong ${total} dữ liệu`}</span>}
                locale={{ items_per_page: 'trang' }}
                onChange={handleChangePagination}
              />
            </div>
          </ConfigProvider>
        </div>
      </div>
    </Layout>
  );
}

export default Units;
