import { ContactsOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import type { FormProps } from 'antd';
import { DatePicker, Drawer, Form, FormInstance, Input, Select } from "antd";
import { useForm } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from "react";
import * as IssuedCardsApi from '../../../api/rank/issuedCardApi';
import useToast from "../../../hook/useToast";
import { fommatSelectOption } from "../../../until";
import './style.scss';

const { RangePicker } = DatePicker;

interface DrawerProps {
  isOpen: boolean
  closeDrawer: () => void
  dataInfoIssuedCards?: IssuedCardsApi.IInfoIssuedCards
  dataRank: IssuedCardsApi.IListRankEdit[]
  id?: number
  getListIssuedCards: () => void
}
const DrawerEditCard: React.FC<DrawerProps> = ({
  isOpen,
  dataInfoIssuedCards,
  dataRank,
  id,
  closeDrawer,
  getListIssuedCards
}) => {
  const [form] = useForm();
  const inputRef = useRef<FormInstance>(null);
  const [dateStart, setDateStart] = useState<string>('')
  const pushToast = useToast();
  const [dateEnd, setDateEnd] = useState<string>('')
  const submitForm = () => {
    if (inputRef.current) {
      inputRef.current.submit();
    }
  };
  const onFinish: FormProps<any>['onFinish'] = async (values) => {
    // values.date
    console.log(values.rank, 1111);
    if (closeDrawer && id) {
      const result = await IssuedCardsApi.updateCardIssuance(id, values.rank, dateStart, dateEnd)
      if (result.status) {
        pushToast(result.message, "success", 2000)
        closeDrawer();
        getListIssuedCards()
      } else {
        pushToast(result.message, "error", 2000)

      }

    }
  }
  const handleDateChange = (e: any) => {
    if (e && e[0].isValid && e[0].isValid()) {
      const dateStringStart = e[0].format('YYYY-MM-DD');
      setDateStart(dateStringStart);

    }
    if (e && e[1].isValid && e[1].isValid()) {
      const dateStringEnd = e[1].format('YYYY-MM-DD');
      setDateEnd(dateStringEnd);

    }
  };

  useEffect(() => {
    form.setFieldValue('username', dataInfoIssuedCards?.username)
    form.setFieldValue('phone', dataInfoIssuedCards?.phone)
    form.setFieldValue('code', dataInfoIssuedCards?.code)
    form.setFieldValue('rank', dataInfoIssuedCards?.rank.id)
    form.setFieldValue('date', [dayjs(dataInfoIssuedCards?.releaseDate), dayjs(dataInfoIssuedCards?.expirationDate)])
    if (dataInfoIssuedCards) {
      setDateStart(dataInfoIssuedCards.releaseDate)
      setDateEnd(dataInfoIssuedCards.expirationDate)
    }

  }, [dataInfoIssuedCards, dataRank])

  return (
    <Form
      form={form}
      ref={inputRef}
      onFinish={onFinish}
      initialValues={{ remember: true }}
    >
      <Drawer
        title={<p className="font-medium fw-500 text-344054 py-3 px-6 bg-FCFCFD">Chỉnh sửa phát hành thẻ</p>}
        placement="right"
        rootClassName="custom-drawer"
        closable={false}
        onClose={closeDrawer}
        open={isOpen}
        footer={<div className="py-2 px-6 bg-FCFCFD">
          <button onClick={submitForm} className="border-none bg-096DD9 rounded-2  h-8 w-full text-sm font-regular text-white">Cập nhật</button>
        </div>}
      >
        <div className=" d-flex flex-column">
          <div className="d-flex flex-column gap-10-px">
            <div className="d-flex flex-column gap-1">
              <p className="text-sm  font-regular text-344054">Họ và tên</p>
              <Form.Item
                name="username"
                className="m-none-form-item text-explan"
              >
                <Input
                  placeholder="Họ và tên"
                  disabled
                  className="custom-input-card bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular   h-10"
                  prefix={<UserOutlined className="text-base text-98A2B3" />}
                />
              </Form.Item>
            </div>

            <div className="d-flex flex-column gap-1">
              <p className="text-sm  font-regular text-344054">Số điện thoại</p>
              <Form.Item
                name="phone"
                className="m-none-form-item text-explan"
              >
                <Input
                  placeholder="Số điện thoại"
                  disabled
                  className="custom-input-card bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular   h-10"
                  prefix={<PhoneOutlined className="text-base text-98A2B3" />}
                />
              </Form.Item>
            </div>

            <div className="d-flex flex-column gap-1">
              <p className="text-sm  font-regular text-344054">Mã thành viên</p>
              <Form.Item
                name="code"
                className="m-none-form-item text-explan"
              >
                <Input
                  placeholder="Mã thành viên (Để trống hệ thống tự sinh)"
                  disabled
                  className="custom-input-card bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular   h-10"
                  prefix={<ContactsOutlined className="text-base text-98A2B3" />}
                />
              </Form.Item>
            </div>

            <div className="d-flex flex-column gap-1">
              <p className="text-sm  font-regular text-344054">Hạng thẻ</p>
              <Form.Item
                name="rank"
                rules={[{ required: true, message: 'Chọn hạng thẻ!' }]}
                className="m-none-form-item text-explan"
              >
                <Select
                  placeholder="Hạng thẻ"
                  options={fommatSelectOption(dataRank)}
                  className="custome-select-h-10 "
                  popupClassName='popup-select'
                />
              </Form.Item>
            </div>

            <div className="d-flex flex-column gap-1">
              <p className="text-sm  font-regular text-344054">Ngày phát hành</p>
              <Form.Item
                name="date"
                rules={[{ required: true, message: 'Vui lòng chọn ngày!' }]}
                className="m-none-form-item text-explan t-ctrl-date-picker-bg-FCFCFD "
              >
                <RangePicker
                  popupClassName="t-ctrl-date-picker"
                  placeholder={["Ngày phát hành", "Ngày hết hạn"]}
                  // value={dataInfoIssuedCards?.expirationDate ? [dayjs(dataInfoIssuedCards.expirationDate), dayjs(dataInfoIssuedCards.releaseDate)] : null}
                  onChange={(e) =>
                    handleDateChange(e)
                  }
                />

              </Form.Item>
            </div>

          </div>
        </div>
      </Drawer>
    </Form>

  )
}
export default DrawerEditCard
