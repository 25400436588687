import React from 'react'
import './CarCard.scss'

const logoBrand = <svg width="251" height="166" viewBox="0 0 251 166" fill="none" xmlns="http://www.w3.org/2000/svg" className='brand'> <path d="M55.5059 166.001L124.954 50.3239L124.921 50.293L55.5059 166.001Z" fill="url(#paint0_linear_15451_59706)"/> <path d="M148.55 0H101.458L0.101562 166H55.5059L124.921 50.2917L124.954 50.3227L151.902 5.4127L148.55 0Z" fill="url(#paint1_linear_15451_59706)"/> <path d="M116.508 124.927C110.91 124.927 105.782 127.834 103.168 132.412C99.9501 138.072 83.9287 166.001 83.9287 166.001H250.41L151.902 5.44531L124.954 50.3553L170.638 124.803L116.508 124.958V124.927Z" fill="url(#paint2_linear_15451_59706)"/> <defs> <linearGradient id="paint0_linear_15451_59706" x1="90.23" y1="-179.699" x2="90.23" y2="246.192" gradientUnits="userSpaceOnUse"> <stop stopColor="#4887F6"/> <stop offset="1" stopColor="white" stopOpacity="0"/> </linearGradient> <linearGradient id="paint1_linear_15451_59706" x1="76.0019" y1="-329.957" x2="76.0019" y2="281.046" gradientUnits="userSpaceOnUse"> <stop stopColor="#4887F6"/> <stop offset="1" stopColor="white" stopOpacity="0"/> </linearGradient> <linearGradient id="paint2_linear_15451_59706" x1="167.169" y1="-313.691" x2="167.169" y2="277.275" gradientUnits="userSpaceOnUse"> <stop stopColor="#4887F6"/> <stop offset="1" stopColor="white" stopOpacity="0"/> </linearGradient> </defs> </svg>

interface Props {
  name: string,
  avatar: string,
}

const CarCard:React.FC<Props> = (props) => {
  const { name, avatar } = props

  return (
    <div className="car-card">
      { logoBrand }
      <div className="car-card__name fsz-16 lh-24 font-medium">{ name }</div>
      <img src={avatar} alt={name} className="car-card__avatar" />
    </div>
  )
}

export default CarCard
