import React, { useEffect, useState } from 'react'
import './UploadImage.scss'
import { EditFilled, PlusCircleFilled } from '@ant-design/icons'
// import { Trash } from 'iconsax-react'
// import {IMAGE_URL, IMAGE_URL2} from '../../env'

interface Props {
  imgUrl?: string,
  onChange?: (file: any) => void,
}

const UploadImage: React.FC<Props> = (props) => {
  const { imgUrl, onChange } = props

  // const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)

  useEffect(() => {
    if (imgUrl) {
      setPreviewUrl(imgUrl)
      // if (imgUrl.includes('/stores/')) {
      //   setPreviewUrl(`${IMAGE_URL2}${imgUrl}`)
      // } else {
      //   setPreviewUrl(`${IMAGE_URL}${imgUrl}`)
      // }
    } else {
      setPreviewUrl(null)
    }
  }, [imgUrl])

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      // setSelectedFile(file)
      setPreviewUrl(URL.createObjectURL(file))

      if (onChange) {
        const base64Image = await convertToBase64(file)
        onChange(base64Image)
      }
    }
  }

  // const handleRemoveImage = () => {
  //   // setSelectedFile(null)
  //   setPreviewUrl(null)
  // }

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const base64String = reader.result as string
        resolve(base64String)
      }
      reader.onerror = (error) => {
        reject(error)
      }
    })
  }

  return (
    <div className={`upload-image ${!previewUrl ? 'empty' : ''}`}>
      <input type="file" onChange={handleFileChange} />
      {
        previewUrl
          ? (
            <div className="upload-image__preview">
              <img src={previewUrl} alt="Preview" />
              {/* <Trash
                variant='Bulk'
                size={24}
                className="upload-image__remove"
                onClick={handleRemoveImage}
              /> */}
              <span className="upload-image__preview-icon">
                {/* <EditFilled /> */}
                <PlusCircleFilled />
              </span>
            </div>
          )
          // : <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.6665 26.6665C10.0249 26.6684 8.4403 26.0644 7.21633 24.9704C5.99235 23.8764 5.21507 22.3692 5.0334 20.7377C4.85172 19.1061 5.27843 17.4649 6.23177 16.1284C7.18511 14.7919 8.59804 13.8542 10.1999 13.4949C9.73641 11.3334 10.1506 9.07627 11.3513 7.22013C12.552 5.36399 14.4409 4.06085 16.6024 3.59738C18.7639 3.13392 21.021 3.54809 22.8771 4.74879C24.7333 5.9495 26.0364 7.83837 26.4999 9.99988H26.6665C28.7331 9.99781 30.7268 10.7637 32.2604 12.1488C33.7941 13.534 34.7584 15.4396 34.9661 17.4957C35.1738 19.5518 34.61 21.6118 33.3844 23.2757C32.1587 24.9395 30.3585 26.0886 28.3332 26.4999M24.9999 21.6665L19.9999 16.6665M19.9999 16.6665L14.9999 21.6665M19.9999 16.6665V36.6665" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </svg>
          : (
            <div className="upload-image__preview">
              <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="64" height="64" rx="32" fill="#EAECF0"/><path d="M44.374 40.9813C43.7003 39.3854 42.7226 37.9359 41.4954 36.7134C40.2719 35.4874 38.8227 34.5098 37.2275 33.8348C37.2133 33.8277 37.199 33.8241 37.1847 33.817C39.4097 32.2098 40.8561 29.592 40.8561 26.6384C40.8561 21.7455 36.8918 17.7812 31.999 17.7812C27.1061 17.7812 23.1418 21.7455 23.1418 26.6384C23.1418 29.592 24.5883 32.2098 26.8133 33.8205C26.799 33.8277 26.7847 33.8313 26.7704 33.8384C25.1704 34.5134 23.7347 35.4813 22.5025 36.717C21.2765 37.9404 20.299 39.3897 19.624 40.9848C18.9608 42.5464 18.6032 44.2207 18.5704 45.917C18.5694 45.9551 18.5761 45.993 18.5901 46.0285C18.604 46.064 18.6249 46.0963 18.6515 46.1236C18.6781 46.1509 18.71 46.1726 18.7451 46.1874C18.7802 46.2022 18.818 46.2098 18.8561 46.2098H20.999C21.1561 46.2098 21.2811 46.0848 21.2847 45.9313C21.3561 43.1741 22.4633 40.592 24.4204 38.6348C26.4454 36.6098 29.1347 35.4955 31.999 35.4955C34.8633 35.4955 37.5525 36.6098 39.5775 38.6348C41.5347 40.592 42.6418 43.1741 42.7133 45.9313C42.7168 46.0884 42.8418 46.2098 42.999 46.2098H45.1418C45.18 46.2098 45.2177 46.2022 45.2528 46.1874C45.288 46.1726 45.3198 46.1509 45.3464 46.1236C45.3731 46.0963 45.3939 46.064 45.4079 46.0285C45.4218 45.993 45.4285 45.9551 45.4275 45.917C45.3918 44.2098 45.0383 42.5491 44.374 40.9813ZM31.999 32.7813C30.3597 32.7813 28.8168 32.142 27.6561 30.9813C26.4954 29.8205 25.8561 28.2777 25.8561 26.6384C25.8561 24.9991 26.4954 23.4563 27.6561 22.2955C28.8168 21.1348 30.3597 20.4955 31.999 20.4955C33.6383 20.4955 35.1811 21.1348 36.3418 22.2955C37.5025 23.4563 38.1418 24.9991 38.1418 26.6384C38.1418 28.2777 37.5025 29.8205 36.3418 30.9813C35.1811 32.142 33.6383 32.7813 31.999 32.7813Z" fill="#FCFCFD"/></svg>
              <span className="upload-image__preview-icon">
                <PlusCircleFilled />
              </span>
            </div>
          )
      }
    </div>
  )
}

export default UploadImage
