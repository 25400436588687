import React from 'react'
import { Button, Divider, Drawer, Flex, Tag } from 'antd'
import CarCard from '../../../../components/Cards/CarCard'

interface Props {
  show: boolean
  onClose: () => void
}

const WarrantyHistoryDrawer:React.FC<Props> = (props) => {
  const { show, onClose } = props

  return (
    <Drawer
      open={show}
      title='Thông tin bảo hành'
      className='rs-drawer'
      closeIcon={false}
      footer={
        <Button
          block
          onClick={onClose}
        >Đóng</Button>
      }
      onClose={onClose}
    >
      <Flex vertical className='gap-18px'>
        <CarCard
          name='BMW X3'
          avatar='/images/car-avatar-320x130.png'
        />
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Thương hiệu</span>
          <Flex align='center' justify='flex-end' className='gap-12px'>
            <span className="neutral-gray-400 text-end">BMW</span>
            <img src='/images/car-logo.svg' alt='' className='h-24px w-24px' style={{ objectFit: 'contain' }}/>
          </Flex>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Số RO</span>
          <span className="neutral-gray-400 text-end">#RO132143</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Số TI-TS</span>
          <span className="neutral-gray-400 text-end">RN2KW5711</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Số BCK</span>
          <span className="neutral-gray-400 text-end">51k1-3432</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Loại xe</span>
          <span className="neutral-gray-400 text-end">HA34</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Dòng xe</span>
          <span className="neutral-gray-400 text-end">BMW 1 Series</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Màu xe</span>
          <span className="neutral-gray-400 text-end"> Xanh Đen</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Số khung</span>
          <span className="neutral-gray-400 text-end">38042093</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Số KM hiện tại</span>
          <span className="neutral-gray-400 text-end">30.000 KM</span>
        </Flex>
        <Divider variant='dashed' className='rs-divider primary-7' style={{ margin: 0 }} />
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Loại dịch vụ</span>
          <Tag className='rs-tag daybreak-blue no-border'>Bảo hành định kỳ</Tag>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Ngày bảo hành</span>
          <span className="neutral-gray-400 text-end">20/06/2022</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Ngày kết thúc</span>
          <span className="neutral-gray-400 text-end">20/06/2022</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Địa điểm bảo trì</span>
          <span className="neutral-gray-400 text-end">THACO AUTO GÒ VẤP</span>
        </Flex>
        <Flex justify='space-between' className='gap-8px'>
          <span className="neutral-gray-700">Nội dung</span>
          <span className="neutral-gray-400 text-end maw-170">Giảm 20% khi sử dụng dịch vụ từ THACO AUTO</span>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default WarrantyHistoryDrawer
