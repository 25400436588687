import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import viVN from 'antd/locale/vi_VN';
import { Breadcrumb, ConfigProvider, Input, Pagination, Switch, Table, TableColumnsType } from 'antd';
import React, { useEffect, useState } from 'react';
import Layout from '../../../Layout';
import * as UnitApi from '../../../api/unit/unitApi';
import ModalDeleteManager from '../../../components/ModalDeleteManager';
import useToast from '../../../hook/useToast';
import ModalCreateRegion from './Modal/ModalCreateRegion';
import ModalEditRegion from './Modal/ModalEditRegion';
import { OptionSelect } from '../../../api/unit/unitApi';


const Regions: React.FC = () => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);
  const [openCreateRegion, setOpenCreateRegion] = useState(false)
  const [openEditRegion, setOpenEditRegion] = useState(false)
  const [openDeleteRegion, setOpenDeleteRegion] = useState(false)
  const [dataRegions, setDataRegions] = useState<UnitApi.DataTypeRegions[]>([])
  const pushToast = useToast();
  const [id, setId] = useState<number>()
  const [data, setData] = useState<any>();
  const [dataCreate, setDataCreate] = useState<any>();
  const [search, setSearch] = useState<string>()
  const columns: TableColumnsType<UnitApi.DataTypeRegions> = [
    {
      title: 'Hành động',
      render: (_, record) => (
        <div className='d-flex align-center justify-center gap-10-px'>
          <DeleteOutlined
            className='text-F5222D text-sm border-EAECF0 p-5-px bg-white rounded-2'
            onClick={() => { setOpenDeleteRegion(true); setId(Number(record.key)) }}
          />
          <EditOutlined
            className='text-98A2B3 text-sm border-EAECF0 p-5-px bg-white rounded-2'
            onClick={() => { setOpenEditRegion(true); setId(Number(record.key)) }}
          />
        </div>

      ),
      className: "w-83-px",
      align: 'center'
    },

    {
      title: 'Mã vùng miền',
      dataIndex: 'codeArea',

      className: "w-121-px"
    },
    {
      title: 'Tên vùng miền',
      dataIndex: 'nameArea',
      className: "w-494-px",

    },
    {
      title: 'Toàn quốc',
      dataIndex: 'nationwide',
      className: "w-319-px ",
    },

    {
      title: 'Trạng thái',
      // dataIndex: '',
      className: "w-175-px",
      render: (_, record) => <div className='d-flex align-center gap-10-px'>
        <Switch
          defaultChecked={record.view ? true : false}
          onChange={() => updateActiveRegions(Number(record.key))}
          className="rs-switch rs-switch--sm"
        />
        <p className='font-medium fw-500 text-344054'>{UnitApi.activeArray[record.view]}</p>
      </div>
    },
  ];
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });

  const getListRegions = async (pageCurent: number = 1, pageSize: number = 10, search: string = '') => {
    const result = await UnitApi.getListRegions(pageSize, pageCurent, search);
    if (result?.status) {
      // setDataNationwide(convertData(result.data, pageCurent, pageSize))
      setDataRegions(convertData(result.data))
      setPagination({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result.message, "error", 2000)

    }
  }
  const reloadData = () => {
    getListRegions()
    getListOptionNationwide()
  }

  const convertData = (data: UnitApi.IRegions[]) => {
    // var stt = (pageCurent - 1) * pageSize + 1;
    const convertData = data.map((item) => ({
      codeArea: item.code,
      nameArea: item.title,
      nationwide: item.nationwideTitle,
      key: item.id,
      view: item.view
    }))
    return convertData
  }
  // thay đổi trang thái
  const updateActiveRegions = async (id: number) => {
    const result = await UnitApi.updateActiveRegions(id);
    if (result?.status) {
      getListRegions(pagination.current, pagination.pageSize)
      pushToast(result.message, "success", 2000)
      // setTimeout(() => {
      //   setOpenDeleteArea(false)
      // }, 2000)
    } else {
      pushToast(result.message, "error", 2000)

    }
  }

  const handleDelete = async () => {
    if (id) {
      const result = await UnitApi.deleteActiveNationwide(id);
      if (result?.status) {
        getListRegions(pagination.current, pagination.pageSize)
        setOpenDeleteRegion(false)
        pushToast(result.message, "success", 2000)
        getListOptionNationwide()

      } else {
        pushToast(result.message, "error", 2000)
      }
    }
  }

  function convertNationwide(data: UnitApi.OptionSelect[], id: number) {
    return data.filter((value) => value.value !== id).map((item) => {
      const treeItem: OptionSelect = {
        value: item.value,
        label: `${item.label}`,
        title: item.title,
        children: []
      };
      if (item.children != null && item.children.length > 0) {
        treeItem.children = convertNationwide(item.children, id);
      }
      return treeItem;
    });
  }
  const getListOptionNationwide = async () => {
    const result = await UnitApi.getListOptionNationwide();
    if (result.status) {
      setData(UnitApi.convertRegionalValueToTree(result.data))
      setDataCreate(UnitApi.convertRegionalValueToTree(result.data))
    } else {
      pushToast(result.message, "error", 2000)

    }
  }

  const handleChangePagination = (current: number, pageSize: number) => {
    getListRegions(current, pageSize)
  }

  useEffect(() => {
    getListOptionNationwide()
    getListRegions()

  }, [])

  useEffect(() => {
    if (id) {
      setData(convertNationwide(dataCreate, id))
    }
  }, [id, openEditRegion]);
  return (
    <Layout>
      <ModalCreateRegion
        getListRegions={reloadData}
        isOpen={openCreateRegion}
        closeModal={() => setOpenCreateRegion(!openCreateRegion)}
        data={dataCreate}
      />
      <ModalEditRegion
        id={id}
        data={data}
        isOpen={openEditRegion}
        getListRegions={reloadData}
        closeModal={() => setOpenEditRegion(!openEditRegion)}
      />
      <ModalDeleteManager
        isOpen={openDeleteRegion}
        onConfirm={handleDelete}
        closeModal={() => setOpenDeleteRegion(!openDeleteRegion)}
        title="Bạn có chắc chắn xóa vùng miền này"
        titleDelete="Xóa vùng miền"
        content="Vùng miền và dữ liệu liên quan sẽ bị xóa vĩnh viễn
   không thể khôi phục"
      />
      <Breadcrumb
        items={[
          {
            title: 'THACO AUTO CRM',
          },
          {
            title: <a href="/">Danh mục đơn vị</a>,
          },
          {
            title: 'Vùng miền',
          },
        ]}
      />
      <div className='title-page'>
        Vùng miền
      </div>
      <div className='d-flex align-center gap-10-px pb-18-px departmental-unit-filter'>
        <p className='text-base font-medium text-344054 white-space-nowrap'>Danh sách vùng miền</p>
        <Input
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Tìm kiếm"
          className="bg-white border-E1E1E1 text-sm rounded-2 font-regular input-custom"
        />
        <button className='h-38 white-space-nowrap d-flex justify-center align-center border-096DD9 text-096DD9 bg-white rounded-2 text-sm font-regular'
          onClick={() => getListRegions(pagination.current, pagination.pageSize, search)}
        >
          Tìm kiếm
        </button>
        <button
          className='h-38 white-space-nowrap d-flex justify-center align-center border-none text-white bg-096DD9 rounded-2 text-sm font-regular gap-10-px'
          onClick={() => setOpenCreateRegion(true)}
        >
          <PlusOutlined className='text-sm text-white' />
          Tạo mới
        </button>
      </div>
      <div className="t-table-block d-flex flex-column justify-between h-822-px">
        <Table
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
          columns={columns}
          dataSource={dataRegions}
          pagination={false}
          showSorterTooltip={false}
          className="w-fit-table border-end-table zise-filter "

        />
        <div className="pagination-block border-top-F2F4F7">
          <ConfigProvider locale={viVN}>
            <div className="pagination-block">
              <Pagination
                total={pagination.total}
                current={pagination.current}
                pageSize={pagination.pageSize}
                showSizeChanger
                // showQuickJumper
                showTotal={() => `Tổng cộng ${Math.ceil(pagination.total / pagination.pageSize) === 0 ? 1 : Math.ceil(pagination.total / pagination.pageSize)} trang`}
                // showTotal={() => `Tổng cộng ${total} trang`}

                // showTotal={(total, range) => <span className='text-[#8E8E8E]'>{`${range[0]}-${range[1]} trong ${total} dữ liệu`}</span>}
                locale={{ items_per_page: 'trang' }}
                onChange={handleChangePagination}
              />
            </div>
          </ConfigProvider>
        </div>
      </div>
    </Layout>
  );
}

export default Regions;
