/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Flex, Form, Progress } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../../Layout";
import * as customerApi from '../../../../api/customer/customerApi';
import * as selectOptionsApi from '../../../../api/selectOptions/selectOptionsApi';
import BreadcrumbBlock from "../../../../components/BreadcrumbBlock/BreadcrumbBlock";
import useToast from '../../../../hook/useToast';
import { regexPhoneNumber, validateEmail } from "../../../../until";
import PersonalInformation from "./Component/PersonalInformation";
// import InfoRelative from "./Component/InfoRelative";
import './style.scss';
import CarListCard from "../../Tabs/CarListCard";
import CreateOwnedCarModal from "../../Modal/CreateOwnedCar";
import CreateFavoriteCarModal from "../../Modal/CreateFavoriteCar";
import FavoriteCarDrawer from "../Drawer/FavoriteCar";
import OwnedCarDrawer from "../Drawer/OwnedCar";
import RelativeCollapse from "../../../../components/Relative/RelativeCollapse";
import { PlusCircleOutlined } from "@ant-design/icons";
import AddRelativeModal from "../../Modal/AddRelative";

// interface CollapseProps {
//   title: string
//   relationship: string
//   name: string
//   gender: string
//   phoneNumber: string
//   dateOfBirth: string
//   career: string
//   email: string
//   address: string
//   show?: boolean
// }

const AddCustomer: React.FC = () => {
  let errorsObj: {
    email: string,
    listRelatives: string,
    profession: string,
    userName: string;
    image: string;
    phone: string[];
    birthday: string;
    province: string;
    address: string;
    group: string;
    tag: string;
    hobby: string;
    car: string;
  } = { email: "", listRelatives: '', profession: "", userName: "", image: '', phone: [], birthday: "", province: "", address: "", group: "", tag: "", hobby: "", car: "" };
  const [errorObject, setErrorObject] = useState(errorsObj)
  const [error, setError] = useState(false)
  const [submit, setSubmit] = useState(true)
  const [province, setProvince] = useState<selectOptionsApi.ISelectOption[]>([])
  const [hobby, setHobby] = useState<selectOptionsApi.ISelectOption[]>([])
  const [tag, setTag] = useState<selectOptionsApi.ISelectOption[]>([])
  const [dataGroup, setDataGroup] = useState<selectOptionsApi.ISelectOption[]>([])
  const [dataRelatives, setDataRelatives] = useState<selectOptionsApi.ISelectOption[]>([])
  const [listRelatives, setListRelatives] = useState<selectOptionsApi.relationshipProp[]>([]);

  const [provinceId, setProvinceId] = useState<number | null>(null)
  const [valuesTag, setValuesTag] = useState<number[]>([]);
  const [valuesGroup, setValuesGroup] = useState<number[]>([]);
  const [valuesInterest, setValuesInterest] = useState<number[]>([]);
  const [valuesInterestRelative, setValuesInterestRelative] = useState<number[]>([]);
  const [valuesRelatives, setValuesRelatives] = useState<number | null>(null);
  const [userName, setUserName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [profession, setProfession] = useState<string>('');
  const [sex, setSex] = useState(1)
  const [marri, setMarri] = useState(0)
  const [date, setDate] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [phoneNumbers, setPhoneNumbers] = useState<selectOptionsApi.Phone[]>([{ phone: '', type: 1 }]);
  const [valueCar, setValueCar] = useState<number[]>([])
  const [percent, setPercent] = useState<number>(16)

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const pushToast = useToast();
  const navigator = useNavigate()

  const gListProvice = async () => {
    const result = await selectOptionsApi.getListProvince();
    if (result?.status) {
      setProvince(convertData(result.data))
    } else {
      console.log('object');
    }
  }
  const gListHobby = async () => {
    const result = await selectOptionsApi.getListHobby();
    if (result?.status) {
      setHobby(convertData(result.data))
    } else {
      console.log('object');
    }
  }

  const gListTag = async () => {
    const result = await selectOptionsApi.getListTag();
    if (result?.status) {
      setTag(convertData(result.data))
    } else {
      console.log('object');
    }
  }

  const getListRelatives = async () => {
    const result = await selectOptionsApi.getListRelatives();
    if (result?.status) {
      setDataRelatives(convertData(result.data))
    } else {
      console.log('object');
    }
  }

  const gListGroup = async () => {
    const result = await selectOptionsApi.getListGroup();
    if (result?.status) {
      setDataGroup(convertData(result.data))
    } else {
      console.log('object');
    }
  }
  // gValidatePhone(convertData2(phoneNumbers)).then(result => {

  //   console.log(result);
  // });
  const gValidatePhone = async (phoneArray: string[]) => {
    const result = await selectOptionsApi.getValidatePhone(phoneArray);
    if (result.data.length > 0) {
      pushToast(`${result.message}  ${convertData3(result.data)}`, 'error', 2000)
      return false
    } else {
      return true
    }
  }
  const gValidateMail = async (mail: string) => {
    const result = await selectOptionsApi.getValidateMail(mail);
    if (result.data.length > 0) {
      pushToast(result.message, 'error', 2000)
      return false
    } else {
      return true
    }
  }
  const convertData = (data: selectOptionsApi.IHobby[]) => {
    const convertData = data.map((item) => ({
      value: item.id,
      label: item.title,
    }))
    return convertData
  }
  const convertData2 = (data: selectOptionsApi.Phone[]) => {
    const convertData = data.map((item) => (item.phone))
    return convertData
  }
  const convertData3 = (data: { id: number, phone: string }[]) => {
    const convertData = data.map((item) => (item.phone))
    return convertData
  }
  useEffect(() => {
    gListProvice()
    gListHobby()
    gListTag()
    gListGroup()
    getListRelatives()
  }, [])
  async function validatePhoneFunction() {
    const isValid = await gValidatePhone(convertData2(phoneNumbers));
    return isValid
  }
  async function validateEmailFunction() {
    const isValid = await gValidateMail(email);
    return isValid;
  }
  // validatePhoneFunction()
  // validateEmailFunction()
  const handleSave = async () => {
    let test = false

    var x = false;
    var y = false;
    await validateEmailFunction().then(result => {
      x = result
    })
    await validatePhoneFunction().then(result => {
      y = result
    })
    if (!x || !y) {
      test = true
    }
    if (!submit) {
      if (listRelatives.length === 0) {
        pushToast("Vui lòng thêm ít nhất 1 người thân!", "warning", 2000)
      } else {
        const result = await customerApi.addCustomer(
          userName,
          sex,
          marri,
          profession,
          phoneNumbers,
          date,
          email,
          provinceId,
          address,
          uploadedFile,
          valuesGroup,
          valuesTag,
          valuesInterest,
          valueCar,
          listRelatives,
        );
        if (result?.status) {
          pushToast("Thêm mới thành công!", "success", 2000)
          setTimeout(() => {
            navigator('/customer/list')
          }, 2000)
        } else {
          pushToast(result.message, "error", 2000)
        }
      }
    }
    if (date === '') {
      errorsObj.birthday = 'Vui lòng chọn ngày sinh!';
      test = true
      setError(true)
    }
    if (regexPhoneNumber(phoneNumbers).filter((value) => value === false).length > 0) {
      const data: string[] = regexPhoneNumber(phoneNumbers).map((value) => {
        if (value === true) {
          return ''
        } else {
          return 'Số điện thoại không đúng định dạng!'
        }
      })
      errorsObj.phone = data;
      test = true
      setError(true)
    }
    if (userName === '') {
      errorsObj.userName = 'Vui lòng nhập họ và tên!';
      test = true
      setError(true)
    }
    if (email === '') {
      errorsObj.email = 'Vui lòng nhập địa chỉ email!';
      test = true
      setError(true)
    }
    if (!validateEmail(email)) {
      errorsObj.email = 'Email không đúng định dạng!';
      test = true
      setError(true)
    }
    if (profession === '') {
      errorsObj.profession = 'Vui lòng nhập nghề nghiệp!';
      test = true
      setError(true)
    }
    if (provinceId === null) {
      errorsObj.province = 'Vui lòng chọn thành phố!';
      test = true
      setError(true)
    }
    if (uploadedFile === null) {
      errorsObj.image = 'Vui lòng chọn ảnh đại diện!';
      test = true
      setError(true)
    }
    if (valuesGroup.length === 0) {
      errorsObj.group = 'Vui lòng chọn nhóm!';
      test = true
      setError(true)
    }
    if (valuesTag.length === 0) {
      errorsObj.tag = 'Vui lòng chọn thẻ tag!';
      test = true
      setError(true)
    }
    if (valuesInterest.length === 0) {
      errorsObj.hobby = 'Vui lòng chọn sở thích!';
      test = true
      setError(true)
    }
    if (valueCar.length === 0) {
      errorsObj.car = 'Vui lòng chọn xe quan tâm!';
      test = true
      setError(true)
    }
    setErrorObject(errorsObj)
    if (test) {
      return
    } else {
      setSubmit(false)
    }
  }

  // CAR LIST
  const [showOwnedCarDrawer, setShowOwnedCarDrawer] = useState(false)
  const [showFavoriteCarDrawer, setShowFavoriteCarDrawer] = useState(false)
  const [showCreateOwnedCarModal, setShowCreateOwnedCarModal] = useState(false)
  const [showCreateFavoriteCarModal, setShowCreateFavoriteCarModal] = useState(false)
  const cars = [
    { id: 1, name: 'BMW series 7 2023', logo: '/images/car-logo.svg', avatar: '/images/car.svg', type: 'Xe du lịch', brand: 'KIA', class: 'BMW 1 Series', orderDate: '21/02/2022', receiveDate: '21/02/2022', year: '2022', vinNumber: '01K1234' },
    { id: 2, name: 'BMW series 7 2023', logo: '/images/car-logo.svg', avatar: '/images/car.svg', type: 'Xe du lịch', brand: 'KIA', class: 'BMW 1 Series', orderDate: '21/02/2022', receiveDate: '21/02/2022', year: '2022', vinNumber: '01K1234' },
    { id: 3, name: 'BMW series 7 2023', logo: '/images/car-logo.svg', avatar: '/images/car.svg', type: 'Xe du lịch', brand: 'KIA', class: 'BMW 1 Series', orderDate: '21/02/2022', receiveDate: '21/02/2022', year: '2022', vinNumber: '01K1234' },
  ]

  // RELATIVES
  const [openIndex, setOpenIndex] = useState<any>(0)
  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index)
  }
  const relatives = [
    {
      title: 'Nguyễn Thu Hoài',relationship: 'Mẹ',name: 'Nguyễn Thu Hoài',gender: 'Nữ',phoneNumber: '0977721242',dateOfBirth: '20/06/1968',career: 'Nhân viên ngân hàng',email: 'hoangkimquoc@gmail.com',address: '245E/1 Hoàng Văn Thụ, Tân Bình, TPHCM',
    },
    {
      title: 'Anh Phạm', relationship: 'Cha',name: 'Anh Phạm',gender: 'Nữ',phoneNumber: '0977721242',dateOfBirth: '20/06/1968',career: 'Nhân viên ngân hàng',email: 'hoangkimquoc@gmail.com',address: '245E/1 Hoàng Văn Thụ, Tân Bình, TPHCM',
    },
  ]

  // ADD RELATIVE
  const [showAddRelativeModal, setShowAddRelativeModal] = useState(false)

  // BASIC INFORMATION SUBMIT
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  return (
    <>
      <Layout contentClassName='customer-add-content'>
        <BreadcrumbBlock
          items={[
            { title: <Link to="/">THACO AUTO CRM</Link>, },
            { title: <Link to="/customer/list">Khách hàng</Link>, },
            { title: <Link to="/customer/list">Danh sách khách hàng</Link>, },
            { title: 'Thêm mới khách hàng', },
          ]}
          title='Thêm mới khách hàng'
          backLink='/customer/list'
          className="gutter-block-end"
        />

        <Flex vertical className="gap-24px">
          <Flex align="center" className="gap-12px">
            <p className="text-344054 text-sm fw-600 text-center font-bold">Thông tin cơ bản của khách hàng</p>
            <Button
              type="primary"
              className="rs-button rs-button--sm mis-auto"
              onClick={() => setIsFormSubmitted(true)}
            >Xác nhận</Button>
          </Flex>

          <div className="mw-1062">
            <PersonalInformation
              isFormSubmitted={isFormSubmitted}
              onSuccess={(formData) => {
                console.log(formData)
                setIsFormSubmitted(false)
              }}
            />
          </div>

          <div className="mw-1062">
            <Flex vertical className="gap-18px">
              <Flex align="center" className="gap-10px">
                <div className="title-16 font-medium">Thông tin người thân</div>
                <Button
                  icon={<PlusCircleOutlined />}
                  className="rs-button rs-button--sm mis-auto btn-add-relative"
                  onClick={() => setShowAddRelativeModal(true)}
                >Thêm người thân</Button>
              </Flex>

              {
                relatives.map((item: any, index: number) => (
                  <RelativeCollapse
                    key={index}
                    show={openIndex === index}
                    showControl
                    {...item}
                    onToggle={() => handleToggle(index)}
                    // onDelete={() => console.log('on delete')}
                    // onEdit={() => console.log('on edit')}
                  />
                ))
              }
            </Flex>
          </div>

          <CarListCard
            tableData={cars}
            onCarTypeChange={(type) => console.log(type)}
            onDeleteOwnedCarDetail={(id) => console.log(id)}
            onDeleteFavoriteCarDetail={(id) => console.log(id)}
            onShowOwnedCarDetail={(id) => setShowOwnedCarDrawer(true)}
            onShowFavoriteCarDetail={(id) => setShowFavoriteCarDrawer(true)}
            onCreateOwnedCar={() => setShowCreateOwnedCarModal(true)}
            onCreateFavoriteCar={() => setShowCreateFavoriteCarModal(true)}
          />
        </Flex>
      </Layout>

      {/* OWNED CAR DRAWER */}
      <OwnedCarDrawer
        show={showOwnedCarDrawer}
        onClose={() => setShowOwnedCarDrawer(false)}
      />

      {/* FAVORITE CAR DRAWER */}
      <FavoriteCarDrawer
        show={showFavoriteCarDrawer}
        onClose={() => setShowFavoriteCarDrawer(false)}
      />

      {/* CREATE OWNED CAR MODAL */}
      <CreateOwnedCarModal
        show={showCreateOwnedCarModal}
        modalClose={() => setShowCreateOwnedCarModal(false)}
      />

      {/* CREATE FAVORITE CAR MODAL */}
      <CreateFavoriteCarModal
        show={showCreateFavoriteCarModal}
        modalClose={() => setShowCreateFavoriteCarModal(false)}
      />

      {/* ADD RELATIVE MODAL */}
      <AddRelativeModal
        show={showAddRelativeModal}
        modalClose={() => setShowAddRelativeModal(false)}
        onSuccess={() => setShowAddRelativeModal(false)}
      />
    </>
  )
}
export default AddCustomer
