import React, { useEffect, useState } from 'react';
import './Information.scss';
import { Button, Switch, Tag } from 'antd';
import { EditFilled, MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import VehicleInfoPopup from './VehicleInfoPopup';
import RankBenefitPopup from './RankBenefitPopup';
import WarrantyHistoryPopup from './WarrantyHistoryPopup';
import { ICustomerDetail } from '../../../api/customer/customerApi';
import { dataGender, dataStatusMarital } from '../../../services/data';
import { avatarDefault } from '../../../components/ExportImgFigma';
import { URL_CUSTOMER, URL_RANK } from '../../../env';
import * as historyApi from '../../../api/history/historyApi';
import { useNavigate } from 'react-router-dom';
import useToast from '../../../hook/useToast';
import { ICardCustomer, getInfoCardCustomer } from '../../../api/rank/rankApi';
import useThrottle from '../../../hook/useThrottle';
import RelativeCard from '../../../components/Relative/RelativeCard';
import OrderHistoryCard from './OrderHistoryCard';
import WarrantyHistoryCard from './WarrantyHistoryCard';
import CarListCard from './CarListCard';
import OrderHistoryDrawer from '../Component/Drawer/OrderHistory';
import WarrantyHistoryDrawer from '../Component/Drawer/WarrantyHistory';
import FavoriteCarDrawer from '../Component/Drawer/FavoriteCar';
import OwnedCarDrawer from '../Component/Drawer/OwnedCar';
import CreateOwnedCarModal from '../Modal/CreateOwnedCar';
import CreateFavoriteCarModal from '../Modal/CreateFavoriteCar';

const onVIPChange = (checked: boolean) => {
  console.log('vip change to', checked)
}
interface Props {
  infoCustomer?: ICustomerDetail;
}
const InformationTab: React.FC<Props> = ({
  infoCustomer
}) => {

  const navigator = useNavigate()
  const pushToast = useToast()

  const [isShowAllPhoneNumber, setShowAllPhoneNumber] = useState(false)
  const [isShowAllSource, setShowAllSource] = useState(false)
  const [isBenefitPopupShow, setBenefitPopupShow] = useState(false)
  const [isWarrantyHistoryShow, setWarrantyHistoryShow] = useState(false)
  const [isInfoPopupShow, setInfoPopupShow] = useState(false)

  const [pagination, setPagination] = useState({ current: 1, pageSize: 2, total: 0 });
  const [dataHistoryMaintenance, setDataHistoryMaintenance] = useState<historyApi.IHistoryMaintenance[]>()
  const [data, setData] = useState<historyApi.IHistoryCarDetail>()
  const [infoCardCustomer, setInfoCardCustomer] = useState<ICardCustomer>()

  const getListHistoryMaintenance = async (limit: number = 2, pageNumber: number = 1, customerId: string = '', carId: string = '') => {
    const result = await historyApi.getListHistoryMaintenance(limit, pageNumber, customerId, carId)
    if (result?.status) {
      setDataHistoryMaintenance(result.data);
      setPagination({ current: pageNumber, total: result.total, pageSize: limit })
    } else {
      pushToast(result.message, 'error', 1000)
    }
  }

  const gInfoCardCustomer = useThrottle(async () => {
    // const result = await getInfoCardCustomer(Number(infoCustomer?.id))
    // if (result?.status) {
    //   setInfoCardCustomer(result.data)
    //   setBenefitPopupShow(true)
    // } else {
    //   pushToast(result.message, 'error', 1000)
    // }
    setBenefitPopupShow(true)
  }, 1000)

  const showAllPhoneNumber = () => {
    setShowAllPhoneNumber(!isShowAllPhoneNumber)
  }

  const showAllSource = () => {
    setShowAllSource(!isShowAllSource)
  }

  const handleButtonInfoClick = (info: boolean) => {
    setInfoPopupShow(info)
  };

  useEffect(() => {
    if (data && infoCustomer) {
      getListHistoryMaintenance(pagination.pageSize, pagination.current, infoCustomer?.id.toString(), data?.carId.toString())
    }
  }, [data])

  // RELATIVES
  const relatives = [
    {
      title: 'Minh Anh', relationship: 'Vợ',name: 'Minh Anh',gender: 'Nữ',phoneNumber: '0977721242',dateOfBirth: '20/06/1993',career: 'Nhân viên ngân hàng',email: 'hoangkimquoc@gmail.com',address: '245E/1 Hoàng Văn Thụ, Tân Bình, TPHCM',
    },
    {
      title: 'Minh Anh', relationship: 'Con',name: 'Minh Anh',gender: 'Nữ',phoneNumber: '0977721242',dateOfBirth: '20/06/1993',career: 'Nhân viên ngân hàng',email: 'hoangkimquoc@gmail.com',address: '245E/1 Hoàng Văn Thụ, Tân Bình, TPHCM',
    },
    {
      title: 'Minh Anh', relationship: 'Con',name: 'Minh Anh',gender: 'Nữ',phoneNumber: '0977721242',dateOfBirth: '20/06/1993',career: 'Nhân viên ngân hàng',email: 'hoangkimquoc@gmail.com',address: '245E/1 Hoàng Văn Thụ, Tân Bình, TPHCM',
    },
  ]

  // CAR LIST
  const [showOwnedCarDrawer, setShowOwnedCarDrawer] = useState(false)
  const [showFavoriteCarDrawer, setShowFavoriteCarDrawer] = useState(false)
  const [showCreateOwnedCarModal, setShowCreateOwnedCarModal] = useState(false)
  const [showCreateFavoriteCarModal, setShowCreateFavoriteCarModal] = useState(false)
  const cars = [
    { id: 1, name: 'BMW series 7 2023', logo: '/images/car-logo.svg', avatar: '/images/car.svg', type: 'Xe du lịch', brand: 'KIA', class: 'BMW 1 Series', orderDate: '21/02/2022', receiveDate: '21/02/2022', year: '2022', vinNumber: '01K1234' },
    { id: 2, name: 'BMW series 7 2023', logo: '/images/car-logo.svg', avatar: '/images/car.svg', type: 'Xe du lịch', brand: 'KIA', class: 'BMW 1 Series', orderDate: '21/02/2022', receiveDate: '21/02/2022', year: '2022', vinNumber: '01K1234' },
    { id: 3, name: 'BMW series 7 2023', logo: '/images/car-logo.svg', avatar: '/images/car.svg', type: 'Xe du lịch', brand: 'KIA', class: 'BMW 1 Series', orderDate: '21/02/2022', receiveDate: '21/02/2022', year: '2022', vinNumber: '01K1234' },
  ]

  // WARRANTY HISTORY
  const [showWarrantyHistoryDrawer, setShowWarrantyHistoryDrawer] = useState(false)
  const warrantyHistoryItems = [
    { id: 1, roNumber: 'RO#128484', bckNumber: '51k1-3432', titsNumber: 'RN2KW5711', vinNumber: '01K1234', carColor: 'Màu xanh - 343', carType: 'Xe du lịch', kmNumber: '30.000 KM', serviceType: 'Bảo hành định kỳ', price: '21/02/2022' },
    { id: 2, roNumber: 'RO#128484', bckNumber: '51k1-3432', titsNumber: 'RN2KW5711', vinNumber: '01K1234', carColor: 'Màu xanh - 343', carType: 'Xe du lịch', kmNumber: '30.000 KM', serviceType: 'Bảo hành định kỳ', price: '21/02/2022' },
    { id: 3, roNumber: 'RO#128484', bckNumber: '51k1-3432', titsNumber: 'RN2KW5711', vinNumber: '01K1234', carColor: 'Màu xanh - 343', carType: 'Xe du lịch', kmNumber: '30.000 KM', serviceType: 'Bảo hành định kỳ', price: '21/02/2022' },
  ]

  // ORDER HISTORY
  const [showOrderHistoryDrawer, setShowOrderHistoryDrawer] = useState(false)
  const orderHistoryItems = [
    { id: 1, orderCode: '#08033333', srdlCode: '#SR099333', quantity: '01', quantityDKDV: '01', paymentMethod: 'Trả thẳng', dateGDTD: '21/02/2022', dateDNHS: '21/02/2022', dateXuat: '21/02/2022', },
    { id: 2, orderCode: '#08033333', srdlCode: '#SR099333', quantity: '01', quantityDKDV: '01', paymentMethod: 'Trả thẳng', dateGDTD: '21/02/2022', dateDNHS: '21/02/2022', dateXuat: '21/02/2022', },
    { id: 3, orderCode: '#08033333', srdlCode: '#SR099333', quantity: '01', quantityDKDV: '01', paymentMethod: 'Trả thẳng', dateGDTD: '21/02/2022', dateDNHS: '21/02/2022', dateXuat: '21/02/2022', },
  ]

  return (
    <>
      <div className="information-tab">
        <div className="information-tab__head">
          <div className="avatar-block">
            <img
              src={URL_CUSTOMER + infoCustomer?.image}
              alt="NVHH"
              className="avatar-block__avatar ob-cover"
              onError={(e) => {
                e.currentTarget.src = avatarDefault;
              }}
            />

            <div className="avatar-block__content">
              <div className="name">Nguyễn Văn Họ Hoàng</div>

              <div
                className={`rank-block`}
              >
                <div
                  className={`item `}
                >
                  <div className="rank">
                    <img
                      src='/images/medal/golden.svg'
                      alt="Vàng"
                      className='rank-img'
                    />
                    <span className='rank-name'>Thành viên Vàng</span>
                    {/* <span className='rank-point primary-7'>(3000 Điểm)</span> */}
                  </div>
                </div>

                <div className="item">
                  <span
                    className="font-medium primary-7 cursor-pointer"
                    onClick={gInfoCardCustomer}
                  >Quyền lợi</span>
                </div>
              </div>
            </div>
            {/* <div className="avatar-block__content">
              <div className="name">{infoCustomer?.userName}</div>

              <div
                className={`rank-block ${!infoCustomer?.rank?.title?.length ? "hidden" : ""}`}
              >
                <div
                  className={`item `}
                >
                  <div className="rank">
                    <img
                      src={URL_RANK + infoCustomer?.rank?.icon}
                      alt="Vàng"
                      className='rank-img'
                    />
                    <span className='rank-name'>{infoCustomer?.rank?.title}</span>
                  </div>
                </div>

                <div className="item">
                  <span
                    className="font-medium primary-7 cursor-pointer"
                    onClick={gInfoCardCustomer}
                  >Quyền lợi</span>
                </div>
              </div>
            </div> */}
          </div>

          <Button
            type="primary"
            className='rs-button'
            onClick={() => navigator(`/customer/update-customer/${infoCustomer?.id}`)}
          >
            Cập nhật
            <EditFilled />
          </Button>
        </div>

        <div className="information-tab__content">
          <div className="information-tab__content-left">
            <div className="block vip-block">
              <img src="/images/medal/vip-diamond.svg" alt="VIP" className='vip-block__img' />
              <span className="font-bold">VIP cần theo dõi chăm sóc định kỳ</span>
              <Switch defaultChecked onChange={onVIPChange} className='rs-switch' />
            </div>

            <div className="block">
              <span className="font-bold">Thông tin cá nhân</span>
            </div>

            <div className="information-table-show">
              <div className="item">
                <span className="item-left">Tình trạng hôn nhân</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>{dataStatusMarital[Number(infoCustomer?.statusMarital || 0)].title}</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Nguồn</span>
                <div className="item-right flex-column">
                  {
                    !isShowAllSource
                      ? <div className="first-item-of-list">
                        <span className='neutral-gray-400'>DDMS - Sales</span>
                        <Tag
                          className='rs-tag daybreak-blue no-border cursor-pointer'
                          onClick={showAllSource}
                        >
                          <PlusCircleFilled />
                          <span>2 nguồn</span>
                        </Tag>
                      </div>
                      : <>
                        <span className='neutral-gray-400'>DDMS - Sales</span>
                        <span className='neutral-gray-400'>IPCC</span>
                        <Tag
                          className='rs-tag daybreak-blue no-border cursor-pointer'
                          onClick={showAllSource}
                        >
                          <MinusCircleFilled />
                          <span>Thu gọn</span>
                        </Tag>
                      </>
                  }
                </div>
              </div>
              <div className="item">
                <span className="item-left">Thuộc</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>Đại lý bán hàng ABC</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Nghề nghiệp</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>{infoCustomer?.profession}</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Ngày sinh</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>{infoCustomer?.birthday}</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Giới tính</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>{dataGender[Number(infoCustomer?.gender || 0)].title}</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Số điện thoại</span>
                <div className="item-right flex-column items-end">
                  <span className='neutral-gray-400'>
                    0977721242
                    <span className="sunset-orange-6">&nbsp;(Công ty)</span>
                  </span>
                  {
                    !isShowAllPhoneNumber &&
                      <Tag
                        className='rs-tag daybreak-blue no-border cursor-pointer'
                        onClick={showAllPhoneNumber}
                      >
                        <PlusCircleFilled />
                        <span>2 số phụ</span>
                      </Tag>
                  }

                  {
                    isShowAllPhoneNumber &&
                      <>
                        <span className='neutral-gray-400'>
                          0977721242
                          <span className="sunset-orange-6">&nbsp;(Cá nhân)</span>
                        </span>
                        <span className='neutral-gray-400'>
                          0977721242
                          <span className="sunset-orange-6">&nbsp;(Cá nhân)</span>
                        </span>

                        <Tag
                          className='rs-tag daybreak-blue no-border cursor-pointer'
                          onClick={showAllPhoneNumber}
                        >
                          <MinusCircleFilled />
                          <span>Thu gọn</span>
                        </Tag>
                      </>
                  }
                </div>
              </div>
              <div className="item">
                <span className="item-left">Email</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>{infoCustomer?.email}</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Địa chỉ</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>{infoCustomer?.address}</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Nhóm</span>
                <div className="item-right">
                  {/* <div className="ant-tags">
                    <Tag className='rs-tag golden-purple'>Âm nhạc</Tag>
                    <Tag className='rs-tag golden-purple'>Thể thao</Tag>
                  </div> */}

                  {/* If empty */}
                  <span className='neutral-gray-400'>Chưa xác định</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Nhân viên chăm sóc</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>Trần Nam Bá</span>

                  {/* If empty */}
                  {/* <span className='neutral-gray-400'>Chưa xác định</span> */}
                </div>
              </div>
            </div>
          </div>

          <div className="information-tab__content-right">
            <RelativeCard
              title='Danh sách người thân'
              items={relatives}
            />
          </div>
        </div>

        <CarListCard
          tableData={cars}
          onCarTypeChange={(type) => console.log(type)}
          onDeleteOwnedCarDetail={(id) => console.log(id)}
          onDeleteFavoriteCarDetail={(id) => console.log(id)}
          onShowOwnedCarDetail={(id) => setShowOwnedCarDrawer(true)}
          onShowFavoriteCarDetail={(id) => setShowFavoriteCarDrawer(true)}
          onCreateOwnedCar={() => setShowCreateOwnedCarModal(true)}
          onCreateFavoriteCar={() => setShowCreateFavoriteCarModal(true)}
        />

        <WarrantyHistoryCard
          title='Thông tin bảo hành - bảo dưỡng'
          tableData={warrantyHistoryItems}
          onShowDetail={() => setShowWarrantyHistoryDrawer(true)}
        />

        <OrderHistoryCard
          title='Lịch sử mua hàng'
          tableData={orderHistoryItems}
          onShowDetail={() => setShowOrderHistoryDrawer(true)}
        />
      </div >

      {
        isInfoPopupShow
          ? <VehicleInfoPopup
            className="show"
            data={data}
            onClose={() => { setInfoPopupShow(false) }}
            dataHistoryMaintenance={dataHistoryMaintenance ?? []
            }
            onShowHistory={() => { setWarrantyHistoryShow(true) }}
          />
          : null
      }
      {
        isWarrantyHistoryShow
          ? <WarrantyHistoryPopup
            className="show"
            onClose={() => { setWarrantyHistoryShow(false) }}
            // setPagination={setPagination}
            customerId={infoCustomer?.id.toString()}
            cardId={data?.carId.toString()}
          />
          : null
      }
      {
        isBenefitPopupShow
          ? <RankBenefitPopup
            className="show"
            infoCardCustomer={infoCardCustomer}
            onClose={() => { setBenefitPopupShow(false) }}
          />
          : null
      }

      {/* OWNED CAR DRAWER */}
      <OwnedCarDrawer
        show={showOwnedCarDrawer}
        onClose={() => setShowOwnedCarDrawer(false)}
      />

      {/* FAVORITE CAR DRAWER */}
      <FavoriteCarDrawer
        show={showFavoriteCarDrawer}
        onClose={() => setShowFavoriteCarDrawer(false)}
      />

      {/* WARRANTY HISTORY DRAWER */}
      <WarrantyHistoryDrawer
        show={showWarrantyHistoryDrawer}
        onClose={() => setShowWarrantyHistoryDrawer(false)}
      />

      {/* ORDER HISTORY DRAWER */}
      <OrderHistoryDrawer
        show={showOrderHistoryDrawer}
        onClose={() => setShowOrderHistoryDrawer(false)}
      />

      {/* CREATE OWNED CAR MODAL */}
      <CreateOwnedCarModal
        show={showCreateOwnedCarModal}
        modalClose={() => setShowCreateOwnedCarModal(false)}
      />

      {/* CREATE FAVORITE CAR MODAL */}
      <CreateFavoriteCarModal
        show={showCreateFavoriteCarModal}
        modalClose={() => setShowCreateFavoriteCarModal(false)}
      />
    </>
  );
}

export default InformationTab;
