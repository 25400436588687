import { CloseOutlined, PlusOutlined } from "@ant-design/icons"
import { Input, Modal } from "antd"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import './style.scss'
import useThrottle from "../../../../../hook/useThrottle"
import { addCategoryCar } from "../../../../../api/categoryCar/categoryCarApi"
interface ModalProps {
  isOpen: boolean
  onSubmitFormModal?: (iSsuccess: boolean, errorMessage?: string) => void;
  closeModal: () => void
}
const ModalCreateBrand: React.FC<ModalProps> = ({
  isOpen,
  onSubmitFormModal, closeModal
}) => {

  let errorsObj = { title: "", uploadedFile: "", result: "" };
  const [errors, setErrors] = useState(errorsObj);

  const imgRef = useRef<HTMLInputElement>(null);

  const [title, setTitle] = useState("")
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const checkVariables = useThrottle(() => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (!title) {
      errorObj.title = "Vui lòng nhập tên thương hiệu!"
      error = true;
    }
    if (!uploadedFile) {
      errorObj.uploadedFile = "Vui lòng chọn ảnh thương hiệu xe!"
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    } else {
      aCategoryCar()
    }
  }, 1000)

  const aCategoryCar = async () => {
    const result = await addCategoryCar(title, uploadedFile)
    if (result?.status) {
      onSubmitFormModal?.(true)
    } else {
      onSubmitFormModal?.(false, result.message)
    }
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setUploadedFile(file);
  };

  const handleUploadClick = (event: React.MouseEvent) => {

    event.preventDefault();
    const inputElement = document.querySelector('input[type="file"]') as HTMLInputElement | null;
    if (inputElement) {
      inputElement.click();
    }
  };

  useEffect(() => {
    if (isOpen) {
    } else {
      setTitle("")
      setUploadedFile(null)

      setErrors(errorsObj)
    }
  }, [isOpen])
  return (
    <Modal
      title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
        <span className="text-base text-344054 fw-500 font-medium">Tạo mới thương hiệu xe</span>
        <CloseOutlined className="text-base text-8B8B8B" onClick={closeModal} />
      </div>}
      open={isOpen}
      onCancel={closeModal}
      centered
      className="w-modal modal-content ant-modal-size"
      closeIcon={false}
      footer={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-2 px-6 w-full border-t-F0F0F0">
        <span className="text-sm text-98A2B3 cursor-pointer" onClick={closeModal}>Huỷ</span>
        <button className="text-white text-sm bg-096DD9 border-none shadow-btn-save rounded-2 h-8 px-15-px"
          onClick={checkVariables}>
          Lưu thương hiệu mới
        </button>
      </div>}
    >
      <div className="p-6 d-flex flex-column gap-18-px">
        <div className="d-flex flex-column gap-1">
          <p className="text-sm  font-regular text-344054">Tên thương hiệu xe</p>
          <div className="d-flex flex-column">
            <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 pl-3  py-1 h-8  overflow-hidden">
              <Input
                placeholder="Tên thương hiệu xe"
                className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input  hover-none"
                maxLength={50}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            {
              errors.title &&
              <span className="text-F5222D text-base font-regular pl-2">{errors.title}</span>
            }
          </div>
        </div>


        <div className="d-flex flex-column">
          <div className="border-F2F4F7 rounded-2-px w-104-px h-104-px bg-F9FAFB d-flex align-center justify-center">
            {
              uploadedFile ? (
                <img
                  src={URL.createObjectURL(uploadedFile)}
                  alt="Uploaded"
                  className="w-104-px h-104-px"
                  onClick={() => (imgRef as any).current.click()}
                />
              ) : (
                <div>

                  <div
                    className="d-flex flex-column align-center gap-2 cursor-pointer"
                    onClick={handleUploadClick}
                  >
                    <PlusOutlined className="text-sm text-252626" />
                    <span className="text-sm text-98A2B3 text-center">Tải lên <br /> Logo</span>
                  </div>
                </div>
              )
            }
          </div>
          {
            errors.uploadedFile &&
            <span className="text-F5222D text-base font-regular pl-2">{errors.uploadedFile}</span>
          } <input
            hidden
            type="file"
            accept="image/*"
            ref={imgRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </div>
      </div>
    </Modal>
  )
}
export default ModalCreateBrand
