import { API_URL, API_URL_v2 } from '../env';

export const mixFnum = (number : number) => {
  return new Intl.NumberFormat('vi-VN', { maximumSignificantDigits: 3 }).format(number);
}

export const mixGetV1 = async (url : string, params = {} as any) => {
  Object.keys(params).map((isKey, index) => {
    if (params[isKey] || params[isKey] === 0) {
      if (Array.isArray(params[isKey])) {
        params[isKey].map((val: any) => {
          url = `${url}${index === 0 ? '?' : '&'}${isKey}=${val}`
          return true
        })
      } else {
        url = `${url}${index === 0 ? '?' : '&'}${isKey}=${params[isKey]}`
      }
    }
    return null
  })
  const response = await fetch(`${API_URL}${url}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  if (!response.ok) {
    if (response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    throw new Error('Network response was not ok');
  }
  const temp = await response.json()
  return temp
}

export const mixGetV2 = async (url : string, params = {} as any) => {
  const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MSwidGltZSI6MTcyMzQ0ODM5MCwiaXAiOiIxMTUuNzguMTQuMjQ0In0.x_OQ9U3-_B3oIlxuAIi0Tq_Z0yFEAdC-DhfUtgfp2Yo'
  Object.keys(params).map((isKey, index) => {
    if (params[isKey] || params[isKey] === 0) {
      if (Array.isArray(params[isKey])) {
        params[isKey].map((val: any) => {
          url = `${url}${index === 0 ? '?' : '&'}${isKey}=${val}`
          return true
        })
      } else {
        url = `${url}${index === 0 ? '?' : '&'}${isKey}=${params[isKey]}`
      }
    }
    return null
  })
  const response = await fetch(`${API_URL_v2}${url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    if (response.status === 401) {
      console.log('Error token')
    }
    console.log('Errors')
  }
  const temp = await response.json()
  return temp
}

export const mixPostV1 = async (url: string, param: {}) => {
  const response = await fetch(`${API_URL}${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(param),
  })
  if (!response.ok) {
    if (response.status === 401) {
      localStorage.removeItem('token')
      window.location.href = '/login'
    }
  }
  const temp = await response.json()
  return temp
}

export const mixPostV2 = async (url: string, param: any) => {
  const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MSwidGltZSI6MTcyMzQ0ODM5MCwiaXAiOiIxMTUuNzguMTQuMjQ0In0.x_OQ9U3-_B3oIlxuAIi0Tq_Z0yFEAdC-DhfUtgfp2Yo'
  const formData = new FormData()
  Object.keys(param).map((keyParam: any) => {
    formData.append(keyParam, param[keyParam])
    return true
  })

  const response = await fetch(`${API_URL_v2}${url}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  })
  if (!response.ok) {
    if (response.status === 401) {
      console.log('Error token')
    }
    console.log('Errors')
  }
  const temp = await response.json()
  return temp
}

export const mixDelV1 = async (url: string, param: {}) => {
  const response = await fetch(`${API_URL}${url}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(param),
  })
  if (!response.ok) {
    if (response.status === 401) {
      localStorage.removeItem('token')
      window.location.href = '/login'
    }
  }
  const temp = await response.json()
  return temp
}

export const mixTreeIds = (arr = [] as any) => {
  const res = [] as any
  arr.map( (item: any) => {
    if (typeof item === 'number' && !isNaN(item)) {
      res.push(item)
    } else {
      const temp = item.split('-')
      res.push(parseInt(temp[1]))
    }
    return true
  })
  return res
}

export const mixGender = (valx: any) => {
  const list = [
    {
      value: 1,
      title: 'Nam',
    },
    {
      value: 0,
      title: 'Nữ',
    },
    {
      value: 2,
      title: 'Không xác định',
    }
  ]
  const obs = {} as any
  list.map(val => {
    obs[`s${val.value}`] = val
    return true
  })
  if (valx || valx === 0) {
    return obs[`s${valx}`] || null
  }
  return list
}

export const mixGetTime = (dateString: any) => {
  const [day, month, year] = dateString.split("-")
  const dateObject = new Date(year, month - 1, day)
  return dateObject.getTime()
}

export const mixDate = (date = null, option = {} as any) => {
  let str = ''
  try {
    let d = new Date()
    if (date) {
      d = new Date(date)
    }
    const year = d.getFullYear()
    const month = (d.getMonth() + 1).toString().padStart(2, '0')
    const day = d.getDate().toString().padStart(2, '0')
    if (option.hour) {
      const hours = d.getHours().toString().padStart(2, '0')
      const minutes = d.getMinutes().toString().padStart(2, '0')

      str = `${day}${option.bass || '/'}${month}${option.bass || '/'}${year} ${hours}:${minutes}`
      return str
    }

    if (option.ishour) {
      let hour = d.getHours() as any
      let minute = d.getMinutes() as any
      if (hour < 10) {
        hour = `0${hour}`
      }
      if (minute < 10) {
        minute = `0${minute}`
      }
      str = `${hour}:${minute}`
      return str
    }

    str = `${day}${option.bass || '/'}${month}${option.bass || '/'}${year}`
  } catch (error) {
    str = `2${option.bass || '/'}2${option.bass || '/'}2022`
  }
  return str
}

export const datetimeFormat = (date: string) => {
  let result = ''

  try {
    const newDate = new Date(date)
    const _time = newDate.toLocaleTimeString("en-GB")
    const _date = newDate.toLocaleDateString("en-GB")
    result = `${_time} ${_date}`
  } catch (error) {
    result = ''
  }

  return result
}
