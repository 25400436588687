import React, { useRef, useState, useEffect } from 'react'
import './CreateImportDataTemplateModal.scss'
import { Button, Form, Input, Modal, Select } from 'antd'
import DraggableListSorting from './DraggableListSorting'
import { mixGetV2, mixPostV2 } from '../../../../services/mixin'
import useToast from '../../../../hook/useToast';
import { API_URL_v2 } from "../../../../env";
interface Props {
  show: boolean
  modalClose?: () => void
  modalFinish?: () => void
  listSource?: []
  listSourceV2?: []
}

const CreateImportDataTemplateModal:React.FC<Props> = (props) => {
  const pushToast = useToast();
  const formRef: any = useRef(null)
  const { show, modalClose, modalFinish, listSource, listSourceV2 } = props
  const [loading, setLoading] = useState(false)
  const [selectedItems, setSelectedItems] = useState<any>([])

  const onFormSubmit = () => {
    formRef?.current.submit()
  }

  const onFormFinish = async (values: any) => {
    const param = {
      name: values.templateName,
      template_table: '[]',
      source_id: values.source_id,
    }
    const temp = [] as any
    selectedItems.map((val: any, index: Number) => {
      temp.push({
        source_column_warehouse_id: val.id,
        position: index
      })
      return true
    })
    param.template_table = JSON.stringify(temp)
    setLoading(true)
    const res = await mixPostV2('/api/v1/be/template_excel', param)
    if (res.status) {
      pushToast("Thao tác thành công", "success")
      setLoading(false)
      setSelectedItems([])
      setSourceItems([])
      formRef?.current.resetFields()
      if (modalFinish) {
        modalFinish()
      }
      window.location.href = `${API_URL_v2}/api/v1/warehouse/download_excel/${res.data.id}`
    } else {
      pushToast("Error!", "warning")
      setLoading(false)
    }
  }

  const onChooseSystem = (e: any) => {
    getcolumn(e)
  }

  const onModalClose = () => {
    console.log('on close')
    setSelectedItems([])
    setSourceItems([])
    formRef?.current.resetFields()

    if (modalClose) {
      modalClose()
    }
  }

  const [sourceItems, setSourceItems] = useState<any>([])
  let wait1 = false
  const getcolumn = async (id = null) => {
    if (!id) {
      return
    }
    if (wait1) {
      return
    }
    wait1 = true
    const res = await mixGetV2(`/api/v1/be/source_column_warehouses?type_id=${id}&is_active=1`)
    wait1 = false
    if (res.status) {
      const temp = [] as any
      res.data.items.map((val: any) => {
        temp.push({ id: val.id, text: val.name, code: `{${val.column_warehouse.column}}`, })
        return true
      })
      setSourceItems([...temp])
    }
  }

  return (
    <Modal
      open={show}
      title='Tạo mới template import dữ liệu vào trung tâm dữ liệu'
      className='rs-modal w-769 import-data-template-modal footer-content-end'
      maskClosable={false}
      keyboard={false}
      centered
      footer={() => (
        <>
          <Button
            className="rs-button"
            onClick={onModalClose}
          >Hủy</Button>
          <Button
            type="primary"
            loading={loading}
            className="rs-button"
            onClick={onFormSubmit}
          >Lưu và tải về</Button>
        </>
      )}
      onCancel={onModalClose}
    >
      <Form
        ref={formRef}
        className='rs-form'
        requiredMark={false}
        onFinish={onFormFinish}
      >
        <Form.Item
          label="Tên mẫu template"
          name="templateName"
          rules={[
            { required: true, message: 'Vui lòng nhập tên mẫu template' },
          ]}
        >
          <Input placeholder='Nhập tên' />
        </Form.Item>
        <Form.Item
          label="Loại"
          name="system"
        >
          <Select
            placeholder='Chọn loại'
            options={listSource}
            onChange={(e) => onChooseSystem(e)}
          />
        </Form.Item>

        <Form.Item
          label="Nguồn"
          name="source_id"
          rules={[
            { required: true, message: 'Vui lòng chọn nguồn.' },
          ]}
        >
          <Select
            placeholder='Chọn nguồn'
            options={listSourceV2}
          />
        </Form.Item>

        <DraggableListSorting
          items={sourceItems}
          onChange={(items) => setSelectedItems(items)}
        />
      </Form>
    </Modal>
  )
}

export default CreateImportDataTemplateModal
