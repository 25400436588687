import React, { useEffect } from 'react';
import Layout from '../../Layout';
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock';
import { Link } from 'react-router-dom';
import './Customers.scss';
import ARCFilterBlock from './ARCComponents/ARCFilterBlock';
import ARCStatisticalCards from './ARCComponents/ARCStatisticalCards';
import ARCTopCarOwned from './ARCComponents/ARCTopCarOwned';
import ARCGrowthChart from './ARCComponents/ARCGrowthChart';
import ARCTopCustomersByLocation from './ARCComponents/ARCTopCustomersByLocation';
import ARCTopCarsByLocation from './ARCComponents/ARCTopCarsByLocation';
import ARCTopCustomerByUnit from './ARCComponents/ARCTopCustomerByUnit';

const Customer: React.FC = () => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);

  return (
    <Layout contentClassName='arc-content'>
      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: 'Báo cáo phân tích', },
          { title: 'Khách hàng', },
        ]}
        title='Khách hàng'
      />

      <ARCFilterBlock />
      <ARCStatisticalCards />

      <div className="grid-2-cols">
        <ARCTopCarOwned />
        <ARCGrowthChart />
      </div>

      <div className="grid-2-cols">
        <ARCTopCustomersByLocation />
        <ARCTopCarsByLocation />
      </div>

      <ARCTopCustomerByUnit />
    </Layout>
  );
}

export default Customer;
