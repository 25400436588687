import React from "react";
import RightPopup from "../../components/Popup/RightPopup";
import { CrownOutlined } from "@ant-design/icons";
import './ShowRank.scss';
import * as BenefitApi from '../../api/rank/benefitApi';
import { URL_RANK } from "../../env";

interface Props {
  className?: string;
  onClose?: () => void;
  dataBenefitRank?: BenefitApi.IBenefitRank
}

const ShowRank: React.FC<Props> = ({ className, dataBenefitRank, onClose }) => {
  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <RightPopup
      title="Chi tiết"
      className={className}
      contentClassName="user-card-popup__content"
      onClose={handleOnClose}
    >
      <div className="rank-detail-block">
        <div className="name-detail-rank">
          <span className="font-bold rank-detail-label">Hạng</span>
          <div className="rank">
            <div className="img-box">
              <img
                src={URL_RANK + dataBenefitRank?.rank.icon}
                alt="bac"
                className='rank-detail-img'
              />
            </div>
            <span className='rank-name'>{dataBenefitRank?.rank.title}</span>
          </div>
        </div>
      </div>

      <div className="benefit-block">
        <div className="benefit-block__title font-bold">Quyền lợi hạng</div>
        <div className="benefit-card">
          {dataBenefitRank?.listBenefit.map((item, index) => (
            <div className="item" key={index}>
              <CrownOutlined />
              <span className="neutral-gray-500">{item.description}</span>
            </div>
          ))}



          {/* <img
            src="/images/brand-icon--blue.svg"
            alt="THACO AUTO"
            className="img-brand"
          /> */}
        </div>
      </div>
    </RightPopup>
  )
}

export default ShowRank