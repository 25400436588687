import React, { useEffect, useState } from "react";
import RightPopup from "../../../components/Popup/RightPopup";
import viVN from 'antd/locale/vi_VN';
import './WarrantyHistoryPopup.scss';
import { Button, ConfigProvider, DatePicker, Pagination } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import WarrantyHistoryItem from "../../../components/WarrantyHistory/WarrantyHistoryItem";
import WarrantyHistoryVertical from "../../../components/WarrantyHistory/WarrantyHistoryVetical";
import * as historyApi from '../../../api/history/historyApi';
import useToast from "../../../hook/useToast";

const { RangePicker } = DatePicker;

interface Props {
  className?: string;
  onClose?: () => void;
  onBack?: () => void;
  cardId: string | undefined;
  customerId: string | undefined;
}

const WarrantyHistoryPopup: React.FC<Props> = ({ className, onClose, onBack, customerId, cardId }) => {

  const [pagination, setPagination] = useState({ current: 1, pageSize: 2, total: 0 });
  const [dataHistoryMaintenance, setDataHistoryMaintenance] = useState<historyApi.DatatypeHistoryMaintenance[]>([])
  const pushToast = useToast()

  const getListHistoryMaintenance = async (limit: number = 2, pageNumber: number = 1, customerId: string = '', carId: string = '') => {
    const result = await historyApi.getListHistoryMaintenance(limit, pageNumber, customerId, carId)
    if (result?.status) {
      setDataHistoryMaintenance(convertData(result.data));
      setPagination({ current: pageNumber, total: result.total, pageSize: limit })
    } else {
      pushToast(result.message, 'error', 1000)
    }
  }
  const convertData = (data: historyApi.IHistoryMaintenance[]) => {
    const convertData = data.map((item) => ({
      type: item.typeInteractionName,
      title: 'Phiếu RO#' + item.codeMaintenance,
      info: {
        type: item.carName,
        bks: item.BKS,
        vin: item.VIN,
        carId: item.carCode,
        id: item.carId.toString(),
        maintenanceDay: item.dateMaintenance,
        finishDay: item.dateComplete,
      },
    }))
    return convertData
  }
  useEffect(() => {
    if (cardId && customerId) {
      getListHistoryMaintenance(2, 1, customerId, cardId)

    }
  }, [])
  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }
  }

  // const handleOnBack = () => {
  //   if (onBack && onClose) {
  //     onBack()
  //     onClose()

  //   }
  // }

  const onDateChange = (date: any, dateString: any, info: any) => {
    // console.log(date, dateString, info);
  }

  const handleChangePagination = (current: number, pageSize: number) => {
    getListHistoryMaintenance(pageSize, current, customerId, cardId)
  }

  return (
    <RightPopup
      title="Lịch sử bảo hành - bảo dưỡng"
      className={className}
      contentClassName="warranty-history-popup__content"
      onClose={handleOnClose}
    >
      <Button
        block
        className="rs-button primary-outline"
        onClick={handleOnClose}
      >
        <LeftOutlined />
        <span>Quay lại</span>
      </Button>

      <RangePicker
        className="rs-range-picker"
        popupClassName="rs-range-picker-dropdown"
        onCalendarChange={onDateChange}
      />

      <WarrantyHistoryVertical>
        {
          dataHistoryMaintenance.map((item, index) => (
            <WarrantyHistoryItem
              key={index}
              type={item.type}
              title={item.title}
              info={item.info}
            />
          ))
        }
      </WarrantyHistoryVertical>

      <div className="warranty-history-popup__footer">
        <ConfigProvider locale={viVN}>
          <Pagination
            total={pagination.total}
            current={pagination.current}
            pageSize={pagination.pageSize}
            onChange={handleChangePagination}
          />
        </ConfigProvider>
      </div>
    </RightPopup>
  )
}

export default WarrantyHistoryPopup
