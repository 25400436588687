import { paserParams } from "../../until";
import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";
import { SortType } from "../carLine/carLineApi";

export interface IVersionCar {
    id: number
    categoryCar: string
    carLines: string
    typeVehicle: string
    version: string
    status: number
    year: number | string
}

export interface IVersionCarEdit {
    id: number
    title: string
    categoryCarId: number
    carLinesId: number
    typeVehicleId: number
}

//Danh sách phiên bản xe
export const getListVersionCar = async (
    limit?: number, pageNumber?: number, searchNameVersion?: string, sort?: SortType) => {
    const path = "version/listVersion"
    const params = {
        limit, pageNumber, searchNameVersion, sort
    }
    const result: ResponseSuccessTotal<IVersionCar[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thông tin phiên bản xe
export const getInfoVersionCar = async (
    id: number) => {
    const path = "version/infoVersion"
    const params = {
        id
    }
    const result: ResponseSuccessTotal<IVersionCarEdit> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thêm mới phiên bản xe
export const addVersionCar = async (
    title: string, idCategoryCar: number, idCarlines: number, idTypeVehicle: number
) => {
    const url = `version/addVersion`;
    const params = {
        title, idCategoryCar, idCarlines, idTypeVehicle
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        url, paserParams(params, undefined)
    );
    return result;
}

//Chỉnh sửa phiên bản xe
export const editVersionCar = async (
    id: number, title: string, idCategoryCar: number, idCarlines: number, idTypeVehicle: number
) => {
    const url = `version/editVersion`;
    const params = {
        id, title, idCategoryCar, idCarlines, idTypeVehicle
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, paserParams(params, undefined)
    );
    return result;
}

//Xóa phiên bản xe
export const deleteVersionCar = async (
    id: Number
) => {
    const url = `version/deleteVersion`;
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        url, { params }
    );
    return result;
}

export const updateActiveVersion = async (
    id: number) => {
    const path = "version/activeVersion"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        path, params
    );
    return result;
};
