import { DeleteOutlined, DownloadOutlined, EditOutlined, FilterFilled, PlusOutlined, SearchOutlined, SettingOutlined, UploadOutlined } from '@ant-design/icons';
import { Breadcrumb, Checkbox, ConfigProvider, Input, Pagination, PaginationProps, Table, TableColumnsType, TableProps } from 'antd';
import React, { useEffect, useState } from 'react';
import Layout from '../../../Layout';
import viVN from 'antd/locale/vi_VN';
import './style.scss'
import ModalCreateCar from './Component/Modal/ModalCreateCar';
import ModalEditCar from './Component/Modal/ModalEditCar';
import ModalManagerSegment from './Component/Modal/ModalManagerSegment';
import DrawerAddvance from './Component/Drawer/DrawerAddvance';
import DrawerInfoCar from './Component/Drawer/DrawerInfoCar';
import ModalDeleteManager from '../../../components/ModalDeleteManager';
import useToast from '../../../hook/useToast';
import useDebounce from '../../../hook/useDebounce';
import * as carApi from '../../../api/car/carApi';
import { LIMIT_EXPORT_EXCEL, URL_CAR, URL_CAR_CATEGORY } from '../../../env';
import { carDefault } from '../../../components/ExportImgFigma';
import { dataPagination, exportExcel } from '../../../until';
import * as selectOptionsApi from '../../../api/selectOption/selectOptionsApi';
import * as attributeVehicleApi from '../../../api/attributeVehicle/attributeVehicleApi';
import useThrottle from '../../../hook/useThrottle';
import ModalUpload from '../../../components/Modal/ModalUpload';
import * as XLSX from 'xlsx';

const arrBrand: listBrand[] = [
  {
    id: 1,
    title: 'BMW',
    checked: false
  },
  {
    id: 2,
    title: 'MINI',
    checked: false
  },
  {
    id: 3,
    title: 'Peugeot',
    checked: false
  },
  {
    id: 4,
    title: 'Mazda',
    checked: false
  },
  {
    id: 5,
    title: 'KIA',
    checked: false
  }
]
interface listBrand {
  id: number;
  title: string;
  checked: boolean
}
interface DataType {
  key: React.Key;
  imgCar: string;
  clCar: string;
  codeCar: string;
  nameCar: string;
  logoBrand: string
  brand: string;
  segment: string;
  typeCar: string;
  yearManufacture: string
  versionCar: string
}


const VehicleList: React.FC = () => {

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Hành động',
      dataIndex: '',
      render: (_, record) => (
        <div className='t-hd-table'>
          <div
            className="item-hd-table cursor-pointer"
            onClick={() => gInfoCar(record.key)}>
            <span><EditOutlined /></span>
          </div>
          <DeleteOutlined
            className='text-F5222D text-sm border-EAECF0 p-5-px bg-white rounded-2'
            onClick={() => openModalDeleteCar(Number(record.key))}
          />
        </div>

      ),
      className: "w-93-px",
      align: 'center'
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'imgCar',
      render:
        (imgCar: any) =>
          <div className='w-24 h-11 cursor-pointer' onClick={() => setIsOpenInfoCar(true)}>
            <img
              src={imgCar}
              alt="imgCar"
              className='object-fit-contain w-full h-full'
              onError={(e) => {
                e.currentTarget.src = carDefault;
              }}
            />
          </div>,
      className: "w-129-px",
      align: "center"

    },
    {
      title: 'Chủng loại xe',
      dataIndex: 'clCar',
      sorter: {
        compare: (a, b) => a.clCar.length - b.clCar.length,
        multiple: 1,
      },
      className: "w-135-px"
    },
    {
      title: 'Mã xe',
      dataIndex: 'codeCar',
      sorter: {
        compare: (a, b) => a.codeCar.length - b.codeCar.length,
        multiple: 1,
      },
      render: (codeCar: string) => <div className='td-card-code'>{codeCar}</div>,
    },
    {
      title: 'Tên xe',
      dataIndex: 'nameCar',
      sorter: {
        compare: (a, b) => a.nameCar.length - b.nameCar.length,
        multiple: 1,
      },
      render: (nameCar: string) => <div className='td-card-name'>{nameCar}</div>,
    },
    {
      title: 'Thương hiệu',
      render: (logo: DataType) => <div className='d-flex align-center gap-3 w-full'>
        <div className='w-6 h-6'>
          <img src={logo.logoBrand} className='w-full h-full object-fit-cover' alt='logoBrand' />
        </div>

        <p className='text-sm font-regular text-98A2B3'>{logo.brand}</p>
      </div>,
      filterDropdown: () => {
        return filterVisible ? (
          <div className={`d-flex flex-column shadow-dropdown-fliter`}>
            <div className='d-flex flex-column gap-2 pl-4 py-3'>
              {
                listBrand.map((items, index) => (
                  <Checkbox
                    key={index}
                    checked={items.checked}
                    onChange={() => handleChangeCheck(index)}
                    className="size-check-box size-check-box-inner p-checkbox icon-check-box">
                    <span className="text-sm font-regular fw-400">{items.title}</span>
                  </Checkbox>
                ))
              }
            </div>
            <div className='d-flex justify-between p-2 border-t-F0F0F0'>
              <button
                disabled={!isAnyChecked}
                onClick={reSetAllChecked}
                className='border-none bg-white text-sm font-regular text-BFBFBF' >
                Reset
              </button>
              <button
                onClick={() => setFilterVisible(false)}
                className='border-none bg-096DD9 text-sm rounded-1 h-22-px px-7-px font-regular text-white'>
                Xác nhận
              </button>
            </div>
          </div>
        ) : null;
      },
      filterIcon: () => (
        <FilterFilled
          className='text-sm'
          onClick={() => setFilterVisible(true)}
        />
      ),
      className: "w-132-px ",
    },

    {
      title: 'Phân khúc',
      dataIndex: 'segment',
      sorter: {
        compare: (a, b) => a.segment.length - b.segment.length,
        multiple: 1,
      },
      render: (segment: string) => <div className='td-card-segment'>{segment}</div>,
    },
    {
      title: 'Dòng xe',
      dataIndex: 'typeCar',
      sorter: {
        compare: (a, b) => a.typeCar.length - b.typeCar.length,
        multiple: 1,
      },
      className: "w-163-px"
    },
    {
      title: 'Năm sản xuất',
      dataIndex: 'yearManufacture',
      sorter: {
        compare: (a, b) => a.yearManufacture.length - b.yearManufacture.length,
        multiple: 1,
      },
      className: "w-125px"
    },
    {
      title: 'Phiên bản xe',
      dataIndex: 'versionCar',
      sorter: {
        compare: (a, b) => a.versionCar.length - b.versionCar.length,
        multiple: 1,
      },
      render: (versionCar: string) => <div className='td-card-version'>{versionCar}</div>,
    },
  ];


  useEffect(() => {
    document.title = 'THACO AUTO CRM';

    gCLCarCompany()
    gCLAttributeVehicle()

    return () => {
      setFilterName(undefined)

      setIdCar(undefined)

      setPagination({ current: 1, pageSize: 10, total: 0 })

      setListCar([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pushToast = useToast();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenCreateCar, setIsOpenCreateCar] = useState(false)
  const [isOpenEditCar, setIsOpenEditCar] = useState(false)
  const [isOpenSegment, setIsOpenSegment] = useState(false)
  const [isOpenAddvance, setIsOpenAddvance] = useState(false)
  const [isOpenInfoCar, setIsOpenInfoCar] = useState(false)
  const [IsOpenDeleteVehicle, setIsOpenDeleteVehicle] = useState(false)
  const [filterVisible, setFilterVisible] = useState(false);
  const [listBrand, setListBrand] = useState<listBrand[]>(arrBrand)
  const [openModalUpload, setOpenModalUpload] = useState(false);

  const [filterName, setFilterName] = useState<string>()


  const [idCar, setIdCar] = useState<number>()

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });

  const [sLCategoryCar, setSLCategoryCar] = useState<selectOptionsApi.ICarCompany[]>([])
  const [sLAttributeVehicle, setSLAttributeVehicle] = useState<attributeVehicleApi.IAttributeVehicle[]>([])

  const [infoCar, setInfoCar] = useState<carApi.ICarEdit>()

  const [listCar, setListCar] = useState<DataType[]>([])

  //thương hiệu
  const gCLCarCompany = async () => {
    const result = await selectOptionsApi.getListCarCompany()
    if (result?.status) {
      setSLCategoryCar(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  //phan k
  const gCLAttributeVehicle = useDebounce(async () => {
    const result = await attributeVehicleApi.getListAttributeVehicle(1);
    if (result?.status) {
      setSLAttributeVehicle(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 300)

  const gListCar = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await carApi.getListCar(pageSize, pageCurent, filterName)
    if (result?.status) {
      setListCar(convertData(result.data, pageCurent, pageSize))
      setPagination({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const convertData = (data: carApi.ICar[], pageCurent: number, pageSize: number) => {
    // var stt = (pageCurent - 1) * pageSize + 1;
    const convertData = data.map((item) => ({
      key: item.id,
      imgCar: URL_CAR + item.image,
      clCar: item.typeVehicle,
      codeCar: item.code,
      nameCar: item?.title,
      logoBrand: URL_CAR_CATEGORY + item.categoryCarImage,
      brand: item.categoryCar,
      segment: item.segmentsVehicle,
      typeCar: item.carLines,
      yearManufacture: item.yearCreate.toString(),
      versionCar: item.version,
    }))
    return convertData
  }

  const onPaginationChange: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    gListCar(pageNumber, pageSize)
  }


  const onSubmitFormModalAdd = (iSsuccess: boolean, errorMessage?: string) => {
    if (iSsuccess) {
      setIsOpenCreateCar(false);
      pushToast("Thêm xe thành công", "success")
      gListCar()
    }
    else {
      pushToast(errorMessage, "warning")
    }
  }

  const gInfoCar = useThrottle(async (id: number) => {
    const result = await carApi.getInfoCar(id)
    if (result?.status) {
      setInfoCar(result.data)
      setIsOpenEditCar(true)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const onSubmitFormModalEdit = (iSsuccess: boolean, errorMessage?: string) => {
    if (iSsuccess) {
      setIsOpenEditCar(false);
      pushToast("Sửa xe thành công", "success")
      gListCar()
    }
    else {
      pushToast(errorMessage, "warning")
    }
  }

  const openModalDeleteCar = (id: number) => {
    setIdCar(id)
    setIsOpenDeleteVehicle(true)
  }

  const closeModalDeleteCar = () => {
    setIdCar(undefined)
    setIsOpenDeleteVehicle(false)
  }

  const onConfirmDelete = async () => {
    const result = await carApi.deleteCar(Number(idCar))
    if (result?.status) {
      pushToast("Xóa xe thành công", "success")
      closeModalDeleteCar()
      gListCar()
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onChangeTB: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };
  const handleChangeCheck = (valueCheck: number) => {
    setListBrand(
      prevState => {
        const updatedList = [...prevState];
        updatedList[valueCheck].checked = !updatedList[valueCheck].checked;
        return updatedList;
      }
    )
  }
  const reSetAllChecked = () => {
    setListBrand(prevState =>
      prevState.map(item => ({
        ...item,
        checked: false
      }))
    );
  }

  const isAnyChecked = listBrand.some(item => item.checked);

  const glistByExcel = useThrottle(async () => {
    const result = await carApi.getListCar(LIMIT_EXPORT_EXCEL, 1, filterName)
    if (result?.status) {
      formattedDataExcel(result.data, result?.message)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const formattedDataExcel = (data: carApi.ICar[], message?: string) => {
    const formattedData = data.map((item) => {
      return {
        "Tên": item.title,
        "Mã xe": item.code,
        "Phân khúc": item.segmentsVehicle,
        "Dòng xe": item.carLines,
        "Củng loại": item.typeVehicle,
        "Phiên bản": item.version,
        "Năm sản xuất": item.yearCreate,
        "Thương hiệu": item.categoryCar,
      }
    }
    );
    exportExcel(formattedData, message)
  }

  useEffect(() => {
    gListCar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterName])
  return (
    <Layout>
      {isOpenCreateCar &&
        <ModalCreateCar
          isOpen={isOpenCreateCar}
          sLCategoryCar={sLCategoryCar}
          sLAttributeVehicle={sLAttributeVehicle}
          onSubmitFormModal={onSubmitFormModalAdd}
          closeModal={() => setIsOpenCreateCar(!isOpenCreateCar)}
        />
      }

      {isOpenEditCar &&
        <ModalEditCar
          isOpen={isOpenEditCar}
          infoCar={infoCar}
          sLCategoryCar={sLCategoryCar}
          sLAttributeVehicle={sLAttributeVehicle}
          onSubmitFormModal={onSubmitFormModalEdit}
          closeModal={() => setIsOpenEditCar(!isOpenEditCar)}
        />
      }

      <ModalManagerSegment
        isOpen={isOpenSegment}
        closeModal={() => setIsOpenSegment(!isOpenSegment)}
      />
      <DrawerAddvance
        isOpen={isOpenAddvance}
        closeDrawer={() => setIsOpenAddvance(!isOpenAddvance)}
      />
      <DrawerInfoCar
        isOpen={isOpenInfoCar}
        closeDrawer={() => setIsOpenInfoCar(!isOpenInfoCar)}
      />
      <ModalDeleteManager
        isOpen={IsOpenDeleteVehicle}
        closeModal={closeModalDeleteCar}
        onConfirm={onConfirmDelete}
        title='Bạn có chắc chắn xóa xe này'
        titleDelete='Xóa xe'
        content='Xe và dữ liệu liên quan sẽ bị xóa vĩnh viễn không thể khôi phục'
      />
      <ModalUpload
        isOpen={openModalUpload}
        closeModal={() => setOpenModalUpload(!openModalUpload)}
      />
      <Breadcrumb
        items={[
          {
            title: 'THACO AUTO CRM',
          },
          {
            title: <a href="/">Quản lý xe</a>,
          },
          {
            title: 'Quản lý xe',
          },
        ]}
      />
      <div className='title-page'>
        Quản lý xe
      </div>
      <div className='d-flex gap-3 pb-18-px vehicle-list-filter'>
        <div className="w-630-px border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center pl-3  py-1 h-8 justify-between overflow-hidden">
          <Input
            placeholder="Tìm kiếm xe theo từ khóa"
            className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input place-holder-input-667085 hover-none"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          />
          <div className=" border-l-F2F4F7 h-full d-flex align-center">
            <SearchOutlined className="text-sm text-98A2B3 px-9-px" />
          </div>
        </div>
        <button className='border-EAECF0 bg-white rounded-6-px w-106-px py-2 h-8 d-flex justify-center align-center gap-10-px shadow-btn-save'
          onClick={glistByExcel}
        >
          <DownloadOutlined className='text-sm text-344054' />
          <span className='text-sm fw-400 font-regular text-344054 white-space-nowrap'>Xuất DS</span>
        </button>
        <button
          className='border-EAECF0 bg-white rounded-6-px w-111-px py-2 h-8 d-flex justify-center align-center gap-10-px shadow-btn-save'
          onClick={() => setOpenModalUpload(true)}
        >
          <UploadOutlined className='text-sm text-344054' />
          <span className='text-sm fw-400 font-regular text-344054 white-space-nowrap'>Nhập DS</span>
        </button>
        <button className='border-EAECF0 bg-white rounded-6-px w-146-px py-2 h-8 d-flex justify-center align-center gap-10-px shadow-btn-save'
          onClick={() => setIsOpenSegment(!isOpenSegment)}
        >
          <span className='text-sm fw-400 font-regular text-344054 white-space-nowrap'>Quản lý thuộc tính</span>
        </button>
        <button className='border-none bg-096DD9 rounded-6-px  py-2 h-8 d-flex justify-center align-center gap-10-px w-107-px'
          onClick={() => setIsOpenCreateCar(true)}
        >
          <PlusOutlined className='text-sm text-white' />
          <span className='text-sm fw-400 font-regular text-white white-space-nowrap'>Tạo mới</span>
        </button>
        <button className='border-EAECF0 bg-white rounded-2  w-8  h-8  shadow-btn-save'
          onClick={() => setIsOpenAddvance(true)}
        >
          <SettingOutlined className='text-sm text-344054' />
        </button>
      </div>
      <div className="t-table-block">
        <Table
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={listCar}
          onChange={onChangeTB}
          pagination={false}
          showSorterTooltip={false}
          className="w-fit-table border-end-table zise-filter"
          scroll={{ x: true }}
        />
        <ConfigProvider locale={viVN}>
          <div className="pagination-block">
            <Pagination
              showSizeChanger
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              showTotal={(total) => `Tổng cộng ${dataPagination(pagination.total, pagination.pageSize, pagination.current).totalPage} trang`}
              className='rs-pagination'
              onChange={onPaginationChange}

            />
          </div>
        </ConfigProvider>
      </div>
    </Layout>
  );
}

export default VehicleList;
