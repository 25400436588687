import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Select, Table, Tag } from 'antd'
import './CareFeedback.scss'
import { UploadOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import type { GetProps } from 'antd'
import tableData from './feedback-data.json'
import CreateCareFeedbackModal from '../Modal/CreateCareFeedback'

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>
dayjs.extend(customParseFormat)
const { RangePicker } = DatePicker
// eslint-disable-next-line arrow-body-style
const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days before today and today
  return current && current > dayjs().endOf('day')
}
interface Props {
  id: number;
}
const CareFeedbackTab: React.FC<Props> = ({
  id
}) => {
  const tableColumns: any = [
    { title: 'STT', dataIndex: 'key', key: 'key', align: 'center', },
    { title: 'Ngày chăm sóc', dataIndex: 'date', key: 'date', sorter: true, },
    {
      title: 'Hệ thống', dataIndex: 'system', key: 'system',
      render: (text: string) => <Tag className='rs-tag geek-blue'>{ text }</Tag>
    },
    {
      title: 'Trạng thái', dataIndex: 'status', key: 'status',
      render: (text: string, record: any) => <Tag color={record.statusType} className='rs-tag'>{ text }</Tag>
    },
    { title: 'Ghi chú', dataIndex: 'note', key: 'note', },
    { title: 'Đơn vị', dataIndex: 'unit', key: 'unit', },
    { title: 'Nguồn', dataIndex: 'source', key: 'source', },
    {
      title: 'Nhân viên xử lý', dataIndex: 'staff', key: 'staff',
      render: (text: string) => <span className='primary-6'>{ text }</span>
    },
  ]

  // CREATE FEEDBACK MODAL
  const [showCreateCareFeedbackCarModal, setShowCreateCareFeedbackCarModal] = useState(false)

  return (
    <>
      <div className="care-feedback-tab-new">
        <div className="filter-block">
          <RangePicker
            className="rs-range-picker"
            popupClassName="rs-range-picker-dropdown"
            disabledDate={disabledDate}
            placeholder={['Từ ngày', 'Đến ngày']}
          />
          <Select
            options={[
              { value: '1', label: 'DDMS' },
              { value: '2', label: 'IPCC' },
              { value: '3', label: 'SALESFORCE' },
            ]}
            placeholder="Chọn hệ thống"
            className='rs-select'
            popupClassName='rs-select-dropdown'
          />
          <Select
            options={[
              { value: '1', label: 'Tư vấn' },
              { value: '2', label: 'Tư vấn giải pháp' },
              { value: '3', label: 'Hỗ trợ' },
              { value: '4', label: 'Khiếu nại' },
              { value: '5', label: 'Đăng ký lái thử' },
              { value: '6', label: 'Bàn giao xe' },
            ]}
            placeholder="Chọn trạng thái"
            className='rs-select'
            popupClassName='rs-select-dropdown'
          />
          <Select
            options={[
              { value: '1', label: 'Zalo' },
              { value: '2', label: 'Facebook' },
              { value: '3', label: 'Hotline' },
              { value: '4', label: 'Khác' },
            ]}
            placeholder="Nguồn"
            className='rs-select'
            popupClassName='rs-select-dropdown'
          />
          <Button
            icon={<DownloadOutlined />}
            className='rs-button rs-button--sm'
          >Xuất DS</Button>
          <Button
            icon={<UploadOutlined />}
            className='rs-button rs-button--sm'
          >Nhập DS</Button>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            className='rs-button rs-button--sm'
            onClick={() => setShowCreateCareFeedbackCarModal(true)}
          >Thêm chăm sóc</Button>
        </div>

        <Table
          className='rs-table'
          columns={tableColumns}
          dataSource={tableData}
          showSorterTooltip={false}
          pagination={false}
          scroll={{ x: true }}
          // pagination={{
          //   showSizeChanger: true,
          //   defaultPageSize: 15,
          //   showTotal: (total) => `Tổng cộng ${Math.ceil(total / 15)} trang`,
          //   locale: { items_per_page: ' / trang', jump_to: 'Tới' },
          //   position: ['bottomCenter'],
          //   className: 'rs-pagination',
          //   showQuickJumper: true,
          // }}
        />
      </div>

      <CreateCareFeedbackModal
        show={showCreateCareFeedbackCarModal}
        modalClose={() => setShowCreateCareFeedbackCarModal(false)}
      />
    </>
  )
}

export default CareFeedbackTab;
