import { Button, Modal, Steps } from 'antd'
import React, { useState } from 'react'
import './ImportDataModal.scss'
import ImportDataStep1 from './Step1'
import ImportDataStep2 from './Step2'
import ImportDataStep3 from './Step3'
import ImportDataStep4 from './Step4'
import { mixPostV2 } from '../../../../services/mixin'
import useToast from '../../../../hook/useToast';

interface Props {
  show: boolean
  modalClose?: () => void
}

const ImportDataModal: React.FC<Props> = (props) => {
  const { show, modalClose } = props
  const pushToast = useToast();
  const [currentStep, setCurrentStep] = useState(0)
  const [listFiles, setListFiles] = useState([] as any)

  const importFile = async (index: any) => {
    const temp = listFiles
    if (temp[index]) {
      const param = {
        file: temp[index].temp
      }
      temp[index].status = 2
      const res = await mixPostV2('/api/v1/be/warehouse/import_file', param)
      temp[index].res = res
      if (res.status) {
        temp[index].status = 3
      } else {
        temp[index].status = 4
      }
      const next = index + 1
      setListFiles([...temp])
      importFile(next)
    } else {
      return
    }
  }
  const startImport = () => {
    if (listFiles.length === 0) {
      pushToast("Vui lòng chọn file!", "warning")
      return
    }
    importFile(0)
    setCurrentStep(3)
  }
  return (
    <Modal
      open={show}
      title='Chọn hệ thống và nhập dữ liệu'
      className='rs-modal w-824 import-data-modal'
      maskClosable={false}
      keyboard={false}
      centered
      footer={() => (
        <>
          {
            currentStep === 0 && (
              <Button
                className="rs-button"
                onClick={modalClose}
              >Hủy</Button>
            )
          }
          {
            (currentStep !== 0 && currentStep !== 3) && (
              <Button
                className="rs-button"
                onClick={() => { setCurrentStep(currentStep - 1) }}
              >Quay lại</Button>
            )
          }
          {
            currentStep === 1 && (
              <Button
                className="rs-button"
                onClick={() => { setCurrentStep(currentStep + 1) }}
              >Bỏ qua bước này</Button>
            )
          }
          {
            currentStep !== 3 && (
              <Button
                type="primary"
                className="rs-button"
                onClick={() => { startImport() }}
              >Tiếp theo</Button>
            )
          }
          {
            currentStep === 3 && (
              <>
                <Button
                  className="rs-button"
                  onClick={() => { setCurrentStep(0); setListFiles([]) }}
                >Import thêm</Button>
                <Button
                  type="primary"
                  className="rs-button"
                  onClick={modalClose}
                >Đóng</Button>
              </>
            )
          }
        </>
      )}
      onCancel={modalClose}
      afterClose={() => setCurrentStep(0)}
    >
      <Steps
        current={currentStep}
        type="navigation"
        items={[
          {
            title: 'Tải file excel',
          },
          // {
          //   title: 'Kiểm tra trùng lặp',
          // },
          // {
          //   title: 'Thiết lập dữ liệu',
          // },
          {
            title: 'Kết quả',
          },
        ]}
        className='rs-steps'
      />

      <div className="import-data-modal-content">
        {
          currentStep === 0 && <ImportDataStep1 listFilesChange={(e) => setListFiles(e)} />
        }
        {/* {
          currentStep === 1 && <ImportDataStep2 />
        }
        {
          currentStep === 2 && <ImportDataStep3 />
        } */}
        {
          currentStep === 3 && <ImportDataStep4 listFiles={listFiles} />
        }
      </div>
    </Modal>
  )
}

export default ImportDataModal
