import React, { useEffect, useState } from 'react';
import { Breadcrumb, Input, Table, Pagination, ConfigProvider, Switch, Select } from 'antd';
import type { PaginationProps, TableColumnsType, TableProps } from 'antd';
import {
  DownloadOutlined, EyeOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FilterFilled, UploadOutlined,
} from '@ant-design/icons';
import viVN from 'antd/locale/vi_VN';
import './style.scss';
import Layout from '../../../Layout';
import DrawerInfoAccount from './Drawer/InfoAccount';
import ModalDeleteManager from '../../../components/ModalDeleteManager';
import DrawerEditAccount from './Drawer/EditAccount';
import useToast from '../../../hook/useToast';
import useDebounce from '../../../hook/useDebounce';
import { IAccount, IAccountDetail, getInfoAccount, getListUserAccount, updateActiveUserAccount } from '../../../api/groupPermission/groupPermissionApi';
import * as  selectOptionsApi from '../../../api/selectOptions/selectOptionsApi';
import { dataPagination, exportExcel, fommatSelectOption } from '../../../until';
import useThrottle from '../../../hook/useThrottle';
import * as XLSX from 'xlsx';
import { API_URL, LIMIT_EXPORT_EXCEL } from '../../../env';
import ModalUpload from '../../../components/Modal/ModalUpload';
import ModalUpload_V2 from '../../../components/Modal/ModalUpload_V2';

interface DataType {
  key: React.Key;
  stt: number;
  dv: string;
  knv: string;
  pb: string;
  email: string;
  name: string;
  nq: string;
  tq: string
  hd: boolean
}

interface DataFilter {
  unitId: number | undefined;
  businessUnitId: number | undefined;
  search: string | undefined;
}




const AccountsManagement: React.FC = () => {
  const columns: TableColumnsType<DataType> = [
    {
      title: 'Hành động',
      dataIndex: 'hd',
      align: 'center',
      render: (_, record) => (
        <>
          <div className="t-hd-table">
            <div
              className="item-hd-table cursor-pointer"
              onClick={() => gInfoAccount(Number(record.key), false)}
            >
              <span><EyeOutlined />
              </span>
            </div>
            <div
              className="item-hd-table cursor-pointer"
              onClick={() => gInfoAccount(Number(record.key), true)}
            ><span><EditOutlined /></span>
            </div>
            {/* <div className="item-hd-table t-cl-F5222D cursor-pointer" onClick={() => setIsOpenDeleteAccount(true)}><span><DeleteOutlined /></span></div> */}
            <div className="t-switch-block t-switch-block-sm">
              <Switch
                checked={record.hd}
                onChange={() => uActiveAccount(Number(record.key))}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      sorter: {
        compare: (a, b) => a.stt - b.stt,
        multiple: 1,
      },
    },
    {
      title: 'Họ và tên',
      dataIndex: 'name',
    },
    {
      title: 'Nhóm quyền',
      dataIndex: 'nq',
    },
    {
      title: 'Toàn quốc',
      dataIndex: 'tq',
    },
    {
      title: 'Đơn vị',
      dataIndex: 'dv',
      // filterDropdown: () => {
      //   return filterVisible ? (
      //     <div className={`d-flex flex-column shadow-dropdown-fliter  `}>
      //       <div className='p-2 '>
      //         <Input
      //           placeholder="Tên đơn vị"
      //           className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
      //         />
      //       </div>
      //       <div className='d-flex gap-2 p-2 border-t-F0F0F0'>
      //         <button
      //           className='border-none bg-096DD9 text-sm font-regular text-white w-full rounded-6-px d-flex align-center gap-1 justify-center' >
      //           <SearchOutlined className="text-sm text-white " />
      //           Tìm
      //         </button>
      //         <button
      //           onClick={() => setFilterVisible(false)}
      //           className='border-F2F4F7 bg-white text-sm rounded-6-px h-8  font-regular text-262626 w-full'>
      //           Đóng
      //         </button>
      //       </div>
      //     </div>
      //   ) : null;
      // },
      // filterIcon: () => (
      //   <FilterFilled
      //     className='text-sm'
      //     onClick={() => setFilterVisible(true)}
      //   />
      // ),
    },
    {
      title: 'Khối nghiệp vụ',
      dataIndex: 'knv',
      // filterDropdown: () => {
      //   return filterVisible ? (
      //     <div className={`d-flex flex-column shadow-dropdown-fliter  `}>
      //       <div className='p-2 '>
      //         <Input
      //           placeholder="Tên khối nghiệp vụ"
      //           className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
      //         />
      //       </div>
      //       <div className='d-flex gap-2 p-2 border-t-F0F0F0'>
      //         <button
      //           className='border-none bg-096DD9 text-sm font-regular text-white w-full rounded-6-px d-flex align-center gap-1 justify-center' >
      //           <SearchOutlined className="text-sm text-white " />
      //           Tìm
      //         </button>
      //         <button
      //           onClick={() => setFilterVisible(false)}
      //           className='border-F2F4F7 bg-white text-sm rounded-6-px h-8  font-regular text-262626 w-full'>
      //           Đóng
      //         </button>
      //       </div>
      //     </div>
      //   ) : null;
      // },
      // filterIcon: () => (
      //   <FilterFilled
      //     className='text-sm'
      //     onClick={() => setFilterVisible(true)}
      //   />
      // ),
    },
    {
      title: 'Phòng ban',
      dataIndex: 'pb',
      // filterDropdown: () => {
      //   return filterVisible ? (
      //     <div className={`d-flex flex-column shadow-dropdown-fliter  `}>
      //       <div className='p-2 '>
      //         <Input
      //           placeholder="Tên phòng ban"
      //           className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
      //         />
      //       </div>
      //       <div className='d-flex gap-2 p-2 border-t-F0F0F0'>
      //         <button
      //           className='border-none bg-096DD9 text-sm font-regular text-white w-full rounded-6-px d-flex align-center gap-1 justify-center' >
      //           <SearchOutlined className="text-sm text-white " />
      //           Tìm
      //         </button>
      //         <button
      //           onClick={() => setFilterVisible(false)}
      //           className='border-F2F4F7 bg-white text-sm rounded-6-px h-8  font-regular text-262626 w-full'>
      //           Đóng
      //         </button>
      //       </div>
      //     </div>
      //   ) : null;
      // },
      // filterIcon: () => (
      //   <FilterFilled
      //     className='text-sm'
      //     onClick={() => setFilterVisible(true)}
      //   />
      // ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      // filterDropdown: () => {
      //   return filterVisible ? (
      //     <div className={`d-flex flex-column shadow-dropdown-fliter  `}>
      //       <div className='p-2 '>
      //         <Input
      //           placeholder="Tên email"
      //           className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
      //         />
      //       </div>
      //       <div className='d-flex gap-2 p-2 border-t-F0F0F0'>
      //         <button
      //           className='border-none bg-096DD9 text-sm font-regular text-white w-full rounded-6-px d-flex align-center gap-1 justify-center' >
      //           <SearchOutlined className="text-sm text-white " />
      //           Tìm
      //         </button>
      //         <button
      //           onClick={() => setFilterVisible(false)}
      //           className='border-F2F4F7 bg-white text-sm rounded-6-px h-8  font-regular text-262626 w-full'>
      //           Đóng
      //         </button>
      //       </div>
      //     </div>
      //   ) : null;
      // },
      // filterIcon: () => (
      //   <FilterFilled
      //     className='text-sm'
      //     onClick={() => setFilterVisible(true)}
      //   />
      // ),
    },

  ];


  useEffect(() => {
    document.title = 'THACO AUTO CRM';
    gCLUnitChild()
    gSLBusinessUnit()

    return () => {
      setDataFilter({ unitId: undefined, businessUnitId: undefined, search: undefined })

      setPagination({ current: 1, pageSize: 12, total: 0 })

      setListAccount([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pushToast = useToast();

  const [isOpenDeleteAccount, setIsOpenDeleteAccount] = useState<boolean>(false)
  const [openDrawerInfoAccount, setOpenDrawerInfoAccount] = useState<boolean>(false)
  const [openDrawerEditAccount, setOpenDrawerEditAccount] = useState<boolean>(false)
  const [openModalUpload, setOpenModalUpload] = useState(false);

  const [dataFilter, setDataFilter] = useState<DataFilter>({ unitId: undefined, businessUnitId: undefined, search: undefined })

  const [pagination, setPagination] = useState({ current: 1, pageSize: 12, total: 0 });
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [sLUnitChild, setSLUnitChild] = useState<selectOptionsApi.IUnitChild[]>([])
  const [sLBusinessUnit, setSLBusinessUnit] = useState<selectOptionsApi.IBusinessUnit[]>([])

  const [infoAccount, setInfoAccount] = useState<IAccountDetail>()
  const [listAccount, setListAccount] = useState<DataType[]>([])

  //Danh sách đơn vị
  const gCLUnitChild = async () => {
    const result = await selectOptionsApi.getListUnitChild()
    if (result?.status) {
      setSLUnitChild(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const gSLBusinessUnit = async () => {
    const result = await selectOptionsApi.getListBusinessUnit()
    if (result?.status) {
      setSLBusinessUnit(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const gInfoAccount = useThrottle(async (id: number, isUpdate: boolean) => {
    const result = await getInfoAccount(id)
    if (result?.status) {
      setInfoAccount(result.data)
      if (isUpdate) {
        setOpenDrawerEditAccount(true)
      } else {
        setOpenDrawerInfoAccount(true)
      }
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const gListAccount = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await getListUserAccount(pageSize, pageCurent, dataFilter.unitId, dataFilter.businessUnitId, dataFilter.search)
    if (result?.status) {
      setListAccount(convertData(result.data, pageCurent, pageSize))
      setPagination({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const convertData = (data: IAccount[], pageCurent: number, pageSize: number) => {
    var stt = (pageCurent - 1) * pageSize + 1;
    const convertData = data.map((item) => ({
      key: item.id,
      stt: stt++,
      dv: item.unit,
      knv: item.businessUnit,
      pb: item.department,
      email: item.email,
      name: item.fullname,
      nq: item.groupPermission,
      tq: item.region,
      hd: item.status ? true : false
    }))
    return convertData
  }

  const uActiveAccount = useThrottle(async (id: number) => {
    const result = await updateActiveUserAccount(id);
    if (result?.status) {
      gListAccount(pagination.current, pagination.pageSize)
      pushToast(result.message, "success", 2000)
    } else {
      pushToast(result.message, "error", 2000)

    }
  }, 2000)

  const gListAccountByExcel = useThrottle(async () => {
    const result = await getListUserAccount(LIMIT_EXPORT_EXCEL, 1, dataFilter.unitId, dataFilter.businessUnitId, dataFilter.search)
    formattedDataExcel(result.data, result.message)
  }, 1000)

  const formattedDataExcel = (data: IAccount[], message?: string) => {
    const formattedData = data.map((item) => {
      return {
        "Họ và tên": item.fullname,
        "Nhóm quyền": item.groupPermission,
        "Toàn quốc": item.region,
        "Đơn vị": item.unit,
        "Khối nghiệp vụ": item.businessUnit,
        "Phòng ban": item.department,
        "Email": item.email,
        "Trạng thái": item.status ? "Đang hoạt động" : "Không hoạt động",
      }
    }
    );
    exportExcel(formattedData, message)
  }


  const onSubmitFormModalEdit = (iSsuccess: boolean, message?: string) => {
    if (iSsuccess) {
      setOpenDrawerEditAccount(false);
      pushToast(message, "success")
      gListAccount()
    }
    else {
      pushToast(message, "warning")
    }
  }

  const onPaginationChange: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    gListAccount(pageNumber, pageSize)
  }



  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onChangeTB: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    // console.log('params', pagination, filters, sorter, extra);
  };

  const onResultUploadFile = (iSsuccess: boolean, message?: string) => {
    if (iSsuccess) {
      setOpenModalUpload(false);
      pushToast(message, "success")
      gListAccount()
    }
    else {
      pushToast(message, "warning")
    }
  }



  const exportExcelByUpload = (data: any[], message: string) => {
    const formattedData = data.map((item) => {
      return {
        "Họ tên*": item?.fullname,
        "UserName": item?.username,
        "Password": item?.password,
        "Id nhóm quyền*": item?.groupPermissionId,
        "Vùng miền*": item?.nationwideId,
        "Toàn quốc*": item?.regionId,
        "Đơn vị*": item?.unitId,
        "Khối nghiệp vụ*": item?.businessUnitId,
        "Email*": item?.email,
        "Phòng ban*": item?.departmentId,
      }
    }
    );
    exportExcel(formattedData, message)
  }

  useEffect(() => {
    gListAccount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter])

  return (
    <div className='accounts-management-list'>
      {/* <Helmet>
        <title>THACO AUTO CRM</title>
      </Helmet> */}
      <Layout>
        <DrawerInfoAccount
          isOpen={openDrawerInfoAccount}
          infoAccount={infoAccount}
          closeDrawer={() => setOpenDrawerInfoAccount(false)}
        />
        <DrawerEditAccount
          isOpen={openDrawerEditAccount}
          infoAccount={infoAccount}
          sLUnitChild={sLUnitChild}
          sLBusinessUnit={sLBusinessUnit}
          onSubmitFormModal={onSubmitFormModalEdit}
          closeDrawer={() => setOpenDrawerEditAccount(false)}
        />
        <ModalDeleteManager
          isOpen={isOpenDeleteAccount}
          title="Bạn có chắc chắn xóa người dùng này?"
          titleDelete="Xoá người dùng này"
          content="Tài khoản này sẽ bị xóa và vĩnh viễn không thể khôi phục"
          closeModal={() => setIsOpenDeleteAccount(false)}
        />

        <ModalUpload_V2
          isOpen={openModalUpload}
          linkSampleFile={"https://api.thacocrm.bdata.link/fileExcel/staff/DanhSachTaiKhoan.xlsx"}
          linkUploandFile={`${API_URL}groupPermission/importExcel`}

          onResultUploadFile={onResultUploadFile}
          exportExcelByUpload={exportExcelByUpload}
          closeModal={setOpenModalUpload}
        />
        <Breadcrumb
          items={[
            {
              title: 'THACO AUTO CRM',
            },
            {
              title: <a href="/">Cấu hình</a>,
            },
            {
              title: 'Quản lý tài khoản',
            },
          ]}
        />

        <div className='title-page'>
          Quản lý tài khoản người dùng
        </div>

        <div className='d-flex gap-3 pb-18-px'>

          <Select
            placeholder="Tìm hoặc chọn đơn vị"
            className="custome-select-w-210-px"
            popupClassName='popup-select'
            options={fommatSelectOption(sLUnitChild)}
            onChange={(e) => setDataFilter({ ...dataFilter, unitId: e })}
          />

          <Select
            placeholder="Khối nghiệp vụ"
            className="custome-select-w-210-px"
            popupClassName='popup-select '
            options={fommatSelectOption(sLBusinessUnit)}
            onChange={(e) => setDataFilter({ ...dataFilter, businessUnitId: e })}
          />

          <div className="w-800-px border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center pl-3  py-1 h-8 justify-between overflow-hidden">
            <Input
              placeholder="Tìm người dùng theo tên hoặc mã nhân viên"
              className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input place-holder-input-667085 hover-none"
              onChange={(e) => setDataFilter({ ...dataFilter, search: e.target.value })}
            />
            <div className=" border-l-F2F4F7 h-full d-flex align-center">
              <SearchOutlined className="text-sm text-98A2B3 px-9-px" />
            </div>
          </div>
          <button
            className='border-EAECF0 bg-white rounded-6-px w-106-px py-2 h-8 d-flex justify-center align-center gap-10-px shadow-btn-save'
            onClick={gListAccountByExcel}
          >
            <DownloadOutlined className='text-sm text-344054'
            />
            <span className='text-sm fw-400 font-regular text-344054 white-space-nowrap'>Xuất DS</span>
          </button>
          <button
            className='border-EAECF0 bg-white rounded-6-px w-111-px py-2 h-8 d-flex justify-center align-center gap-10-px shadow-btn-save'
            onClick={() => setOpenModalUpload(true)}
          >
            <UploadOutlined className='text-sm text-344054' />
            <span className='text-sm fw-400 font-regular text-344054 white-space-nowrap'>Nhập DS</span>
          </button>
        </div>

        <div className="t-table-block t-table-accounts-management">
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={listAccount}
            onChange={onChangeTB}
            pagination={false}
            showSorterTooltip={false}
            className=" zise-filter "
          />
          <ConfigProvider locale={viVN}>
            <div className="pagination-block">
              <Pagination
                showSizeChanger
                current={pagination.current}
                pageSize={pagination.pageSize}
                total={pagination.total}
                showTotal={(total) => `Tổng cộng ${dataPagination(pagination.total, pagination.pageSize, pagination.current).totalPage} trang`}
                className='rs-pagination'
                onChange={onPaginationChange}

              />
            </div>
          </ConfigProvider>
        </div>

      </Layout>
    </div>
  );
}

export default AccountsManagement;
