import { DownloadOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import React from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const data = [
  {
    name: 'HCM',
    value: 1200,
  },
  {
    name: 'Hà Nội',
    value: 540,
  },
  {
    name: 'Đà Nẵng',
    value: 280,
  },
  {
    name: 'Nha Trang',
    value: 180,
  },
]

const ARCTopCarsByLocation: React.FC = () => {
  return (
    <div className="arc-card">
      <div className="arc-card__head">
        <span className="title">Top xe theo vị trí</span>
        <div className="control">
          <Select
            placeholder="Từ cao đến thấp"
            defaultValue="cao-den-thap"
            options={[
              { value: "cao-den-thap", label: "Từ cao đến thấp" },
              { value: "thap-den-cao", label: "Từ thấp đến cao" },
            ]}
            className="rs-select"
            popupClassName="rs-select-dropdown"
          />
          <Button className="rs-button">
            <DownloadOutlined />
            <span>Xuất báo cáo</span>
          </Button>
        </div>
      </div>

      <div className="arc-card__content chart-h350">
        <ResponsiveContainer aspect={539/350}>
          <BarChart
            width={539}
            height={350}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            layout="vertical"
            className="rs-recharts"
          >
            <defs>
              <linearGradient id="barGradientPrimary7" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#1890FF" stopOpacity={1}/>
                <stop offset="100%" stopColor="#8CC8FF" stopOpacity={1}/>
              </linearGradient>
            </defs>

            <CartesianGrid
              strokeDasharray="4 4"
              vertical={false}
            />

            <XAxis
              type="number"
            />

            <YAxis
              dataKey="name"
              type="category"
              width={39}
            />

            <Tooltip cursor={{ fill: 'transparent' }} />

            <Bar dataKey="value" fill="url(#barGradientPrimary7)" maxBarSize={40} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ARCTopCarsByLocation;
