import React, { useEffect, useState } from 'react';
import Layout from '../../Layout';
import { Link } from 'react-router-dom';
import { Tabs } from 'antd';
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock';
import './CustomerDetail.scss'
import InformationTab from './Tabs/Information';
import InteractiveHistoryTab from './Tabs/InteractiveHistory';
import CareFeedbackTab from './Tabs/CareFeedback';
import SourceTab from './Tabs/Source';
import { useParams, useNavigate } from 'react-router-dom';
import * as customerApi from '../../api/customer/customerApi';



const CustomerDetail: React.FC = () => {

  useEffect(() => {

    return (() => {
      setInfoCustomer(undefined)
    })
  }, []);

  const { id } = useParams();
  const navigate = useNavigate()

  const [infoCustomer, setInfoCustomer] = useState<customerApi.ICustomerDetail>()

  const gInfoCustomer = async () => {
    const result = await customerApi.getInfoCustomer(Number(id))
    if (result?.status) {
      setInfoCustomer(result.data)
    } else {

    }
  }


  useEffect(() => {
    if (id) {
      gInfoCustomer()
    } else {
      navigate(-1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, id])


  return (
    <Layout contentClassName='customer-detail-content'>
      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: <Link to="/customer/list">Khách hàng</Link>, },
          { title: <Link to="/customer/list">Danh sách khách hàng</Link>, },
          { title: 'Thông tin khách hàng', },
        ]}
        title='Thông tin khách hàng'
        backLink='/customer/list'
      />

      <Tabs
        defaultActiveKey="information"
        items={
          [
            {
              label: 'Thông tin',
              key: 'information',
              children:
                <InformationTab
                  infoCustomer={infoCustomer}
                />,
            },
            // {
            //   label: 'Lịch sử tương tác',
            //   key: 'interactive-history',
            //   children:
            //     <InteractiveHistoryTab
            //       id={Number(id)}
            //     />
            // },
            {
              label: 'Chăm sóc / phản hồi',
              key: 'care-feedback',
              children:
                <CareFeedbackTab
                  id={Number(id)}
                />,
            },
            // {
            //   label: 'Nguồn',
            //   key: 'source',
            //   children: <SourceTab />,
            // },
          ]
        }
        className='rs-tabs'
      />
    </Layout>
  );
}

export default CustomerDetail;
