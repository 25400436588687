import { CalendarOutlined, DeleteOutlined, EditFilled, SearchOutlined, SettingOutlined, ShareAltOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons"
import { ConfigProvider, Input, Pagination, Switch, Table, TableColumnsType, TableProps } from "antd"
import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { arrayGroup } from "../.."
import BreadcrumbBlock from "../../../../../components/BreadcrumbBlock/BreadcrumbBlock"
import Layout from "../../../../../Layout"
import ModalAddCustomer from "../Modal/ModalAddCustomer"
import ModalDeleteGroup from "../Modal/ModalDeleteGroup"
import ModalUpdateInfoGroupV2 from "../Modal/ModalUpdateInfoGroupV2"
import useToast from "../../../../../hook/useToast"
import useDebounce from "../../../../../hook/useDebounce"
import * as groupCustomerApi from "../../../../../api/groupCustomer/groupCustomerApi"
import { dataGender, dataTypeGroup } from "../../../../../services/data"
import { dataPagination, formatPrice } from "../../../../../until"
import viVN from 'antd/locale/vi_VN';
import { PaginationProps } from "antd/lib"
interface DataType {
  key: React.Key;
  stt: number;
  idCard: number | undefined;
  typeCard: string;
  name: string;
  cccd: string;
  birthDate: string;
  sex: string;
  phone: string;
  email: string;
}


const columns: TableColumnsType<DataType> = [
  {
    title: <div className="d-flex justify-center">STT</div>,
    dataIndex: 'stt',
    sorter: {
      compare: (a, b) => a.stt - b.stt,
      multiple: 1,
    },
    className: "w-40-px",
    render: (stt: number) => <div className="text-sm text-344054 pl-4">{stt}</div>
  },
  {
    title: 'Loại thẻ',
    dataIndex: 'typeCard',
    sorter: {
      compare: (a, b) => a.typeCard.length - b.typeCard.length,
      multiple: 1,
    },
    className: "w-170-px",
    render: (_, record) => (record.idCard
      ? (
        <div className="d-flex gap-10-px align-center">
          <img src='/images/exportImg/member_gold.svg' alt="gold" className="w-6 h-6" />
          <p className="text-sm font-regular text-344054">{record.typeCard}</p>
        </div>
      )
      : (<p className="text-sm text-98A2B3 font-regular">Chưa phát hành</p>))


  },
  {
    title: 'Họ và tên',
    dataIndex: 'name',

  },
  {
    title: 'CCCD/CMND',
    dataIndex: 'cccd',
    align: "center"
  },
  {
    title: 'Ngày sinh',
    dataIndex: 'birthDate',
  },
  {
    title: 'Giới tính',
    dataIndex: 'sex',
    sorter: {
      compare: (a, b) => a.sex.length - b.sex.length,
      multiple: 1,
    },
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
    align: "center"
  },
  {
    title: 'Email',
    dataIndex: 'email',

  },
];
const DetailGroup: React.FC = () => {
  const { id } = useParams()

  const pushToast = useToast();
  const navigate = useNavigate()

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenModalDeleteGroup, setIsOpenModalDeleteGroup] = useState<boolean>(false)
  const [isOpenModalAddCustomer, setIsOpenModalAddCustomer] = useState<boolean>(false)
  const [isOpenModalUpdateGroup, setIsOpenModalUpdateGroup] = useState<boolean>(false)


  const [filterName, setFilterName] = useState<string>()

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });


  const [infoGroup, setInfoGroup] = useState<groupCustomerApi.InfoGroupEdit>()
  const [listCustomerGroup, setListCustomerGroup] = useState<DataType[]>([])

  const gInfoGroup = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await groupCustomerApi.getInfoGroup(Number(id), pageSize, pageCurent, filterName)
    if (result?.status) {
      setInfoGroup(result.data)

      setListCustomerGroup(convertData(result.data.listCustomer.list, pageCurent, pageSize))
      setPagination({ current: pageCurent, total: result.data.listCustomer.total, pageSize })
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const convertData = (data: groupCustomerApi.List[], pageCurent: number, pageSize: number) => {
    var stt = (pageCurent - 1) * pageSize + 1;
    const convertData = data.map((item) => ({
      key: item.id,
      stt: stt++,
      idCard: item.rankTitle.id,
      typeCard: item.rankTitle?.title,
      name: item.name,
      cccd: item.CCCD,
      birthDate: item.birthday,
      sex: dataGender[item.gender].title,
      phone: item.phone,
      email: item.email,
    }))
    return convertData
  }

  const onSubmitFormModalAdd = (iSsuccess: boolean, errorMessage?: string) => {
    if (iSsuccess) {
      setIsOpenModalAddCustomer(false);
      pushToast("Thêm khách hàng vào nhóm thành công", "success")
      gInfoGroup()
    }
    else {
      pushToast(errorMessage, "warning")
    }
  }


  const onConfirmDelete = async () => {
    const listCustomer = selectedRowKeys.map(item => Number(item))
    const result = await groupCustomerApi.deleteCustomer(Number(id), listCustomer)
    if (result?.status) {
      pushToast("Xóa khách hàng vào nhóm thành công", "success")
      setSelectedRowKeys([])
      setIsOpenModalDeleteGroup(false)
      gInfoGroup()
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const onSubmitFormModalEdit = (iSsuccess: boolean, message?: string) => {
    if (iSsuccess) {
      setIsOpenModalUpdateGroup(false);
      pushToast(message, "success")
      gInfoGroup()
    }
    else {
      pushToast(message, "warning")
    }
  }

  const onPaginationChange: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    gInfoGroup(pageNumber, pageSize)
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onChangeTB: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };
  const hasSelected = selectedRowKeys.length > 0;
  useEffect(() => {
    if (id) {
      gInfoGroup()
    } else {
      navigate(-1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, filterName]);
  return (
    <Layout>
      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: <Link to="/customer/list">Khách hàng</Link>, },
          { title: <Link to="/customer/group">Quản lý nhóm khách hàng</Link>, },
          { title: 'Chi tiết ', },
        ]}
        title='Chi tiết nhóm'
        backLink='/customer/group'

      />
      <ModalDeleteGroup
        isOpen={isOpenModalDeleteGroup}
        numberCustomer={selectedRowKeys.length}
        onConfirm={onConfirmDelete}
        closeModal={() => setIsOpenModalDeleteGroup(!isOpenModalDeleteGroup)}
      />
      <ModalAddCustomer
        isOpen={isOpenModalAddCustomer}
        idGroup={Number(id)}
        onSubmitFormModal={onSubmitFormModalAdd}
        closeModal={() => setIsOpenModalAddCustomer(!isOpenModalAddCustomer)}
      />

      <ModalUpdateInfoGroupV2
        isOpen={isOpenModalUpdateGroup}
        infoGroup={infoGroup}
        onSubmitFormModal={onSubmitFormModalEdit}
        closeModal={() => setIsOpenModalUpdateGroup(!isOpenModalUpdateGroup)}

      />
      <div className="pt-6 pb-18-px">
        <div className="d-flex justify-between bg-linear-group-customer border-DCF0FC rounded-3 pt-4 pl-4 pb-4 pr-3">
          <div className="w-516-px d-flex gap-3">
            <UserOutlined className='text-32-px text-white h-fit-content  rounded-50-percent p-6 d-flex justify-center bg-1890FF' />
            <div className="d-flex flex-column gap-3 w-full">
              <span className='text-base font-medium fw-500 text-2-row'>{infoGroup?.infoGroup?.title}</span>

              <div className='d-flex align-center gap-2'>
                {
                  infoGroup?.infoGroup?.tags.map((value, indexJob) => (
                    <p key={indexJob} className={` rounded-2  text-xs px-2 d-flex align-center gap-3-px h-22-px font-regular ${value.title === 'Quảng cáo' ? 'bg-E6F7FF  border-91D5FF text-1890FF' : 'bg-FFFBE6  border-FFE58F text-FAAD14'}`}>{value.title}</p>
                  ))
                }
              </div>
              <p className='text-sm font-regular text-344054 text-3-row'>{infoGroup?.infoGroup?.description}</p>
              <div className='d-flex align-center justify-between'>
                <div className='d-flex align-center gap-2'>
                  <img src='/images/exportImg/UserGroup.svg' alt="số lượng" className='w-4 h-4' />
                  <p className='text-sm text-344054 font-regular'>Số lượng</p>
                </div>
                <p className='text-sm text-667085 font-regular'>{formatPrice(infoGroup?.infoGroup?.groupCustomerDetails ?? 0)}</p>
              </div>
              <div className='d-flex align-center justify-between'>
                <div className='d-flex align-center gap-2'>
                  <ShareAltOutlined className='text-base text-096DD9' />
                  <p className='text-sm text-344054 font-regular'>Chia sẻ với</p>
                </div>
                <p className='text-sm text-667085 font-regular'>{dataTypeGroup.filter((items) => items.value == infoGroup?.infoGroup?.status)[0]?.label}</p>
              </div>
              <div className='d-flex align-center justify-between'>
                <div className='d-flex align-center gap-2'>
                  <CalendarOutlined className='text-base text-096DD9' />
                  <p className='text-sm text-344054 font-regular'>Tạo ngày</p>
                </div>
                <p className='text-sm text-667085 font-regular'>{infoGroup?.infoGroup?.dateCreate}</p>
              </div>
              <div className='d-flex align-center justify-between'>
                <div className='d-flex align-center gap-2'>
                  <UserAddOutlined className='text-base text-096DD9' />
                  <p className='text-sm text-344054 font-regular'>Người tạo nhóm</p>
                </div>
                <p className='text-sm text-667085 font-regular'>{infoGroup?.infoGroup?.nameAdmin}</p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column justify-between">
            <div className="d-flex gap-3">
              <button
                className="bg-white border-096DD9 rounded-7-px gap-10-px text-base font-medium text-096DD9 d-flex align-center px-4 h-8"
                onClick={() => navigate('/customer/create-group', { state: { id: id } })}
              >
                Sửa điều kiện
                <SettingOutlined className="text-base text-096DD9" />
              </button>
              <button
                className="bg-096DD9 border-none rounded-7-px gap-10-px text-base font-medium text-white d-flex align-center px-4 h-8"
                onClick={() => setIsOpenModalUpdateGroup(true)}
              >
                Cập nhật thông tin nhóm <EditFilled className="text-base text-white" />
              </button>
            </div>
            <div className="d-flex align-center justify-end gap-6">
              <span className="text-sm text-344054 font-regular">Tự động thêm khách hàng có cùng điều kiện</span>
              <Switch
                className="custom-switch switch-handle switch-checked switch-inner size-text-checked siz-text-unchecked switch-inset switch-before switch-m switch-mt m-none-switch"
                value={infoGroup?.infoGroup?.role ? true : false}
              />
            </div>
          </div>

        </div>
        <div className="py-18-px d-flex justify-between">
          <div className="w-375-px border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center pl-3  py-1 h-8 justify-between overflow-hidden">
            <Input
              placeholder="Tìm kiếm số điện thoại, hoặc email, hoặc mã thành viên"
              className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input place-holder-input-667085 hover-none"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
            />
            <div className=" border-l-F2F4F7 h-full d-flex align-center">
              <SearchOutlined className="text-sm text-98A2B3 px-9-px" />
            </div>
          </div>
          <div className="d-flex  gap-3">
            <button
              onClick={() => setIsOpenModalAddCustomer(true)}
              className="bg-096DD9 border-none  rounded-2 text-white text-sm px-15-px h-8"
            >
              Thêm khách hàng
            </button>
            <button
              disabled={!hasSelected}
              onClick={() => setIsOpenModalDeleteGroup(true)}
              className={` rounded-2 px-15-px d-flex align-center gap-10-px h-8 text-sm ${hasSelected ? 'bg-FFF1F0 border-F5222D text-F5222D' : 'bg-F9FAFB border-F2F4F7 text-BBBBBC'}`}
            >
              <DeleteOutlined className={`text-sm  ${hasSelected ? "text-F5222D" : "text-BBBBBC"}`} />
              {hasSelected ? `Xoá ${selectedRowKeys.length} khách hàng khỏi nhóm` : 'Xoá khách hàng'}
            </button>
          </div>
        </div>
        {
          listCustomerGroup.length > 0
            ?
            (<div className="t-table-block">
              <Table
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={listCustomerGroup}
                onChange={onChangeTB}
                pagination={false}
                showSorterTooltip={false}
                className="w-fit-table border-end-table"
              />
              <ConfigProvider locale={viVN}>
                <div className="pagination-block">
                  <Pagination
                    showSizeChanger
                    current={pagination.current}
                    pageSize={pagination.pageSize}
                    total={pagination.total}
                    showTotal={(total) => `Tổng cộng ${dataPagination(pagination.total, pagination.pageSize, pagination.current).totalPage} trang`}
                    className='rs-pagination'
                    onChange={onPaginationChange}

                  />
                </div>
              </ConfigProvider>
            </div>)
            :
            (<div className='d-flex justify-center w-full pt-20'>
              <div className='d-flex flex-column gap-2'>
                <img src='/images/exportImg/empty-image.svg' alt="emtyGroup" className='w-326-px h-200-px' />
                <p className='text-sm text-667085 font-regular'>Chưa có khách hàng nào trong nhóm phù hợp với điều kiện</p>
              </div>
            </div>)
        }

      </div>
    </Layout>
  )
}
export default DetailGroup
