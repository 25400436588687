import { paserParams } from "../../until";
import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";
import { SortType } from "../carLine/carLineApi";
import { ITag } from "../selectOptions/selectOptionsApi";

export interface IVoucher {
    id: number
    code: string
    title: string
    description: string
    dateStart: string
    timeStart: string
    dateEnd: string
    timeEnd: string
    quantity: number
    orderValue: number
    reducedAmount: number
    percentDecrease: number
    reducedAmountMaximum: number
    usageCount: number
    role: number
    type: number
    view: number
    status: number
}

export interface IVoucherEdit {
    id: number
    code: string
    title: string
    description: string
    quantity: number
    orderValue: number
    reducedAmount: number
    percentDecrease: number
    reducedAmountMaximum: number
    usageCount: number
    dateStart: string
    timeStart: string
    dateEnd: string
    timeEnd: string
    role: number
    type: number
    view: number
    status: number
    voucherCustomers: ITag[]
    voucherGroupCustomers: ITag[]
}

export interface IVoucherCode {
    id: number
    code: string
    voucherId: number
    conversionDate?: string
    phone: string
    view: number
    status: number
    customerId: number
    customerName: string
    agentId: any
    agentName: string
}

export interface ILoopupVoucher {
    id: number;
    code: string;
    customerId: number;
    customerName: string;
    statusConversion: number;
    voucherId: number;
    title: string;
    description: string;
    quantity: number;
    orderValue: number;
    reducedAmount: number;
    percentDecrease: number;
    reducedAmountMaximum: number;
    usageCount: number;
    dateStart: string;
    timeStart: string;
    dateEnd: string;
    timeEnd: string;
    role: number;
    type: number;
    view: number;
    status: number;
}


// Tra cuu voucher
export const getLoopupVoucher = async (
    code: string) => {
    const path = "voucher/loopupVoucher"
    const params = {
        code
    }
    const result: ResponseSuccessTotal<ILoopupVoucher> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Danh sách voucher
export const getListVoucher = async (
    limit?: number, pageNumber?: number, status?: number, search?: string, dateStart?: string, dateEnd?: string) => {
    const path = "voucher/listVoucher"
    const params = {
        limit, pageNumber, status, search, dateStart, dateEnd
    }
    const result: ResponseSuccessTotal<IVoucher[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thông tin voucher
export const getInfoVoucher = async (
    id: number) => {
    const path = "voucher/infoVoucher"
    const params = {
        id
    }
    const result: ResponseSuccessTotal<IVoucherEdit> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thêm mới voucher
export const addVoucher = async (
    title: string, description: string, quantity: number, type: number, orderValue: number, role: number, usageCount: number,
    dateStart: string, timeStart: string, dateEnd: string, timeEnd: string,
    reducedAmount?: number, percentDecrease?: number, reducedAmountMaximum?: number, groupCustomerId?: number[], customerId?: number[]
) => {
    const url = `voucher/addVoucher`;
    const params = {
        title, description, quantity, type, orderValue, role, usageCount,
        dateStart, timeStart, dateEnd, timeEnd,
        reducedAmount, percentDecrease, reducedAmountMaximum, groupCustomerId, customerId
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        url, paserParams(params, undefined)
    );
    return result;
}

export const conversionVoucher = async (
    code: string, agentId: number, conversionDate: string, codeOrder: string, customerId?: number
) => {
    const url = `voucher/conversionVoucher`;
    const params = {
        code, customerId, agentId, conversionDate, codeOrder
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, params
    );
    return result;
}

//Chỉnh sửa voucher
export const editVoucher = async (
    id: number, title: string, description: string, quantity: number, type: number, orderValue: number, role: number, usageCount: number,
    dateStart: string, timeStart: string, dateEnd: string, timeEnd: string,
    reducedAmount?: number, percentDecrease?: number, reducedAmountMaximum?: number, groupCustomerId?: number[], customerId?: number[]
) => {
    const url = `voucher/editVoucher`;
    const params = {
        id, title, description, quantity, type, orderValue, role, usageCount,
        dateStart, timeStart, dateEnd, timeEnd,
        reducedAmount, percentDecrease, reducedAmountMaximum, groupCustomerId, customerId
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, paserParams(params, undefined)
    );
    return result;
}

//Xóa voucher
export const deleteVoucher = async (
    id: Number
) => {
    const url = `voucher/deleteVoucher`;
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        url, { params }
    );
    return result;
}

//Cập nhật trạng thái hoạt động voucher
export const updateStatusVoucher = async (
) => {
    const url = `voucher/updateStatusVoucher`;
    const params = {
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, paserParams(params, undefined)
    );
    return result;
}

//Cập nhật trạng thái voucher
export const activeVoucher = async (
    id: number
) => {
    const url = `voucher/activeVoucher`;
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, paserParams(params, undefined)
    );
    return result;
}

//Danh sách mã voucher
export const getListVoucherCode = async (
    voucherId: number, limit?: number, pageNumber?: number, status?: number, search?: string) => {
    const path = "voucher/listVoucherCode"
    const params = {
        voucherId, limit, pageNumber, status, search
    }
    const result: ResponseSuccessTotal<IVoucherCode[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};


//Cập nhật trạng thái mã voucher
export const activeVoucherCode = async (
    id: number
) => {
    const url = `voucher/activeVoucherCode`;
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, paserParams(params, undefined)
    );
    return result;
}