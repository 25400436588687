import React, { useEffect } from 'react';
import Layout from '../../Layout';
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock';
import { Link } from 'react-router-dom';
import VIPCareReport from './ARVIPCareComponents/VIPCareReport';
import VIPCareReportTable from './ARVIPCareComponents/VIPCareReportTable';
import './VIPCustomerCare.scss'

const VIPCustomerCare: React.FC = () => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);
  return (
    <Layout contentClassName='vip-care-content'>
      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: 'Báo cáo phân tích', },
          { title: 'Chăm sóc khách hàng VIP', },
        ]}
        title='Chăm sóc khách hàng VIP'
      />

      <VIPCareReport />
      <VIPCareReportTable />
    </Layout>
  );
}

export default VIPCustomerCare;