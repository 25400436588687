import React, { useEffect, useState } from 'react';
import Layout from '../../Layout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, Button, ConfigProvider, DatePicker, Pagination, Table, TableColumnsType } from 'antd';
import { DownloadOutlined, EllipsisOutlined } from '@ant-design/icons';

import './CampaignSendsNotificationsDetail.scss'
import viVN from 'antd/locale/vi_VN';
import * as campaignApi from '../../api/campaign/campaignApi';
import { dataPagination } from '../../until';
import { PaginationProps } from 'antd/lib';
import useDebounce from '../../hook/useDebounce';
import useToast from '../../hook/useToast';
import * as XLSX from 'xlsx';
import useThrottle from '../../hook/useThrottle';
import { LIMIT_EXPORT_EXCEL } from '../../env';

const { RangePicker } = DatePicker;

interface DataType {
  key: React.Key;
  stt: number;
  name: string;
  date: string;
  status: number;
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'STT',
    dataIndex: 'stt',
    align: 'center',
    sorter: {
      compare: (a, b) => a.stt - b.stt,
      multiple: 1,
    },
  },
  {
    title: 'Khách hàng',
    dataIndex: 'name',
  },
  {
    title: 'Thời gian gửi',
    dataIndex: 'date',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    sorter: {
      compare: (a, b) => a.stt - b.stt,
      multiple: 1,
    },
    render: (text: number) => (
      <div className="status-link">
        <div className="status-block">
          {
            text === 1 &&
            <div className="ctrl-status type-1">
              <div className="text">Thành công</div>
            </div>
          }
          {
            text === 0 &&
            <div className="ctrl-status type-2">
              <div className="text">Thất bại</div>
            </div>
          }
        </div>
        {/* <Link to="#">Log</Link> */}
      </div>
    ),
  },
];

const CampaignSendsNotificationsDetail: React.FC = () => {

  useEffect(() => {

    return (() => {

      setDataFilter({
        date: ["", ""]
      })

      setPagination({ current: 1, pageSize: 10, total: 0 })

      setTitleCampaignSends("")

      setListCustomerReceived([])
    })
  }, []);

  const { id } = useParams();
  const navigate = useNavigate()
  const pushToast = useToast();

  const [dataFilter, setDataFilter] = useState({
    date: ["", ""]
  })

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });

  const [titleCampaignSends, setTitleCampaignSends] = useState("")

  const [listCustomerReceived, setListCustomerReceived] = useState<DataType[]>([])

  const gListCustomerReceived = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await campaignApi.getlistCustomerReceived(Number(id), pageSize, pageCurent, undefined, dataFilter.date[0], dataFilter.date[1])
    if (result?.status) {
      setTitleCampaignSends(result.data.title)
      setListCustomerReceived(convertData(result.data.dataSend, pageCurent, pageSize))
      setPagination({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const convertData = (data: campaignApi.DataSend[], pageCurent: number, pageSize: number) => {
    var stt = (pageCurent - 1) * pageSize + 1;
    const convertData = data.map((item) => ({
      key: item.id,
      stt: stt++,
      name: item.customerName,
      date: item.timeSend,
      status: item.status,
    }))
    return convertData
  }

  const gListCustomerReceivedByExcel = useThrottle(async () => {
    const result = await campaignApi.getlistCustomerReceived(
      Number(id), LIMIT_EXPORT_EXCEL, 1, undefined, dataFilter.date[0], dataFilter.date[1]
    )
    if (result?.status) {
      formattedDataExcel(result.data.dataSend, result.data.title)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const formattedDataExcel = (data: campaignApi.DataSend[], title: string) => {
    const formattedData = data.map((item) => {
      return {
        "Họ và tên": item.customerName,
        "Thời gian gửi": item.timeSend,
        "Trạng thái": item.status ? "Thành công" : "Thất bại",
      }
    }
    );
    exportExcel(formattedData, title)
  }

  function exportExcel(convertData: any, title: string): boolean {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData);
      const workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách');

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = window.URL.createObjectURL(data);
        link.download = fileName;
        link.click();
      };

      saveAsExcel(excelBuffer, `Lịch sử gửi tự động của chiến dịch “${title}".xlsx`);
      return true; // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error);
      return false; // Export failed
    }
  }

  const onDateChange = (date: any, dateString: string[], info: any) => {
    const isNotEmpty = dateString.some(item => item !== "");
    if (isNotEmpty) {
      setDataFilter({ ...dataFilter, date: dateString })
    } else {
      setDataFilter({ ...dataFilter, date: ["", ""] })
    }
  }

  const onPaginationChange: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    gListCustomerReceived(pageNumber, pageSize)
  }

  useEffect(() => {
    if (id) {
      gListCustomerReceived()
    } else {
      navigate(-1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dataFilter])



  return (
    <Layout
      contentClassName="campaign-sends-notification-content"
    >
      <Breadcrumb
        items={[
          {
            title: 'THACO AUTO CRM',
          },
          {
            title: <a href="/marketing/campaign-sends-notifications">Marketing</a>,
          },
          {
            title: <a href="/marketing/campaign-sends-notifications">Gửi thông báo</a>,
          },
          {
            title: 'Chi tiết chiến dịch',
          },
        ]}
      />

      <div className='title-page title-btn-back'>
        <Link to="/marketing/campaign-sends-notifications" className="icon-svg">
          <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6564 7.80321H4.04305L10.3955 2.37463C10.4971 2.28713 10.4354 2.12463 10.3012 2.12463H8.69582C8.62508 2.12463 8.55796 2.14963 8.50536 2.19428L1.65045 8.04963C1.58767 8.10321 1.53732 8.16944 1.50281 8.24385C1.4683 8.31825 1.45044 8.39909 1.45044 8.48088C1.45044 8.56268 1.4683 8.64352 1.50281 8.71792C1.53732 8.79233 1.58767 8.85856 1.65045 8.91213L8.54526 14.8032C8.57247 14.8264 8.60512 14.8389 8.63959 14.8389H10.2993C10.4336 14.8389 10.4953 14.6746 10.3937 14.5889L4.04305 9.16035H14.6564C14.7363 9.16035 14.8016 9.09606 14.8016 9.01749V7.94606C14.8016 7.86749 14.7363 7.80321 14.6564 7.80321Z" fill="currentColor" fillOpacity="0.88" />
          </svg>
        </Link>
        Chi tiết chiến dịch
      </div>

      <div className="vip-care-report-table">
        <div
          className="top"
          style={{ alignItems: 'center' }}
        >
          <span className="fsz-16 font-medium lh-24">Lịch sử gửi tự động của chiến dịch
            <strong> “{titleCampaignSends}”</strong></span>

          <div className="control">
            <div className="t-ctrl-date-picker-block w-256">
              <RangePicker
                popupClassName="t-ctrl-date-picker"
                placeholder={["Từ ngày", "Đến ngày"]}
                onCalendarChange={onDateChange}
              />
            </div>

            <Button
              className="rs-button"
              onClick={gListCustomerReceivedByExcel}
            >
              <DownloadOutlined />
              <span>Xuất DS</span>
            </Button>

            <Button
              className="rs-button"
              icon={<EllipsisOutlined />}
            />
          </div>
        </div>

        <div className="t-table-block t-table-campaign-sends-notifications-detail">
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
            columns={columns}
            dataSource={listCustomerReceived}
            pagination={false}
            showSorterTooltip={false}
          />

          <ConfigProvider locale={viVN}>
            <div className="pagination-block">
              <Pagination
                showSizeChanger
                current={pagination.current}
                pageSize={pagination.pageSize}
                total={pagination.total}
                showTotal={(total) => `Tổng cộng ${dataPagination(pagination.total, pagination.pageSize, pagination.current).totalPage} trang`}
                className='rs-pagination'
                onChange={onPaginationChange}

              />
            </div>
          </ConfigProvider>
        </div>
      </div>
    </Layout>
  );
}

export default CampaignSendsNotificationsDetail;
