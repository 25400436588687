import { CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Table, TableProps } from "antd";
import { useState } from "react";
import * as SelectOptionApi from '../../../../api/selectOptions/selectOptionsApi';
import useToast from "../../../../hook/useToast";
import ModalCreateMajor from "./ModalCreateMajor";
interface ModalProps {
  isOpen: boolean
  dataListBusinessUnit: SelectOptionApi.IBusinessUnit[]
  closeModal: () => void
  getListBusinessUnit: () => void
}
// interface DataType {
//   key: string;
//   major: string;
//   codeMajor: string;
// }
// const data: DataType[] = [
//   {
//     key: '1',
//     major: 'Nghiệp vụ quản trị',
//     codeMajor: 'LPB0001'
//   },
//   {
//     key: '2',
//     major: 'Nghiệp vụ vận hành',
//     codeMajor: 'LPB0001'
//   },
//   {
//     key: '3',
//     major: 'Khối kinh doanh',
//     codeMajor: 'LPB0001'
//   },

// ];
const ModalManagerMajor: React.FC<ModalProps> = ({
  isOpen,
  dataListBusinessUnit,
  getListBusinessUnit,
  closeModal
}) => {
  const pushToast = useToast();
  const [isOpenCreateMajor, setIsOpenCreateMajor] = useState(false)
  const handleOpenAddMajor = () => {
    setIsOpenCreateMajor(!isOpenCreateMajor)
    closeModal()
  }
  const handleDelete = async (id: number) => {
    const result = await SelectOptionApi.deleteBusinessUnit(id);
    if (result?.status) {
      pushToast(result.message, "success", 2000)
      // closeModal()
      getListBusinessUnit()
    } else {
      pushToast(result.message, "error", 2000)
    }
  }
  const columns: TableProps<SelectOptionApi.IBusinessUnit>['columns'] = [
    {
      title: <div className="d-flex align-center gap-18-px pl-6">
        <p className="text-base text-4B4B4B fw-500 font-medium">Khối/nghiệp vụ</p>
        <div className="d-flex align-center rounded-2 h-26-px w-62-px gap-1 border-096DD9 justify-center cursor-pointer" onClick={handleOpenAddMajor}>
          <p className="text-xs text-096DD9 font-regular">Thêm</p>
          <PlusOutlined className="text-xs text-096DD9 " />
        </div>
      </div>,
      render: (text: SelectOptionApi.IBusinessUnit) => <div className="d-flex align-center gap-2">
        <img src='/images/exportImg/Building.svg' alt="major" className="w-6 h-6" />
        <div className="d-flex flex-column gap-1">
          <p className="text-base font-regular text-344054 ">{text.title}</p>
          <p className="text-sm text-8E8E8E font-regualr">{text.code}</p>
        </div>
      </div>,

      className: 'w-275-px'
    },
    {
      title: <p className="text-base text-4B4B4B fw-500 font-medium">Hành động</p>,
      render: (text: SelectOptionApi.IBusinessUnit) => (
        <div className="d-flex align-center gap-6">
          <EditOutlined
            className="text-base text-096DD9 cursor-pointer"
          />
          <DeleteOutlined className="text-base text-F5222D cursor-pointer"
            onClick={() => handleDelete(text.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <ModalCreateMajor
        isOpen={isOpenCreateMajor}
        closeModal={handleOpenAddMajor}
        getListBusinessUnit={getListBusinessUnit}
      />
      <Modal
        title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
          <span className="text-base text-344054 fw-500 font-medium">Quản lý khối/nghiệp vụ</span>
          <CloseOutlined className="text-base text-8B8B8B"
            onClick={closeModal}
          />
        </div>}
        onCancel={closeModal}
        open={isOpen}
        centered
        className="w-modal modal-content ant-modal-size m-none-header"
        closeIcon={false}
        footer={[]}
      >
        <Table
          columns={columns}
          dataSource={dataListBusinessUnit}
          pagination={false}
          className="custom-table"
        />
      </Modal>
    </div>

  )
}
export default ModalManagerMajor

