import { Button, Form, FormInstance, FormProps, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useRef } from "react";
import * as BenefitApi from '../../api/rank/benefitApi';
import useToast from "../../hook/useToast";
import './ShowAddBenefit.scss';

interface Props {
  className?: string;
  closeModal: () => void;
  onShowHistory?: () => void;
  getListBenefit: () => void

}

const ShowAddBenefit: React.FC<Props> = ({ className, closeModal, onShowHistory, getListBenefit }) => {

  const inputRef = useRef<FormInstance>(null);
  const pushToast = useToast();
  const [form] = useForm();

  const submitForm = () => {
    if (inputRef.current) {
      inputRef.current.submit();
    }
  };

  const onFinish: FormProps["onFinish"] = async (values) => {
    const result = await BenefitApi.addBenefit(values.title, values.description);
    if (result?.status) {
      pushToast("Thêm mới thành công!", "success", 1000)
      closeModal();
      getListBenefit()
    } else {
      pushToast(result.message, "error", 1000)
    }
  };

  const popupRef = useRef<HTMLDivElement>(null);

  const handleReset = () => {
    if (inputRef.current) {
      inputRef.current.resetFields();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
        if (closeModal) {
          closeModal();
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closeModal]);

  return (
    <div
      ref={popupRef}
      className="filter-setting add-card-issuance"
      id="addcardissuance"
    >
      <div className="title-add-filter">
        <span>Tạo mới quyền lợi</span>
      </div>
      <div className="content-filter-setting t-scrollbar">
        <Form
          ref={inputRef}
          className="form-block d-none-rq form-benefit"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Form.Item
            label="Tên quyền lợi"
            name="title"
            rules={[{ required: true, message: 'Vui lòng nhập tên quyền lợi!' }]}
          >
            <Input 
             placeholder="Tên quyền lợi" />
          </Form.Item>

          <Form.Item
            label="Mô tả quyền lợi"
            name="description"
            rules={[{ required: true, message: 'Vui lòng mô tả quyền lợi!' }]}
          >
            <TextArea 
            rows={2} 
            placeholder="Thành viên có quyền lợi này sẽ được những gì?" />
          </Form.Item>
        </Form>
      </div>
      <div className="footer-filter-block ctrl-btn-ft-benefit">
        <div className="btn-block">
          <Button
            className="btn btn-df"
            onClick={handleReset}
          >Làm mới thông tin</Button>
          <Button className="btn btn-primary" onClick={submitForm}>
            <div className="text">
              Thêm quyền lợi
            </div>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ShowAddBenefit