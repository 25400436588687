import React, { useEffect, useState } from 'react';
import Layout from '../../Layout';
import { Breadcrumb, Input, Table, Pagination, ConfigProvider, notification, Flex, Button } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import {
  EyeOutlined, EditOutlined,
  DeleteOutlined,
  DownOutlined,
  CloseCircleOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import type { SearchProps } from 'antd/es/input/Search';
import viVN from 'antd/locale/vi_VN';
import { Link } from 'react-router-dom';
import './PoolingDataAuto.scss'
import PopupShowData from './ComponentPoolingData/PopupShowData';


interface DataType {
  key: number;
  name: string;
  phone: string;
  date: string;
}

const data: DataType[] = [
  {
    key: 1,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Nguyễn Lê Hải Phong',
  },
  {
    key: 2,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Tuấn Lê',
  },
  {
    key: 3,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Tuấn Hải',
  },
  {
    key: 4,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Minh Trần',
  },
  {
    key: 5,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Hoàng Anh',
  },
  {
    key: 6,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trung Hà',
  },
  {
    key: 7,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Khánh Hoàn',
  },
  {
    key: 8,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Hạ Trang',
  },
  {
    key: 9,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trần Minh Đức',
  },
  {
    key: 10,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trúc Minh',
  },
  {
    key: 11,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trúc Minh',
  },
  {
    key: 12,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trúc Minh',
  },
  {
    key: 13,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trúc Minh',
  },
  {
    key: 14,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trúc Minh',
  },
  {
    key: 15,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trúc Minh',
  },
  {
    key: 16,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trúc Minh',
  },
];

const { Search } = Input;

const onSearch: SearchProps['onSearch'] = (value, _e, info) => console.log(info?.source, value);

const PoolingDataAutoDetail: React.FC = () => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Hành động',
      dataIndex: '',
      align: 'center',
      render: () => (
        <>
          <div className="t-hd-table">
            <Link to='/customer/pooling-data/pooling-data-auto-detail' className="item-hd-table"><span><EyeOutlined /></span></Link>
            {/* <Link to='/customer/pooling-data/merge-manually' className="item-hd-table"><span><EditOutlined /></span></Link> */}
            <div className="item-hd-table t-cl-F5222D"><span><DeleteOutlined /></span></div>
          </div>
        </>
      ),
    },
    {
      title: 'Họ và tên',
      dataIndex: 'name',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      align: 'center',
    },
    {
      title: 'Nguồn dữ liệu',
      dataIndex: '',
      align: 'center',
      render: () => (
        <>
          <div className="t-table-list-tags">
            <div className="item-tag">DDMS</div>
            <div className="item-tag">IPCC</div>
            <div className="item-tag">SALESFORCE</div>
          </div>
        </>
      ),
    },
    {
      title: 'Ngày thực hiện',
      dataIndex: 'date',
      align: 'right',
      sorter: {
        compare: (a, b) => a.key - b.key,
        multiple: 1,
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onChangeTB: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api['success']({
      message: 'Tạo Voucher thành công!',
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  const [showSelectFilter, setActive] = useState(false);
  const [isShowSelect, setShowSelect] = useState('Điều kiện thông tin cá nhân');
  const handleClearFilter = (isShow:any) => {
    setActive(false)
    setShowSelect(isShow)
  }

  const [loading, setLoading] = useState(false);

  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
    setSelectedRowKeys([]);
    }, 1000);
  };

  const [isStep, setStep] = useState(1);
  const setActiveStep2 = async () => {
    setStep(2)
    setTimeout(() => {
      setStep(3); // Chuyển đến bước 3 sau 3 giây
    }, 3000);
  }

  return (
    <div className='pooling-data-auto'>
      {contextHolder}
      {/* <Helmet>
        <title>THACO AUTO CRM</title>
      </Helmet> */}
      <Layout>
        <Breadcrumb
          items={[
            {
              title: 'THACO AUTO CRM',
            },
            {
              title: <a href="/customer/list">Khách hàng</a>,
            },
            {
              title: <a href="/customer/pooling-data">Gộp dữ liệu khách hàng</a>,
            },
            {
              title: 'Xác nhận dữ liệu gộp tự động',
            },
          ]}
        />

        <div className='title-page t-title-btn-back'>
          <Link to="/customer/pooling-data" className="icon-svg">
            <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.6564 7.80321H4.04305L10.3955 2.37463C10.4971 2.28713 10.4354 2.12463 10.3012 2.12463H8.69582C8.62508 2.12463 8.55796 2.14963 8.50536 2.19428L1.65045 8.04963C1.58767 8.10321 1.53732 8.16944 1.50281 8.24385C1.4683 8.31825 1.45044 8.39909 1.45044 8.48088C1.45044 8.56268 1.4683 8.64352 1.50281 8.71792C1.53732 8.79233 1.58767 8.85856 1.65045 8.91213L8.54526 14.8032C8.57247 14.8264 8.60512 14.8389 8.63959 14.8389H10.2993C10.4336 14.8389 10.4953 14.6746 10.3937 14.5889L4.04305 9.16035H14.6564C14.7363 9.16035 14.8016 9.09606 14.8016 9.01749V7.94606C14.8016 7.86749 14.7363 7.80321 14.6564 7.80321Z" fill="currentColor" fillOpacity="0.88" />
            </svg>
          </Link>
          Xác nhận dữ liệu gộp tự động
        </div>

        {/* Fillter block */}
        <div className='filter-block filter-label-left t-mb-18'>
          <div className="title-dk">Bộ điều kiện</div>
          <div className='filter-select'>
            <div
              className='btn-select-show'
            >
              <span className="content-select" onClick={() => setActive(true)} >{isShowSelect}</span>
              <div
                className={`icon-down ${showSelectFilter ? "active" : ""}`}
                onClick={() => setActive(true)}
              >
                <DownOutlined />
              </div>
              <div
                className={`icon-close ${showSelectFilter ? "active" : ""}`}
                onClick={() => handleClearFilter('Điều kiện thông tin cá nhân')}
              >
                <CloseCircleOutlined />
              </div>
            </div>
            <div className={`list-filter ${showSelectFilter ? "active" : ""}`}>
              <div className="list-item">
                <div className="item-select" onClick={() => handleClearFilter('Điều kiện thông tin cá nhân')} >Điều kiện thông tin cá nhân</div>
                <div className="item-select" onClick={() => handleClearFilter('Điều kiện thông tin cá nhân 1')} >Điều kiện thông tin cá nhân 1</div>
              </div>
            </div>
          </div>
        </div>

        { isStep === 1 &&(
          <div className="empty-data">
            <div className="img-box">
              <img src="/images/empty-image.svg" alt="img empty" />
            </div>
            <div className="sum">Chọn bộ điều kiện để kiểm tra trùng lặp</div>
            <div className="des">
              <div className="icon">
                <svg width="1em" height="1em" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.5 14.7143H4.21429C4.13571 14.7143 4.07143 14.7786 4.07143 14.8571V15.4286C4.07143 15.7446 4.32679 16 4.64286 16H8.07143C8.3875 16 8.64286 15.7446 8.64286 15.4286V14.8571C8.64286 14.7786 8.57857 14.7143 8.5 14.7143ZM6.35714 0C3.12321 0 0.5 2.62321 0.5 5.85714C0.5 8.025 1.67857 9.91786 3.42857 10.9304V13C3.42857 13.3161 3.68393 13.5714 4 13.5714H8.71429C9.03036 13.5714 9.28572 13.3161 9.28572 13V10.9304C11.0357 9.91786 12.2143 8.025 12.2143 5.85714C12.2143 2.62321 9.59107 0 6.35714 0ZM8.64107 9.81786L8 10.1893V12.2857H4.71429V10.1893L4.07321 9.81786C2.66786 9.00536 1.78571 7.50893 1.78571 5.85714C1.78571 3.33214 3.83214 1.28571 6.35714 1.28571C8.88214 1.28571 10.9286 3.33214 10.9286 5.85714C10.9286 7.50893 10.0464 9.00536 8.64107 9.81786Z" fill="#1890FF"/>
                </svg>
              </div>
              <div className="text">Hệ thống sẽ tìm các dữ liệu khách hàng trùng lặp giữa các nguồn dữ liệu</div>
            </div>
            <div className="btn-kttl" onClick={setActiveStep2}>Kiểm tra trùng lặp</div>
          </div>
        )}

        { isStep === 2 &&(
          <div className="load-check-data">
            <div className="img-box">
              <img src="/images/bg-load-auto.png" alt="img check load" />
            </div>
            <div className="line-load">
              <div
                className="percent"
                style={{
                  width: '40%',
                }}
              />
            </div>
            <div className="sum">Hệ thống đang kiểm tra khách hàng trùng lặp theo hệ thống được thiết lập tong bộ điều kiện</div>
          </div>
        )}

        { isStep === 3 &&(
          <div className="data-pooling-auto-list">
            <div className="chart-pooling-block">
              <div className="img-box">
                <img src="/images/chart-auto-pooling.png" alt="img chart pooling" />
              </div>
              <div className="total-data-pooling">
                <div className="total-number">
                  <div className="title">Số KH trùng lặp giữa 3 hệ thống</div>
                  <div className="count">1000</div>
                  <div className="sum">khách hàng</div>
                </div>
                <div className="total-number">
                  <div className="title">Tỉ lệ trùng lặp giữa 3 hệ thống</div>
                  <div className="count pb-2">10%</div>
                </div>
              </div>
            </div>

            <div className="t-table-block t-table-pooling-data-auto-detail">
              {selectedRowKeys.length !== 0 &&
                <Flex align="center" gap="middle" className='ctrl-select-table'>
                  <div className="count-check-all">
                    Đã chọn
                    <div className="cl-096DD9">{selectedRowKeys.length} hàng</div>
                  </div>

                  <Button
                    onClick={start}
                    className='delete-all'
                  >Xóa</Button>
                </Flex>
              }

              <Table
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
                onChange={onChangeTB}
                pagination={false}
                showSorterTooltip={false}
              />

              <ConfigProvider locale={viVN}>
                <div className="pagination-block">
                  <Pagination
                    total={85}
                    showSizeChanger
                    showQuickJumper
                    showTotal={(total) => `Tổng cộng ${total} trang`}
                    locale={{ items_per_page: 'trang' }}
                  />
                </div>
              </ConfigProvider>
            </div>

          </div>
        )}
      </Layout>
    </div>
  );
}

export default PoolingDataAutoDetail;
