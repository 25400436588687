import { CloseOutlined } from "@ant-design/icons";
import { Form, FormInstance, FormProps, Input, Modal, TreeSelect } from "antd";
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useRef, useState } from "react";
// import * as UnitApi from '../../../api/unit/unitApi';
import useToast from "../../../hook/useToast";
import './style.scss';
import {mixPostV2} from '../../../services/mixin'
interface ModalProps {
  isOpen: boolean
  data: []
  closeModal: () => void
  editData?: any
  modalFinish?: () => void
}
const ModalCreate: React.FC<ModalProps> = ({
  isOpen,
  data,
  closeModal,
  editData,
  modalFinish
}) => {
  const [form] = useForm();
  const formRef = useRef<FormInstance>(null);
  const pushToast = useToast();


  const submitForm = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };
  const [loading, setLoading] = useState(false)
  const onFinish: FormProps["onFinish"] = async (values) => {
    console.log(values)
    const param = {
      name: values.name,
      _method: 'PUT',
    }
    setLoading(true)
    const res = await mixPostV2(`/api/v1/be/source_column_warehouse/${editData?.id}`, param)
    if (res.status) {
      pushToast("Thao tác thành công", "success")
      setLoading(false)
      formRef?.current?.resetFields()
      if (modalFinish) {
        modalFinish()
      }
    } else {
      pushToast("Error!", "warning")
      setLoading(false)
    }
  };

  useEffect(() => {
    formRef?.current?.setFieldsValue({
      name: editData.name,
      code: editData?.column_warehouse?.column
    })
  }, [editData])

  return (
    <Modal
      title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
        <span className="text-base text-344054 fw-500 font-medium">Param hệ thống</span>
        <CloseOutlined className="text-base text-8B8B8B" onClick={closeModal} />
      </div>}
      open={isOpen}
      onCancel={closeModal}
      centered
      className="w-modal modal-content-creat-area ant-modal-size"
      closeIcon={false}
      footer={<div className="bg-FCFCFD d-flex justify-end align-center py-2 px-6 w-full border-t-F0F0F0 gap-2">
        <button className="text-sm text-344054 border-F2F4F7 bg-white h-8 px-15-px font-regular rounded-2 shadow-btn-save" onClick={closeModal}>Đóng</button>
        <button className="text-white text-sm bg-096DD9 border-none shadow-btn-save rounded-2 h-8 px-15-px font-regular shadow-btn-save"
          onClick={submitForm}>
          Lưu
        </button>
      </div>}
    >
      <Form
        form={form}
        ref={formRef}
        onFinish={onFinish}
        initialValues={{ remember: true }}
      >
        <div className="d-flex flex-column gap-18-px p-6">
          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Mã Param</p>
            <Form.Item
              name="code"
              rules={[{ required: true, message: 'Nhập mã Param!' }]}
              className="m-none-form-item text-explan"
            >
              <Input
                disabled
                placeholder="Mã Param"
                className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
              />
            </Form.Item>
          </div>

          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Tên Param</p>
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'Nhập tên Param!' }]}
              className="m-none-form-item text-explan"
            >
              <Input
                placeholder="Tên Param"
                className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
              />
            </Form.Item>
          </div>
          {/* <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Hệ thống</p>
            <div className="content-ht">
              <div className="label-he-thong">{editData?.source?.name}</div>
            </div>
          </div> */}


        </div>
      </Form>
    </Modal>
  )
}
export default ModalCreate
