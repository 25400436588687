import React, { useRef, useState } from 'react'
import { Button, DatePicker, Form, Input, Modal, notification, Select, Tag } from 'antd'
import './CreateOwnedCar.scss'

const { TextArea } = Input

interface Props {
  show: boolean
  modalClose?: () => void
}

const CreateCareFeedbackModal:React.FC<Props> = (props) => {
  const { show, modalClose } = props
  const formRef: any = useRef(null)

  const [loading, setLoading] = useState(false)
  const onFormFinish = (values: any) => {
    setLoading(true)

    setTimeout(() => {
      setLoading(false)

      openNotification('success', 'Tạo chăm sóc phản hồi thành công')
      if (modalClose) {
        modalClose()
      }
      if (formRef.current) {
        formRef.current.resetFields()
      }
    }, 1500)
  }

  // SELECTED STATUS
  const [selectedStatus, setSelectedStatus] = useState<any>(null)

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'error' = 'success', message: string = '') => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  return (
    <>
      { contextHolder }

      <Modal
        open={show}
        title='Tạo chăm sóc phản hồi'
        className='rs-modal w-572 footer-content-end create-owned-car-modal'
        footer={
          <Button
            type='primary'
            className='rs-button rs-button--sm'
            loading={loading}
            onClick={() => formRef?.current.submit()}
          >Tạo</Button>
        }
        onCancel={modalClose}
      >
        <Form
          ref={formRef}
          className='rs-form create-owned-car-form'
          onFinish={onFormFinish}
          requiredMark={false}
        >
          <Form.Item
            layout='vertical'
            label='Ngày chăm sóc'
            name='date'
            className='w-full'
            rules={[
              { required: true, message: 'Vui lòng chọn ngày chăm sóc' }
            ]}
          >
            <DatePicker placeholder='Chọn ngày' className='rs-date-picker w-full' />
          </Form.Item>

          <Form.Item
            layout='vertical'
            label='Chọn hệ thống'
            name='system'
            rules={[
              { required: true, message: 'Vui lòng chọn hệ thống' }
            ]}
          >
            <Select
              placeholder='Chọn hệ thống'
              className='rs-select'
              options={[
                { value: 'ipcc', label: 'IPCC' },
                { value: 'ddms', label: 'DDMS' },
                { value: 'saleforce', label: 'SALEFORCE' },
              ]}
              labelRender={({ label }) => (
                <Tag className='rs-tag geek-blue'>{ label }</Tag>
              )}
            />
          </Form.Item>

          <Form.Item
            layout='vertical'
            label='Trạng thái'
            name='status'
            rules={[
              { required: true, message: 'Vui lòng chọn trạng thái' }
            ]}
          >
            <Select
              placeholder='Trạng thái'
              className='rs-select'
              popupClassName='rs-select-dropdown'
              options={[
                { value: 'ho-tro', label: 'Hỗ trợ' },
                { value: 'ban-giao-xe', label: 'Bàn giao xe' },
              ]}
              onChange={(value) => setSelectedStatus(value)}
            />
          </Form.Item>

          {
            selectedStatus === 'ho-tro' &&
              <Form.Item
                layout='vertical'
                label='Nguồn'
                name='source'
                shouldUpdate
                rules={[
                  { required: true, message: 'Vui lòng chọn nguồn' }
                ]}
              >
                <Select
                  placeholder='Nguồn'
                  className='rs-select'
                  popupClassName='rs-select-dropdown'
                  options={[
                    { value: 'zalo', label: 'Zalo' },
                    { value: 'facebook', label: 'Facebook' },
                    { value: 'hotline', label: 'Hotline' },
                    { value: 'other', label: 'Khác' },
                  ]}
                />
              </Form.Item>
          }

          <Form.Item
            layout='vertical'
            label='Ghi chú'
            name='ghi-chu'
            rules={[
              { required: true, message: 'Vui lòng nhập ghi chú' }
            ]}
          >
            <TextArea
              placeholder='Nhập nội dung trao đổi / ghi nhớ...'
              rows={5}
              className='rs-input'
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CreateCareFeedbackModal
