import React from 'react';
import { Link } from 'react-router-dom';
import Menu from './components/PageElements/Menu';
import Header from './components/PageElements/Header';
interface LayoutProps {
  className?: string;
  contentClassName?: string;
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ className = '', contentClassName = '', children }) => {
  return (
    <div className={`app ${className}`}>
      <div className='app-widget'>
        <Link to={'/'} className="app-widget__logo">
          {/* <img src="/logo.svg" alt="THACO AUTO CRM" className="default-logo"/> */}
          <img src="https://thacoauto.vn/storage/logo/logo-thacoauto-2023.png" alt="THACO AUTO CRM" className="default-logo"/>
          <img src="/images/logo-collapsed.svg" alt="THACO AUTO CRM" className="collapsed-logo"/>
        </Link>

        <Menu />
      </div>
      <div className='app-main'>
        <Header />
        <div className={`app-content ${contentClassName}`}>
          {children}
        </div>
      </div>

    </div>
  );
}

export default Layout;
