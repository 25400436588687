import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";
import { UnitLevel } from "../selectOptions/selectOptionsApi";

export const activeArray = [
    "Chưa kích hoạt", "Đang sử dụng"
]
export interface INationwide {
    id: number
    code: string
    title: string
    view: number
}
export interface IOptionNationwide1 {
    id: number
    code: string
    title: string
    children: Children1[]
}
export interface Children1 {
    id: number
    code: string
    title: string
    children: Children1[]
}
export interface DataTypeNationwide {
    codeArea: string;
    nameArea: string;
    view: number
    key: React.Key;

}

export interface OptionSelect {
    value: number;
    title: JSX.Element;
    children: OptionSelect[]
    label: string;

}

// Đon vị vùng miền
export interface DataTypeRegions {
    codeArea: string;
    nameArea: string;
    nationwide: string;
    view: number
    key: number;
}
export interface IRegions {
    id: number
    code: string
    title: string
    view: number
    parentId: number
    nationwideTitle: string
}
export interface IDetailRegions {
    id: number
    code: string
    title: string
    view: number
    parentId: number
}
export interface IDetailUnit {
    id: number
    code: string
    title: string
    parentId: number
}
export interface IOptionNationwide {
    id: number
    code: string
    title: string
    view: number
    parentId: number
    children: Children[]
}

export interface Children {
    id: number
    code: string
    title: string
    view: number
    parentId: number
    children: Children[]
}
export interface DataTypeUnit {
    codeUnit: string;
    unit: string;
    nationwide: string;
    region: string;
    key: number;
    view: number;
    parentId: number | null;
}
export interface IUnit {
    id: number
    code: string
    title: string
    view: number
    parentId: number
    level: number
}
export interface IIssuingUnit {
    id: number
    code: string
    title: string
    view: number
    parentId: number
    level: number
    children: IIssuingUnit[]
}
export interface IIssuingUnitConvert {
    key: number
    title: JSX.Element
    children: IIssuingUnitConvert[]
}
// Đơn vị ban hàng
export const getListIssuingUnit = async (search?: string) => {
    const path = "issuingUnit/listIssuingUnit"
    const params = { search }
    const result: ResponseSuccessTotal<IIssuingUnit[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};
export function convertIssuingUnitValueToTree(data: IIssuingUnit[]) {
    return data.map((item) => {
        const treeItem: IIssuingUnitConvert = {
            key: item.id,
            title: <p className='text-sm text-4B4B4B fw-500 font-medium'>{item.title}</p>,
            children: []
        };
        if (item.children != null && item.children.length > 0) {
            treeItem.children = convertIssuingUnitValueToTree(item.children);
        }
        return treeItem;
    });
}

// danh sách đơn vị
export const getListUnit = async (
    limit?: number, pageNumber?: number, search?: string) => {
    const path = "unit/listUnit"
    const params = {
        limit, pageNumber, search
    }
    const result: ResponseSuccessTotal<IUnit[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

export const getDetailRegions = async (
    id: number) => {
    const path = "regions/infoRegions"
    const params = {
        id
    }
    const result: ResponseSuccessTotal<IDetailRegions> = await axiosClient.get(
        path, { params }
    );
    return result;
};

// danh sách đơn vị vùng miền
export const getListRegions = async (
    limit?: number, pageNumber?: number, search?: string) => {
    const path = "regions/listRegions"
    const params = {
        limit, pageNumber, search
    }
    const result: ResponseSuccessTotal<IRegions[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};


export const getListNationwide = async (
    limit?: number, pageNumber?: number, search?: string) => {
    const path = "nationwide/listNationwide"
    const params = {
        limit, pageNumber, search
    }
    const result: ResponseSuccessTotal<INationwide[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

// options 
export const getListOptionNationwide = async (
    limit?: number) => {
    const path = "regions/listNationwide"
    const params = {
        limit
    }
    const result: ResponseSuccessTotal<IOptionNationwide[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

export function convertNationwideValueToTree(data: UnitLevel[]) {
    return data.map((item) => {
        const treeItem: OptionSelect = {
            value: item.id,
            label: `${item.title} (${item.code})`,
            title: <span className="text-sm font-regular text-344054">{item.title}<span className="text-sm font-regular text-98A2B3 pl-1">({item.code})</span></span>,
            children: []
        };
        if (item.children != null && item.children.length > 0) {
            treeItem.children = convertNationwideValueToTree(item.children);
        }
        return treeItem;
    });
}

export function convertRegionalValueToTree(data: IOptionNationwide1[]) {
    return data.map((item) => {
        const treeItem: OptionSelect = {
            value: item.id,
            label: `${item.title} (${item.code})`,
            title: <span className="text-sm font-regular text-344054">{item.title}</span>,
            children: []
        };
        if (item.children != null && item.children.length > 0) {
            treeItem.children = convertRegionalValueToTree(item.children);
        }
        return treeItem;
    });
}

export const detailNationwide = async (id: number) => {
    const path = "nationwide/infoNationwide"
    const params = { id }
    const result: ResponseSuccess<INationwide> = await axiosClient.get(
        path, { params }
    );
    return result;
};
export const detailUnit = async (id: number) => {
    const path = "unit/infoUnit"
    const params = { id }
    const result: ResponseSuccess<IDetailUnit> = await axiosClient.get(
        path, { params }
    );
    return result;
};

export const updateActiveNationwide = async (
    id: number) => {
    const path = "nationwide/activeNationwide"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        path, params
    );
    return result;
};

export const updateActiveRegions = async (
    id: number) => {
    const path = "regions/activeRegions"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        path, params
    );
    return result;
};

export const updateActiveUnit = async (
    id: number) => {
    const path = "unit/activeUnit"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        path, params
    );
    return result;
};


export const updateNationwide = async (
    id: number, code: string, title: string) => {
    const path = "nationwide/editNationwide"
    const params = {
        id, code, title
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        path, params
    );
    return result;
};

export const updateRegions = async (
    id: number, code: string, title: string, parentId: number) => {
    const path = "regions/editRegions"
    const params = {
        id, code, title, parentId
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        path, params
    );
    return result;
};


export const updateUnit = async (
    id: number, code: string, title: string, parentId: number) => {
    const path = "unit/editUnit"
    const params = {
        id, code, title, parentId
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        path, params
    );
    return result;
};


export const addActiveNationwide = async (
    code: string, title: string
) => {
    const path = "nationwide/addNationwide"
    const params = {
        code, title
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        path, params
    );
    return result;
};

export const addUnit = async (
    code: string, title: string, parentId: number
) => {
    const path = "unit/addUnit"
    const params = {
        code, title, parentId
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        path, params
    );
    return result;
};
export const addRegions = async (
    code: string, title: string, parentId: number
) => {
    const path = "regions/addRegions"
    const params = {
        code, title, parentId
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        path, params
    );
    return result;
};

export const deleteUnit = async (
    id: number
) => {
    const path = "unit/deleteUnit"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        path, { params }
    );
    return result;
};
export const deleteActiveNationwide = async (
    id: number
) => {
    const path = "nationwide/deleteNationwide"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        path, { params }
    );
    return result;
};


export const deleteActiveRegions = async (
    id: number
) => {
    const path = "regions/deleteRegions"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        path, { params }
    );
    return result;
};
