import { CloseOutlined } from "@ant-design/icons"
import { Input, Modal, Select } from "antd"
import { useEffect, useState } from "react"
import { ICarCompany } from "../../../../../api/selectOption/selectOptionsApi"
import useThrottle from "../../../../../hook/useThrottle"
import { fommatSelectOption } from "../../../../../until"
import './style.scss'
interface ModalProps {
  isOpen: boolean
  sLCategoryCar: ICarCompany[]
  aCarLine: (addTitle: string, addCategoryCar: number) => void
  closeModal: () => void
}
const ModalCreateVehicle: React.FC<ModalProps> = ({
  isOpen, sLCategoryCar,
  aCarLine, closeModal
}) => {

  let errorsObj = { addTitle: "", addCategoryCar: "", result: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [addTitle, setAddTitle] = useState("")
  const [addCategoryCar, setaddCategoryCar] = useState<number>()

  const checkVariables = useThrottle(() => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (!addCategoryCar) {
      errorObj.addCategoryCar = "Vui lòng chọn tên thương hiệu xe!"
      error = true;
    }
    if (addTitle === undefined || !addTitle.trim()) {
      errorObj.addTitle = "Vui lòng nhập Dòng xe!"
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    } else {
      aCarLine(addTitle, Number(addCategoryCar))
      resetDataAdd()
    }


  }, 1000)

  const resetDataAdd = () => {
    setAddTitle("")
    setaddCategoryCar(undefined)

    closeModal()
  }


  useEffect(() => {
    if (isOpen) {

    } else {
      setErrors(errorsObj)
    }
  }, [isOpen])

  return (
    <Modal
      title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
        <span className="text-base text-344054 fw-500 font-medium">Tạo mới dòng xe</span>
        <CloseOutlined className="text-base text-8B8B8B" onClick={resetDataAdd} />
      </div>}
      open={isOpen}
      onCancel={resetDataAdd}
      centered
      className="w-modal modal-content-vehicle ant-modal-size"
      closeIcon={false}
      footer={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-2 px-6 w-full border-t-F0F0F0">
        <span className="text-sm text-98A2B3 cursor-pointer" onClick={resetDataAdd}>Huỷ</span>
        <button className="text-white text-sm bg-096DD9 border-none shadow-btn-save rounded-2 h-8 px-15-px"
          onClick={checkVariables}>
          Lưu dòng xe mới
        </button>
      </div>}
    >
      <div className="p-6 d-flex flex-column gap-18-px">
        <div className="d-flex flex-column gap-1">
          <p className="text-sm  font-regular text-344054">Tên thương hiệu xe</p>
          <div className="w-full">
            <Select
              showSearch
              placeholder="Tên thương hiệu xe"
              className="custome-select-t "
              popupClassName='popup-select'
              options={fommatSelectOption(sLCategoryCar)}
              value={addCategoryCar}
              onChange={(e) => setaddCategoryCar(e)}
            />
            {
              errors.addCategoryCar &&
              <span className="text-F5222D text-base font-regular pl-2">{errors.addCategoryCar}</span>
            }

          </div>
        </div>
        <div className="d-flex flex-column gap-1">
          <p className="text-sm  font-regular text-344054">Dòng xe</p>
          <div className="d-flex flex-column ">
            <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 pl-3  py-1 h-8  overflow-hidden">
              <Input
                placeholder="Dòng xe"
                className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input  hover-none"
                maxLength={50}
                value={addTitle}
                onChange={(e) => setAddTitle(e.target.value)}
              />
            </div>
            {
              errors.addTitle &&
              <span className="text-F5222D text-base font-regular pl-2">{errors.addTitle}</span>
            }

          </div>
        </div>


      </div>
    </Modal>
  )
}
export default ModalCreateVehicle
