import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
// import EmptyComponentLinkText from '../../../components/EmptyComponent/EmptyComponentLinkText';
import './styles.scss'
import ItemNotification from '../../../components/NotificationTemplatesManagement/ItemNotification';
import ShowNotification from './ShowNotification';
import * as TemplateApi from '../../../api/marketing/templateApi';

interface Props {
  dataTemplate?: TemplateApi.INotificationTemplate[]
}

const SMS: React.FC<Props> = (dataTemplate) => {
  const [isNotificationShow, setNotificationShow] = useState(false)
  const navigate = useNavigate()

  return (
    <>
      {/* empty show */}
      {/* <EmptyComponentLinkText
        content={"Bạn chưa có mẫu thông báo nào, thử"}
        link={'/'}
        namelink={'tạo mới ngay'}
      /> */}
      <div className="notification-templates-management-list">
        {
          dataTemplate && dataTemplate.dataTemplate?.map((item, index) => (
            // <ItemNotification
            //   key={index}
            //   type={item.type}
            //   typestatus={item.typestatus}
            //   typecontent={item.typecontent}
            //   title={item.title}
            //   onOpen={() => { setNotificationShow(true) }}
            //   onEdit={() => navigate('/marketing/notification-templates-management/edit-sms')}
            // />
            <ItemNotification
              key={index}
              type={item.targetId}
              typestatus={item.status}
              typecontent={item.description}
              title={item.title}
              onOpen={() => { setNotificationShow(true) }}
              onEdit={() => navigate('/marketing/notification-templates-management/edit-sms')}
            />
          ))
        }
      </div>
      {
        isNotificationShow
          ? <ShowNotification
            className="show"
            onClose={() => { setNotificationShow(false) }}
          />
          : null
      }
    </>
  )
}

export default SMS
