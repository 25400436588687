import React from 'react'
import './AccountRoleList.scss'

interface Props {
  children?: React.ReactNode
}

const AccountRoleList:React.FC<Props> = (props) => {
  const { children } = props

  return (
    <div className="account-role-list">{ children }</div>
  )
}

export default AccountRoleList
