import { DeleteOutlined, DownloadOutlined, EditOutlined, PlusOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Breadcrumb, ConfigProvider, Input, Pagination, PaginationProps, Select, Table, TableColumnsType, TableProps, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import Layout from '../../../Layout';
import viVN from 'antd/locale/vi_VN';
import './style.scss'
import ModalCreateVersionCar from './component/modal/ModalCreateVersionCar';
import ModalEditVersionCar from './component/modal/ModalEditVersionCar';
import ModalDeleteManager from '../../../components/ModalDeleteManager';
import * as selectOptionsApi from '../../../api/selectOption/selectOptionsApi';
import * as carLineApi from '../../../api/carLine/carLineApi';
import useToast from '../../../hook/useToast';
import { DataStatusButton, dataSoft } from '../../../services/data';
import { dataPagination, exportExcel } from '../../../until';
import useDebounce from '../../../hook/useDebounce';
import * as versionCarApi from '../../../api/versionCar/versionCarApi';
import useThrottle from '../../../hook/useThrottle';
import ModalUpload from '../../../components/Modal/ModalUpload';

import { LIMIT_EXPORT_EXCEL } from '../../../env';

interface DataType {
  key: React.Key
  stt: number
  nameBrand: string
  typeCar: string
  clCar: string
  versionCar: string
  status: number
  year: number | string
}
const VehicleVersionManagement: React.FC = () => {
  useEffect(() => {
    gCLCarCompany()

    return () => {
      setFilterName(undefined)
      setFilterSort(undefined)

      setIdVersionCar(undefined)

      setPagination({ current: 1, pageSize: 10, total: 0 })

      setListVersionCar([])
    }
  }, [])
  const columns: TableColumnsType<DataType> = [
    {
      title: 'Hành động',
      dataIndex: '',
      render: (_, record) => (
        <div className='t-hd-table'>
          <div
            className="item-hd-table cursor-pointer"
            onClick={() => gInfoVersionCar(record.key)}>
            <span><EditOutlined /></span>
          </div>
          <DeleteOutlined
            className='text-F5222D text-sm border-EAECF0 p-5-px bg-white rounded-2'
            onClick={() => openModalDeleteVersionCar(Number(record.key))}
          />
        </div>
        // <div className='d-flex align-center gap-10-px'>
        //   <EditOutlined
        //     className='text-98A2B3 text-base border-EAECF0 p-2 bg-white rounded-2'
        //     onClick={() => gInfoVersionCar(record.key)}
        //   />
        //   <DeleteOutlined
        //     className='text-F5222D text-base border-EAECF0 p-2 bg-white rounded-2'
        //     onClick={() => openModalDeleteVersionCar(Number(record.key))}
        //   />
        // </div>

      ),
      // className: "w-93-px",
      align: "center",
      width: 93,
    },
    {
      title: 'STT',
      dataIndex: 'stt',
      sorter: {
        compare: (a, b) => a.stt - b.stt,
        multiple: 1,
      },
      // className: "w-60-px",
      align: "center",
      width: 60,

    },
    {
      title: 'Tên thương hiệu',
      dataIndex: 'nameBrand',
      // className: "w-250-px",
      sorter: {
        compare: (a, b) => a.nameBrand.length - b.nameBrand.length,
        multiple: 1,
      },
      render: (text) => <div className='text-2-row'>{text}</div>,
      width: 200,
    },
    {
      title: 'Dòng xe',
      dataIndex: 'typeCar',
      sorter: {
        compare: (a, b) => a.typeCar.length - b.typeCar.length,
        multiple: 1,
      },
      // className: "w-250-px",
      render: (text) => <div className='text-2-row'>{text}</div>,
      width: 200,
    },
    {
      title: 'Đời xe',
      dataIndex: 'year',
      sorter: true,
      // className: "w-250-px",
      render: (text) => <div className='text-2-row'>{text}</div>,
      width: 200,
    },
    {
      title: 'Chủng loại xe',
      dataIndex: 'clCar',
      sorter: {
        compare: (a, b) => a.clCar.length - b.clCar.length,
        multiple: 1,
      },
      // className: "w-250-px",
      render: (text) => <div className='text-2-row'>{text}</div>,
      width: 200,
    },
    {
      title: 'Phiên bản xe',
      dataIndex: 'versionCar',
      sorter: {
        compare: (a, b) => a.versionCar.length - b.versionCar.length,
        multiple: 1,
      },
      // className: "w-250-px",
      render: (text) => <div className='text-2-row'>{text}</div>,
      width: 200,
    },
    // {
    //   title: 'Trạng thái',
    //   dataIndex: '',
    //   className: "w-175-px",
    //   render: (_, record) => <div className='d-flex align-center gap-10-px'>
    //     <Switch
    //       defaultChecked={record.status ? true : false}
    //       onChange={() => updateActiveVersion(Number(record.key))}
    //       className="rs-switch rs-switch--sm"
    //     />
    //     <p className='font-medium fw-500 text-344054'>{selectOptionsApi.activeArray[record.status]}</p>
    //   </div>
    // },
  ];

  const pushToast = useToast();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenCreateVersionCar, setIsOpenCreateVersionCar] = useState<boolean>(false)
  const [isOpenEditVersionCar, setIsOpenEditVersionCar] = useState<boolean>(false)
  const [isOpenDeleteVersionCar, setIsOpenDeleteVersionCar] = useState<boolean>(false)
  const [openModalUpload, setOpenModalUpload] = useState(false);

  const [filterName, setFilterName] = useState<string>()
  const [filterSort, setFilterSort] = useState<carLineApi.SortType>()

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });

  const [idVersionCar, setIdVersionCar] = useState<number>()

  const [sLCategoryCar, setSLCategoryCar] = useState<selectOptionsApi.ICarCompany[]>([])// thương hiệu

  const [infoVersionCar, setInfoVersionCar] = useState<versionCarApi.IVersionCarEdit>()

  const [listVersionCar, setListVersionCar] = useState<DataType[]>([])

  const gCLCarCompany = async () => {
    const result = await selectOptionsApi.getListCarCompany()
    if (result?.status) {
      setSLCategoryCar(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const gListVersionCar = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await versionCarApi.getListVersionCar(pageSize, pageCurent, filterName, filterSort)
    if (result?.status) {
      setListVersionCar(convertData(result.data, pageCurent, pageSize))
      setPagination({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const convertData = (data: versionCarApi.IVersionCar[], pageCurent: number, pageSize: number) => {
    var stt = (pageCurent - 1) * pageSize + 1;
    const convertData = data.map((item) => ({
      key: item.id,
      stt: stt++,
      nameBrand: item.categoryCar,
      typeCar: item.carLines,
      clCar: item.typeVehicle,
      versionCar: item.version,
      status: item.status,
      year: item.year || '2024'
    }))
    return convertData
  }

  const gInfoVersionCar = useThrottle(async (id: number) => {
    const result = await versionCarApi.getInfoVersionCar(id)
    if (result?.status) {
      setInfoVersionCar(result.data)
      setIsOpenEditVersionCar(true)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const onSubmitFormModalAdd = (iSsuccess: boolean, errorMessage?: string) => {
    if (iSsuccess) {
      setIsOpenCreateVersionCar(false);
      pushToast("Thêm phiên bản xe thành công", "success")
      gListVersionCar()
    }
    else {
      pushToast(errorMessage, "warning")
    }
  }

  const onSubmitFormModalEdit = (iSsuccess: boolean, errorMessage?: string) => {
    if (iSsuccess) {
      setIsOpenEditVersionCar(false);
      pushToast("Sửa phiên bản xe thành công", "success")
      gListVersionCar()
    }
    else {
      pushToast(errorMessage, "warning")
    }
  }

  const openModalDeleteVersionCar = (id: number) => {
    setIdVersionCar(id)
    setIsOpenDeleteVersionCar(true)
  }

  const closeModalDeleteVersionCar = () => {
    setIdVersionCar(undefined)
    setIsOpenDeleteVersionCar(false)
  }

  const onConfirmDelete = async () => {
    const result = await versionCarApi.deleteVersionCar(Number(idVersionCar))
    if (result?.status) {
      pushToast("Xóa phiên bản xe thành công", "success")
      closeModalDeleteVersionCar()
      gListVersionCar()
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const onPaginationChange: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    gListVersionCar(pageNumber, pageSize)
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onChangeTB: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const updateActiveVersion = async (id: number) => {
    const result = await versionCarApi.updateActiveVersion(id);
    if (result?.status) {
      gListVersionCar()
      pushToast(result.message, "success", 2000)
    } else {
      pushToast(result.message, "error", 2000)

    }
  }

  const glistByExcel = useThrottle(async () => {
    const result = await versionCarApi.getListVersionCar(LIMIT_EXPORT_EXCEL, 1, filterName, filterSort)
    if (result?.status) {
      formattedDataExcel(result.data, result?.message)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const formattedDataExcel = (data: versionCarApi.IVersionCar[], message?: string) => {
    const formattedData = data.map((item) => {
      return {
        "Tên thương hiệu": item.categoryCar,
        "Dòng xe": item.carLines,
        "Chủng loại": item.typeVehicle,
        "Phiên bản xe": item.version,
        "Trạng thái": DataStatusButton[item.status].label
      }
    }
    );
    exportExcel(formattedData, message)
  }



  useEffect(() => {
    gListVersionCar()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterName, filterSort])
  return (
    <Layout>
      <ModalCreateVersionCar
        isOpen={isOpenCreateVersionCar}
        sLCategoryCar={sLCategoryCar}
        onSubmitFormModal={onSubmitFormModalAdd}
        closeModal={() => setIsOpenCreateVersionCar(!isOpenCreateVersionCar)}
      />
      <ModalEditVersionCar
        isOpen={isOpenEditVersionCar}
        infoVersionCar={infoVersionCar}
        sLCategoryCar={sLCategoryCar}
        onSubmitFormModal={onSubmitFormModalEdit}
        closeModal={() => setIsOpenEditVersionCar(!isOpenEditVersionCar)}
      />
      <ModalDeleteManager
        isOpen={isOpenDeleteVersionCar}
        title="Bạn có chắc chắn xóa phiên bản này?"
        titleDelete="Xoá thương hiệu"
        content="Phiên bản và dữ liệu liên quan sẽ bị xóa vĩnh viễn
      không thể khôi phục"
        onConfirm={onConfirmDelete}
        closeModal={closeModalDeleteVersionCar}
      />
      <ModalUpload
        isOpen={openModalUpload}
        closeModal={() => setOpenModalUpload(!openModalUpload)}
      />
      <Breadcrumb
        items={[
          {
            title: 'THACO AUTO CRM',
          },
          {
            title: <a href="/">Quản lý xe</a>,
          },
          {
            title: 'Quản lý phiên bản  xe',
          },
        ]}
      />
      <div className='title-page'>
        Quản lý phiên bản  xe
      </div>
      <div className='d-flex gap-3 pb-18-px vehicle-category-management-filter'>
        <div className="fix-w-100 w-468-px border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center pl-3  py-1 h-8 justify-between overflow-hidden">
          <Input
            placeholder="Tìm kiếm phiên bản xe"
            className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input place-holder-input-667085 hover-none"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          />
          <div className=" border-l-F2F4F7 h-full d-flex align-center">
            <SearchOutlined className="text-sm text-98A2B3 px-9-px" />
          </div>
        </div>
        <div className='w-352-px'>
          <Select
            placeholder="Chọn bộ lọc"
            className="custome-select"
            popupClassName='popup-select'
            options={dataSoft}
            value={filterSort}
            onChange={(e) => setFilterSort(e)}
          />
        </div>
        <button
          className='border-EAECF0 bg-white rounded-6-px w-106-px py-2 h-8 d-flex justify-center align-center gap-10-px shadow-btn-save'
          onClick={glistByExcel}
        >
          <DownloadOutlined className='text-sm text-344054' />
          <span className='text-sm fw-400 font-regular text-344054 white-space-nowrap'>Xuất DS</span>
        </button>
        <button
          className='border-EAECF0 bg-white rounded-6-px w-111-px py-2 h-8 d-flex justify-center align-center gap-10-px shadow-btn-save'
          onClick={() => setOpenModalUpload(true)}
        >
          <UploadOutlined className='text-sm text-344054' />
          <span className='text-sm fw-400 font-regular text-344054 white-space-nowrap'>Nhập DS</span>
        </button>
        <button className='border-none bg-096DD9 rounded-6-px  py-2 h-8 d-flex justify-center align-center gap-10-px w-107-px'
          onClick={() => setIsOpenCreateVersionCar(true)}
        >
          <PlusOutlined className='text-sm text-white' />
          <span className='text-sm fw-400 font-regular text-white white-space-nowrap'>Tạo mới</span>
        </button>
      </div>
      <div className="t-table-block">
        <Table
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={listVersionCar}
          onChange={onChangeTB}
          pagination={false}
          showSorterTooltip={false}
          className="w-fit-table border-end-table"
        />
        <ConfigProvider locale={viVN}>
          <div className="pagination-block">
            <Pagination
              showSizeChanger
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              showTotal={(total) => `Tổng cộng ${dataPagination(pagination.total, pagination.pageSize, pagination.current).totalPage} trang`}
              className='rs-pagination'
              onChange={onPaginationChange}

            />
          </div>
        </ConfigProvider>
      </div>
    </Layout>
  );
}

export default VehicleVersionManagement;
