import React, { useEffect, useState } from 'react';
import { Drawer, Switch, Tag, message, } from 'antd';
import { BellOutlined, ClockCircleOutlined, CopyOutlined, DownloadOutlined, ShoppingCartOutlined, TagOutlined, TeamOutlined, } from '@ant-design/icons';
import './ManualCampaignPopup.scss';
import CampaignNetworkCard from '../../../components/Cards/CampaignNetworkCard';
import { DataSend, IICampaignDetail, getlistCampaignChannel, getlistCustomerReceived } from '../../../api/campaign/campaignApi';
import { formatPrice } from '../../../until';
import { LIMIT_EXPORT_EXCEL, URL_CHANNEL } from '../../../env';
import * as XLSX from 'xlsx';
import useThrottle from '../../../hook/useThrottle';
import useToast from '../../../hook/useToast';
interface Props {
  isShow?: boolean
  infoCampaign?: IICampaignDetail
  onPopupClose?: () => void
}

const ManualCampaignDetailPopup: React.FC<Props> = ({
  isShow = false, infoCampaign,
  onPopupClose
}) => {

  const pushToast = useToast();

  const gListCustomerReceivedByExcel = useThrottle(async () => {
    const result = await getlistCustomerReceived(
      Number(infoCampaign?.id), LIMIT_EXPORT_EXCEL, 1,
    )
    if (result?.status) {
      formattedDataExcel(result.data.dataSend, result.data.title)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const gListCampaignChannelByExcel = useThrottle(async (
    notificationTemplateId: number, channelId: number, title: string
  ) => {
    const result = await getlistCampaignChannel(
      Number(infoCampaign?.id), notificationTemplateId, channelId
    )
    if (result?.status) {
      formattedDataExcel(result.data, title)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const formattedDataExcel = (data: DataSend[], title: string) => {
    const formattedData = data.map((item) => {
      return {
        "Họ và tên": item.customerName,
        "Thời gian gửi": item.timeSend,
        "Trạng thái": item.status ? "Thành công" : "Thất bại",
      }
    }
    );
    exportExcel(formattedData, title)
  }

  function exportExcel(convertData: any, title: string): boolean {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData);
      const workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách');

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = window.URL.createObjectURL(data);
        link.download = fileName;
        link.click();
      };

      saveAsExcel(excelBuffer, `Danh sách “${title}".xlsx`);
      return true; // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error);
      return false; // Export failed
    }
  }

  async function copyTextToClipboard(text: any) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = (copyText: any) => {
    copyTextToClipboard(copyText)
      .then(() => {
        message.success({
          content: 'Copied',
          className: 'rs-message'
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const focusTagRendered = (type: string) => {
    let result
    switch (type) {
      case 'promotion':
        result = (
          <Tag className="rs-tag volcano">
            <TagOutlined />
            <span>Khuyến mãi</span>
          </Tag>
        )
        break;

      case 'notification':
        result = (
          <Tag className="rs-tag daybreak-blue">
            <BellOutlined />
            <span>Thông báo</span>
          </Tag>
        )
        break;

      case 'marketing':
        result = (
          <Tag className="rs-tag golden-purple">
            <ShoppingCartOutlined />
            <span>Tiếp thị</span>
          </Tag>
        )
        break;

      case 'customer-care':
        result = (
          <Tag className="rs-tag magenta">
            <TeamOutlined />
            <span>CSKH</span>
          </Tag>
        )
        break;

      default:
        result = (
          <Tag className="rs-tag daybreak-blue">
            <BellOutlined />
            <span>{type}</span>
          </Tag>
        )
        break;
    }
    return result
  }

  return (
    <Drawer
      title="Chi tiết chiến dịch"
      rootClassName="rs-drawer w-580"
      className="manual-campaign-popup"
      closeIcon={false}
      open={isShow}
      footer={false}
      onClose={onPopupClose}
    >
      <div className="voucher-add__content">
        <div className="block">
          <div className="flex gap-12px">
            <Switch
              disabled
              defaultChecked
              className="rs-switch"
              checked={infoCampaign?.view ? true : false}
            />
            <div className="flex gap-8px">
              <span className="neutral-gray-400">Mã chiến dịch</span>
              <span className="neutral-gray-400">{infoCampaign?.code}</span>
              <CopyOutlined
                className="primary-7"
                onClick={() => handleCopyClick(infoCampaign?.code)}
              />
            </div>
          </div>

          <span className="fsz-16 lh-24 font-medium">{infoCampaign?.title}</span>
          <span className="neutral-gray-400">Tạo lúc {infoCampaign?.dateCreated}</span>
        </div>

        <div className="block">
          <span className="font-medium lh-24 fsz-16">Mục tiêu</span>
          <div className="focus-tags">
            {focusTagRendered(infoCampaign?.targetTitle + "")}
          </div>
        </div>

        <div className="block">
          <span className="fsz-16 lh-24 font-medium">Tập khách hàng nhận</span>
          <div className="block-flex receive-customer-item">
            <div className="flex">
              <span className="neutral-gray-400">Khách hàng nhận&nbsp;</span>
              <span className="primary-6">({formatPrice(infoCampaign?.numberSend)})</span>
            </div>
            <div className="flex align-center gap-8px">
              <span className="neutral-gray-400 fsz-12 lh-20">Tải danh sách</span>
              <DownloadOutlined
                className="primary-6 cursor-pointer"
                onClick={gListCustomerReceivedByExcel}
              />
            </div>
          </div>
        </div>

        <div className="block">
          <span className="fsz-16 lh-24 font-medium">Kênh & nội dung và trạng thái nhận</span>

          <div className="campaign-network-cards flex flex-column gap-16px">
            {
              infoCampaign?.network && infoCampaign?.network.map((item, i) =>
                <CampaignNetworkCard
                  key={i}
                  networkImg={URL_CHANNEL + item.image}
                  networkName={item.title}
                  code={item.description}
                  numberOfSuccess={item.sendSuccess}
                  numberOfFail={item.sendFail}
                  onDownload={() => gListCampaignChannelByExcel(item.notificationTemplateId, item.channelId, item.title)}
                />
              )
            }
            {/* <CampaignNetworkCard
              networkImg='/images/campaign/zalo.svg'
              networkName='ZNS'
              code='ZMS01'
              numberOfSuccess={24000}
              numberOfFail={1000}
            // progressing={true} // true: đang gửi | false: đã gửi
            // progressingNumber={10000} // nếu đang gửi thì truyền số lượng đã gửi được đến hiện tại
            // total={48000}
            // onDetail={} // sự kiện xem chi tiết
            // onDownload={} // sự kiện tải về
            />
           */}
          </div>
        </div>

        <div className="block">
          <span className="fsz-16 lh-24 font-medium">Thời gian gửi</span>
          <div className="flex gap-8px neutral-gray-400">
            <ClockCircleOutlined />
            <span className="fsz-12 lh-20 neutral-gray-400">{infoCampaign?.dateSend}</span>
          </div>
        </div>

        <div className="text-center">
          <span className="neutral-gray-400">Chiến dịch được tạo bởi&nbsp;</span>
          <span className="primary-6">{infoCampaign?.adminName}</span>
        </div>
      </div>
    </Drawer>
  )
}

export default ManualCampaignDetailPopup
