import { Radio, RadioChangeEvent } from "antd";
import React, { useEffect, useRef, useState } from "react";

interface Props {
  onClose?: () => void;
  settypeCard: (value: number | undefined) => void;
  typeCard: number | undefined;
}

const SelectFilterRankUser: React.FC<Props> = ({ onClose, settypeCard, typeCard }) => {
  const handleOnClose = () => {
    if (value === 4) {
      settypeCard(undefined)

    } else {
      settypeCard(value)
    }

    if (onClose) {
      onClose()
    }
  }
  const handleReset = () => {
    settypeCard(undefined)
    // onClose?.()

  }

  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
        if (onClose) {
          onClose();
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [onClose]);
  const [value, setValue] = useState<number>();
  const onChange = (e: RadioChangeEvent) => {


    setValue(e.target.value);


  };

  return (
    <div
      ref={popupRef}
      className='list-filter active'
    >
      <div className="list-add-filter-content">
        <Radio.Group defaultValue={4} value={value ?? typeCard} onChange={onChange}>
          <Radio className="text" value={4}>Tất cả</Radio>
          <Radio className="text" value={3}>Chưa phát hành</Radio>
        </Radio.Group>
      </div>
      <div
        className="btn-filter"
      >
        <div className="btn" onClick={handleReset}>
          Reset
        </div>
        <div
          className="btn text-btn-filter"
          onClick={handleOnClose}
        >
          Xác nhận
        </div>
      </div>
    </div>
  )
}

export default SelectFilterRankUser
