import React, { useState, useEffect } from 'react'
import Layout from '../../Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { Button, Flex, Input, Table, TableColumnsType } from 'antd'
import { SearchProps } from 'antd/es/input'
import { DeleteOutlined, EditOutlined, DownloadOutlined } from '@ant-design/icons'
import CreateImportDataTemplateModal from './Component/TemplateModal/CreateImportDataTemplateModal'
import DetailImportDataTemplateModal from './Component/TemplateModal/DetailImportDataTemplateModal'
import ModalDeleteManager from '../../components/ModalDeleteManager';
import useToast from '../../hook/useToast';
import { mixGetV2, mixDate, mixPostV2 } from '../../services/mixin'
import { API_URL_v2 } from "../../env";

const { Search } = Input;
interface DataType {
  key: React.Key
  templateName: string
  date: string
}

const CustomerPoolingDataTemplateManagement:React.FC = () => {
  const pushToast = useToast();
  const [listTemplate, setListTemplate] = useState([] as any)
  const [paramTemplate, setParamTemplate] = useState({
    'sort[]': 'id,desc',
    page: 1,
    limit: 100000,
    name: '',
  })
  let wait = false
  const getTemplate = async () => {
    if (wait) {
      return
    }
    wait = true
    const res = await mixGetV2('/api/v1/be/template_excels', paramTemplate)
    wait = false
    if (res.status) {
      setListTemplate([...res.data.items])
    }
  }

  useEffect(() => {
    getTemplate()
  }, [paramTemplate])


  //  SEARCH CHANGE
  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    setParamTemplate({...paramTemplate, name: value})
  }

  // TABLE COLUMNS
  const columns: TableColumnsType<DataType> = [
    {
      title: 'Hành động',
      dataIndex: '',
      key: 'action',
      render: (_d, row: any) => (
        <Flex align='center' className='gap-10px'>
          <Button className="rs-button rs-button--icon-only" onClick={() => {window.location.href = `${API_URL_v2}/api/v1/warehouse/download_excel/${row.id}`}}><DownloadOutlined /></Button>
          <Button className="rs-button rs-button--icon-only edit" onClick={() => {setShowDetailImportDataTemplateModal(true); setIdDetail(row.id)}}><EditOutlined /></Button>
          <Button className="rs-button rs-button--icon-only delete" onClick={() => {setIdDelete(row.id); setIsOpenDelete(true)}}><DeleteOutlined /></Button>
        </Flex>
      ),
    },
    {
      title: 'Tên mẫu',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (d: any) => (
        mixDate(d)
      ),
    },
  ];

  const [showImportDataTemplateModal, setShowImportDataTemplateModal] = useState(false)
  const [showDetailImportDataTemplateModal, setShowDetailImportDataTemplateModal] = useState(false)
  const [idDetail, setIdDetail] = useState(null as any)

  const [listSource, setListSource] = useState([] as any)
  let wait1 = false
  const getSource = async () => {
    if (wait1) {
      return
    }
    wait1 = true
    const res = await mixGetV2('/api/v1/fe/setting/column_warehouse')
    wait1 = false
    if (res.status) {
      const items = JSON.parse(res.data.value)
      const temp = [] as any
      items.map((val: any) => {
        temp.push({
          value: val.id,
          label: val.name,
        })
        return true
      })
      setListSource([...temp])
    }
  }

  const [listSourceV2, setListSourceV2] = useState([] as any)
  let wait2 = false
  const getSourceV2 = async () => {
    if (wait2) {
      return
    }
    wait2 = true
    const res = await mixGetV2('/api/v1/fe/setting/sources')
    wait2 = false
    if (res.status) {
      const items = JSON.parse(res.data.value)
      const temp = [] as any
      items.map((val: any) => {
        temp.push({
          value: val.id,
          label: val.name,
        })
        return true
      })
      setListSourceV2([...temp])
    }
  }

  useEffect(() => {
    getSource()
    getSourceV2()
  }, [])

  const [IsOpenDelete, setIsOpenDelete] = useState(false)
  const [idDelete, setIdDelete] = useState(null)
  const onConfirmDelete = async () => {
    const res = await mixPostV2(`/api/v1/be/template_excel/${idDelete}`, {_method: 'DELETE',})
    if (res.status) {
      setIsOpenDelete(false)
      pushToast("Thao tác thành công", "success")
      getTemplate()
    } else {
      pushToast("Error!", "warning")
    }
  }

  return (
    <>
      {/* {contextHolder} */}

      <Layout className='customer-list'>
        <BreadcrumbBlock
          items={[
            { title: <Link to="/">THACO AUTO CRM</Link>, },
            { title: 'Khách hàng', },
            { title: <Link to="/customer/pooling-data">Gộp dữ liệu khách hàng</Link>, },
            { title: 'Quản lý danh sách mẫu template', },
          ]}
          title='Quản lý danh sách mẫu template'
          backLink='/customer/pooling-data'
          className='gutter-block-end'
        />

        <Flex vertical className='gap-18px'>
          <Flex align='center' className='gap-12px'>
            <Search
              placeholder="Tìm kiếm mẫu template"
              allowClear
              className="rs-input-search"
              onSearch={onSearch}
            />
            <Button
              type="primary"
              className="rs-button rs-button--sm"
              onClick={() => {setShowImportDataTemplateModal(true)}}
            >Tạo mẫu mới</Button>
          </Flex>

          <Table
            columns={columns}
            dataSource={listTemplate}
            showSorterTooltip={false}
            rowKey="id"
            className="rs-table"
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 15,
              showTotal: (total) => `Tổng cộng ${Math.ceil(total / 15)} trang`,
              locale: { items_per_page: ' / trang', jump_to: 'Tới' },
              position: ['bottomCenter'],
              className: 'rs-pagination',
              showQuickJumper: true,
            }}
          />
        </Flex>
      </Layout>

      <CreateImportDataTemplateModal
        show={showImportDataTemplateModal}
        listSource={listSource}
        listSourceV2={listSourceV2}
        modalClose={() => {setShowImportDataTemplateModal(false)}}
        modalFinish={() => {setShowImportDataTemplateModal(false); getTemplate()}}
      />

      <DetailImportDataTemplateModal
        show={showDetailImportDataTemplateModal}
        id={idDetail}
        listSource={listSource}
        listSourceV2={listSourceV2}
        modalClose={() => {setShowDetailImportDataTemplateModal(false)}}
        modalFinish={() => {setShowDetailImportDataTemplateModal(false); getTemplate()}}
      />

      <ModalDeleteManager
        isOpen={IsOpenDelete}
        closeModal={() => setIsOpenDelete(false)}
        onConfirm={onConfirmDelete}
        title='Bạn có chắc chắn xóa template này'
        titleDelete='Xóa template'
        content='Dữ liệu sẽ bị xóa vĩnh viễn không thể khôi phục'
      />
    </>
  )
}

export default CustomerPoolingDataTemplateManagement
