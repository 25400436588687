import { Drawer, Form, FormInstance, Input, Select } from "antd"
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useRef } from "react";
import type { FormProps } from 'antd';
import useToast from "../../../../hook/useToast";
import { IAccountDetail, editUserAccount } from "../../../../api/groupPermission/groupPermissionApi";
import { IBusinessUnit, IUnitChild } from "../../../../api/selectOptions/selectOptionsApi";
import { fommatSelectOption } from "../../../../until";
interface DrawerProps {
  isOpen: boolean
  infoAccount?: IAccountDetail
  sLUnitChild: IUnitChild[]
  sLBusinessUnit: IBusinessUnit[]
  onSubmitFormModal?: (iSsuccess: boolean, message?: string) => void;
  closeDrawer: () => void
}
const DrawerEditAccount: React.FC<DrawerProps> = ({
  isOpen, infoAccount, sLUnitChild, sLBusinessUnit,
  onSubmitFormModal, closeDrawer
}) => {


  const [form] = useForm();
  const inputRef = useRef<FormInstance>(null);
  const pushToast = useToast();
  const submitForm = () => {
    if (inputRef.current) {
      inputRef.current.submit();
    }
  };
  const onFinish: FormProps<any>['onFinish'] = async (values) => {
    const { dv, cq } = values
    const result = await editUserAccount(
      Number(infoAccount?.id), dv, cq
    )
    if (result?.status) {
      onSubmitFormModal?.(true, result.message)
    } else {
      onSubmitFormModal?.(false, result.message)
    }
  }

  useEffect(() => {
    if (isOpen) {
      form.setFieldValue('name', infoAccount?.fullname)
      form.setFieldValue('dv', infoAccount?.unit.id)
      form.setFieldValue('cq', infoAccount?.groupPermission.id)

    } else {
      form.resetFields()
    }
  }, [isOpen])

  return (
    <Form
      form={form}
      ref={inputRef}
      onFinish={onFinish}
      initialValues={{ remember: true }}
    >
      <Drawer
        title={<p className="font-medium fw-500 text-344054 py-3 px-6 bg-FCFCFD">Chỉnh sửa thông tin</p>}
        placement="right"
        rootClassName="custom-drawer"
        closable={false}
        onClose={closeDrawer}
        open={isOpen}
        footer={<div className="py-2 px-6 bg-FCFCFD d-flex justify-end gap-2">
          <button
            className="border-EDEDEE bg-white rounded-2  h-8  text-sm font-regular text-344054 px-2"
            onClick={closeDrawer}
          >
            Hủy
          </button>
          <button onClick={submitForm} className="border-none bg-096DD9 rounded-2  h-8  text-sm font-regular text-white px-2">Cập nhật</button>
        </div>}
      >
        <div className="d-flex flex-column ">
          <p className="text-sm fw-700 font-bold text-344054 py-4">Thông tin cơ bản</p>
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-column gap-3">
              <p className=" text-sm fw-500 font-medium text-475467 ">Mã số nhân viên</p>
              <Input
                placeholder="Mã đơn vị"
                className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-10"
                value={infoAccount?.code}
                disabled
              />
            </div>
            <div className="d-flex flex-column gap-3">
              <p className=" text-sm fw-500 font-medium text-475467 ">Họ và tên</p>
              <Form.Item
                name="name"
                className="m-none-form-item text-explan"
              >
                <Input
                  disabled
                  placeholder="Họ và tên"
                  className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-10"
                />
              </Form.Item>

            </div>
          </div>
          <p className="text-sm fw-700 font-bold text-344054 py-5">Quyền và nhóm quyền</p>
          <div className="d-flex flex-column gap-3">

            <div className="d-flex flex-column gap-3">
              <p className=" text-sm fw-500 font-medium text-475467 ">Đơn vị</p>
              <Form.Item
                name="dv"
                className="m-none-form-item text-explan"
                rules={[{ required: true, message: 'Vui lòng chọn đơn vị!' }]}
              >
                <Select
                  showSearch
                  placeholder="Chọn đơn vị "
                  className="custome-select-98A2B3"
                  popupClassName='popup-select '
                  options={fommatSelectOption(sLUnitChild)}
                />
              </Form.Item>

            </div>
            <div className="d-flex flex-column gap-3">
              <p className=" text-sm fw-500 font-medium text-475467 mr-6">Chọn nhóm quyền</p>
              <Form.Item
                name="cq"
                className="m-none-form-item text-explan"
                rules={[{ required: true, message: 'Vui lòng chọn nhóm quyền!' }]}
              >
                <Select
                  placeholder="Chọn nhóm quyền"
                  className="custome-select-98A2B3"
                  popupClassName='popup-select '
                  options={fommatSelectOption(sLBusinessUnit)}
                />
              </Form.Item>

            </div>

          </div>
        </div>
      </Drawer>
    </Form>

  )
}
export default DrawerEditAccount
