import React, { useEffect, useState, } from 'react';
import { Link, useLocation } from "react-router-dom";
import { BarChartOutlined, SmileOutlined, MenuUnfoldOutlined, MenuFoldOutlined, HeartOutlined, ContainerOutlined, LineChartOutlined,HddOutlined, SettingOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import './Menu.scss'

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps['items'] = [
  getItem('Báo cáo phân tích', 'analysis-report', <BarChartOutlined />, [
    getItem(
      <Link to={'/'} rel="noopener noreferrer">
        Chiến dịch Marketing
      </Link>,
      '/',
    ),
    getItem(
      <Link to={'/analysis-report/customers'} rel="noopener noreferrer">
        Khách hàng
      </Link>,
      '/analysis-report/customers',
    ),
    getItem(
      <Link to={'/analysis-report/vip-customer-care'} rel="noopener noreferrer">
        Chăm sóc khách hàng VIP
      </Link>,
      '/analysis-report/vip-customer-care',
    ),
    getItem(
      <Link to={'/analysis-report/customer-care'} rel="noopener noreferrer">
        Chăm sóc khách hàng
      </Link>,
      '/analysis-report/customer-care',
    ),
    getItem(
      <Link to={'/analysis-report/voucher'} rel="noopener noreferrer">
        Voucher
      </Link>,
      '/analysis-report/voucher',
    ),
  ]),

  getItem('Khách hàng', 'customer', <SmileOutlined />, [
    getItem(
      <Link to={'/customer/list'} rel="noopener noreferrer">
        Danh sách khách hàng
      </Link>,
      '/customer/list',
    ),
    getItem(
      <Link to={'/customer/group'} rel="noopener noreferrer">
        Nhóm khách hàng
      </Link>,
      '/customer/group',
    ),
    getItem(
      <Link to={'/customer/pooling-data'} rel="noopener noreferrer">
        Gộp dữ liệu khách hàng
      </Link>,
      '/customer/pooling-data',
    ),
    getItem(
      <Link to={'/customer/hobby'} rel="noopener noreferrer">
        Sở thích
      </Link>,
      '/customer/hobby',
    ),
    getItem(
      <Link to={'/customer/career'} rel="noopener noreferrer">
        Nghề nghiệp
      </Link>,
      '/customer/career',
    ),
  ]),

  getItem('Thẻ thành viên', 'member-card', <HeartOutlined />, [
    getItem(
      <Link to={'/member-card/card-issuance'} rel="noopener noreferrer">
        Phát hành thẻ
      </Link>,
      '/member-card/card-issuance',
    ),
    getItem(
      <Link to={'/member-card/class-list'} rel="noopener noreferrer">
        Danh sách hạng
      </Link>,
      '/member-card/class-list',
    ),
    getItem(
      <Link to={'/member-card/class-benefits'} rel="noopener noreferrer">
        Quyền lợi hạng
      </Link>,
      '/member-card/class-benefits',
    ),
  ]),

  getItem('Quản lý xe', 'vehicle-management', <ContainerOutlined />, [
    getItem(
      <Link to={'/vehicle-management/vehicle-list'} rel="noopener noreferrer">
        Danh sách xe
      </Link>,
      '/vehicle-management/vehicle-list',
    ),
    getItem(
      <Link to={'/vehicle-management/vehicle-category-management'} rel="noopener noreferrer">
        Quản lý chủng loại xe
      </Link>,
      '/vehicle-management/vehicle-category-management',
    ),
    getItem(
      <Link to={'/vehicle-management/brand-management'} rel="noopener noreferrer">
        Quản lý thương hiệu
      </Link>,
      '/vehicle-management/brand-management',
    ),
    getItem(
      <Link to={'/vehicle-management/vehicle-type-management'} rel="noopener noreferrer">
        Quản lý dòng xe
      </Link>,
      '/vehicle-management/vehicle-type-management',
    ),
    getItem(
      <Link to={'/vehicle-management/vehicle-version-management'} rel="noopener noreferrer">
        Quản lý phiên bản xe
      </Link>,
      '/vehicle-management/vehicle-version-management',
    ),
  ]),

  getItem('Marketing', 'marketing', <LineChartOutlined />, [
    getItem(
      <Link to={'/marketing/campaign-sends-notifications'} rel="noopener noreferrer">
        Chiến dịch gửi thông báo
      </Link>,
      '/marketing/campaign-sends-notifications',
    ),
    getItem(
      <Link to={'/marketing/notification-templates-management'} rel="noopener noreferrer">
        Q.lý mẫu thông báo
      </Link>,
      // 'Q.lý mẫu thông báo',
      '/marketing/notification-templates-management',
      // null,
      // [
      //   getItem(
      //     <Link to={'/marketing/notification-templates-management?id=THACO-AUTO'} rel="noopener noreferrer">
      //       THACO AUTO
      //     </Link>,
      //     '/marketing/notification-templates-management',
      //   ),
      //   getItem(
      //     <Link to={'/marketing/notification-templates-management?id=BMW'} rel="noopener noreferrer">
      //       BMW
      //     </Link>,
      //     '/marketing/notification-templates-management',
      //   ),
      //   getItem(
      //     <Link to={'/marketing/notification-templates-management?id=Mazda'} rel="noopener noreferrer">
      //       Mazda
      //     </Link>,
      //     '/marketing/notification-templates-management',
      //   ),
      //   getItem(
      //     <Link to={'/marketing/notification-templates-management?id=KIA'} rel="noopener noreferrer">
      //       KIA
      //     </Link>,
      //     '/marketing/notification-templates-management',
      //   ),
      //   getItem(
      //     <Link to={'/marketing/notification-templates-management?id=Mini'} rel="noopener noreferrer">
      //       Mini
      //     </Link>,
      //     '/marketing/notification-templates-management',
      //   ),
      //   getItem(
      //     <Link to={'/marketing/notification-templates-management?id=Peugeot'} rel="noopener noreferrer">
      //       Peugeot
      //     </Link>,
      //     '/marketing/notification-templates-management',
      //   ),
      // ],
    ),
    getItem(
      <Link to={'/marketing/notification-fees-setting'} rel="noopener noreferrer">
        Cài đặt phí gửi thông báo
      </Link>,
      '/marketing/notification-fees-setting',
    ),
    getItem(
      <Link to={'/marketing/vouchers-management'} rel="noopener noreferrer">
        Quản lý Voucher
      </Link>,
      '/marketing/vouchers-management',
    ),
    getItem(
      <Link to={'/marketing/voucher-search'} rel="noopener noreferrer">
        Tra cứu Voucher
      </Link>,
      '/marketing/voucher-search',
    ),
  ]),

  getItem('Danh mục đơn vị', 'departmental-unit', <HddOutlined />, [
    getItem(
      <Link to={'/departmental-unit/nationwide'} rel="noopener noreferrer">
        Toàn quốc
      </Link>,
      '/departmental-unit/nationwide',
    ),
    getItem(
      <Link to={'/departmental-unit/regions'} rel="noopener noreferrer">
        Vùng miền
      </Link>,
      '/departmental-unit/regions',
    ),
    getItem(
      <Link to={'/departmental-unit/units'} rel="noopener noreferrer">
        Đơn vị
      </Link>,
      '/departmental-unit/units',
    ),
    getItem(
      <Link to={'/departmental-unit/agency'} rel="noopener noreferrer">
        Đại lý
      </Link>,
      '/departmental-unit/agency',
    ),
    getItem(
      <Link to={'/departmental-unit/departments'} rel="noopener noreferrer">
        Phòng ban
      </Link>,
      '/departmental-unit/departments',
    ),
    getItem(
      <Link to={'/departmental-unit/issuing-unit'} rel="noopener noreferrer">
        Đơn vị ban hành
      </Link>,
      '/departmental-unit/issuing-unit',
    ),
  ]),

  getItem('Cấu hình hệ thống', 'system-configuration', <SettingOutlined />, [
    getItem(
      <Link to={'/system-configuration/accounts-management'} rel="noopener noreferrer">
        Quản lý tài khoản
      </Link>,
      '/system-configuration/accounts-management',
    ),
    getItem(
      <Link to={'/system-configuration/account-role'} rel="noopener noreferrer">
        Phân quyền
      </Link>,
      '/system-configuration/account-role',
    ),
    getItem(
      <Link to={'/system-configuration/general-configuration'} rel="noopener noreferrer">
        Cấu hình chung
      </Link>,
      '/system-configuration/general-configuration',
    ),
  ]),
];

const WidgetMenu: React.FC = () => {
  const pathConvert = (path: string) => {
    const pathArr = path.split('/')
    let result = ''

    if (pathArr.length > 3) {
      result = [pathArr[0], pathArr[1], pathArr[2]].join('/')
    } else {
      result = path
    }

    return result
  }

  let location = useLocation()
  const [current, setCurrent] = useState(
    location.pathname === '/' || location.pathname === ''
      ? '/'
      : pathConvert(location.pathname)
  )

  const parents = ['analysis-report', 'customer', 'member-card', 'vehicle-management', 'marketing', 'departmental-unit', 'system-configuration']
  const [currentParent, setCurrentParent] = useState(
    parents.find(item => current.includes(item)) || 'analysis-report'
  )

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname)
      }
      const parents = ['analysis-report', 'customer', 'member-card', 'vehicle-management', 'marketing', 'departmental-unit', 'system-configuration']
      const parentName = parents.find(item => current.includes(item)) || ''
      setCurrentParent(parentName)
    }
  }, [location, current])

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key)
  };

  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    const appElem = document.getElementsByClassName('app')[0]
    if (!appElem) return false
    appElem.classList.toggle('menu-collapsed')
    setCollapsed(!collapsed);
  };

  return (
    <div className='app-menu-vertical'>
      <Menu
        onClick={onClick}
        defaultSelectedKeys={[current]}
        defaultOpenKeys={[currentParent]}
        mode="inline"
        items={items}
        inlineCollapsed={collapsed}
        className='rs-scrollbar'
      />
      <div
        className='menu-trigger'
        onClick={toggleCollapsed}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </div>
    </div>
  );
};

export default WidgetMenu;
