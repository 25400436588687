import { CloseOutlined } from "@ant-design/icons"
import { Modal, Select, Input } from "antd"
import './style.scss'
import { IVersionCarEdit, editVersionCar } from "../../../../../api/versionCar/versionCarApi"
import { ICarCompany, IRelative, getCLCarLines, getCLTypeVehicle } from "../../../../../api/selectOption/selectOptionsApi"
import { useEffect, useState } from "react"
import useDebounce from "../../../../../hook/useDebounce"
import useThrottle from "../../../../../hook/useThrottle"
import useToast from "../../../../../hook/useToast"
import { fommatSelectOption } from "../../../../../until"
interface ModalProps {
  isOpen: boolean
  infoVersionCar?: IVersionCarEdit
  sLCategoryCar: ICarCompany[]
  onSubmitFormModal?: (iSsuccess: boolean, errorMessage?: string) => void;
  closeModal: () => void
}
const ModalEditVersionCar: React.FC<ModalProps> = ({
  isOpen, infoVersionCar, sLCategoryCar,
  onSubmitFormModal, closeModal
}) => {
  const pushToast = useToast();

  let errorsObj = { versionCar: "", categoryCar: "", carLines: "", typeVehicle: "", result: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [versionCar, setVersionCar] = useState("")
  const [categoryCar, setCategoryCar] = useState<number>()//th xe
  const [carLines, setCarLines] = useState<number>()//d xe
  const [typeVehicle, setTypeVehicle] = useState<number>()//chungr

  const [cLCarLines, setCLCarLines] = useState<IRelative[]>([])
  const [cLTypeVehicle, setCLTypeVehicle] = useState<IRelative[]>([])

  //dòng xe
  const changeCategoryCar = useDebounce(async (id: number, isSearch?: boolean) => {
    setCategoryCar(id)
    if (isSearch) {
      setCarLines(undefined)
      setTypeVehicle(undefined)
    }
    const result = await getCLCarLines(id)
    if (result?.status) {
      setCLCarLines(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 200)

  //Chủng loại xe
  const changeCarLines = useDebounce(async (id: number, isSearch?: boolean) => {
    setCarLines(id)
    if (isSearch) {
      setTypeVehicle(undefined)
    }
    const result = await getCLTypeVehicle(id)
    if (result?.status) {
      setCLTypeVehicle(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 200)

  const checkVariables = useThrottle(() => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (!versionCar) {
      errorObj.versionCar = "Vui lòng nhập phiên bản xe!"
      error = true;
    }
    if (!categoryCar) {
      errorObj.categoryCar = "Vui lòng chọn tên thương hiệu xe!"
      error = true;
    }
    if (!carLines) {
      errorObj.carLines = "Vui lòng chọn dòng xe!"
      error = true;
    }
    if (!typeVehicle) {
      errorObj.typeVehicle = "Vui lòng chọn chủng loại xe!"
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    } else {
      eVersionCar()
    }
  }, 1000)

  const eVersionCar = async () => {
    const result = await editVersionCar(Number(infoVersionCar?.id), versionCar, Number(categoryCar), Number(carLines), Number(typeVehicle))
    if (result?.status) {
      onSubmitFormModal?.(true)
    } else {
      onSubmitFormModal?.(false, result.message)
    }
  }

  useEffect(() => {
    if (isOpen) {
      setVersionCar(infoVersionCar?.title ?? "")
      setCategoryCar(infoVersionCar?.categoryCarId)
      setCarLines(infoVersionCar?.carLinesId)
      setTypeVehicle(infoVersionCar?.typeVehicleId)

      changeCategoryCar(infoVersionCar?.categoryCarId)
      changeCarLines(infoVersionCar?.carLinesId)
    } else {
      setErrors(errorsObj)

      setVersionCar("")
      setCategoryCar(undefined)
      setCarLines(undefined)
      setTypeVehicle(undefined)

      setCLCarLines([])
      setCLTypeVehicle([])
    }
  }, [isOpen, infoVersionCar])

  return (
    <Modal
      title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
        <span className="text-base text-344054 fw-500 font-medium">Chỉnh sửa phiên bản xe</span>
        <CloseOutlined className="text-base text-8B8B8B" onClick={closeModal} />
      </div>}
      open={isOpen}
      onCancel={closeModal}
      centered
      className="w-modal modal-content-verssion ant-modal-size"
      closeIcon={false}
      footer={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-2 px-6 w-full border-t-F0F0F0">
        <span className="text-sm text-98A2B3 cursor-pointer" onClick={closeModal}>Huỷ</span>
        <button className="text-white text-sm bg-096DD9 border-none shadow-btn-save rounded-2 h-8 px-15-px"
          onClick={checkVariables}>
          Lưu chỉnh sửa
        </button>
      </div>}
    >
      <div className="p-6 d-flex flex-column gap-18-px">
        <div className="d-flex flex-column gap-1">
          <p className="text-sm  font-regular text-344054">Phiên bản xe</p>
          <div className="d-flex flex-column">
            <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 pl-3  py-1 h-8  overflow-hidden">
              <Input
                placeholder="Phiên bản xe"
                className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input  hover-none"
                maxLength={50}
                value={versionCar}
                onChange={(e) => setVersionCar(e.target.value)}
              />
            </div>
            {
              errors.versionCar &&
              <span className="text-F5222D text-base font-regular pl-2">{errors.versionCar}</span>
            }
          </div>
        </div>
        <div className="d-flex flex-column gap-1">
          <p className="text-sm  font-regular text-344054">Tên thương hiệu xe</p>
          <div className="d-flex flex-column">
            <div className='w-full'>
              <Select
                placeholder="Tên thương hiệu xe"
                className="custome-select-t "
                popupClassName='popup-select'
                options={fommatSelectOption(sLCategoryCar)}
                value={categoryCar}
                onChange={(e) => changeCategoryCar(e, true)}
              />
            </div>
            {
              errors.categoryCar &&
              <span className="text-F5222D text-base font-regular pl-2">{errors.categoryCar}</span>
            }
          </div>
        </div>

        <div className="d-flex flex-column gap-1">
          <p className="text-sm  font-regular text-344054">Đời xe</p>
          <div className="d-flex flex-column">
            <div className='w-full'>
              <Select
                placeholder="Đời xe"
                className="custome-select-t "
                popupClassName='popup-select'
                options={[{ value: '2024', label: '2024' }, { value: '2023', label: '2023' }, { value: '2022', label: '2022' }, ]}
                // value={carLines}
                // onChange={(e) => changeCarLines(e)}
              />
            </div>
            {
              errors.carLines &&
              <span className="text-F5222D text-base font-regular pl-2">{errors.carLines}</span>
            }
          </div>
        </div>

        <div className="d-flex flex-column gap-1">
          <p className="text-sm  font-regular text-344054">Dòng xe</p>
          <div className="d-flex flex-column">
            <div className='w-full'>
              <Select
                placeholder="Dòng xe"
                className="custome-select-t "
                popupClassName='popup-select'
                options={fommatSelectOption(cLCarLines)}
                value={carLines}
                onChange={(e) => changeCarLines(e, true)}
              />
            </div>
            {
              errors.carLines &&
              <span className="text-F5222D text-base font-regular pl-2">{errors.carLines}</span>
            }
          </div>
        </div>

        <div className="d-flex flex-column gap-1">
          <p className="text-sm  font-regular text-344054">Chủng loại xe</p>
          <div className="d-flex flex-column">
            <div className='w-full'>
              <Select
                placeholder="Chủng loại xe"
                className="custome-select-t "
                popupClassName='popup-select'
                options={fommatSelectOption(cLTypeVehicle)}
                value={typeVehicle}
                onChange={(e) => setTypeVehicle(e)}
              />
            </div>
            {
              errors.typeVehicle &&
              <span className="text-F5222D text-base font-regular pl-2">{errors.typeVehicle}</span>
            }
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default ModalEditVersionCar
