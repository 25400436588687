import { Button, Form, FormInstance, FormProps, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useRef } from "react";
import * as BenefitApi from '../../api/rank/benefitApi';
import useToast from "../../hook/useToast";
import './ShowParam.scss';
import { mixDate } from '../../services/mixin'

interface Props {
  className?: string
  closeModal: () => void
  onShowHistory?: () => void
  detailData?: any
}

const ShowParam: React.FC<Props> = ({ className, closeModal, onShowHistory, detailData }) => {

  const inputRef = useRef<FormInstance>(null);
  const pushToast = useToast();
  const [form] = useForm();

  const submitForm = () => {
    if (inputRef.current) {
      inputRef.current.submit();
    }
  };

  const onFinish: FormProps["onFinish"] = async (values) => {
    const result = await BenefitApi.addBenefit(values.title, values.description);
    if (result?.status) {
      pushToast("Thêm mới thành công!", "success", 1000)
      closeModal();
    } else {
      pushToast(result.message, "error", 1000)
    }
  };

  const popupRef = useRef<HTMLDivElement>(null);

  const handleReset = () => {
    if (inputRef.current) {
      inputRef.current.resetFields();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
        if (closeModal) {
          closeModal();
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closeModal]);

  return (
    <div
      ref={popupRef}
      className="filter-setting add-card-issuance"
      id="addcardissuance"
    >
      <div className="title-add-filter">
        <span>Chi tiết</span>
        <div className="icon-close" onClick={closeModal}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.92473 7.99916L13.6122 2.41166C13.6908 2.31881 13.6247 2.17773 13.5033 2.17773H12.0783C11.9944 2.17773 11.914 2.21523 11.8587 2.27952L7.99258 6.88845L4.12651 2.27952C4.07294 2.21523 3.99258 2.17773 3.90687 2.17773H2.48187C2.36044 2.17773 2.29437 2.31881 2.37294 2.41166L7.06044 7.99916L2.37294 13.5867C2.35534 13.6074 2.34405 13.6327 2.3404 13.6596C2.33676 13.6865 2.34092 13.7139 2.35239 13.7386C2.36386 13.7632 2.38216 13.784 2.40511 13.7985C2.42806 13.8131 2.4547 13.8207 2.48187 13.8206H3.90687C3.9908 13.8206 4.07115 13.7831 4.12651 13.7188L7.99258 9.10988L11.8587 13.7188C11.9122 13.7831 11.9926 13.8206 12.0783 13.8206H13.5033C13.6247 13.8206 13.6908 13.6795 13.6122 13.5867L8.92473 7.99916Z" fill="black" fill-opacity="0.45"/></svg>
        </div>
      </div>
      <div className="content-filter-setting t-scrollbar">
        <div className="title-show-param">Thông tin Param</div>
        <div className="info-show-param">
          <div className="one-item">
            <div className="left-content">Tên Param:</div>
            <div className="right-content">{detailData?.name}</div>
          </div>
          <div className="one-item">
            <div className="left-content">Mã Param:</div>
            <div className="right-content">{detailData?.column_warehouse?.column}</div>
          </div>
          <div className="one-item">
            <div className="left-content">Ngày tạo:</div>
            <div className="right-content">{mixDate(detailData?.created_at)}</div>
          </div>
          <div className="one-item">
            <div className="left-content">Trạng thái</div>
            <div className="right-content">Đang hoạt động</div>
          </div>
          <div className="one-item">
            <div className="left-content">Hệ thống</div>
            <div className="right-content">
              <div className="label">{detailData?.source?.name}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShowParam
