import { DeleteOutlined, DownloadOutlined, DownOutlined, EditOutlined, PlusOutlined, SearchOutlined, SortAscendingOutlined, UploadOutlined } from '@ant-design/icons';
import { Breadcrumb, Input, Select, Switch, Table, TableColumnsType, TableProps } from 'antd';
import React, { useEffect, useState } from 'react';
import ModalDeleteManager from '../../../components/ModalDeleteManager';
import Layout from '../../../Layout';
import ModalCreateBrand from './Component/Modal/ModalCreateBrand';
import ModalEditBrand from './Component/Modal/ModalEditBrand';
import './style.scss'
import { SortType } from '../../../api/carLine/carLineApi';
import useToast from '../../../hook/useToast';
import useDebounce from '../../../hook/useDebounce';
import * as categoryCarApi from '../../../api/categoryCar/categoryCarApi';
import * as selectOptionsApi from '../../../api/selectOptions/selectOptionsApi';
import { LIMIT_EXPORT_EXCEL, URL_CAR_CATEGORY } from '../../../env';
import { dataSoft, DataStatusButton } from '../../../services/data';
import useThrottle from '../../../hook/useThrottle';
import ModalUpload from '../../../components/Modal/ModalUpload';
import * as XLSX from 'xlsx';
import { exportExcel } from '../../../until';

interface DataType {
  key: React.Key;
  stt: number;
  logoBrand: string;
  nameBrand: string;
  status: number
}

const BrandManagement: React.FC = () => {
  useEffect(() => {

    return () => {
      setFilterName(undefined)
      setFilterSort(undefined)

      setIdCategoryCar(undefined)

      setListCategoryCar([])
    }
  }, [])

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Hành động',
      dataIndex: '',
      render: (_, record) => (
        <div className='t-hd-table'>
          <div
            className="item-hd-table cursor-pointer"
            onClick={() => gInfoCategoryCar(record.key)}>
            <span><EditOutlined /></span>
          </div>
          <DeleteOutlined
            className='text-F5222D text-sm border-EAECF0 p-5-px bg-white rounded-2'
            onClick={() => openModalDeleteCategoryCar(Number(record.key))}
          />
        </div>
        // <div className='d-flex align-center gap-10-px'>
        //   <EditOutlined className='text-98A2B3 text-base border-EAECF0 p-2 bg-white rounded-2'
        //     onClick={() => gInfoCategoryCar(record.key)}
        //   />
        //   <DeleteOutlined
        //     className='text-F5222D text-base border-EAECF0 p-2 bg-white rounded-2'
        //     onClick={() => openModalDeleteCategoryCar(Number(record.key))}
        //   />
        // </div>
      ),
      className: "w-93-px",
      align: "center"
    },
    {
      title: 'STT',
      dataIndex: 'stt',
      sorter: {
        compare: (a, b) => a.stt - b.stt,
        multiple: 1,
      },
      className: "w-60-px",
      align: "center"

    },
    {
      title: 'Logo thương hiệu',
      dataIndex: 'logoBrand',
      className: "w-501-px",
      render: (logo: any) => (<img src={logo} alt="" className='w-40-px h-40-px' />)
    },

    {
      title: 'Tên thương hiệu',
      dataIndex: 'nameBrand',
      sorter: {
        compare: (a, b) => a.nameBrand.length - b.nameBrand.length,
        multiple: 1,
      },
      render: (nameBrand: string) => <div className='text-2-row'>{nameBrand}</div>,
      className: "w-501-px"
    },
    {
      title: 'Trạng thái',
      dataIndex: '',
      className: "w-175-px",
      render: (_, record) => <div className='d-flex align-center gap-10-px'>
        <Switch
          defaultChecked={record.status ? true : false}
          onChange={() => updateActiveCategoryCar(Number(record.key))}
          className="rs-switch rs-switch--sm"
        />
        <p className='font-medium fw-500 text-344054'>{selectOptionsApi.activeArray[record.status]}</p>
      </div>
    },
  ];

  const pushToast = useToast();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenCreatVehicle, setIsOpenCreatVehicle] = useState<boolean>(false)
  const [isOpenEditVehicle, setIsOpenEditVehicle] = useState<boolean>(false)
  const [isOpenDeleteBrand, setIsOpenDeleteBrand] = useState<boolean>(false)
  const [openModalUpload, setOpenModalUpload] = useState(false);

  const [filterName, setFilterName] = useState<string>()
  const [filterSort, setFilterSort] = useState<SortType>()


  const [idCategoryCar, setIdCategoryCar] = useState<number>()

  const [infoCategoryCar, setInfoCategoryCar] = useState<categoryCarApi.ICategoryCarEdit>()

  const [listCategoryCar, setListCategoryCar] = useState<DataType[]>([])

  const gListCategoryCar = useDebounce(async (pageCurent: number = 1, pageSize: number = 100) => {
    const result = await categoryCarApi.getListCategoryCar(pageSize, pageCurent, filterName, filterSort)
    if (result?.status) {
      setListCategoryCar(convertData(result.data, pageCurent, pageSize))
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const convertData = (data: categoryCarApi.ICategoryCar[], pageCurent: number, pageSize: number) => {
    var stt = (pageCurent - 1) * pageSize + 1;
    const convertData = data.map((item) => ({
      key: item.id,
      stt: stt++,
      logoBrand: URL_CAR_CATEGORY + item.image,
      nameBrand: item.title,
      status: item.status
    }))
    return convertData
  }

  const gInfoCategoryCar = useThrottle(async (id: number) => {
    const result = await categoryCarApi.getInfoCategoryCar(id)
    if (result?.status) {
      setInfoCategoryCar(result.data)
      setIsOpenEditVehicle(true)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const onSubmitFormModalAdd = (iSsuccess: boolean, errorMessage?: string) => {
    if (iSsuccess) {
      setIsOpenCreatVehicle(false);
      pushToast("Thêm thương hiệu xe", "success")
      gListCategoryCar()
    }
    else {
      pushToast(errorMessage, "warning")
    }
  }

  const onSubmitFormModalEdit = (iSsuccess: boolean, errorMessage?: string) => {
    if (iSsuccess) {
      setIsOpenEditVehicle(false);
      pushToast("Sửa thương hiệu xe", "success")
      gListCategoryCar()
    }
    else {
      pushToast(errorMessage, "warning")
    }
  }

  const openModalDeleteCategoryCar = (id: number) => {
    setIdCategoryCar(id)
    setIsOpenDeleteBrand(true)
  }

  const closeModalDeleteCategoryCar = () => {
    setIdCategoryCar(undefined)
    setIsOpenDeleteBrand(false)
  }

  const onConfirmDelete = async () => {
    const result = await categoryCarApi.deleteCategoryCar(Number(idCategoryCar))
    if (result?.status) {
      pushToast("Xóa thương hiệu xe thành công", "success")
      closeModalDeleteCategoryCar()
      gListCategoryCar()
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onChangeTB: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const updateActiveCategoryCar = async (id: number) => {
    const result = await categoryCarApi.updateActiveCategoryCar(id);
    if (result?.status) {
      gListCategoryCar()
      pushToast(result.message, "success", 2000)
    } else {
      pushToast(result.message, "error", 2000)

    }
  }

  const glistByExcel = useThrottle(async () => {
    const result = await categoryCarApi.getListCategoryCar(LIMIT_EXPORT_EXCEL, 1, filterName, filterSort)
    if (result?.status) {
      formattedDataExcel(result.data, result?.message)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const formattedDataExcel = (data: categoryCarApi.ICategoryCar[], message?: string) => {
    const formattedData = data.map((item) => {
      return {
        "Tên": item.title,
        "Trạng thái": DataStatusButton[item.status].label
      }
    }
    );
    exportExcel(formattedData, message)
  }

  useEffect(() => {
    gListCategoryCar()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterName, filterSort])

  return (

    <Layout>
      <ModalCreateBrand
        isOpen={isOpenCreatVehicle}
        onSubmitFormModal={onSubmitFormModalAdd}
        closeModal={() => setIsOpenCreatVehicle(!isOpenCreatVehicle)}
      />

      <ModalEditBrand
        isOpen={isOpenEditVehicle}
        infoCategoryCar={infoCategoryCar}
        onSubmitFormModal={onSubmitFormModalEdit}
        closeModal={() => setIsOpenEditVehicle(!isOpenEditVehicle)}
      />
      <ModalDeleteManager
        isOpen={isOpenDeleteBrand}
        title="Bạn có chắc chắn xóa thương hiệu này"
        titleDelete="Xóa thương hiệu"
        content="Thương hiệu và dữ liệu liên quan sẽ bị xóa vĩnh viễn
      không thể khôi phục"
        onConfirm={onConfirmDelete}
        closeModal={closeModalDeleteCategoryCar}
      />
      <ModalUpload
        isOpen={openModalUpload}
        closeModal={() => setOpenModalUpload(!openModalUpload)}
      />
      <Breadcrumb
        items={[
          {
            title: 'THACO AUTO CRM',
          },
          {
            title: <a href="/">Quản lý xe</a>,
          },
          {
            title: 'Quản lý thương hiệu xe',
          },
        ]}
      />
      <div className='title-page'>
        Quản lý thương hiệu xe
      </div>
      <div className='d-flex gap-3 pb-18-px brand-management-filter'>
        <div className="w-564-px border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center pl-3  py-1 h-8 justify-between overflow-hidden">
          <Input
            placeholder="Tìm kiếm theo thương hiệu xe"
            className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input place-holder-input-667085 hover-none"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          />
          <div className=" border-l-F2F4F7 h-full d-flex align-center">
            <SearchOutlined className="text-sm text-98A2B3 px-9-px" />
          </div>
        </div>
        <div className='d-flex align-center gap-2'>
          <span className='text-sm font-regular fw-400 text-344054 white-space-nowrap'>Sắp xếp</span>
          <div className="w-200-px border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center px-3  py-2-px h-8 justify-between overflow-hidden">
            <div className="d-flex align-center  w-full">
              <SortAscendingOutlined className="text-18-px pr-10-px text-344054" />
              <Select
                showSearch
                className="w-full  text-left text-span-select text-sm  bg-FCFCFD-ant-select font-regular p-none-select h-8 line-none line-none-item inline-none size-selector "
                popupClassName='position-dropdow custome-select-item h-popUp'
                variant="borderless"
                placeholder="Chọn bộ lọc"
                suffixIcon={null}
                options={dataSoft}
                value={filterSort}
                onChange={(e) => setFilterSort(e)}
              />

            </div>
            <div className="  h-full d-flex align-center">
              <DownOutlined className="text-sm text-98A2B3 " />
            </div>
          </div>
        </div>
        <button
          className='border-EAECF0 bg-white rounded-6-px w-106-px py-2 h-8 d-flex justify-center align-center gap-10-px shadow-btn-save'
          onClick={glistByExcel}
        >
          <DownloadOutlined className='text-sm text-344054' />
          <span className='text-sm fw-400 font-regular text-344054 white-space-nowrap'>Xuất DS</span>
        </button>
        <button
          className='border-EAECF0 bg-white rounded-6-px w-111-px py-2 h-8 d-flex justify-center align-center gap-10-px shadow-btn-save'
          onClick={() => setOpenModalUpload(true)}
        >
          <UploadOutlined className='text-sm text-344054' />
          <span className='text-sm fw-400 font-regular text-344054 white-space-nowrap'>Nhập DS</span>
        </button>
        <button className='border-none bg-096DD9 rounded-6-px  py-2 h-8 d-flex justify-center align-center gap-10-px w-107-px' onClick={() => setIsOpenCreatVehicle(true)}>
          <PlusOutlined className='text-sm text-white' />
          <span className='text-sm fw-400 font-regular text-white white-space-nowrap'>Tạo mới</span>
        </button>
      </div>
      <div className="t-table-block">
        <Table
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={listCategoryCar}
          onChange={onChangeTB}
          pagination={false}
          showSorterTooltip={false}
          className="w-fit-table border-end-table"
        />

      </div>
    </Layout>

  );
}

export default BrandManagement;
