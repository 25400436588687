import React, { useEffect } from 'react';
import Layout from '../../Layout';
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock';
import { Link } from 'react-router-dom';
import './CustomerCare.scss'
import ARCCFilterBlock from './ARCCComponents/ARCCFilterBlock';
import ARCCStatisticalCards from './ARCCComponents/ARCCStatisticalCards';
import ARCCQuantityRequested from './ARCCComponents/ARCCQuantityRequested';
import ARCCStatisticalChart from './ARCCComponents/ARCCStatisticalChart';
import ARCCComplaintStatisticsChartByProvince from './ARCCComponents/ARCCComplaintStatisticsChartByProvince';
import { Tabs, TabsProps } from 'antd';
import ARCCSupportStatisticsPieChart from './ARCCComponents/ARCCSupportStatisticsPieChart';
import ARCCComplaintStatisticsPieChart from './ARCCComponents/ARCCComplaintStatisticsPieChart';


const tabItems: TabsProps['items'] = [
  {
    label: 'Khiếu nại',
    key: 'tab-1',
    children: <ARCCComplaintStatisticsPieChart />,
  },
  {
    label: 'Hỗ trợ khách hàng',
    key: 'tab-2',
    children: <ARCCSupportStatisticsPieChart />
  },
];

const CustomerCare: React.FC = () => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);
  return (
    <Layout contentClassName='arcc-content'>
      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: 'Báo cáo phân tích', },
          { title: 'Chăm sóc khách hàng', },
        ]}
        title='Chăm sóc khách hàng'
      />

      <ARCCFilterBlock />
      <ARCCStatisticalCards />

      <div className="arcc-grid-93">
        <ARCCQuantityRequested />
        <ARCCStatisticalChart />
      </div>

      <Tabs
        defaultActiveKey="tab-1"
        items={tabItems}
        className='rs-tabs'
      />

      <ARCCComplaintStatisticsChartByProvince />
    </Layout>
  );
}

export default CustomerCare;