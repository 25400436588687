import React, { useState, useEffect, useRef } from 'react';
import Layout from '../../Layout';
import {
  Breadcrumb,
  Button,
  Form,
  Space,
  Input,
  Select,
} from 'antd';
import './PoolingDataMapping.scss'
import SelectSystem from './ComponentPoolingData/SelectSystem';
import DragDropTitle from './ComponentPoolingData/DragDropTitle';
import { Link } from 'react-router-dom';
import { DeleteFilled, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { mixGetV2, mixPostV2 } from '../../services/mixin'
import { calc } from 'antd/es/theme/internal';
import useToast from '../../hook/useToast';

const PoolingDataMapping: React.FC = () => {
  const pushToast = useToast();
  const onFinish = async (values: any) => {
    const params = {
      source: [] as any,
      column: [] as any,
    }
    let error = false
    values.listadd.map((val: any, index: any) => {
      if (val && val.source_id) {
        params.source.push({
          source_id: val.source_id,
          position: index + 1
        })
      } else {
        error = true
      }
      return true
    })
    if (error) {
      pushToast("Vui lòng chọn nguồn!", "warning")
      return
    }
    listData.map((val:any, index:any) => {
      params.column.push({
        field: val.field,
        source_column_warehouses_id: Number(val.id),
        position: index + 1
      })
      return true
    })
    const res = await mixPostV2('/api/v1/be/setting_mapping', {
      value: JSON.stringify(params),
    })
    if (res.status) {
      pushToast("Thao tác thành công", "success")
    } else {
      pushToast("Error!", "warning")
    }
  };
  const onFormSubmit = () => {
    formRef?.current.submit()
  }

  const [opSelectSystem, setOpSelectSystem] = useState(false)
  const formRef: any = useRef(null)

  const [paramListData, setParamListData] = useState({
    'sort[]': 'id,desc',
    page: 1,
    limit: 100000,
    name: '',
    type_id : null,
  })
  let wait = false
  const [listData, setListData] = useState([] as any)
  const getListData = async () => {
    if (wait) {
      return
    }
    wait = true
    const res = await mixGetV2('/api/v1/be/source_column_warehouses', paramListData)
    const res2 = await mixGetV2('/api/v1/be/setting_mapping')
    const obTemp = {} as any
    if (res2.status) {
      const temp = JSON.parse(res2.data.value || '{}')
      temp.column.map((val: any) => {
        obTemp[`id${val.source_column_warehouses_id}`] = val
        return true
      })

      const tempSrc = temp.source
      tempSrc.sort((a: any, b: any) => {
        return a.position - b.position
      })
      getSourceV2(tempSrc)
      const formvals = {
        listadd: [] as any
      }
      tempSrc.map((val: any) => {
        formvals.listadd.push({
          source_id: val.source_id,
        })
        return true
      })

      formRef?.current.setFieldsValue(formvals)
    }
    if (res.status) {
      const temp = [] as any
      res.data.items.map((val: any) => {
        temp.push({
          id: val.id.toString(),
          name: `${val.column_warehouse.column} {${val.name}}`,
          field: val.column_warehouse.column,
          status: false,
          position: obTemp[`id${val.id}`].position
        })
        return true
      })
      temp.sort((a: any, b: any) => {
        return a.position - b.position
      })
      setListData(temp)
    }
  }

  const [listSourceV2, setListSourceV2] = useState([] as any)
  const [listSourceV0, setListSourceV0] = useState([] as any)
  let wait2 = false
  const getSourceV2 = async (dataDf = [] as any) => {
    if (wait2) {
      return
    }
    wait2 = true
    const res = await mixGetV2('/api/v1/fe/setting/sources')
    wait2 = false
    if (res.status) {
      const items = JSON.parse(res.data.value)
      const temp = [] as any
      const obtemp = {} as any
      items.map((val: any) => {
        temp.push({
          value: val.id,
          label: val.name,
        })
        obtemp[`id${val.id}`] = {
          value: val.id,
          label: val.name,
        }
        return true
      })
      setListSourceV2([...temp])
      setListSourceV0([...temp])
      if (dataDf.length > 0) {
        const tempobsrc = {} as any
        dataDf.map((val: any, index: any) => {
          tempobsrc[`id${index}`] = obtemp[`id${val.source_id}`]
          return true
        })
        setObSource({...obSource, ...tempobsrc})
      }
    }
  }

  const [obSource, setObSource] = useState({} as any)
  useEffect(() => {
    const temp = [] as any
    const list = [] as any
    Object.keys(obSource).map((iskey: any) => {
      list.push(obSource[iskey])
      return true
    })
    listSourceV0.map((val: any) => {
      let findData = list.filter((x : any) => x.value === val.value)
      if (findData.length === 0) {
        temp.push(val)
      }
      return true
    })
    setListSourceV2([...temp])
  }, [obSource])

  useEffect(() => {
    getSourceV2()
  }, [])

  useEffect(() => {
    getListData()
  }, [paramListData])

  return (
    <div className='pooling-data-mapping'>
      <Layout>
        <Breadcrumb
          items={[
            {
              title: 'THACO AUTO CRM',
            },
            {
              title: <Link to="/">Khách hàng</Link>,
            },
            {
              title: <Link to="/customer/pooling-data">Gộp dữ liệu khách hàng</Link>,
            },
            {
              title: 'Thiết lập mapping dữ liệu',
            },
          ]}
        />

        <div className="t-title-back-button-right">
          <div className='title-page t-title-btn-back'>
            <Link to="/customer/pooling-data" className="icon-svg">
              <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.6564 7.80321H4.04305L10.3955 2.37463C10.4971 2.28713 10.4354 2.12463 10.3012 2.12463H8.69582C8.62508 2.12463 8.55796 2.14963 8.50536 2.19428L1.65045 8.04963C1.58767 8.10321 1.53732 8.16944 1.50281 8.24385C1.4683 8.31825 1.45044 8.39909 1.45044 8.48088C1.45044 8.56268 1.4683 8.64352 1.50281 8.71792C1.53732 8.79233 1.58767 8.85856 1.65045 8.91213L8.54526 14.8032C8.57247 14.8264 8.60512 14.8389 8.63959 14.8389H10.2993C10.4336 14.8389 10.4953 14.6746 10.3937 14.5889L4.04305 9.16035H14.6564C14.7363 9.16035 14.8016 9.09606 14.8016 9.01749V7.94606C14.8016 7.86749 14.7363 7.80321 14.6564 7.80321Z" fill="currentColor" fillOpacity="0.88" />
              </svg>
            </Link>
            Thiết lập mapping dữ liệu
          </div>

          <div
            className="btn-input btn-primary"
            onClick={onFormSubmit}
          >
            <div className="text">Lưu định nghĩa</div>
          </div>
        </div>

        <div className="pooling-data-mapping-content">
          <div className="title-page-cl-fabc16">
            <ExclamationCircleOutlined />
            <div className="text">Người dùng cần định nghĩa các biến (param) đã khai báo từ nguồn với CRM</div>
          </div>
          <div className="list-mapping">
            <div className="one-item">
              <div className="title">Biến trên CRM</div>
              <DragDropTitle listData={listData} onChange={(e: any) => {
                setListData([...e])
              }} />
            </div>

            <div className="list-add-mapping">
              <Form
                name="dynamic_form_add_mapping"
                onFinish={onFinish}
                ref={formRef}
                autoComplete="off"
                className='form-add-select'
              >
                <Form.List name="listadd">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space key={key} className='one-add-mapping' align="baseline">
                          { !obSource[`id${key}`] ? (
                            <SelectSystem
                              showListSelect={(e: any) => {
                                const temp = {} as any
                                temp[`id${key}`] = e
                                setObSource({...obSource, ...temp})

                                const formvals = formRef?.current.getFieldsValue()
                                formvals.listadd[name] = {
                                  source_id: e.value,
                                  position: name,
                                }
                                formRef?.current.setFieldsValue(formvals)
                              }}
                              listSource={listSourceV2}
                            />
                          ): (
                            <>
                              <div className="title-add">
                                <div className="text">{obSource[`id${key}`].label}</div>
                                <div className="icon">
                                  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.75 7.98413C2.75 8.09904 2.77263 8.21282 2.81661 8.31898C2.86058 8.42514 2.92503 8.5216 3.00628 8.60285C3.08753 8.6841 3.18399 8.74855 3.29015 8.79253C3.39631 8.8365 3.51009 8.85913 3.625 8.85913C3.73991 8.85913 3.85369 8.8365 3.95985 8.79253C4.06601 8.74855 4.16247 8.6841 4.24372 8.60285C4.32497 8.5216 4.38942 8.42514 4.43339 8.31898C4.47737 8.21282 4.5 8.09904 4.5 7.98413C4.5 7.86922 4.47737 7.75544 4.43339 7.64928C4.38942 7.54312 4.32497 7.44666 4.24372 7.36541C4.16247 7.28416 4.06601 7.21971 3.95985 7.17574C3.85369 7.13176 3.73991 7.10913 3.625 7.10913C3.51009 7.10913 3.39631 7.13176 3.29015 7.17574C3.18399 7.21971 3.08753 7.28416 3.00628 7.36541C2.92503 7.44666 2.86058 7.54312 2.81661 7.64928C2.77263 7.75544 2.75 7.86922 2.75 7.98413ZM7.125 7.98413C7.125 8.2162 7.21719 8.43875 7.38128 8.60285C7.54538 8.76694 7.76794 8.85913 8 8.85913C8.23207 8.85913 8.45462 8.76694 8.61872 8.60285C8.78281 8.43875 8.875 8.2162 8.875 7.98413C8.875 7.75207 8.78281 7.52951 8.61872 7.36541C8.45462 7.20132 8.23207 7.10913 8 7.10913C7.76794 7.10913 7.54538 7.20132 7.38128 7.36541C7.21719 7.52951 7.125 7.75207 7.125 7.98413ZM11.5 7.98413C11.5 8.2162 11.5922 8.43875 11.7563 8.60285C11.9204 8.76694 12.1429 8.85913 12.375 8.85913C12.6071 8.85913 12.8296 8.76694 12.9937 8.60285C13.1578 8.43875 13.25 8.2162 13.25 7.98413C13.25 7.75207 13.1578 7.52951 12.9937 7.36541C12.8296 7.20132 12.6071 7.10913 12.375 7.10913C12.1429 7.10913 11.9204 7.20132 11.7563 7.36541C11.5922 7.52951 11.5 7.75207 11.5 7.98413Z" fill="#000"/>
                                  </svg>
                                  <div className="ctrl-delete" onClick={() => {
                                    const temp = obSource
                                    delete temp[`id${key}`]
                                    setObSource({...obSource, ...temp})
                                    remove(name)
                                  }}>
                                    <DeleteFilled className='icon-delete'/>
                                    <div className="title-delete">Xóa hệ thống</div>
                                  </div>

                                </div>
                              </div>
                              <div className='list-data-x'>
                                {listData.map((val: any) => (
                                  <div key={val.id} className='item-column'>{ val.name }</div>
                                ))}
                              </div>
                              <Form.Item
                                {...restField}
                                name={[name, 'source_id']}
                                className='d-none'
                              >
                                <Select
                                  placeholder='Chọn nguồn'
                                  options={listSourceV2}
                                />
                              </Form.Item>
                            </>
                          )}
                        </Space>
                      ))}
                      {fields.length < listSourceV0.length &&
                        <Form.Item className='ctrl-btn-add'>
                          <Button className='btn btn-primary-none-bg' onClick={() => add()} block icon={<PlusOutlined />}>
                            Thêm điều kiện
                          </Button>
                        </Form.Item>
                      }
                    </>
                  )}
                </Form.List>
              </Form>
            </div>
          </div>
        </div>

      </Layout>
    </div>
  );
}

export default PoolingDataMapping;
