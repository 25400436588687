import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";
import { UnitLevel } from "../selectOptions/selectOptionsApi";

export interface IDepartmentalUnit {
    id: number
    code: string
    title: string
    view: number
    parentId: number
    businessUnitTitle: string
    level: number
}

export interface DataTypeDepartmentalUnit {
    codeDepartment: string;
    nameDepartment: string;
    businessUnitTitle: string
    nationwide: string;
    unit: string;
    region: string;
    key: number;
    view: number;
    parentId: number;
}
export interface IDetailDepartmentalUnit {
    id: number
    code: string
    title: string
    view: number
    parentId: number
    businessUnitId: number
    businessUnitTitle: string
}
// danh sách đơn vị phòng ban
export const getListDepartmentalUnit = async (
    limit?: number, pageNumber?: number, search?: string) => {
    const path = "departments/listDepartments"
    const params = {
        limit, pageNumber, search
    }
    const result: ResponseSuccessTotal<IDepartmentalUnit[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};
export const getListNationwide = async () => {
    const path = "departments/listNationwide"
    const result: ResponseSuccess<UnitLevel[]> = await axiosClient.get(
        path, {}
    );
    return result;
};
export const updateActiveDepartmentalUnit = async (
    id: number) => {
    const path = "departments/activeDepartments"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        path, params
    );
    return result;
};
export const addDepartments = async (
    code: string, title: string, businessUnitId: number, parentId: number
) => {
    const path = "departments/addDepartments"
    const params = {
        code, title, businessUnitId, parentId
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        path, params
    );
    return result;
};

export const deleteDepartments = async (
    id: number
) => {
    const path = "departments/deleteDepartments"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        path, { params }
    );
    return result;
};
export const detailDepartment = async (id: number) => {
    const path = "departments/infoDepartments"
    const params = { id }
    const result: ResponseSuccess<IDetailDepartmentalUnit> = await axiosClient.get(
        path, { params }
    );
    return result;
};


export const updateDepartment = async (
    id: number, code: string, title: string, businessUnitId: number, parentId: number) => {
    const path = "departments/editDepartments"
    const params = {
        id, code, title, businessUnitId, parentId
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        path, params
    );
    return result;
};
