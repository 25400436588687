export const API_URL = "https://api.tccrm.bdata.cloud/";
export const API_URL_v2 = "http://sync-thaco.buso.asia"
// export const API_URL = "http://192.168.1.50:5000/";

export const URL_CAR = "https://api.tccrm.bdata.cloud/uploads/car/"
export const URL_TEMPLATE = "https://api.tccrm.bdata.cloud/uploads/typeZNS/"
export const URL_CAR_CATEGORY = "https://api.tccrm.bdata.cloud/uploads/categoryCar/"
export const URL_CUSTOMER = "https://api.tccrm.bdata.cloud/uploads/customer/"
export const URL_RANK = "https://api.tccrm.bdata.cloud/uploads/rank/"

export const ICON_SUPPORT_CHANEL = "https://api.tccrm.bdata.cloud/uploads/iconSupportChanel/"//NGUÔN
export const ICON_SUPPORT_TYPE = "https://api.tccrm.bdata.cloud/uploads/iconSupport/"//loại
export const ICON_SATISFACTION_LEVEL = "https://api.tccrm.bdata.cloud/uploads/iconSatisfactionLevel/"//mức độ

export const URL_COUNTRY = "https://api.tccrm.bdata.cloud/uploads/country/"

export const URL_NETWORK = "https://api.tccrm.bdata.cloud/uploads/network/"
export const URL_CHANNEL = "https://api.tccrm.bdata.cloud/uploads/channel/"

export const LIMIT_EXPORT_EXCEL = 10000