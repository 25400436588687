import { paserParams } from "../../until";
import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";
import { SortType } from "../carLine/carLineApi";

export interface IGroup {
    id: number
    title: string
    description: string
    type: number
    status: number
    nameAdmin: string
    dateCreate: string
    tags: Tag[]
    countCustomer: number
}

export interface Tag {
    id: number
    title: string
}

export interface IGroupEdit {
    id: number
    title: string
    categoryCarId: number
    carLinesId: number
}

export interface IGroupEditRS {
    timestamp: string
    id: number
    description: string
    adminId: number
    title: string
    type: number
    role: number
    status: number
    delf: number
}

export interface IFilter {
    date?: string[];
    idCreator?: number;
    share?: number;
}

export interface ICustomerByFilter {
    id: number
    name: string
    phone: string
    rank: Rank
}

export interface Rank {
    id?: number
    title: string
    icon?: string
}


export interface InfoGroupEdit {
    infoGroup: InfoGroup
    listCustomer: ListCustomer
}

export interface InfoGroup {
    id: number
    title: string
    description: string
    type: number
    role: number
    nameAdmin: string
    dateCreate: string
    tags: Tag[]
    groupCustomerDetails: number
    status: number
    unitId?: number
}

export interface ListCustomer {
    total: number
    list: List[]
}

export interface List {
    id: number
    CCCD: string
    name: string
    birthday: string
    gender: number
    phone: string
    email: string
    rankTitle: Rank
}

export interface ICustomer {
    id: number
    name: string
    phone: string
}

export interface ICustomerTMP {
    id: number
    name: string
    phone: string
    checked: boolean
}

export interface ISLGroup {
    id: number
    title: string
    countCustomer: number
}

export interface IDuplicate {
    quantity: number
    percent: number
    group1: Group1
    group2: Group1
}

export interface Group1 {
    id: number
    title: string
}

export interface IFilterID {
    priceLowest: number
    priceHighest: number
    dateMaintenanceStart: string
    dateMaintenanceEnd: string
    birthdayStart: string
    birthdayEnd: string
    listCategoryCar: number[]
    listRank: number[]
    listAreaProvince: string[]
    gender: number[]
}

export const getListGroup = async (
    limit?: number, pageNumber?: number, adminId?: number, status?: number, dateStart?: string, dateEnd?: string
) => {
    const path = "groupCustomer/listGroup"
    const params = {
        limit, pageNumber, adminId, status, dateStart, dateEnd
    }
    const result: ResponseSuccessTotal<IGroup[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thông tin nhóm khách hàng
export const getInfoGroup = async (
    id: number, limit?: number, pageNumber?: number, search?: string
) => {
    const path = "groupCustomer/groupDetail"
    const params = {
        id, limit, pageNumber, search
    }
    const result: ResponseSuccess<InfoGroupEdit> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thêm mới nhóm khách hàng
export const addGroup = async (
    status: number, title: string, description: string, listTags: number[], type: number, unitId?: number, listCustomer?: number[]
) => {
    const url = "groupCustomer/addGroup";
    const params = {
        status, title, description, listTags, type, unitId, listCustomer
    }
    const result: ResponseSuccess<IGroupEditRS> = await axiosClient.post(
        url, paserParams(params, undefined)
    );
    return result;
}

//Thông tin bộ lọc
export const getInfoFilter = async (
    idGroup: number
) => {
    const path = "groupCustomer/infoFilter"
    const params = {
        idGroup
    }
    const result: ResponseSuccess<IFilterID> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Chỉnh sửa nhóm khách hàng
export const editGroup = async (
    id: number, title: string, description: string, status: number, listTags: Number[], type: number, unitId?: number
) => {
    const url = "groupCustomer/editGroup";
    const params = {
        id, title, description, status, listTags, type, unitId
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, paserParams(params, undefined)
    );
    return result;
}

//Chỉnh sửa điều kiện
export const editFilterGroup = async (
    groupCustomerId: number,
    listCategoryCar?: number[], priceLowest?: number, priceHighest?: number, dateMaintenanceStart?: string, dateMaintenanceEnd?: string,
    gender?: number[], birthdayStart?: string, birthdayEnd?: string, listArea?: number[], listProvince?: number[], listRank?: number
) => {
    const url = "groupCustomer/editFilter";
    const params = {
        groupCustomerId,
        listCategoryCar, priceLowest, priceHighest, dateMaintenanceStart, dateMaintenanceEnd,
        gender, birthdayStart, birthdayEnd, listArea, listRank
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, paserParams(params, undefined)
    );
    return result;
}

//Xóa nhóm khách hàng
export const deleteGroup = async (
    id: number
) => {
    const url = "groupCustomer/deleteGroup";
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        url, { params }
    );
    return result;
}


//Danh sách khách hàng lọc được
export const getlistCustomerByFilter = async (
    search?: string, categoryCarId?: number[], priceLowest?: number, priceHighest?: number, dateMaintenanceStart?: string, dateMaintenanceEnd?: string,
    gender?: number[], birthdayStart?: string, birthdayEnd?: string, areaId?: number[], rankId?: number[],
    provinceId?: number[]
) => {
    const path = "groupCustomer/listCustomerByFilter"
    const params = {
        search, categoryCarId, priceLowest, priceHighest, dateMaintenanceStart, dateMaintenanceEnd,
        gender, birthdayStart, birthdayEnd, areaId, rankId,
        provinceId
    }
    const result: ResponseSuccessTotal<ICustomerByFilter[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Danh sách khách hàng
export const getListCustomer = async (
    limit?: number, pageNumber?: number, search?: string
) => {
    const path = "groupCustomer/listCustomer"
    const params = {
        limit, pageNumber, search
    }
    const result: ResponseSuccessTotal<ICustomer[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};



//Thêm khách hàng vào nhóm khách hàng
export const addCustomer = async (
    id: number, listCustomer: number[]
) => {
    const url = "groupCustomer/addCustomer";
    const params = {
        id, listCustomer
    }
    const result: ResponseSuccess<IGroupEditRS> = await axiosClient.post(
        url, paserParams(params, undefined)
    );
    return result;
}

//Xóa khách hàng ra khỏi nhóm khách hàng
export const deleteCustomer = async (
    id: number, listCustomer: number[]
) => {
    const url = "groupCustomer/deleteCustomer";
    const params = {
        id, listCustomer
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        url, paserParams(params, undefined)
    );
    return result;
}

//Danh sách nhóm gộp
export const getListGroupAndGroup = async (
    idGroup1?: number
) => {
    const path = "groupCustomer/listGroupAndGroup"
    const params = {
        idGroup1
    }
    const result: ResponseSuccess<ISLGroup[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Đếm sự trùng lặp giữa hai nhóm
export const getCountDuplicate = async (
    idGroup1: number, idGroup2: number
) => {
    const path = "groupCustomer/countDuplicate"
    const params = {
        idGroup1, idGroup2
    }
    const result: ResponseSuccess<IDuplicate> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Gộp nhóm khách hàng
export const addGroupCombination = async (
    idGroup1: number, idGroup2: number, status: number, title: string, description: string, listTags: number[], type: number, unitId?: number
) => {
    const url = "groupCustomer/groupCombination";
    const params = {
        idGroup1, idGroup2, status, title, description, listTags, type, unitId
    }
    const result: ResponseSuccess<IGroupEditRS> = await axiosClient.post(
        url, paserParams(params, undefined)
    );
    return result;
}

