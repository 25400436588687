import { WarningOutlined } from "@ant-design/icons"
import { Checkbox, DatePicker, GetProp, Slider, SliderSingleProps, Table, TableColumnsType, TableProps, Tree, TreeDataNode, TreeProps } from "antd"
import { Link, useLocation, useNavigate } from "react-router-dom"
import BreadcrumbBlock from "../../../../../components/BreadcrumbBlock/BreadcrumbBlock"
import Layout from "../../../../../Layout"
import './style.scss'
import dayjs from 'dayjs';
import { dataGender } from "../../../../../services/data"
import useToast from "../../../../../hook/useToast"
import * as selectOptionsApi from "../../../../../api/selectOptions/selectOptionsApi"
import { useEffect, useState } from "react"
import { URL_CAR_CATEGORY } from "../../../../../env"
import { mixFnum, mixTreeIds } from "../../../../../services/mixin"
import useDebounce from "../../../../../hook/useDebounce"
import { ICustomerByFilter, addCustomer, editFilterGroup, getInfoFilter, getlistCustomerByFilter } from "../../../../../api/groupCustomer/groupCustomerApi"
import useThrottle from "../../../../../hook/useThrottle"
import { fomartListAreaAndListProvince } from "../../../../../until"
const { RangePicker } = DatePicker;

interface DataType {
  key: React.Key;
  idCard: number | null
  typeCard: string;
  name: string;
  phone: string;
}

const columns: TableColumnsType<DataType> = [

  {
    title: 'Hạng thành viên',
    dataIndex: 'typeCard',
    sorter: {
      compare: (a, b) => a.typeCard.length - b.typeCard.length,
      multiple: 1,
    },
    className: "w-166-px",
    render: (_, record) => (record.idCard
      ? (
        <div className="d-flex gap-10-px align-center">
          <img src='/images/exportImg/member_gold.svg' alt="gold" className="w-6 h-6" />
          <p className="text-sm font-regular text-344054">{record.typeCard}</p>
        </div>
      )
      : (<p className="text-sm text-98A2B3 font-regular">Chưa phát hành</p>))


  },
  {
    title: 'Họ và tên',
    dataIndex: 'name',
    className: "w-165-px",

  },

  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
    className: "w-106-px",
    align: "end"
  },

];
const marks: SliderSingleProps['marks'] = {
  0: '0',
  1000000000: '1 tỷ',
  3000000000: '3 tỷ',
  5000000000: '5tỷ',
};
const CreateGroup: React.FC = () => {
  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    gSLCarCompany()
    gSLArea()
    gSLRank()

    return () => {
      setDataFilter({
        categoryCar: [],
        price: [0, 5000000000],
        maintCal: [],
        gender: [],
        birthday: [],
        area: [],
        rank: [],
      } as any
      )
    }
  }, [])

  const navigate = useNavigate()
  const pushToast = useToast();

  const [dataFilter, setDataFilter] = useState({
    categoryCar: [],
    price: [0, 5000000000],
    maintCal: [],
    gender: [],
    birthday: [],
    area: [],
    rank: [],
  } as any)

  const [sLCategoryCar, setSLCategoryCar] = useState<selectOptionsApi.ICategoryCar[]>([])
  const [sLArea, setSLArea] = useState([])
  const [sLRank, setSLRank] = useState<selectOptionsApi.IRank[]>([])

  const [listCustomerF, setListCustomerF] = useState<DataType[]>([])

  const gInfoFilter = useDebounce(async () => {
    const result = await getInfoFilter(+id)
    if (result?.status) {
      const {
        listCategoryCar, priceLowest, priceHighest, dateMaintenanceStart, dateMaintenanceEnd,
        birthdayStart, birthdayEnd, listAreaProvince, listRank, gender
      } = result.data

      const maintCal = dateMaintenanceStart ? [dateMaintenanceStart, dateMaintenanceEnd] : []
      const birthday = birthdayStart ? [birthdayStart, birthdayEnd] : []

      const data = {
        categoryCar: listCategoryCar,
        price: [priceLowest, priceHighest],
        maintCal: maintCal,
        gender: gender,
        birthday: birthday,
        area: listAreaProvince,
        rank: listRank,
      }
      setDataFilter(data)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const glistCustomerByFilter = useDebounce(async () => {
    const {
      categoryCar, price, maintCal, gender, birthday, area, rank
    } = dataFilter
    const listArea = mixTreeIds(area)

    const result = await getlistCustomerByFilter(
      undefined, categoryCar, price[0], price[1], maintCal[0], maintCal[1],
      gender, birthday[0], birthday[1], listArea, rank
    )
    if (result?.status) {
      convertData(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const convertData = (data: any[]) => {
    const convertData = data.map((item) => ({
      key: item.id,
      idCard: item.rankTitle.id,
      typeCard: item.rankTitle.title,
      name: item.name,
      phone: item.phone
    }))
    setListCustomerF(convertData)
  }

  const gSLCarCompany = async () => {
    const result = await selectOptionsApi.getListCategoryCar()
    if (result?.status) {
      setSLCategoryCar(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const gSLArea = async () => {
    const result = await selectOptionsApi.getListArea()
    if (result?.status) {
      formatArea(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const formatArea = (data: any) => {
    const tempArea = [] as any
    data.map((val: any) => {
      const children = [] as any
      if (val.province) {
        val.province.map((val2: any) => {
          children.push({
            title: `${val2.title} (${mixFnum(val2.customerCount)})`,
            key: `${val.id}-${val2.id}`,
          })
          return true
        })
      }
      tempArea.push({
        title: `${val.title} (${mixFnum(val.countCustomer)})`,
        key: val.id,
        children
      })
      return true
    })
    setSLArea(tempArea)
  }

  const gSLRank = async () => {
    const result = await selectOptionsApi.getListRank()
    if (result?.status) {
      setSLRank(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const eFilterGroup = useThrottle(async () => {
    const {
      categoryCar, price, maintCal, gender, birthday, area, rank
    } = dataFilter
    const { ListArea, listProvince } = fomartListAreaAndListProvince(area)


    const result = await editFilterGroup(
      +id, categoryCar, price[0], price[1], maintCal[0], maintCal[1],
      gender, birthday[0], birthday[1], ListArea, listProvince, rank
    )
    if (result?.status) {
      addCustomersToTheGroup()
    } else {
      pushToast(result?.message, "warning")
    }

  }, 1000)

  const addCustomersToTheGroup = useThrottle(async () => {

    const listCustomer = listCustomerF.map(item => Number(item.key))
    const result = await addCustomer(id, listCustomer)
    if (result?.status) {
      pushToast(result?.message, "success")
      navigate(`/customer/group`)
    } else {
      pushToast(result.message, "warning")
    }
  }, 1000)


  const onChangeCategoryCar: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    setDataFilter({ ...dataFilter, categoryCar: [...checkedValues] })
  }

  const onChangePrice = (value: number[]) => {
    setDataFilter({ ...dataFilter, price: [...value] })
  }

  const onChangeMaintCal = (_: any, dateString: string[]) => {//ls bảo dương

    const isOK = dateString.every((item) => item.length > 0)
    if (isOK)
      setDataFilter({ ...dataFilter, maintCal: dateString })
    else {
      setDataFilter({ ...dataFilter, maintCal: [] })
    }
  };

  const onChangeGender: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    setDataFilter({ ...dataFilter, gender: [...checkedValues] })
  }

  const onChangeBirthday = (_: any, dateString: any) => {
    setDataFilter({ ...dataFilter, birthday: dateString })
  };

  const onCheckArea: TreeProps['onCheck'] = (checkedKeys, info) => {
    setDataFilter({ ...dataFilter, area: checkedKeys })
  };

  const onChangeRank: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    setDataFilter({ ...dataFilter, rank: [...checkedValues] })
  }

  const onChangeTB: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };


  useEffect(() => {
    if (id) {
      gInfoFilter()
    } else {
      navigate(-1)
    }
  }, [id])

  useEffect(() => {
    if (id) {
      glistCustomerByFilter()
    }
  }, [dataFilter])

  return (
    <Layout>

      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: <Link to="/customer/list">Khách hàng</Link>, },
          { title: <Link to="/customer/group">Quản lý nhóm khách hàng</Link>, },
          { title: 'Tạo nhóm mới', },
        ]}
        title='Tạo nhóm mới'
        backLink='/customer/group'
      />
      <div className="d-flex justify-between pt-7 pb-6">
        {id
          ?
          (
            <div className="d-flex align-center gap-3">
              <p className="font-medium fw-500 text-344054">Cập nhật điều kiện nhóm</p>
              <div className="d-flex align-center gap-2">
                <WarningOutlined className="text-base text-FF4D4F" />
                <p className="text-sm text-FF4D4F font-regular">(Danh sách khách hàng sẽ thay đổi theo điều kiện)</p>
              </div>
            </div>
          )
          : (
            <p className="font-medium fw-500 text-344054">Thiết lập điều kiện khách hàng để tạo nhóm</p>
          )
        }
        <button className="border-none bg-096DD9 text-white text-sm font-regular px-4 h-8 rounded-6-px"
          // onClick={addCustomersToTheGroup}
          onClick={eFilterGroup}
        >
          Cập nhật
        </button>
      </div>
      <div className="add-filter active">
        <div className="d-flex">
          <div className="content-add-filter t-scrollbar">
            <div className="col-add-filter">
              <div className="one-add-filter-block t-mb-24 t-scrollbar">
                <div className="col-title-add-filter">Thương hiệu sở hữu</div>
                <div className="list-add-filter-content">
                  <Checkbox.Group
                    value={dataFilter?.categoryCar}
                    onChange={onChangeCategoryCar}
                  >

                    {
                      sLCategoryCar && sLCategoryCar.map((item, i) =>
                        <Checkbox key={i}
                          className="one-add-filter-content"
                          value={item.id}
                        >
                          <div className="img-text">
                            <img src={`${URL_CAR_CATEGORY}${item.image}`} alt="" />
                            <div className="text">{item.title} ({mixFnum(item.historyCount)})</div>
                          </div>
                        </Checkbox>
                      )
                    }
                  </Checkbox.Group>
                </div>
              </div>

              <div className="one-add-filter-block t-mb-24">
                <div className="col-title-add-filter">Mức giá xe sở hữu</div>
                <div className="list-add-filter-content slider-price">
                  <Slider
                    min={0}
                    max={5000000000}
                    range marks={marks}
                    tooltip={{ open: false }}
                    defaultValue={dataFilter?.price}
                    value={dataFilter?.price}
                    onChange={onChangePrice}
                  />
                </div>
              </div>

              <div className="one-add-filter-block t-mb-24 t-pt-16">
                <div className="col-title-add-filter">Lịch sử bảo dưỡng</div>
                <div className="t-ctrl-date-picker-bg pt-6">
                  <RangePicker
                    className="rs-range-picker"
                    popupClassName="rs-range-picker-dropdown"
                    placeholder={['Chọn từ ngày', 'Đến ngày']}
                    value={dataFilter.maintCal.length > 1 ? [dayjs(dataFilter.maintCal[0]), dayjs(dataFilter.maintCal[1])] : null}
                    onChange={(value, dateString) => onChangeMaintCal(value, dateString)}
                  />
                </div>

              </div>
            </div>

            <div className="col-add-filter pl-8">
              <div className="one-add-filter-block t-mb-24">
                <div className="col-title-add-filter">Giới tính</div>
                <div className="list-select-2col">
                  <Checkbox.Group
                    value={dataFilter.gender}
                    onChange={onChangeGender}
                  >
                    {
                      dataGender.map((item, i) =>
                        <Checkbox
                          key={i}
                          className="one-add-filter-content"
                          value={item.value}
                        >
                          <div className="text">{item.title}</div>
                        </Checkbox>
                      )
                    }

                  </Checkbox.Group>
                </div>
              </div>

              <div className="one-add-filter-block t-mb-24 ">
                <div className="col-title-add-filter">Sinh nhật sắp tới</div>
                <div className="t-ctrl-date-picker-bg pt-6">
                  <RangePicker
                    className="rs-range-picker"
                    popupClassName="rs-range-picker-dropdown"
                    placeholder={['Chọn từ ngày', 'Đến ngày']}
                    value={dataFilter.birthday.length > 1 ? [dayjs(dataFilter.birthday[0]), dayjs(dataFilter.birthday[1])] : null}
                    onChange={(value, dateString) => onChangeBirthday(value, dateString)}
                  />
                </div>

              </div>

              <div className="one-add-filter-block t-mb-24">
                <div className="col-title-add-filter">Khu vực</div>
                <div className="list-select-2col tree-select">
                  <Tree
                    checkable
                    checkedKeys={dataFilter.area}
                    onSelect={onSelect}
                    onCheck={onCheckArea}
                    treeData={sLArea}
                    defaultExpandAll={true}
                  />
                </div>
              </div>

              <div className="one-add-filter-block">
                <div className="col-title-add-filter">Hạng thành viên</div>
                <div className="list-add-filter-content">
                  <Checkbox.Group
                    value={dataFilter.rank}
                    onChange={onChangeRank}
                  >
                    {
                      sLRank && sLRank.map((item, i) =>
                        <Checkbox
                          key={i}
                          className="one-add-filter-content" value={item.id}>
                          <div className="text">{item.title}</div>
                        </Checkbox>
                      )
                    }
                  </Checkbox.Group>
                </div>
              </div>
            </div>


          </div>
          <div className="w-630-px pl-8 border-left-EAECF0">
            <div className="">
              <div className="col-title-add-filter">
                {id ? (<p className="pb-4">Xem trước dữ liệu mẫu của nhóm</p>) : (<p>Thuộc tính khác</p>)}
              </div>
              {
                id
                  ?
                  (<div className="t-table-block">
                    <Table
                      rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
                      columns={columns}
                      dataSource={listCustomerF}
                      onChange={onChangeTB}
                      pagination={false}
                      showSorterTooltip={false}
                      className="w-fit-table border-end-table"
                    />
                  </div>)
                  :
                  (<div className="list-add-filter-content">
                    <Checkbox.Group >
                      <Checkbox className="one-add-filter-content" value="1">
                        <div className="text">Thuộc tính 1</div>
                      </Checkbox>
                      <Checkbox className="one-add-filter-content" value="2">
                        <div className="text">Thuộc tính 1</div>
                      </Checkbox>
                      <Checkbox className="one-add-filter-content" value="3">
                        <div className="text">Thuộc tính 1</div>
                      </Checkbox>
                      <Checkbox className="one-add-filter-content" value="4">
                        <div className="text">Thuộc tính 1</div>
                      </Checkbox>
                    </Checkbox.Group>
                  </div>)
              }

            </div>
          </div>
        </div>

      </div>

    </Layout>
  )
}
export default CreateGroup
