import React, { useEffect, useMemo, useRef, useState } from 'react';
import Layout from '../../Layout';
import { Breadcrumb, Input, Table, Pagination, ConfigProvider, notification, Switch, Form, Tooltip, Space, Button, Select, Flex, Spin } from 'antd';
import type { TableColumnsType, TableProps, TooltipProps } from 'antd';
import {
  EyeOutlined, EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  DownOutlined,
  CloseCircleOutlined,
  UserAddOutlined,
  BranchesOutlined,
  InfoCircleFilled,
  DeleteFilled,
  PlusOutlined,
} from '@ant-design/icons';
import type { SearchProps } from 'antd/es/input/Search';
import viVN from 'antd/locale/vi_VN';
import { Link } from 'react-router-dom';
import './PoolingDataSetup.scss'
import './PoolingDataDetail.scss'
import PopupShowData from './ComponentPoolingData/PopupShowData';
import ShowParam from './ShowParam';
import ModalParamCreate from './Modal/ModalParamCreate';
import ModalParamEdit from './Modal/ModalParamEdit';
import { datetimeFormat, mixGetV2, mixPostV2 } from '../../services/mixin';
import EmptyComponent from '../../components/EmptyComponent/EmptyComponent';
import ModalDeleteManager from '../../components/ModalDeleteManager';

interface DataType {
  id: number;
  key: number;
  name: string;
  maparam: string;
  date: string;
  status: number
}

const { Search } = Input
const PoolingDataSetup: React.FC = () => {
  const [isShowParam, setShowParam] = useState(false)
  const [openParamCreate, setOpenParamCreate] = useState(false)
  const [openParamEdit, setOpenParamEdit] = useState(false)

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Hành động',
      dataIndex: '',
      align: 'center',
      render: (text, record: any) => (
        <>
          <div className="t-hd-table">
            <Switch
              defaultChecked={record.status === 1}
              // checked={record.status === 1}
              className="rs-switch rs-switch--sm"
              onChange={(status) => updateStatusCondition(record.key, status)}
            />
            <div className="item-hd-table" onClick={() => setShowParam(true)}><span><EyeOutlined /></span></div>
            <div
              className="item-hd-table cl-1890FF cursor-pointer"
              onClick={() => {
                setIsCreateCondition(2)
                getDetail(record.key)
              }}
            ><EditOutlined /></div>
            <div
              className="item-hd-table dust-red-6 cursor-pointer"
              onClick={() => {setSelectedId(record.id); setShowDeleteModal(true)}}
            ><DeleteOutlined /></div>
          </div>
        </>
      ),
    },
    {
      title: 'Tên Điều kiện',
      dataIndex: 'name',
    },
    {
      title: 'Mô tả',
      dataIndex: 'maparam',
      align: 'left',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'date',
      align: 'right',
      sorter: {
        compare: (a: any, b: any) => a.date - b.date,
        multiple: 1,
      },
    },
  ];

  // const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
  //   console.log('selectedRowKeys changed: ', newSelectedRowKeys);
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  const onChangeTB: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  // const setShowNoti = () => {
  //   setShowData(false)
  //   openNotification()
  // };

  const [api, contextHolder] = notification.useNotification()
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Request success!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    })
  }

  const text = <span>Thông tin trùng lặp giữa các nguồn khi thỏa mãn tất cả điều kiện thiết lập bên dưới:</span>;
  const [arrow, setArrow] = useState<'Show' | 'Hide' | 'Center'>('Show');

  const mergedArrow = useMemo<TooltipProps['arrow']>(() => {
    if (arrow === 'Hide') {
      return false;
    }

    if (arrow === 'Show') {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const [isCreateCondition, setIsCreateCondition] = useState(0) // 0: default - 1: create - 2: edit
  const [formLoading, setFormLoading] = useState(false)
  const formRef:any = useRef(null)
  const onFormSubmit = () => {
    if (formRef?.current) {
      formRef.current.submit()
    }
  }

  const onFinish = async (values: any) => {
    setFormLoading(true)
    const { title, status, conditions } = values

    if (!conditions) {
      openNotification('error', 'Vui lòng thêm ít nhất 1 điều kiện!')
      setFormLoading(false)
      return
    }

    const moreMigrations = conditions.map((item: any) => ({
      more_migration_id: item?.more_migration_id ? item.more_migration_id : 0,
      source_column_warehouse_id: item.condition,
    }))
    const is_active = status ? 1 : 0

    let params: any = {
      name: title,
      is_active,
      more_migrations: JSON.stringify(moreMigrations),
      describe: '',
    }

    let res: any
    if (isCreateCondition === 1) {
      res = await mixPostV2('/api/v1/be/data_migration', params)
    }
    if (isCreateCondition === 2) {
      params['_method'] = 'PUT'
      res = await mixPostV2(`/api/v1/be/data_migration/${selectedId}`, params)
    }

    if (res.status) {
      openNotification('success', 'Tạo điều kiện gộp thành công!')
      setIsCreateCondition(0)
      setFormLoading(false)
      setSelectedId(null)
      getItems()
    } else {
      setFormLoading(false)
      openNotification('error', res.msg)
    }
  }

  // GET LIST
  const [tableLoading, setTableLoading] = useState(false)
  const [items, setItems] = useState({
    list: [],
    total: 0,
  })
  const getItems = async (searchName = '') => {
    setTableLoading(true)
    let url = '/api/v1/be/data_migrations?sort[]=id,desc&limit=1000000&page=1'
    if (searchName) {
      url += `&name=${searchName}`
    }
    const res = await mixGetV2(url)

    if (res.status) {
      const list = res.data.items.map((item: any) => ({
        ...item,
        key: item.id,
        date: datetimeFormat(item.created_at),
        maparam: item.describe,
        status: item.is_active,
      }))
      setItems({
        list: list,
        total: res.data.items.length
      })
      setTableLoading(false)
    }
  }
  const onSearch: SearchProps['onSearch'] = (value, _e, info) => getItems(value)
  // END GET LIST

  // GET DETAIL
  const [selectedId, setSelectedId] = useState<any>(null)
  const [detailLoading, setDetailLoading] = useState(false)
  const getDetail = async (id: number) => {
    setSelectedConditions([])
    setConditionList(conditions)
    setDetailLoading(true)
    setSelectedId(id)
    try {
      const res = await mixGetV2(`/api/v1/be/data_migration/${id}`)

      if(res.status) {
        const moreMigrations:any = []
        const selectedIds:any = []
        // eslint-disable-next-line array-callback-return
        res.data.more_migration.map((item: any) => {
          moreMigrations.push({
            ...item,
            condition: item.source_column_warehouse_id.toString(),
          })
          selectedIds.push(item.source_column_warehouse_id.toString())
        })
        const fieldsValue = {
          title: res.data.name,
          status: res.data.is_active,
          conditions: moreMigrations
        }
        formRef?.current.setFieldsValue(fieldsValue)
        setSelectedConditions(selectedIds)
        setDetailLoading(false)
      }
    } catch (error) {

    }
  }
  // END GET DETAIL

  // GET CONDITION LIST
  const [selectedConditions, setSelectedConditions] = useState<any>([])
  const [conditions, setConditions] = useState<any>([])
  const [conditionList, setConditionList] = useState<any>([])
  const getConditionList = async (type: string = '') => {
    try {
      const res = await mixGetV2('/api/v1/be/source_column_warehouses?is_active=1')
      if (res.status) {
        const result = res.data.items.map((item: any) => ({
          value: item.id.toString(),
          label: item.name,
        }))
        setConditions(result)
        conditionListFunc(result)
      }
    } catch (error) {

    }
  }

  const conditionListFunc = (list?: any) => {
    const arr = list ? list : conditions
    const result: any = []
    // eslint-disable-next-line array-callback-return
    console.log(arr, selectedConditions)
    arr.map((item: any) => {
      const isExist = selectedConditions.includes(item.value)
      if (!isExist) {
        result.push(item)
      }
    })
    setConditionList(result)
  }
  // END GET CONDITION LIST

  useEffect(() => {
    getItems()
    getConditionList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onValuesChange = (changedFields: any, allFields: any) => {
    const conditionsField = allFields?.conditions

    if (conditionsField?.length > 0) {
      const conditionIds = conditionsField.map((item: any) => item?.condition)
      conditionListFunc()
      setSelectedConditions(conditionIds)
    }
  }

  // UPDATE STATUS CONDITION
  const updateStatusCondition = async (id: number, status: boolean) => {
    const params = {
      is_active: status,
      _method: 'PUT'
    }

    try {
      const res = await mixPostV2(`/api/v1/be/data_migration/${id}`, params)

      if (res.status) {
        openNotification('success', 'Cập nhật trạng thái thành công!')
        getItems()
      }
    } catch (error) {}
  }
  // END UPDATE STATUS CONDITION

  // DELETE CONDITION
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const deleteCondition = async () => {
    const params = {
      _method: 'DELETE'
    }

    try {
      const res = await mixPostV2(`/api/v1/be/data_migration/${selectedId}`, params)

      if (res.status) {
        openNotification('success', 'Xóa điều kiện gộp thành công!')
        getItems()
        setShowDeleteModal(false)
        setSelectedId(null)
        setIsCreateCondition(0)
      }
    } catch (error) {}
  }
  // END DELETE CONDITION

  const newOptions = (list?: any) => {
    const arr = list ? list : conditions
    const result: any = []
    // eslint-disable-next-line array-callback-return
    arr.map((item: any) => {
      const isExist = selectedConditions.includes(item.value)
      if (!isExist) {
        result.push(item)
      }
    })
    setConditionList(result)
  }

  return (
    <div className='pooling-data-auto-detail customer-list'>
      {contextHolder}
      {/* <Helmet>
        <title>THACO AUTO CRM</title>
      </Helmet> */}
      <Layout>
        <Breadcrumb
          items={[
            {
              title: 'THACO AUTO CRM',
            },
            {
              title: <a href="/customer/list">Khách hàng</a>,
            },
            {
              title: <a href="/customer/pooling-data">Gộp dữ liệu khách hàng</a>,
            },
            {
              title: 'Điều kiện gộp',
            },
          ]}
        />

        <div className="t-title-back-button-right">
          <div className='title-page t-title-btn-back'>
            <Link to="/customer/pooling-data" className="icon-svg">
              <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.6564 7.80321H4.04305L10.3955 2.37463C10.4971 2.28713 10.4354 2.12463 10.3012 2.12463H8.69582C8.62508 2.12463 8.55796 2.14963 8.50536 2.19428L1.65045 8.04963C1.58767 8.10321 1.53732 8.16944 1.50281 8.24385C1.4683 8.31825 1.45044 8.39909 1.45044 8.48088C1.45044 8.56268 1.4683 8.64352 1.50281 8.71792C1.53732 8.79233 1.58767 8.85856 1.65045 8.91213L8.54526 14.8032C8.57247 14.8264 8.60512 14.8389 8.63959 14.8389H10.2993C10.4336 14.8389 10.4953 14.6746 10.3937 14.5889L4.04305 9.16035H14.6564C14.7363 9.16035 14.8016 9.09606 14.8016 9.01749V7.94606C14.8016 7.86749 14.7363 7.80321 14.6564 7.80321Z" fill="currentColor" fillOpacity="0.88" />
              </svg>
            </Link>
            Điều kiện gộp
          </div>

          <Button
            icon={<BranchesOutlined />}
            disabled={isCreateCondition > 0}
            type='primary'
            className="rs-button rs-button--sm"
            onClick={() => setIsCreateCondition(1)}
          >Thêm bộ điều kiện</Button>
        </div>

        <div className="colum2-1fr-400">
          <div className="left-content">
            {/* Fillter block */}
            <div className='filter-block t-mb-18'>
              <div className='t-search-block w-100'>
                <Search placeholder="Tìm kiếm tên" onSearch={onSearch} />
              </div>

              {/* <div className='filter-select'>
                <div
                  className='btn-select-show'
                >
                  <span className="content-select" onClick={() => setActive(true)} >{isShowValue}</span>
                  <div
                    className={`icon-down ${showSelectFilter ? "active" : ""}`}
                    onClick={() => setActive(true)}
                  >
                    <DownOutlined />
                  </div>
                  <div
                    className={`icon-close ${showSelectFilter ? "active" : ""}`}
                    onClick={() => handleClearFilter('Chọn bộ lọc')}
                  >
                    <CloseCircleOutlined />
                  </div>
                </div>
                <div className={`list-filter ${showSelectFilter ? "active" : ""}`}>
                  <div className="list-item">
                    <div className="item-select" onClick={() => handleClearFilter('Chọn hệ thống 1')} >Chọn hệ thống 1</div>
                    <div className="item-select" onClick={() => handleClearFilter('Chọn hệ thống 2')} >Chọn hệ thống 2</div>
                    <div className="item-select" onClick={() => handleClearFilter('Chọn hệ thống 3')} >Chọn hệ thống 3</div>
                    <div className="item-select" onClick={() => handleClearFilter('Chọn hệ thống 4')} >Chọn hệ thống 4</div>
                  </div>
                </div>
              </div> */}

              {/* <div
                className="btn-input btn-primary"
                onClick={() => setOpenParamCreate(true)}
              >
                <div className="text">Tạo mới</div>
              </div> */}
            </div>

            <div className="t-table-block t-table-pooling-data-auto-detail">
              <Table
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
                // rowSelection={rowSelection}
                columns={columns}
                // dataSource={data}
                dataSource={items.list}
                onChange={onChangeTB}
                pagination={false}
                showSorterTooltip={false}
                loading={tableLoading}
              />

              <ConfigProvider locale={viVN}>
                <div className="pagination-block">
                  <Pagination
                    total={items.total}
                    align="center"
                    showSizeChanger
                    showQuickJumper
                    showTotal={(total) => `Tổng cộng ${total} trang`}
                    locale={{ items_per_page: 'trang' }}
                  />
                </div>
              </ConfigProvider>
            </div>
          </div>
          <div className="right-content">
            <div className="title-add-filter">
              <span>Chi tiết điều kiện gộp</span>
            </div>
            <div className="content-filter-setting">
              {
                isCreateCondition !== 0
                  ? (
                    <Spin spinning={detailLoading}>
                      <Form
                        ref={formRef}
                        name="dynamic_form_add_seclect"
                        className="form-block d-none-rq form-benefit"
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        layout="vertical"
                        onValuesChange={onValuesChange}
                        onFinish={onFinish}
                      >
                        <Form.Item
                          label="Tên điều kiện:"
                          name="title"
                          rules={[{ required: true, message: 'Nhập tên điều kiện!' }]}
                        >
                          <Input placeholder="Nhập tên điều kiện" />
                        </Form.Item>
                        <div className="status-add">
                          <label>Trạng thái</label>
                          <Form.Item
                            name="status"
                            style={{ marginBlockEnd: 0 }}
                          >
                            <Switch
                              className="rs-switch rs-switch--sm"
                            />
                          </Form.Item>
                        </div>

                        <Form.List name="conditions">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name, ...restField }) => (
                                <Space key={key} className='w-100' align="baseline">
                                  <DeleteFilled className='icon-delete' onClick={() => remove(name)} />
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'condition']}
                                    rules={[{ required: true, message: 'Vui lòng chọn điều kiện!' }]}
                                    shouldUpdate
                                  >
                                    <Select
                                      options={conditionList}
                                      className='rs-select rs-select--neutral-gray-100'
                                      popupClassName='rs-select-dropdown'
                                      placeholder="Chọn hệ thống"
                                      showSearch
                                      optionFilterProp="label"
                                      onFocus={() => newOptions()}
                                    />
                                  </Form.Item>
                                </Space>
                              ))}
                              <Form.Item>
                                <Button className='btn btn-primary-none-bg' type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  Thêm điều kiện
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </Form>
                    </Spin>
                  )
                  : <EmptyComponent content='Chưa có thông tin điều kiện gộp' />
              }
            </div>

            <div className="footer-filter-block">
              <div className="btn-block">
                <Button
                  className="rs-button rs-button--sm"
                  disabled={isCreateCondition === 0}
                  onClick={() => setIsCreateCondition(0)}
                >Hủy</Button>
                <Button
                  type={isCreateCondition === 0 ? 'default' : 'primary'}
                  className="rs-button rs-button--sm"
                  disabled={isCreateCondition === 0}
                  loading={formLoading}
                  onClick={onFormSubmit}
                >{ isCreateCondition === 2 ? 'Lưu' : 'Tạo' }</Button>
              </div>
            </div>
          </div>
        </div>

        {
          isShowParam
            ? <ShowParam
              className="show"
              closeModal={() => setShowParam(!isShowParam)}
            />
            : null
        }

        <ModalParamCreate
          isOpen={openParamCreate}
          closeModal={() => setOpenParamCreate(!openParamCreate)} data={[]}
        />

        <ModalParamEdit
          isOpen={openParamEdit}
          closeModal={() => setOpenParamEdit(!openParamEdit)} data={[]}
        />
      </Layout>

      <ModalDeleteManager
        isOpen={showDeleteModal}
        onConfirm={deleteCondition}
        closeModal={() => setShowDeleteModal(false)}
        title="Bạn có chắc chắn xóa bộ điều kiện gộp này?"
        titleDelete="Xóa"
        content="Bộ điều kiện gộp và dữ liệu liên quan sẽ bị xóa vĩnh viễn không thể khôi phục"/>
    </div>
  );
}

export default PoolingDataSetup;
